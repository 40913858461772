import * as React from "react";
import BaseComponent from "components/BaseComponent";
import FormSectionHeading from "components/form/Sections/FormSectionHeading";
import {
    DataTable,
    DataTableBody,
    DataTableRow,
    DataTableRowColumn,
    DataTableRowHeaderColumn
} from "components/DataTable";
import EmptyDataTableRow from "shared/EmptyDataTableRow";
import {DataBaseComponentState} from "components/DataBaseComponent";
import {HostedInstanceResource} from "client/resources/hostedInstanceResource";
import { EnvironmentVariable } from "client/repositories/instanceEnvironmentVariableRepository";
import InstanceEnvironmentVariableValue from "../../settings/instanceEnvironmentVariables/InstanceEnvironmentVariableValue";
import Callout, {CalloutType} from "components/Callout";

interface Props extends DataBaseComponentState {
    redirectToInstanceTasks: () => Promise<boolean>;
    instanceAssignedEnvironmentVariables: EnvironmentVariable[];
    instance: HostedInstanceResource; 
}

export class InstanceAssignedEnvironmentVariableDetails extends BaseComponent<Props, never> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const instanceVars = this.props.instanceAssignedEnvironmentVariables;

        return <><FormSectionHeading title="Assigned Environment Variables"/>
            { instanceVars.length > 0 ? this.callout() : null}
            <DataTable>
                <DataTableBody>
                    {this.variableRows()}
                </DataTableBody>
            </DataTable>
        </>;
    }

    private callout() {
        return <Callout type={CalloutType.Information}>These will replace Environment Variables with same Name</Callout>
    }

    private variableRows() {
        const instanceVars = this.props.instanceAssignedEnvironmentVariables;

        if (instanceVars.length == 0) {
            return <EmptyDataTableRow message="No instance assigned envrionment variables"/>;
        }

        return instanceVars.map((item, i) => {
            return <DataTableRow key={i}>
                <DataTableRowHeaderColumn>{item.Name}</DataTableRowHeaderColumn>
                <DataTableRowColumn>
                    <InstanceEnvironmentVariableValue value={item.Value} />
                </DataTableRowColumn>
            </DataTableRow>
        });
    }
}