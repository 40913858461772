import * as React from "react";
import {Component} from "react";
import {ActionButtonType, OpenDeleteDialogButton} from "components/Button";
import Callout, {CalloutType} from "components/Callout";

interface Props {
    stepDescription: string;
    onConfirm(): Promise<boolean>;
}

export default class MoveToStepConfirmationDialogButton extends Component<Props> {
    
    constructor(props: Props) {
        super(props);
    }
    
    render() {
        return (
            // We're using OpenDeleteDialogButton component instead of creating a new generic one in order to remain 
            // compatible with OctopusServer components so we can migrate easily to the expected FrontEndPlatform components. 
            // See https://trello.com/c/JCCeR07F for more details.
            <OpenDeleteDialogButton
                type={ActionButtonType.Secondary}
                key={"move to step dialog"}
                label="Move to Here"
                disabled={false}
                dialogTitle={`Are you sure you want to move to this step?`}
                dialogButtonLabel="Move"
                dialogButtonBusyLabel="Moving..."
                onDeleteClick={() => this.props.onConfirm()}>
                <Callout title="Use with caution!" type={CalloutType.Danger}>
                    Moving to step <strong>"{this.props.stepDescription}"</strong> can cause <strong>data loss or corruption!</strong><br/>
                    Make sure that you fully understand the implications of doing this step out of order before proceeding
                </Callout>
            </OpenDeleteDialogButton>
        );
    }
}
