import {DataBaseComponent, DataBaseComponentState} from "../../components/DataBaseComponent";
import {ReefResource} from "../../client/resources/reefResource";
import SaveDialogLayout from "../../components/DialogLayout/SaveDialogLayout";
import {required, Select, Text} from "../../components/form";
import repository from "../../client/repository";
import * as React from "react";
import { CreateKubernetesClusterRequest } from "client/resources/createKubernetesClusterRequest";
import { VirtualMachineType } from "client/resources/virtualMachineType";
import {Number} from "shared/Number";
import _ = require("lodash");
import {Checkbox} from "@octopusdeploy/design-system-components";

interface Props {
    existingReef: ReefResource;
    afterCreate: () => void;
}

interface State extends DataBaseComponentState {
    model: CreateKubernetesClusterRequest;
}

class CreateKubernetesClusterDialog extends DataBaseComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            model: {
                AvailabilityZones: null,
                ClusterVersion: null,
                NodeDiskSizeInGB: null,
                NodeVmType: null,
                HostedScriptsVersion: null,
                NodePoolSnapshotResourceId: null,
                OverrideSupportedLimit: null,
            }
        };
    }

    async componentDidMount() {
        this.populateWithMostRecentCluster();
    }

    render() {
        const model = this.state.model;
        return <SaveDialogLayout title={`Add new Kubernetes Cluster`}
                                 onSaveClick={() => this.createKubernetesCluster()}
                                 errors={this.state.errors}
                                 saveButtonLabel={`Add`}
                                 busy={this.state.busy}>
            <Text label="KubernetesClusterVersion"
                  value={model.ClusterVersion}
                  onChange={value => this.modifyModelState({ClusterVersion: value})}
                  validate={required("Please enter the kubernetes cluster version to use for this reef")}
                  error={this.getFieldError("KubernetesClusterVersion")}
            />
            <Select value={_.toString(model.NodeVmType)}
                    onChange={(value: string) => this.modifyModelState({NodeVmType: (value as VirtualMachineType)})}
                    items={Object.entries(VirtualMachineType).map(e => ({text: e[0], value: e[0]}))}
                    fieldName="KubernetesNodeVmType"
                    hintText="The VM type to use for K8 nodes"
            />
            <Number label="KubernetesNodeDiskSizeInGB"
                    value={model.NodeDiskSizeInGB}
                    min={0}
                    onChange={value => this.modifyModelState({NodeDiskSizeInGB: value})}
                    validate={required("Enter the Azure VM size to use for the Kubernetes nodes. Changing this value will require the cluster to be recreated.")}
                    error={this.getFieldError("KubernetesNodeDiskSizeInGB")}
            />
            <Number label="AvailabilityZones"
                    value={model.AvailabilityZones}
                    min={1}
                    max={2}
                    onChange={value => this.modifyModelState({AvailabilityZones: value})}
                    validate={required("Enter the number of availability zones to use for the nodes in the Kubernetes cluster.")}
                    error={this.getFieldError("AvailabilityZones")}
            />
            <Text label="HostedScriptsVersion"
                  value={model.HostedScriptsVersion}
                  onChange={value => this.modifyModelState({HostedScriptsVersion: value})}
                  validate={required("Please enter the Hosted Scripts version for this Kubernetes Cluster")}
                  error={this.getFieldError("HostedScriptsVersion")}
            />
            <Text label="NodePool Snapshot Resource Id"
                  value={model.NodePoolSnapshotResourceId}
                  onChange={value => this.modifyModelState({NodePoolSnapshotResourceId: value})}
                  error={this.getFieldError("NodePool Snapshot Resource Id")}
            />
            <Checkbox label="Override supported cluster limit"
                  value={model.OverrideSupportedLimit}
                  onChange={value => this.modifyModelState({OverrideSupportedLimit: value})}
                  error={this.getFieldError("Override supported cluster limit")}
            />
        </SaveDialogLayout>;
    }

    private modifyModelState(createResource: Partial<CreateKubernetesClusterRequest>): void {
        this.setState((prevState: State) => ({model: {...prevState.model, ...createResource}}));
    }

    private async createKubernetesCluster(): Promise<boolean> {
        return await this.doBusyTask(async () => {
            await repository.Reefs.createKubernetesCluster(this.props.existingReef.Id, this.state.model);
            this.props.afterCreate();
        });
    }

    private populateWithMostRecentCluster(): void {
        const mostRecentCluster = this.props.existingReef.KubernetesClusters[this.props.existingReef.KubernetesClusters.length - 1];

        if (mostRecentCluster !== undefined) {
            this.setState({
                model: {
                    ClusterVersion: mostRecentCluster.ClusterVersion,
                    HostedScriptsVersion: mostRecentCluster.HostedScriptsVersion,
                    AvailabilityZones: mostRecentCluster.AvailabilityZones,
                    NodeDiskSizeInGB: mostRecentCluster.NodeDiskSizeInGB,
                    NodeVmType: mostRecentCluster.NodeVmType,
                    NodePoolSnapshotResourceId: null,
                    OverrideSupportedLimit: false,
                }
            });
        }
    }
}

export default CreateKubernetesClusterDialog;
