export enum VirtualMachineType {
    Standard_B4ms = "Standard_B4ms",
    Standard_B8ms = "Standard_B8ms",
    Standard_D4s_v4 = "Standard_D4s_v4",
    Standard_D8s_v4 = "Standard_D8s_v4",
    Standard_D16s_v4 = "Standard_D16s_v4",
    Standard_D32s_v4 = "Standard_D32s_v4",
    Standard_E4s_v4 = "Standard_E4s_v4",
    Standard_E8s_v4 = "Standard_E8s_v4",
    Standard_E16s_v4 = "Standard_E16s_v4",
    Standard_E20s_v4 = "Standard_E20s_v4",
    Standard_E32s_v4 = "Standard_E32s_v4",
    Standard_F8s_v2 = "Standard_F8s_v2",
    Standard_F16s_v2 = "Standard_F16s_v2",
}