import React, {FC} from "react";
import {css} from "@emotion/css";
import {colorScales} from "@octopusdeploy/design-system-tokens";
import {EnvironmentName} from "client/resources/environmentName";


const bannerStyle = css`
    color: ${colorScales.white};
    font-size: 0.8125rem;
    height: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    a, a:hover {
        color: ${colorScales.white};
    }
`;

const productionStyle = css`
    ${bannerStyle};
    background-color: ${colorScales.purple["600"]};
`;

const testStyle = css`
    ${bannerStyle};
    background-color: ${colorScales.orange["400"]};
`;

const developmentStyle = css`
    ${bannerStyle};
    background-color: ${colorScales.green["400"]};
`;

function getEnvironmentStyle(name: EnvironmentName): string {
    switch (name) {
        case EnvironmentName.Production:
            return productionStyle;
        case EnvironmentName.Preprod:
        case EnvironmentName.Test:
            return testStyle;
        case EnvironmentName.Development:
            return developmentStyle;
    }
}

interface EnvironmentProps {
    name?: EnvironmentName;
}

const Environment: FC<EnvironmentProps> = ({name}) => {
    if (!name) {
        return <span>...</span>;
    }

    return <div className={getEnvironmentStyle(name)}>
        {name}
    </div>;
};

export default Environment;