import * as React from "react";
import DataBaseComponent, {DataBaseComponentState} from "components/DataBaseComponent";
import PaperLayout from "components/PaperLayout";
import repository from "client/repository";
import SimpleDataTable from "components/SimpleDataTable";
import {FriendlyLocalTime} from "shared/FriendlyLocalTime";
import {bytesToMegaBytes} from "../../storageConversions";
import OverflowMenu from "components/Menu/OverflowMenu";
import {DiagnosticFileResource} from "client/resources/diagnosticFileResource";
import {DownloadDiagnosticFile} from "./downloadDiagnosticFileDialog";
import Callout, { CalloutType } from "components/Callout";

interface Props {
    instanceId: string;
    breadcrumbTitle: string;
}

interface State extends DataBaseComponentState {
    files?: DiagnosticFileResource[];
}

class DataTable extends SimpleDataTable<DiagnosticFileResource> {
}

class InstanceDiagnosticFiles extends DataBaseComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
        };
    }

    async componentDidMount() {
        await this.loadData();
    }

    render() {
        const files = this.state.files;
        return (
            <PaperLayout busy={this.state.busy} errors={this.state.errors} title={files && "Diagnostic Files"} breadcrumbTitle={this.props.breadcrumbTitle}>
                <Callout type={CalloutType.Warning} title={"Warning"}>
                    Please delete Diagnostic Files once downloaded. Note: All Diagnostic Files will be automatically deleted after 7 days.
                </Callout>
                <DataTable
                    data={this.state.files}
                    onRow={(file: DiagnosticFileResource) => this.buildRow(file)} onRowOverflowMenu={(file: DiagnosticFileResource) => this.getOverflowMenu(file)}
                    headerColumns={["Name", "Created", "Size In MB"]}
                    onEmpty={<div>No files found</div>}/>
            </PaperLayout>
        );
    }

    buildRow(file: DiagnosticFileResource) {
        return [
            file.Name,
            <FriendlyLocalTime time={file.Created} />,
            bytesToMegaBytes(file.SizeInBytes)
        ];
    }

    loadData = async () => {
        await this.doBusyTask(async () => {
            this.setState({ files: await repository.HostedInstances.getDiagnosticFiles(this.props.instanceId) });
        });
    }

    private GetDownloadLink(file: DiagnosticFileResource) {
        return <DownloadDiagnosticFile 
            instanceId={this.props.instanceId} 
            file={file}
            onSaveClick={async (url) => {window.open(url); return true;}}
        />
    }
    private getOverflowMenu(file: DiagnosticFileResource) {

        return [
            OverflowMenu.dialogItem("Download", this.GetDownloadLink(file)),
            OverflowMenu.deleteItem(
                "Delete",
                "Are you sure you want to delete this diagnostic file?",
                () => this.deleteDiagnosticFile(file),
                <>
                    <p>Do you wish to continue?</p>
                </>)
        ];
    }

    private deleteDiagnosticFile(file: DiagnosticFileResource) {
        return this.doBusyTask(async () => {
            await repository.HostedInstances.deleteDiagnosticFile(this.props.instanceId, file);
            await this.loadData();
        });
    }
}

export default InstanceDiagnosticFiles;