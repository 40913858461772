import { BetterUptimeStatus } from "client/resources/betterUptimeStatus";
import {UpgradeStrategyType} from "client/resources/upgradeStrategy";
import {InstanceStatus} from "../../client/resources/instanceStatus";

export const instancesRouteLinks = {
    root: "/instances",
    filtered,
    create: "/instances/create",
    createBranch: "/instances/create-branch",
    bulkChangeUpgradeStrategy: "/instances/upgrade-strategy",
    moveHostedInstances: "/instances/move-hosted-instances",
    searchTaskLog: "/instances/task-log/search",
    gatherTaskLogMetrics: "/instances/task-log/metrics",

  instance: (instanceId: string) => ({
        root: `/instances/${instanceId}`,
        liveInformation: `/instances/${instanceId}/live-information`,
        supportUsers: `/instances/${instanceId}/support-users`,
        tasks: `/instances/${instanceId}/tasks`,
        upgradeHistory: `/instances/${instanceId}/upgrade-history`,
        secretRotationHistory: `/instances/${instanceId}/secret-rotation-history`,
        diagnosticFiles: `/instances/${instanceId}/diagnostic-files`,
        workers: `/instances/${instanceId}/workers`
    })
};

function filtered(filter: { status?: InstanceStatus[], betterUptimeStatus?: BetterUptimeStatus, isInPool?: boolean, version?: string, envVariable?: string[], upgradeStrategy?: UpgradeStrategyType[], shouldBeMonitored?: boolean }) {
    const filters = [];

    if (filter.status) {
        filter.status.forEach(value => filters.push(`statuses=${value}`));
    }

    if(filter.envVariable){
        filter.envVariable.forEach(value => filters.push(`envVariable=${value}`))
    }
    
    if (filter.version) {
        filters.push(`version=${filter.version}`);
    }

    if (filter.betterUptimeStatus) {
        filters.push(`betterUptimeStatus=${filter.betterUptimeStatus}`);
    }

    if (filter.isInPool !== undefined) {
        filters.push(`isInPool=${filter.isInPool}`);
    }

    if (filter.upgradeStrategy) {
        filter.upgradeStrategy.forEach(value => filters.push(`upgradeStrategies=${value}`));
    }

    if (filter.shouldBeMonitored !== undefined) {
        filters.push(`shouldBeMonitored=${filter.shouldBeMonitored}`);
    }

    return `/instances?${filters.join("&")}`;
}