import moment, { Moment } from "moment";

const RELATIVE_TIMESTAMP_THRESHOLD = moment.duration(1, "hour");

export const friendlyLocalTime = (fromDate: Moment, toDate: Moment): string => {
    const relativeCutoff = toDate.clone().subtract(RELATIVE_TIMESTAMP_THRESHOLD);

    const midnight = toDate.clone().startOf("day");

    if (fromDate.isAfter(relativeCutoff)) {
        return fromDate.from(toDate);
    }
    if (fromDate.isSameOrAfter(midnight)) {
        return fromDate.format("hh:mm A");
    }
    return fromDate.format("Y-MM-DD hh:mm A");
};
