import * as React from "react";
import DeleteDialogLayout from "components/DialogLayout/DeleteDialogLayout";
import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent";
import repository from "client/repository";
import { InstanceTaskLockResource } from "client/resources/instanceTaskLockResource";

interface Props {
    lock: InstanceTaskLockResource;
    onDelete: () => Promise<void>;
}

type State = DataBaseComponentState;

class DeleteInstanceTaskLockDialog extends DataBaseComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    render() {
        return <DeleteDialogLayout title={"Delete Instance Task Lock"}
                                 busy={this.state.busy}
                                 errors={this.state.errors}
                                 onDeleteClick={() => this.delete()}>
        <p>Are you sure you want to delete this instance task lock for instance '{this.props.lock.HostedInstanceId}'?</p>
        </DeleteDialogLayout>;
    }

    private delete() {
        return this.doBusyTask(async () => {
            await repository.HostedInstances.deleteInstanceTaskLock(this.props.lock);
            await this.props.onDelete();
        });
    }
}

export default DeleteInstanceTaskLockDialog;