// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* This file is DEPRECATED
   We are moving to the Octopus Design System
   https://www.octopus.design
  
   When changing component styles:
   1. Do not use variables from this file
   2. Aim to use Emotion \`css\` + themeTokens.colors
       - see Dashboard.tsx for example
   3. If you need to use Less, reference CSS variables
       - see index.ejs for example
       - for full list see https://github.com/OctopusDeploy/OctopusDeploy/blob/main/frontend/packages/design-system-tokens/src/generated/themeTokens.ts
   4. Remove variables from this file when they are no longer used
 */
.style_breadcrumb__KIhPe {
  font-size: 0.875rem;
  /* mark.siedle: This matches the dataTableColumnHeader, so this has a consistent look. */
  line-height: 1rem;
  color: var(--colorTextSubtle);
}
.style_breadcrumb__KIhPe .style_breadcrumbLink__mr95i {
  color: var(--colorTextPrimary);
  text-decoration: none;
}
.style_breadcrumb__KIhPe .style_breadcrumbLink__mr95i > :active,
.style_breadcrumb__KIhPe .style_breadcrumbLink__mr95i > :visited {
  color: var(--colorTextPrimary);
}
.style_breadcrumb__KIhPe .style_breadcrumbLink__mr95i:hover {
  color: var(--colorTextLinkDefault);
}
`, "",{"version":3,"sources":["webpack://./app/components/Breadcrumb/style.less"],"names":[],"mappings":"AAAA;;;;;;;;;;;;EAYE;AAVF;EACE,mBAAA;EAYA,wFAAwF;EAXxF,iBAAA;EACA,6BAAA;AAaF;AAhBA;EAKI,8BAAA;EACA,qBAAA;AAcJ;AAbI;;EACI,8BAAA;AAgBR;AAdI;EACI,kCAAA;AAgBR","sourcesContent":["@import \"../../resources/styles/colors\";\n\n.breadcrumb {\n  font-size: 0.875rem; /* mark.siedle: This matches the dataTableColumnHeader, so this has a consistent look. */\n  line-height: 1rem;\n  color: @secondaryText;\n  .breadcrumbLink {\n    color: @primaryText;\n    text-decoration: none;\n    &>:active, &>:visited{\n        color: @primaryText;\n    }\n    &:hover{\n        color: @primary;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"breadcrumb": `style_breadcrumb__KIhPe`,
	"breadcrumbLink": `style_breadcrumbLink__mr95i`
};
module.exports = ___CSS_LOADER_EXPORT___;
