import {ReefResource} from "../../client/resources/reefResource";
import * as React from "react";
import InfoDialogLayout from "../../components/DialogLayout/InfoDialogLayout";
import ExternalLink from "../../components/Navigation/ExternalLink";
import BaseComponent from "../../components/BaseComponent";
import {KubernetesClusterResource} from "../../client/resources/KubernetesClusterResource";
import CopyToClipboard from "../../components/CopyToClipboardButton";

interface Props {
    reef: ReefResource;
    cluster: KubernetesClusterResource;
    azureServicePrincipalSubscriptionId: string;
}

class ConnectionDetailsDialog extends BaseComponent<Props, never> {

    render() {
        const getCredentialsCommand = `az aks get-credentials --name ${this.props.cluster.Name} --resource-group ${this.props.reef.ResourceGroupName} --subscription ${this.props.azureServicePrincipalSubscriptionId}`;
        const kubectlConfigCommand = `kubectl config use-context ${this.props.cluster.Name}`;

        return <InfoDialogLayout title={"Connection Details"}
                                 busy={null}
                                 errors={null}>
            <React.Fragment>
                <div>
                    Add <strong>{this.props.cluster.Name}</strong> cluster to your local kubectl config <CopyToClipboard value={getCredentialsCommand} />
                </div>
                <code>{getCredentialsCommand}</code>
                <br/>

                Once you have the credentials stored locally you can use them either

                <br/>

                <div>
                    with <ExternalLink href="https://kubernetes.io/docs/tasks/tools/">kubectl</ExternalLink> <CopyToClipboard value={kubectlConfigCommand} />
                </div>

                <code>
                    {kubectlConfigCommand}
                </code>

                <br/>

                OR

                <br/>
                <br/>

                <div>
                    {/* List of Lens handlers: https://github.com/lensapp/lens/blob/master/src/renderer/protocol-handler/app-handlers.ts */}
                    with <ExternalLink href="https://k8slens.dev/">Lens</ExternalLink> by <ExternalLink href="lens://cluster"> adding the cluster to it.</ExternalLink>
                </div>


            </React.Fragment>
        </InfoDialogLayout>;
    }

}

export default ConnectionDetailsDialog;
