import {instancesRouteLinks} from "areas/instances/instancesRouteLinks";
import {backgroundTasksRouteLinks} from "areas/backgroundTasks/backgroundTasksRouteLinks";
import {reefsRouteLinks} from "areas/reefs/reefsRouteLinks";
import {dynamicWorkerServicesRouteLinks} from "areas/dynamicWorkerServices/dynamicWorkerServicesRouteLinks";
import {auditLinks} from "areas/auditing/auditRouteLinks";
import {messageBusLinks} from "areas/messageBus/messageBusRouteLinks";
import {instanceTaskLocksRouteLinks} from "areas/instanceTaskLocks/instanceTaskLocksRouteLinks";
import {settingsRouteLinks} from "areas/settings/settingsRouteLinks";
import {cloudSubscriptionsRouteLinks} from "./areas/instances/cloudSubscriptionsRouteLinks";
import {azureRegionsRouteLinks} from "areas/azureRegions/azureRegionsRouteLinks";
import {hubsRouteLinks} from "./areas/hubs/hubsRouteLinks";

export const routeLinks = {
    root: "/",
    instances: instancesRouteLinks,
    cloudSubscriptions: cloudSubscriptionsRouteLinks,
    audit: auditLinks,
    messageBus: messageBusLinks,
    backgroundTasks: backgroundTasksRouteLinks,
    dynamicWorkerServices: dynamicWorkerServicesRouteLinks,
    reefs: reefsRouteLinks,
    instanceTaskLocks: instanceTaskLocksRouteLinks,
    settings: settingsRouteLinks,
    azureRegions: azureRegionsRouteLinks,
    hubs: hubsRouteLinks
};

export default routeLinks;