export const prodSnoozeUptimeShortIds: string[] = [];

export interface SnoozeInstanceAlertUntilTime {
    hostedInstanceId: string;
    metricType: string[];
    snoozeUntilDate: Date;
    comment: string;
    linkToCard?: string;
} 

export const instancesNearingPlatformLimitsToSnooze: SnoozeInstanceAlertUntilTime[] = [
    {
        hostedInstanceId: "HostedInstances-41103",
        metricType: ["CPU"], 
        snoozeUntilDate: new Date("2026-08-01"), 
        comment: "philips-ei (i041103) limits are reviewed monthly as part of its maintenance card (see link)",
        linkToCard: "https://app.shortcut.com/octopusdeploy/story/52382/philips-i041103-monthly-outage-window-tasks"
    },
    {
        hostedInstanceId: "HostedInstances-7696",
        metricType: ["CPU", "DTU", "Memory"],
        snoozeUntilDate: new Date("2024-12-01"),
        comment: "We have a project scheduled to extend our platform's limits and support drbsystems(i007696) requirements",
        linkToCard: "https://app.shortcut.com/octopusdeploy/story/87635/expend-platform-cpu-memory-dtu-limits-to-support-enterprise-customers"
    },
    {
        hostedInstanceId: "HostedInstances-50129",
        metricType: ["DTU"],
        snoozeUntilDate: new Date("2024-10-15"),
        comment: "guestlinek (i050129) is reaching our platform's DTU limit on DB maintenance due to its large DB (~130GiB)",
        linkToCard: "https://app.shortcut.com/octopusdeploy/story/87688/guestlinek-is-reaching-platform-s-dtu-limit-3000-dtus"
    }
];
