import * as React from "react";
import { Tooltip } from "react-tippy";
import "react-tippy/dist/tippy.css";
import { injectGlobal } from "@emotion/css";
import { themeTokens } from "@octopusdeploy/design-system-tokens";

export enum ToolTipPosition {
    Right= "right",
    Left= "left",
    Top = "top",
    Bottom = "bottom"
}

export type ToolTipContent = string | (() => JSX.Element) | (() => string) | JSX.Element;

interface ToolTipProps {
    content: ToolTipContent;
    children?: React.ReactNode;
    position?: ToolTipPosition;
    open?: boolean;
    trigger?: "mouseenter" | "focus" | "click" | "manual";
    size?: "small" | "regular";
}

const ToolTip: React.StatelessComponent<ToolTipProps> = (props: ToolTipProps) => {
    let content = props.content;
    if (typeof content === "function") {
        content = content();
    }

    const position = props.position ? props.position : ToolTipPosition.Top;

    return <Tooltip open={props.open}
        trigger={props.trigger}
        arrow={true}
        position={position}
        html={<span>{content}</span>}
        size={"small"}>
        {props.children}
    </Tooltip>;
};

// Temporary fix for global styling applied by @octopusdeploy/design-system-components
// https://github.com/OctopusDeploy/OctopusDeploy/pull/25667
injectGlobal({
    ".tippy-popper .tippy-tooltip": {
        backgroundColor: themeTokens.color.background.inverse.primary,
        color: themeTokens.color.text.inverse,
    },
});

export default ToolTip;
