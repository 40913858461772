import React from "react";
import InternalLink from "../../components/Navigation/InternalLink";
import ToolTip from "../../components/ToolTip";
import * as styles from "./styles";
import {InstanceNearingPlatformLimitResource} from "../../client/resources/InstanceNearingPlatformLimitResource";
import {instancesRouteLinks} from "../instances/instancesRouteLinks";
import {HostedInstanceLookupResource} from "../../client/resources/hostedInstanceLookupResource";
import {SnoozedAlertData} from "./AlertSnoozing";
import ExternalLink from "../../components/Navigation/ExternalLink";

export interface InstanceNearingPlatformLimitAlertProps {
    instanceNearingPlatformLimit: InstanceNearingPlatformLimitResource;
    instanceLinkData: HostedInstanceLookupResource;
    snoozedAlertData: SnoozedAlertData;
    icon: JSX.Element;
    color: string;
}

function InstanceLink(instanceLinkData: HostedInstanceLookupResource) {
    return <InternalLink to={instancesRouteLinks.instance(instanceLinkData.Id).root}>
        {instanceLinkData.DnsPrefix} ({instanceLinkData.ShortId})
    </InternalLink>;
}

function ExternalLinkToCardIfExists(linkToCard: string, icon: JSX.Element) {
    if (!linkToCard) return icon;
    
    return <ExternalLink href={linkToCard} showIcon={false}>{icon}</ExternalLink>;    
}

function DisplayIconWithSnoozeData(snoozedAlertData: SnoozedAlertData, icon: JSX.Element) {
    if (!snoozedAlertData.snoozeComment)  return icon;
        
    return <ToolTip content={snoozedAlertData.snoozeComment}>
        {ExternalLinkToCardIfExists(snoozedAlertData.linkToCard, icon)}
    </ToolTip>;    
}

export const InstanceNearingPlatformLimitAlertItem = (props: InstanceNearingPlatformLimitAlertProps) => {
    const instanceAlertData = props.instanceNearingPlatformLimit;
    return <div className={styles.metricItem}>
        <div style={{color: props.color}}>
            {DisplayIconWithSnoozeData(props.snoozedAlertData, props.icon)}               
        </div>
        <div>{instanceAlertData.ResourceType} Limit:</div>
        <div className={styles.metricTitle}><InstanceLink {...props.instanceLinkData}/> </div>
        <span style={{color: props.color}}>
            <ToolTip 
                content={`Current instance limit/usage: ${instanceAlertData.InstanceLimitOrUsage} ${instanceAlertData.ResourceUnits}` +
                    ` from platform limit: ${instanceAlertData.PlatformLimit} ${instanceAlertData.ResourceUnits}`}>
                {instanceAlertData.InstanceLimitOrUsage}/{instanceAlertData.PlatformLimit} {instanceAlertData.ResourceUnits}
            </ToolTip>
        </span>
    </div>;
};
