import * as React from "react";
import FilteredBackgroundTasks from "./FilteredBackgroundTasks";

interface Props {
    documentId: string;
    breadcrumbTitle: string;
}

class RelatedBackgroundTasks extends React.Component<Props> {

    private filteredBackgroundTasks = React.createRef<FilteredBackgroundTasks>(); 
    
    constructor(props: Props) {
        super(props);
    }   

    render() {
        const excludeRecurring: boolean = true;

        return <FilteredBackgroundTasks 
                ref={this.filteredBackgroundTasks} 
                breadcrumbTitle={this.props.breadcrumbTitle}
                documentId={this.props.documentId}
                excludeRecurring={excludeRecurring} 
        ></FilteredBackgroundTasks>;
    }
}

export default RelatedBackgroundTasks;