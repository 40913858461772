//TODO: These are actually worker sizes, change related code to WorkerSize
export const DynamicWorkerTaskCapValues = [
    "TaskCap_5", 
    "TaskCap_10",
    "TaskCap_20",
    "TaskCap_40",
    "TaskCap_80",
    "TaskCap_160"
] as const

export const HostedInstanceTaskCapValues = [
    5,
    10,
    20,
    40,
    80,
    160
] as const
