import React from "react";
import { BooleanRadioButton, BooleanRadioButtonGroup } from "@octopusdeploy/design-system-components";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent";
import SaveDialogLayout from "components/DialogLayout/SaveDialogLayout";
import { Text } from "components/form";
import repository from "client/repository";
import Callout, { CalloutType } from "components/Callout";
import { ActionButton, ActionButtonType } from "components/Button/ActionButton";
import { BulkUpgradeHostedInstanceVersionRequest } from "client/resources/BulkUpgradeHostedInstanceVersionRequest";

interface Props {
    importedHostedInstanceIds: string[];
}

interface State extends DataBaseComponentState {
    upgradeRequest: BulkUpgradeHostedInstanceVersionRequest;
    hostedInstanceIdsDelimitedByLine: string;
}

class BulkUpgradeHostedInstanceVersionDialog extends DataBaseComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            upgradeRequest: {
                hostedInstanceIds: [],
                UpgradeDuringOutageWindow: true,
                Version: "",
            },
            hostedInstanceIdsDelimitedByLine: "",
        };
    }

    render() {
        const upgradeRequest = this.state.upgradeRequest;
        return <SaveDialogLayout title={`Bulk Upgrade Hosted Instance Version`}
            onSaveClick={() => this.bulkUpgradeHostedInstanceVersion()}
            errors={this.state.errors}
            saveButtonDisabled={!upgradeRequest.Version || !upgradeRequest.hostedInstanceIds.length}
            busy={this.state.busy}
            extraHeaderContent={className => (<div className={className}><strong>Specified Instances: {upgradeRequest.hostedInstanceIds.length}</strong></div>)}>
            <Callout type={CalloutType.Warning} title="!!! Do not use in Production !!!">
            </Callout>
            <br/>
            <Callout type={CalloutType.Information} title="Import your data">
                <p>All the hosted instances displayed on the current page will be imported.</p>
                <p><strong>Note</strong>: This will override the existing ids.</p>
                <ActionButton key="Import"
                                   type={ActionButtonType.CreateRelease}
                                   label={"Import"}
                                   onClick={() => this.updateHostedInstanceIds(this.props.importedHostedInstanceIds.join("\n"))}/>
            </Callout>
            <BooleanRadioButtonGroup value={upgradeRequest.UpgradeDuringOutageWindow} onChange={UpgradeDuringOutageWindow => this.updateUpgradeRequest({ UpgradeDuringOutageWindow })}>
                <BooleanRadioButton value={true} label="Upgrade During Outage Window" />
                <BooleanRadioButton value={false} label="Upgrade Immediately" />
            </BooleanRadioButtonGroup>
            <Text label="Version"
                value={upgradeRequest.Version}
                onChange={value => this.updateUpgradeRequest({ Version: value })}
            />
            <Text label="Ids, one per line"
                      value={this.state.hostedInstanceIdsDelimitedByLine}
                      onChange={ids => this.updateHostedInstanceIds(ids)}
                      multiLine={true}
                      hintText={"Ids, e.g. HostedInstances-1"}
            />
        </SaveDialogLayout>;
    }

    private updateHostedInstanceIds(idsDelimitedByLine: string) {
        return this.setState(prevState => ({
            hostedInstanceIdsDelimitedByLine: idsDelimitedByLine,
            upgradeRequest: { ...prevState.upgradeRequest, hostedInstanceIds: idsDelimitedByLine.split("\n").filter(id => id.trim() !== "") },
          }));
    }

    private updateUpgradeRequest(update: Partial<BulkUpgradeHostedInstanceVersionRequest>) {
        return this.setState(prevState => ({upgradeRequest: {...prevState.upgradeRequest, ...update}}));
    }

    private async bulkUpgradeHostedInstanceVersion(): Promise<boolean> {
        return await this.doBusyTask(async () => {
            await repository.HostedInstances.bulkUpgradeVersion({...this.state.upgradeRequest});
        });
    }
}

export default BulkUpgradeHostedInstanceVersionDialog;
