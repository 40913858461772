import {navigationBarIconLinkStyles} from "@octopusdeploy/design-system-components";
import * as React from "react";
import {Component} from "react";
import ExternalLink from "../../../components/Navigation/ExternalLink";
import {RouteComponentProps, withRouter} from "react-router-dom";

type HelpComponentProps = RouteComponentProps<void>;

type Props = HelpComponentProps;

class Help extends Component<Props, {}> {
    
    constructor(props: Props) {
        super(props);
        this.state = {};
    }


    render() {
        const helpLinks: Record<string, string | undefined>  = {
            "/": "https://github.com/OctopusDeploy/hosted-docs/blob/master/portal/dashboard.md"
        };
        
        return (
            <ExternalLink className={navigationBarIconLinkStyles} showIcon={false} href={helpLinks[this.props.location.pathname]}>
                <svg width="24" height="24" viewBox="0 0 24 24">
                    <path d="M12 2.39999C6.67502 2.39999 2.40002 6.71249 2.40002 12C2.40002 17.325 6.67502 21.6 12 21.6C17.2875 21.6 21.6 17.325 21.6 12C21.6 6.71249 17.2875 2.39999 12 2.39999ZM12 20.4C7.35002 20.4 3.60002 16.65 3.60002 12C3.60002 7.38749 7.35002 3.59999 12 3.59999C16.6125 3.59999 20.4 7.38749 20.4 12C20.4 16.65 16.6125 20.4 12 20.4ZM11.4 15.3C10.875 15.3 10.5 15.7125 10.5 16.2C10.5 16.725 10.875 17.1 11.4 17.1C11.8875 17.1 12.3 16.725 12.3 16.2C12.3 15.7125 11.8875 15.3 11.4 15.3ZM13.0875 7.19999H10.875C9.48752 7.19999 8.40002 8.32499 8.40002 9.71249V10.05C8.40002 10.3875 8.66252 10.65 9.00002 10.65C9.30002 10.65 9.60002 10.3875 9.60002 10.05V9.71249C9.60002 8.99999 10.1625 8.39999 10.875 8.39999H13.0875C13.8 8.39999 14.4 8.99999 14.4 9.71249C14.4 10.1625 14.1375 10.6125 13.725 10.8375L11.475 11.9625C11.0625 12.1875 10.8 12.6375 10.8 13.125V13.8C10.8 14.1375 11.0625 14.4 11.4 14.4C11.7 14.4 12 14.1375 12 13.8V13.125C12 13.0875 12 13.05 12.0375 13.0125L14.2875 11.8875C15.075 11.4375 15.6 10.6125 15.6 9.71249C15.6 8.32499 14.475 7.19999 13.0875 7.19999Z" />
                </svg>
            </ExternalLink>
        );
    };
}

export default withRouter<HelpComponentProps>(Help);