// We use our own AJAX function so that we're not too coupled to jQuery
import DetailedError from "utils/detailedError";
import {keys} from "lodash";
import {authTokenProvider} from "../auth-token-provider";

interface AjaxOptions {
    url: string;
    method?: string;
    onUnauthenticated: () => void;
    success: (data: any) => void;
    error: (error: DetailedError) => void;
    raw?: boolean;
    requestBody?: any;
    nonStale?: boolean;
    withCredentials: boolean;
}

export default class Ajax {
    options: AjaxOptions;

    constructor(options: AjaxOptions) {
        this.options = options;
    }

    execute() {
        const request = createXMLHTTPObject();
        if (!request) {
            return;
        }

        request.open(this.options.method, this.options.url, true);

        // The auth token injection only works for Cloud Portal, so only allow withCredentials with relative URLs
        request.withCredentials = this.options.withCredentials && this.isLocalRelativeUrl(this.options.url);

        request.onreadystatechange = () => {
            if (request.readyState !== XMLHttpRequest.DONE) {
                return;
            } else if (request.status < 100 || request.status >= 400) {
                this.handleError(request);
            } else {
                this.handleSuccess(request);
            }
        };
        this.setHeaders(request).then(() => {
            if (request.readyState === XMLHttpRequest.DONE) {
                return;
            }
            this.sendRequest(request);
        }, reason => {
            console.log("AJAX SetHeaders failed: ", reason);
        });
    }

    private isLocalRelativeUrl(url: string) : boolean {
        if (!url)
            return false;

        return new URL(document.baseURI).origin === new URL(url, document.baseURI).origin;
    }

    private sendRequest(request: XMLHttpRequest) {
        const body = this.options.requestBody;
        if (typeof (body) === "undefined") {
            request.send();
        } else if (typeof (body) === "string" || isFormData(body)) {
            request.send(body);
        } else {
            const bodyJson = JSON.stringify(body);
            request.send(bodyJson);
        }
    }

    private async setHeaders(request: XMLHttpRequest) {
        request.setRequestHeader("Accept", "application/json");

        if (request.withCredentials) {
            const accessToken = await authTokenProvider.getAccessToken();
            request.setRequestHeader("Authorization", `Bearer ${accessToken}`);
        }
        
        if (this.options.requestBody && !isFormData(this.options.requestBody)) {
            request.setRequestHeader("Content-type", "application/json");
        }
    }

    private handleSuccess = (request: XMLHttpRequest) => {
        const responseBody = request.responseText;
        this.options.success(deserialize(responseBody, this.options.raw));
    };

    private handleError = (request: XMLHttpRequest) => {
        if (request.status === 401) {
            this.options.onUnauthenticated();
            return;
        }

        const err = generateDetailedError(request);
        this.options.error(err);
    };
}

const deserialize = (responseText: string, raw: boolean, forceJson = false) => {
    if (raw && !forceJson) {
        return responseText;
    }

    if (responseText && responseText.length) {
        return JSON.parse(responseText);
    }

    return null;
};

const isFormData = (item: any): boolean => {
    return item instanceof FormData;
};

const generateDetailedError = (request: XMLHttpRequest) => {
    let error = new DetailedError(request.status);

    if (request.status === XMLHttpRequest.UNSENT) {
        error.ErrorMessage = "There was a problem with your request.";
        error.Errors = ["Unable to establish a connection to the server. " +
        "The server may be offline. Please check your network connection."];
        return error;
    }

    if (request.status === 403) {
        //If FrontDoor blocks, we should have this div in the response
        if (request.responseText.indexOf("<div id='errorref'>") >= 0) {
            const trackingReference = request.getResponseHeader("x-azure-ref");
            error.ErrorMessage = "Something went wrong"
            error.Errors = ["Please contact #cloud-platform-requests with this reference: " + trackingReference];
            return error;
        }
        //Access denied doesn't return a body
        else {
            error.ErrorMessage = "Access denied."
            error.Errors = ["You are not authorized to perform this action. Please contact #cloud-platform-requests."];
            return error;
        }
    }

    try {
        const response = deserialize(request.responseText, false, true);
        if (typeof response === "string") {
            error.ErrorMessage = response;
        } else if (response.title) {
            error.ErrorMessage = response.title;
            error.Errors = keys(response.errors).map(k => k + ": " + response.errors[k]);
        } else {
            error = DetailedError.create(request.status, response);

            if (!error.ErrorMessage) {
                error.ErrorMessage = "There was a problem with your request.";
                error.Errors = ["The server returned a status of: " + request.status];
                error.FullException = request.response;
            }
        }
    } catch (err) {
        error.ErrorMessage = "There was a problem with your request.";
        error.Errors = ["Unhandled error when communicating with Cloud Portal. " +
        "The server returned a status of: " + request.status];
        error.FullException = err.toString();
    }
    return error;
};

const XMLHttpFactories = [
    () => {
        return new XMLHttpRequest();
    },
    () => {
        return new ActiveXObject("Msxml2.XMLHTTP") as XMLHttpRequest;
    },
    () => {
        return new ActiveXObject("Msxml3.XMLHTTP") as XMLHttpRequest;
    },
    () => {
        return new ActiveXObject("MSXML2.XMLHTTP.3.0") as XMLHttpRequest;
    },
    () => {
        return new ActiveXObject("Microsoft.XMLHTTP") as XMLHttpRequest;
    }
];

const createXMLHTTPObject = () => {
    let xmlhttp: XMLHttpRequest = null;
    for (let i = 0; i < XMLHttpFactories.length; i++) {
        try {
            xmlhttp = XMLHttpFactories[i]();
        } catch (e) {
            continue;
        }
        break;
    }
    return xmlhttp;
};
