import * as React from "react";
import cn from "classnames";

import styles = require("./style.less");

interface DataTableRowHeaderColumnProps {
    className?: string;
    inSidebar?: boolean;
}

export class DataTableRowHeaderColumn extends React.Component<DataTableRowHeaderColumnProps, any> {
    render() {
        const {
            className,
            inSidebar,
            ...otherProps
        } = this.props;
        return (
            <th className={cn(className, styles.dataTableRowHeaderColumn, inSidebar ? styles.dataTableRowHeaderColumnInSidebar : null)} {...otherProps}>
                {this.props.children}
            </th>
        );
    }
}