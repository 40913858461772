import * as React from "react";
import {Text, required} from "components/form";
import Summary from "components/form/Sections/Summary";
import ExpandableFormSection from "components/form/Sections/ExpandableFormSection";
import FormPaperLayout from "components/FormPaperLayout/FormPaperLayout";
import {OptionalFormBaseComponentState} from "components/form/FormBaseComponent";
import {FormBaseComponent} from "components/form/FormBaseComponent/FormBaseComponent";
import Select from "components/form/Select/Select";
import repository from "client/repository";
import {ReefResource} from "client/resources/reefResource";
import routeLinks from "routeLinks";
import {InternalRedirect} from "components/Navigation/InternalRedirect";
import { CreateHostedBranchInstanceRequest } from "client/resources/createHostedBranchInstanceRequest";
import Callout, {CalloutType} from "../../../components/Callout";
import ExternalLink from "../../../components/Navigation/ExternalLink";
import {AzureRegionResource} from "../../../client/resources/azureRegionResource";
import {reefDisplayLabel} from "../../reefs/ReefDisplayLabel";

interface State extends OptionalFormBaseComponentState<Partial<CreateHostedBranchInstanceRequest>> {
    model: Partial<CreateHostedBranchInstanceRequest>;
    cleanModel: Partial<CreateHostedBranchInstanceRequest>;
    reefs?: ReefResource[];
    azureRegions?: AzureRegionResource[];
    redirectTo?: string;
}

class CreateBranchInstance extends FormBaseComponent<{}, State, Partial<CreateHostedBranchInstanceRequest>> {
    constructor(props: {}) {
        super(props);
        this.state = {
            model: {},
            cleanModel: {},
        };
    }

    async componentDidMount() {
        return await this.doBusyTask(async () => {
            const [reefs, azureRegions, defaultLicense] = await Promise.all([repository.Reefs.list(), repository.AzureRegions.list(), repository.HostedInstances.getDefaultBranchInstanceLicense()]);
            this.setState({
                reefs,
                azureRegions,
                model: {
                    LicenseXml: defaultLicense.LicenseXml
                }
            });
        });
    }

    render() {

        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo}/>;
        }

        return <FormPaperLayout
            title="Create Instance"
            busy={this.state.busy}
            errors={this.state.errors}
            model={this.state.model}
            cleanModel={this.state.cleanModel}
            saveButtonLabel={"Queue Instance Creation"}
            saveText="Create instance task queued"
            saveButtonBusyLabel={"Queuing"}
            expandAllOnMount={true}
            overFlowActions={null}
            onSaveClick={() => this.handleSaveClick()}>
            {this.state.reefs && (this.getBody())}
        </FormPaperLayout>;
    }



    getBody() {
        return <div>
            {this.getPublicDNSFormElement()}
            {this.getReefFormElement()}
            {this.getBranchFormElement()}
            {this.getVersionElement()}
            {
                <Callout type={CalloutType.Warning} title="Warning">
                    <p>If this license has expired, please head to <ExternalLink
                      href={`https://billing.octopushq.com/server-licenses/14d1fb6e-5688-4a79-c228-08daccd1363d?showCloudXmlDialog=true`}>
                        the licensing system
                    </ExternalLink>, update this license's expiry and then paste below. Bonus points for those willing to make a PR to update this prefill</p>
                </Callout>
            }
            {this.getLicenseXmlElement()}
        </div>;
    }


    getPublicDNSFormElement() {
        const model = this.state.model;
        return <ExpandableFormSection
            errorKey="DnsPrefix"
            title="DNS Prefix"
            summary={model.DnsPrefix ? Summary.summary(model.DnsPrefix) : Summary.placeholder("No DNS prefix")}
            help={<span>Enter the public DNS prefix for this instance.</span>}>
            <Text label="Public DNS Prefix"
                  value={model.DnsPrefix}
                  onChange={DnsPrefix => this.setModelState({DnsPrefix})}
                  validate={required("Please enter the DNS prefix to use for this instance")}
                  error={this.getFieldError("DnsPrefix")}
                  autoFocus={true}
            />
        </ExpandableFormSection>;
    }
    
    getReefFormElement() {
        const model = this.state.model;

        const handleReefChanged = (reefId: string) => {
            this.doBusyTask(async () => {
                this.setModelState({ReefId: reefId});
            });
        };

        return <React.Fragment>
            <ExpandableFormSection
                errorKey="ReefId"
                title="Reef"
                summary={model.ReefId ? Summary.summary(model.ReefId) : Summary.placeholder("No reef")}
                help={<span>Select the reef in which to provision this instance.</span>}>
                <Select value={this.state.model.ReefId}
                        onChange={handleReefChanged}
                        items={this.state.reefs
                          .filter(r => r.AvailableToCustomers)
                          .map(r => ({
                              text: `${reefDisplayLabel(r)} - ${this.state.azureRegions.find(azr => azr.Id === r.AzureRegionId).Region}`, 
                              value: r.Id}))}
                        fieldName="ReefId"
                        hintText="The reef that the instance is to be created in"
                />
            </ExpandableFormSection>
        </React.Fragment>;
    }

    getBranchFormElement() {
        const model = this.state.model;
        return <ExpandableFormSection
          errorKey="Branch"
          title="Branch"
          summary={model.Branch ? Summary.summary(model.Branch) : Summary.placeholder("No Branch")}
          help={<span>Enter the Branch for this instance.</span>}>
            <Text label="Branch"
                  value={model.Branch}
                  onChange={Branch => this.setModelState({Branch})}
                  validate={required("Please enter the Branch to use for this instance")}
                  error={this.getFieldError("Branch")}
            />
        </ExpandableFormSection>;
    }
    
    getVersionElement() {
        const model = this.state.model;
        return <ExpandableFormSection
          errorKey="Version"
          title="Version"
          summary={model.Version ? Summary.summary(model.Version) : Summary.placeholder("No Version")}
          help={<span>Enter the Version for this instance.</span>}>
            <Text label="Version"
                  value={model.Version}
                  onChange={Version => this.setModelState({Version})}
                  validate={required("Please enter the Version to use for this instance")}
                  error={this.getFieldError("Version")}
            />
        </ExpandableFormSection>;
    }

    private getLicenseXmlElement() {
        const model = this.state.model;
        return <ExpandableFormSection
          errorKey="LicenseXml"
          title="License XML"
          summary={model.LicenseXml ? Summary.summary(model.LicenseXml) : Summary.placeholder("No license XML")}
          help={<span>Enter the license XML.</span>}>
            <Text label="License XML"
                  value={model.LicenseXml}
                  onChange={LicenseXml => this.setModelState({LicenseXml})}
                  hintText={"Please enter the license XML to associate with this instance"}
                  error={this.getFieldError("LicenseXml")}
                  multiLine={true}
                  rows={10}
            />
        </ExpandableFormSection>
    }

    private handleSaveClick() {
        return this.doBusyTask(async () => {
            const model = this.state.model;
            const hostedBranchInstance = await repository.HostedInstances.createBranch(model as CreateHostedBranchInstanceRequest);
            this.setState({redirectTo: routeLinks.backgroundTasks.task(hostedBranchInstance.BackgroundTask.Id).root, model});
        });
    }
}

export default CreateBranchInstance;
