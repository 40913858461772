import client from "client/client";
import {MeResource} from "client/resources/meResource";

export default class AuthenticationRepository {

    private rootUri = "/api/authentication";

    authenticate(googleToken: string): Promise<{}> {
        return client.post(`${this.rootUri}/token`, {token: googleToken});
    }

    me(): Promise<MeResource> {
        return client.get(`${this.rootUri}/me`);
    }

    logout() {
        return client.post(`${this.rootUri}/logout`);
    }
}