import * as React from "react";
import PaperLayout from "components/PaperLayout/PaperLayout";
import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent";
import AdvancedFilterLayout from "components/AdvancedFilterLayout/AdvancedFilterLayout";
import repository from "client/repository";
import {AdvancedFilterTextInput} from "components/AdvancedFilterLayout";
import AuditDataTable from "areas/auditing/AuditDataTable";
import {AuditEntryResource} from "client/resources/auditEntryResource";
import {IQuery, QueryStringFilters} from "components/QueryStringFilters/QueryStringFilters";
import {RouteComponentProps} from "react-router-dom";
import {AuditListArgs} from "client/repositories/auditRepository";

type Filter = Partial<AuditListArgs>;

interface State extends DataBaseComponentState {
    auditEntries?: AuditEntryResource[];
    filter: Filter;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props extends RouteComponentProps<{}> {
}

interface Query extends IQuery {
    documentId?: string;
}

class FilterLayout extends AdvancedFilterLayout<Filter> {
}

const AuditQueryStringFilters = QueryStringFilters.For<Filter, Query>();

class Audit extends DataBaseComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            filter: this.getEmptyFilter()
        };
    }

    render() {

        const state = this.state;
        const filter = state.filter;

        const filterSections = [
            {
                render: <>
                    <AdvancedFilterTextInput
                        fieldName="document Id"
                        value={filter.documentId}
                        onChange={documentId => this.filterChanged({documentId})}
                    />
                </>
            }
        ];

        return <PaperLayout title="Audit"
                            busy={this.state.busy}
                            errors={this.state.errors}
                            fullWidth={true}>
            {
                <>
                    <AuditQueryStringFilters filter={this.state.filter} getQuery={this.queryFromFilters} getFilter={this.getFilterFromQuery} onFilterChange={this.filterChanged} />
                    <FilterLayout
                        filter={this.state.filter}
                        defaultFilter={this.getEmptyFilter()}
                        onFilterReset={() => this.filterChanged(this.getEmptyFilter())}
                        filterSections={filterSections}
                        initiallyShowFilter={false}
                        renderContent={() => this.state.auditEntries && <AuditDataTable auditEntries={this.state.auditEntries} />}
                    />
                </>
            }
        </PaperLayout>;
    }

    private refreshData = async () => {
        await this.doBusyTask(async () => {
            const auditEntries = await repository.Audit.list(this.state.filter);

            this.setState({
                auditEntries,
            });
        });
    };

    private filterChanged = (filter: Filter) => {
        this.setState({filter}, async () => await this.refreshData());
    };

    private getEmptyFilter(): Filter {
        return {};
    }

    private queryFromFilters = (filter: Filter): Query => {
        return {
            documentId: filter.documentId,
        };
    };

    private getFilterFromQuery = (query: Query): Filter => {
        return {
            documentId: query.documentId,
        };
    };
}

export default Audit;