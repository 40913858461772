// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_dataTableRowColumn__ARkRW:nth-child(2) {
  padding: 0.575rem 1.125rem;
}
.style_dataTableRowColumn__ARkRW {
  vertical-align: middle;
}
.style_calloutList__LYCbI {
  padding-inline-start: 40px;
  list-style-type: disc;
}
`, "",{"version":3,"sources":["webpack://./app/areas/settings/style.less"],"names":[],"mappings":"AAAA;EACI,0BAAA;AACJ;AAEA;EACI,sBAAA;AAAJ;AAGA;EACI,0BAAA;EACA,qBAAA;AADJ","sourcesContent":[".dataTableRowColumn:nth-child(2) {\n    padding: 0.575rem 1.125rem;\n}\n\n.dataTableRowColumn {\n    vertical-align: middle;\n}\n\n.calloutList {\n    padding-inline-start: 40px;\n    list-style-type: disc;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dataTableRowColumn": `style_dataTableRowColumn__ARkRW`,
	"calloutList": `style_calloutList__LYCbI`
};
module.exports = ___CSS_LOADER_EXPORT___;
