import React from "react";
import {BooleanRadioButton, BooleanRadioButtonGroup} from "@octopusdeploy/design-system-components";
import {DataBaseComponent, DataBaseComponentState, Errors} from "components/DataBaseComponent";
import repository from "client/repository";
import {BusyState} from "components/BusyFromPromise/BusyFromPromise";
import {HostedInstanceResource} from "client/resources/hostedInstanceResource";
import {ReefResource} from "client/resources/reefResource";
import SaveDialogLayout from "components/DialogLayout/SaveDialogLayout";
import {Text} from "components/form";
import {HostedInstanceUpgradeRequest} from "../../../client/resources/hostedInstanceUpgradeRequest";
import Callout, {CalloutType} from "../../../components/Callout";
import semver = require("semver");

interface UpgradeDialogProps {
    errors: Errors;
    busy: BusyState;
    instance: HostedInstanceResource;
    reef: ReefResource;
    onSaveClick(): Promise<boolean>;
}

interface UpgradeDialogState extends DataBaseComponentState {
    upgradeRequest: Partial<HostedInstanceUpgradeRequest>;
}

export class UpgradeDialog extends DataBaseComponent<UpgradeDialogProps, UpgradeDialogState> {
    constructor(props: UpgradeDialogProps) {
        super(props);
        this.state = {
            upgradeRequest: {
                UpgradeDuringOutageWindow: true, 
                Version: this.props.instance.Version
            }
        };
    }

    render() {
        const request = this.state.upgradeRequest;
        return <SaveDialogLayout title={"Upgrade"}
                                 busy={this.state.busy}
                                 errors={this.state.errors}
                                 saveButtonDisabled={this.versionSelectionResultsInDowngrade()}                                 
                                 onSaveClick={() => this.upgrade(request)}>


            {this.versionSelectionResultsInDowngrade() &&
                <Callout type={CalloutType.Danger} title="Version must be equal or higher than the current value">
                    Please use the Downgrade dialog if you wish to downgrade the Octopus Server version.
                </Callout>}
            
            <BooleanRadioButtonGroup value={request.UpgradeDuringOutageWindow} onChange={UpgradeDuringOutageWindow => this.updateUpgradeRequest({UpgradeDuringOutageWindow})}>
                <BooleanRadioButton value={true} label="Upgrade During Outage Window" />
                <BooleanRadioButton value={false} label="Upgrade Immediately" />
            </BooleanRadioButtonGroup>

            <Text label="Version"
                  hintText="New version for this hosted instance"
                  value={request.Version}
                  onChange={Version => this.updateUpgradeRequest({Version})}
                  autoFocus={true}
            />

        </SaveDialogLayout>;
    }
    private versionSelectionResultsInDowngrade() {
        return semver.valid(this.props.instance.Version) &&
            semver.valid(this.state.upgradeRequest.Version) &&
            semver.gt(this.props.instance.Version, this.state.upgradeRequest.Version)
    }
    
    private updateUpgradeRequest(update: Partial<HostedInstanceUpgradeRequest>) {
        return this.setState(prevState => ({upgradeRequest: {...prevState.upgradeRequest, ...update}}));
    }

    private upgrade(request: Partial<HostedInstanceUpgradeRequest>) {
        return this.doBusyTask(async () => {
            await repository.HostedInstances.upgrade(this.props.instance, request as HostedInstanceUpgradeRequest);
            await this.props.onSaveClick();
            return true;
        });
    }
}
