// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* This file is DEPRECATED
   We are moving to the Octopus Design System
   https://www.octopus.design
  
   When changing component styles:
   1. Do not use variables from this file
   2. Aim to use Emotion \`css\` + themeTokens.colors
       - see Dashboard.tsx for example
   3. If you need to use Less, reference CSS variables
       - see index.ejs for example
       - for full list see https://github.com/OctopusDeploy/OctopusDeploy/blob/main/frontend/packages/design-system-tokens/src/generated/themeTokens.ts
   4. Remove variables from this file when they are no longer used
 */
.style_busyIndicator__zVqq2 div:first-child {
  background-color: var(--colorBorderSubtle);
}
.style_busyIndicatorHiddenSpacer__dtK5n {
  height: 0.25rem;
}
.style_circularBusyIndicator__eMzre div:first-child {
  vertical-align: middle;
  background-color: transparent;
}
`, "",{"version":3,"sources":["webpack://./app/components/BusyIndicator/style.less"],"names":[],"mappings":"AAAA;;;;;;;;;;;;EAYE;AAVF;EACI,0CAAA;AAYJ;AATA;EACI,eAAA;AAWJ;AARA;EACI,sBAAA;EACA,6BAAA;AAUJ","sourcesContent":["@import \"../../resources/styles/colors\";\n\n.busyIndicator div:first-child {\n    background-color: @divider;\n}\n\n.busyIndicatorHiddenSpacer {\n    height: 0.25rem; // This matches <LinearProgress> height so we avoid jankiness from show/hide.\n}\n\n.circularBusyIndicator div:first-child {\n    vertical-align: middle;\n    background-color: transparent; // @white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"busyIndicator": `style_busyIndicator__zVqq2`,
	"busyIndicatorHiddenSpacer": `style_busyIndicatorHiddenSpacer__dtK5n`,
	"circularBusyIndicator": `style_circularBusyIndicator__eMzre`
};
module.exports = ___CSS_LOADER_EXPORT___;
