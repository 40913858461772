import MultiSelect from "components/MultiSelect/MultiSelect";
import FormFieldProps from "components/form/FormFieldProps";
import * as React from "react";
import TaskCapChip from "shared/TaskCapChip";
import {HostedInstanceTaskCapValues} from "./dynamicWorkerTaskCapValues";
import {TaskCapResource} from "../client/resources/TaskCapResource";

interface TaskCapMultiSelectProps extends FormFieldProps<string[]> {
    autoFocus?: boolean;
    label?: string | JSX.Element;
    error?: string;
}

const TaskCapTypedMultiSelect = MultiSelect<TaskCapResource>();

const TaskCapMultiSelect: React.StatelessComponent<TaskCapMultiSelectProps> = props => {
    const chipRenderer = (k: TaskCapResource, onRequestDelete: (event: object) => void) => {
        return <TaskCapChip taskCap={k} onRequestDelete={onRequestDelete} />;
    };
    
    return <TaskCapTypedMultiSelect
        items={HostedInstanceTaskCapValues.map(t => ({
            Id: `${t}`, Name: `${t}`
        }))}
        fieldName="TaskCap"
        renderChip={chipRenderer}
        {...props} />;
};

export default TaskCapMultiSelect;
