import * as React from "react";
import * as showdown from "showdown";
import xss from "xss";
import style = require("./style.less");

interface MarkdownProps {
    markup: string;
}

const Markdown: React.StatelessComponent<MarkdownProps> = props => {
    const converter = new showdown.Converter({
        tables: true,
        strikethrough: true,
        openLinksInNewWindow: true,
        disableForced4SpacesIndentedSublists: true,
        literalMidWordUnderscores: true
    });

    const html = xss(converter.makeHtml(props.markup));
    return <div className={style.markdown} dangerouslySetInnerHTML={{__html: html}} />;
};

export default Markdown;