import moment from "moment";
import { themeTokens } from "@octopusdeploy/design-system-tokens";

export type freshnessUnit = "days" | "hours";

export function getHeaderColor(lastUpdated: string, freshness: freshnessUnit) {

    const lastUpdatedUnitsAgo = moment().diff(lastUpdated, freshness);

    if (lastUpdatedUnitsAgo <= 2) {
        return themeTokens.color.border.info;
    }

    if (lastUpdatedUnitsAgo <= 5) {
        return themeTokens.color.border.warning;
    }

    return themeTokens.color.border.danger;
}