import * as React from "react";
import * as ReactDOM from "react-dom";
import {Provider} from "react-redux";
import App from "./App";
import "index.less";
import store from "./store";
import {ThemeFromLocalStorage} from "./components/Theme";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";

const render = (Component: any) => {
    ReactDOM.render(
        <ErrorBoundary>
            <ThemeFromLocalStorage>
                <Provider store={store}>
                    <Component />
                </Provider>
            </ThemeFromLocalStorage>
        </ErrorBoundary>,
        document.getElementById("app")
    );
};

render(App);

//Do NOT move to a separate file as this wil break hot reloading
if ((module as any).hot) {
    (module as any).hot.accept("./reducers", () => {
        // When reducers change, replace them but keep the current app state
        console.log("Store updated. Replacing root reducer");
        // If the callback to hot.accept throws
        // an exception, the HMR update will fail. If using
        // webpack-dev-server, the page will be reloaded,
        // and we lose the error. So try/catch/log.
        //
        try {
            const nextReducer = require("./reducers").reducers;
            store.replaceReducer(nextReducer);
        } catch (err) {
            console.error("Error updating store", err);
        }
    });

    (module as any).hot.accept("./App", () => {
        try {
            const NextApp = require("./App").default;
            render(NextApp);
        } catch (err) {
            console.error("Error updating store", err);
        }
    });
}
