import React from "react";
import {FriendlyLocalTime} from "shared/FriendlyLocalTime";
import {freshnessUnit, getHeaderColor} from "areas/dashboard/getHeaderColor";
import * as styles from "./styles";

export interface AlertsProps {
    header: React.ReactNode;
    children?: React.ReactNode;
    lastUpdated: string;
    freshness: freshnessUnit;
}

export const Alerts = (props: AlertsProps) => {
    return <div className={styles.cardContainer}>
        <div className={styles.cardTitle} style={{backgroundColor: getHeaderColor(props.lastUpdated, props.freshness)}}>
            <div>{props.header}</div>
            <div className={styles.lastUpdated}>
                Updated: <FriendlyLocalTime time={props.lastUpdated} />
            </div>
        </div>
        <div className={styles.cardContent}>{props.children}</div>
    </div>;
};

export interface LimitProps {
    text: React.ReactNode;
    value: number;
    limit: number;
    color: string;
    icon: JSX.Element;
    numberToString?(value: number): string;
}

export const LimitItem = (props: LimitProps) => {
    const numberToString = props.numberToString || (value => value.toString());

    return <div className={styles.metricItem}>
        <div style={{color: props.color}}>{props.icon}</div>
        <div className={styles.metricTitle}>{props.text}</div>
        <div style={{color: props.color}}>{numberToString(props.value)}/{numberToString(props.limit)}</div>
    </div>;
};

export interface AlertProps {
    text: React.ReactNode;
    value: number | React.ReactNode;
    icon: JSX.Element;
    color: string;
    title?: string;
}

export const AlertItem = (props: AlertProps) => {
    return <div className={styles.metricItem} title={props.title}>
        <div style={{color: props.color}}>{props.icon}</div>
        <div className={styles.metricTitle}>{props.text}</div>
        <div style={{color: props.color}}>{props.value}</div>
    </div>;
};