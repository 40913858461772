import * as React from "react";
import {Checkbox, DatePicker} from "@octopusdeploy/design-system-components";
import {required, Text} from "components/form";
import Summary from "components/form/Sections/Summary";
import ExpandableFormSection from "components/form/Sections/ExpandableFormSection";
import FormPaperLayout from "components/FormPaperLayout/FormPaperLayout";
import OptionalFormBaseComponent, {OptionalFormBaseComponentState} from "components/form/FormBaseComponent";
import repository from "client/repository";
import {InternalRedirect} from "components/Navigation/InternalRedirect";
import {cloneDeep} from "lodash";
import {RouteComponentProps} from "react-router-dom";
import {dynamicWorkerServicesRouteLinks} from "areas/dynamicWorkerServices/dynamicWorkerServicesRouteLinks";
import routeLinks from "routeLinks";
import {DynamicWorkerTypeResource} from "../../../client/resources/dynamicWorkerTypeResource";
import moment from "moment";
import {DataTable, DataTableBody, DataTableRow} from "../../../components/DataTable";
import {WorkerTypeSizeResource} from "../../../client/resources/workerTypeSizeResource";
import {default as IconButton, Icon} from "../../../components/IconButton/IconButton";
import InputWithActions from "../../../components/InputWithActions";

type Props = RouteComponentProps<{ id: string, type: string }>;

interface State extends OptionalFormBaseComponentState<DynamicWorkerTypeResource> {
    redirectTo?: string;
    workerServiceId: string;
}

export class EditDynamicWorkerType extends OptionalFormBaseComponent<Props, State, DynamicWorkerTypeResource> {
    constructor(props: Props) {
        super(props);
        this.state = {
            workerServiceId: "",
        };
    }

    async componentDidMount() {
        const id = this.props.match.params.id;
        const type = this.props.match.params.type;

        const model = id
            ? await repository.DynamicWorkerServices.workerType(id, type)
            : {
                Id: "",
                Type: "",
                Description: "",
                OperatingSystem: "Windows",
                DefaultForOperatingSystem: false,
                KeepFailedWorkersForDebugging: false,
                MaximumPendingConfigureTrustTime: "",
                Sizes: {}
            };

        this.setState({
            workerServiceId: id,
            model,
            cleanModel: cloneDeep(model),
        });
    }

    render() {

        const state = this.state;

        if (state.redirectTo) {
            return <InternalRedirect to={state.redirectTo}/>;
        }

        const isNew = !this.props.match.params.id;
        const actionName = `${isNew ? "Create" : "Edit"} Dynamic Worker Type`;

        return <FormPaperLayout
            title={actionName}
            breadcrumbTitle="Dynamic Worker Types"
            breadcrumbPath={routeLinks.dynamicWorkerServices.service(this.props.match.params.id).workerTypes}
            busy={state.busy}
            errors={state.errors}
            model={state.model}
            cleanModel={state.cleanModel}
            saveButtonLabel="Save"
            saveText="Dynamic Worker Type updated"
            saveButtonBusyLabel={"Saving"}
            expandAllOnMount={true}
            onSaveClick={this.handleSaveClick}
        >
            {state.model && this.getBody()}
        </FormPaperLayout>;
    }

    getBody() {
        const model = this.state.model;

        return <div>
            <ExpandableFormSection
                errorKey="Type"
                title="Type"
                summary={model.Type ? Summary.summary(model.Type) : Summary.placeholder("No type")}
                help={<span>Enter the well-known-name for this worker image.</span>}>
                <Text label="Type"
                      value={model.Type}
                      onChange={type => this.setModelState({Type: type})}
                      validate={required("Please enter the Type to use for this dynamic worker type")}
                      error={this.getFieldError("Type")}
                      autoFocus={true}
                />
            </ExpandableFormSection>

            <ExpandableFormSection
                errorKey="Description"
                title="Description"
                summary={model.Description ? Summary.summary(model.Description) : Summary.placeholder("No description set")}
                help={<span>Enter the description for this worker image. Users will see this in Octopus.</span>}>
                <Text label="Description"
                      value={model.Description}
                      onChange={description => this.setModelState({Description: description})}
                      validate={required("Please enter the description this dynamic worker type")}
                      error={this.getFieldError("Description")}
                />
            </ExpandableFormSection>

            <ExpandableFormSection
                errorKey="OperatingSystem"
                title="Operating System"
                summary={model.OperatingSystem ? Summary.summary(model.OperatingSystem) : Summary.placeholder("No Operating System set")}
                help={<span>Enter the OperatingSystem for this worker image. Something like `Windows` or `Ubuntu`.</span>}>
                <Text label="OperatingSystem"
                      value={model.OperatingSystem}
                      onChange={operatingSystem => this.setModelState({OperatingSystem: operatingSystem})}
                      validate={required("Please enter the Operating System this service")}
                      error={this.getFieldError("OperatingSystem")}
                />
            </ExpandableFormSection>

            <ExpandableFormSection
                errorKey="DefaultForOperatingSystem"
                title="Operating System Default"
                summary={model.DefaultForOperatingSystem ? Summary.summary("This is the default worker image for this operating system") : Summary.placeholder("This is not the default worker image for this operating system")}
                help={<span>Is this the default for this operating system?</span>}>

                <Checkbox label="Operating System Default"
                           value={model.DefaultForOperatingSystem}
                           onChange={defaultForOperatingSystem => this.setModelState({DefaultForOperatingSystem: defaultForOperatingSystem})}
                           error={this.getFieldError("DefaultForOperatingSystem")}
                />
            </ExpandableFormSection>

            <ExpandableFormSection
                errorKey="StartDateUtc"
                title="Start Date"
                summary={model.StartDateUtc ? Summary.summary(moment.utc(model.StartDateUtc).local().toString()) : Summary.placeholder("No start date set")}
                help={<span>When should this worker image start appearing for users</span>}>
                <InputWithActions
                    input={
                        <DatePicker variant="inline"
                                    label="Start Date"
                                    value={model.StartDateUtc}
                                    onChange={startDateUtc => this.setModelState({StartDateUtc: startDateUtc})}
                                    error={this.getFieldError("StartDateUtc")}
                        />
                    }
                    actions={
                        <IconButton toolTipContent="Clear Start Date"
                                    onClick={() => this.setModelState({StartDateUtc: null})}
                                    icon={Icon.Remove} />
                    }
                />
            </ExpandableFormSection>

            <ExpandableFormSection
                errorKey="DeprecationDateUtc"
                title="Deprecation Date"
                summary={model.DeprecationDateUtc ? Summary.summary(moment.utc(model.DeprecationDateUtc).local().toString()) : Summary.placeholder("No deprecation set")}
                help={<span>From this date, users will start seeing warnings in the Octopus UI and deployment logs</span>}>
                <InputWithActions
                    input={
                        <DatePicker variant="inline"
                                    label="Deprecation Date"
                                    value={model.DeprecationDateUtc}
                                    onChange={deprecationDateUtc => this.setModelState({DeprecationDateUtc: deprecationDateUtc})}
                                    error={this.getFieldError("DeprecationDateUtc")} />
                    }
                    actions={
                        <IconButton toolTipContent="Clear Deprecation Date"
                                    onClick={() => this.setModelState({DeprecationDateUtc: null})}
                                    icon={Icon.Remove} />
                    }
                />
            </ExpandableFormSection>

            <ExpandableFormSection
                errorKey="EndOfLifeDateUtc"
                title="End of Life Date"
                summary={model.EndOfLifeDateUtc ? Summary.summary(moment.utc(model.EndOfLifeDateUtc).local().toString()) : Summary.placeholder("No end of life date set")}
                help={<span>From this date, deployments using this worker image will start to fail.</span>}>
                <InputWithActions
                    input={
                        <DatePicker variant="inline"
                                    label="End of Life Date"
                                    value={model.EndOfLifeDateUtc}
                                    onChange={endOfLifeDateUtc => this.setModelState({EndOfLifeDateUtc: endOfLifeDateUtc})}
                                    error={this.getFieldError("EndOfLifeDateUtc")} />
                    }
                    actions={
                        <IconButton toolTipContent="Clear End of Life Date"
                                    onClick={() => this.setModelState({EndOfLifeDateUtc: null})}
                                    icon={Icon.Remove} />
                    } />
            </ExpandableFormSection>

            <ExpandableFormSection
                errorKey="WorkerStateTimeouts"
                title="Worker State Timeouts"
                summary={Summary.summary("Maximum timeouts for worker states - per worker type")}
                help={<span>Maximum times for worker states after which they will timeout and be queued for deletion - per worker type</span>}>
                <Text label="Maximum 'Pending Configure Trust' Time (dd.hh:mm:ss)"
                      value={model.MaximumPendingConfigureTrustTime}
                      onChange={maximumPendingConfigureTrustTime => this.setModelState({MaximumPendingConfigureTrustTime: maximumPendingConfigureTrustTime})}
                      validate={required("Please enter the timeout for Pending Configure Trust state")}
                      error={this.getFieldError("MaximumPendingConfigureTrustTime")} 
                    />
            </ExpandableFormSection>

            <ExpandableFormSection
                errorKey="KeepFailedWorkersForDebugging"
                title="Keep Failed Workers For Debugging"
                summary={model.KeepFailedWorkersForDebugging ? Summary.summary("Enabled, failed workers will be kept for debugging") : Summary.placeholder("Disabled, failed workers will be queued for deletion")}
                help={<span>Automatically keep for debugging workers that fail provisioning or health checks?</span>}>

                <Checkbox label="Keep Failed Workers For Debugging"
                           value={model.KeepFailedWorkersForDebugging}
                           onChange={keepFailedWorkersForDebugging => this.setModelState({KeepFailedWorkersForDebugging: keepFailedWorkersForDebugging})}
                           error={this.getFieldError("KeepFailedWorkersForDebugging")}
                />
            </ExpandableFormSection>

            {Object.keys(this.state.model.Sizes).map(k => {
                return <ExpandableFormSection
                    errorKey={k}
                    title={k}
                    summary={Summary.summary(`Size configuration for pool ${k}`)}
                    help={<span>Size configuration for pool {k}</span>}>
                        <DataTable>
                            <DataTableBody>
                                <DataTableRow>
                                    <Text label="Desired Pool Size (must be positive)"
                                          value={this.state.model.Sizes[k].DesiredPoolSize.toString()}
                                          onChange={DesiredPoolSize => this.setSizeProperty(k, { DesiredPoolSize: Number(DesiredPoolSize) })}
                                          validate={required("Please enter the desired pool size")} />
                                    <Text label="Maximum configuration time (dd.hh:mm:ss)"
                                          value={this.state.model.Sizes[k].MaximumConfigurationTime}
                                          onChange={MaximumConfigurationTime => this.setSizeProperty(k, { MaximumConfigurationTime })} />
                                    <Text label="Maximum provision time (dd.hh:mm:ss)"
                                          value={this.state.model.Sizes[k].MaximumProvisionTime}
                                          onChange={MaximumProvisionTime => this.setSizeProperty(k, { MaximumProvisionTime })} />
                                </DataTableRow>
                            </DataTableBody>
                        </DataTable>
                </ExpandableFormSection>; })}
        </div>;
    }

    private setSizeProperty(size: string, state: Partial<WorkerTypeSizeResource>) {
        this.setState({model: { ...this.state.model, Sizes: { ...this.state.model.Sizes, [size]: { ...this.state.model.Sizes[size], ...state }}}});
    }

    private handleSaveClick = () => {
        return this.doBusyTask(async () => {
            this.state.model.Id
                ? await repository.DynamicWorkerServices.updateWorkerType(this.state.workerServiceId, this.state.model)
                : await repository.DynamicWorkerServices.createWorkerType(this.state.workerServiceId, this.state.model);
            this.setState({redirectTo: dynamicWorkerServicesRouteLinks.service(this.state.workerServiceId).workerTypes});
        });
    };
}
