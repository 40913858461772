// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* This file is DEPRECATED
   We are moving to the Octopus Design System
   https://www.octopus.design
  
   When changing component styles:
   1. Do not use variables from this file
   2. Aim to use Emotion \`css\` + themeTokens.colors
       - see Dashboard.tsx for example
   3. If you need to use Less, reference CSS variables
       - see index.ejs for example
       - for full list see https://github.com/OctopusDeploy/OctopusDeploy/blob/main/frontend/packages/design-system-tokens/src/generated/themeTokens.ts
   4. Remove variables from this file when they are no longer used
 */
.styles_clickableChip__OxMpd > div:first-child:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  opacity: 0;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  border-radius: 1rem;
  z-index: 0;
}
.styles_clickableChip__OxMpd > div:first-child:hover:after {
  opacity: 1;
}
.styles_clickableChip__OxMpd > div span {
  z-index: 1;
}
`, "",{"version":3,"sources":["webpack://./app/components/Chips/styles.less"],"names":[],"mappings":"AAAA;;;;;;;;;;;;EAYE;AAVF;EAGQ,WAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,MAAA;EACA,OAAA;EACA,8BAAA;EACA,UAAA;EACA,oBAAA;EACA,4BAAA;EACA,yBAAA;EACA,mBAAA;EACA,UAAA;AAUR;AAzBA;EAkBQ,UAAA;AAUR;AA5BA;EAqBQ,UAAA;AAUR","sourcesContent":["@import \"../../resources/styles/colors\";\n\n.clickableChip {\n    // We target the first child of this InternalLink, the Chip.\n    > div:first-child:after {\n        content: '';\n        position: absolute;\n        width: 100%;\n        height:100%;\n        top:0;\n        left:0;\n        background:rgba(0,0,0,0.2);\n        opacity: 0;\n        transition: all 0.5s;\n        -webkit-transition: all 0.5s;\n        -moz-transition: all 0.5s;\n        border-radius: 1rem;\n        z-index: 0\n    }\n    > div:first-child:hover:after {\n        opacity: 1;\n    }\n    > div span {\n        z-index: 1;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"clickableChip": `styles_clickableChip__OxMpd`
};
module.exports = ___CSS_LOADER_EXPORT___;
