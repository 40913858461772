import * as React from "react";
import {Component} from "react";
import {BackgroundTaskSummaryResource} from "client/resources/backgroundTaskSummaryResource";
import splitStringIntoWords from "utils/StringHelper/splitStringIntoWords";
import {FriendlyLocalTime} from "shared/FriendlyLocalTime";
import DurationBetweenLabel from "components/TimeLabels/DurationBetweenLabel";
import {BackgroundProcessResource} from "client/resources/backgroundProcessResource";
import {shortProcessName} from "shared/shortProcessName";
import {backgroundTasksRouteLinks} from "areas/backgroundTasks/backgroundTasksRouteLinks";
import {displayTaskStatus} from "areas/backgroundTasks/displayTaskStatus";
import {getProcessDescription} from "areas/backgroundTasks/getProcessDescription";
import {getStepDescription} from "areas/backgroundTasks/getStepDescription";
import InternalLink from "components/Navigation/InternalLink/InternalLink";
import moment from "moment";
import {PagingInfo, SimpleDataTablePaging} from "../../components/SimpleDataTable/SimpleDataTablePaging";

interface Props {
    tasks: BackgroundTaskSummaryResource[];
    processes: BackgroundProcessResource[];
    onPageChange: (skip: number, page: number) => void;
    paging: PagingInfo;
}

class BackgroundTasksSummaryTable extends Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return <React.Fragment>
            <SimpleDataTablePaging<BackgroundTaskSummaryResource>
                data={this.props.tasks}
                onRow={(item: BackgroundTaskSummaryResource) => this.buildRow(item)}
                onRowRedirectUrl={(task: BackgroundTaskSummaryResource) => backgroundTasksRouteLinks.task(task.Id).root}
                headerColumns={["ID", "Process", "Status", "Intent", "Next Step", "Current Step", "Created", "Started", "Duration"]}
                onEmpty={<div>No tasks found</div>}
                paging={{...this.props.paging, pageSelected: (skip: number, page: number) => this.props.onPageChange(skip, page)}}
            />
        </React.Fragment>;
    }

    private buildRow(task: BackgroundTaskSummaryResource) {

        return [
            <InternalLink to={backgroundTasksRouteLinks.task(task.Id).root}>{task.Id}</InternalLink>,
            <span title={getProcessDescription(task.ProcessId, this.props.processes)}>{shortProcessName(task.ProcessId)}</span>,
            displayTaskStatus(task.Status),
            <span title={task.Intent}>{task.Intent}</span>,
            <span title={getStepDescription(task.ProcessId, task.NextStepId, this.props.processes)}>{splitStringIntoWords(task.NextStepId)}</span>,
            <span title={getStepDescription(task.ProcessId, task.CurrentStepId, this.props.processes)}>{splitStringIntoWords(task.CurrentStepId)}</span>,
            <FriendlyLocalTime time={task.Created}/>,
            task.Started && <FriendlyLocalTime time={task.Started}/>,
            task.Started && <DurationBetweenLabel from={task.Started} to={task.Finished || moment().toISOString()}/>
        ];
    }
}

export default BackgroundTasksSummaryTable;