// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* This file is DEPRECATED
   We are moving to the Octopus Design System
   https://www.octopus.design
  
   When changing component styles:
   1. Do not use variables from this file
   2. Aim to use Emotion \`css\` + themeTokens.colors
       - see Dashboard.tsx for example
   3. If you need to use Less, reference CSS variables
       - see index.ejs for example
       - for full list see https://github.com/OctopusDeploy/OctopusDeploy/blob/main/frontend/packages/design-system-tokens/src/generated/themeTokens.ts
   4. Remove variables from this file when they are no longer used
 */
.style_actionList__rC3vh {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}
.style_actionList__rC3vh > :not(:first-child) {
  margin-left: 0.25rem !important;
}
.style_actionList__rC3vh > a:not(:first-child) {
  margin-left: 1rem !important;
}
`, "",{"version":3,"sources":["webpack://./app/components/ActionList/style.less"],"names":[],"mappings":"AAAA;;;;;;;;;;;;EAYE;AAVF;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,eAAA;AAYJ;AATA;EACI,+BAAA;AAWJ;AARA;EACI,4BAAA;AAUJ","sourcesContent":["@import \"../../resources/styles/colors\";\n\n.actionList {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    flex-wrap: wrap; // Need this to wrap for mobile.\n}\n\n.actionList > :not(:first-child) {\n    margin-left: 0.25rem !important;\n}\n\n.actionList > a:not(:first-child) {\n    margin-left: 1rem !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actionList": `style_actionList__rC3vh`
};
module.exports = ___CSS_LOADER_EXPORT___;
