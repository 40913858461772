import * as React from "react";
import FlatButton from "material-ui/FlatButton";
import FormFieldProps from "../FormFieldProps";
import styles = require("./style.less");
import cn from "classnames";
import Note from "components/form/Note/Note";
import ExternalLink from "components/Navigation/ExternalLink/ExternalLink";
import Text, {TextInput} from "components/form/Text/Text";
import ToolTip from "components/ToolTip/ToolTip";
import { divider, primary, secondaryText } from "../../../colors";
import { MarkdownProvider } from "components/form/MarkdownEditor/MarkdownProvider";

interface Selection {
    start: number;
    end: number;
}

interface MarkdownEditorProps extends FormFieldProps<string> {
    autoFocus?: boolean;
    hintText?: string;
    restrictHeight?: boolean;
    disabled?: boolean;
    label?: string | JSX.Element;
    error?: string;
    validate?(value: string): string;
    onValidate?(value: string): void;
}

interface MarkdownEditorState {
    error?: string;
    showExternalError: boolean;
}

export default class MarkdownEditor extends React.Component<MarkdownEditorProps, MarkdownEditorState> {
    static defaultProps: Partial<MarkdownEditorProps> = {
        autoFocus: false
    };
    text: TextInput;

    constructor(props: MarkdownEditorProps) {
        super(props);
        this.state = {
            error: null,
            showExternalError: true
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps: MarkdownEditorProps) {
        const isNewExternalErrorAvailable = nextProps.error !== this.props.error;
        if (isNewExternalErrorAvailable) {
            this.setState({showExternalError: true});
        }
    }

    handleChange = (value: string) => {
        this.callValidateAndChange(value);
    }

    callValidateAndChange(value: string) {
        if (this.props.validate) {
            const validateResult = this.props.validate(value);
            this.setState({error: validateResult});
            if (this.props.onValidate) {
                this.props.onValidate(validateResult);
            }
        }
        this.setState({showExternalError: false});
        this.props.onChange(value);
    }

    getInputState = (): MarkdownProvider => {
        const value = this.props.value == null ? "" : this.props.value;
        const selection = this.text.getSelection();
        return new MarkdownProvider(value, selection.start, selection.end);
    }

    setInputState = (value: string, selectionStart: number, selectionEnd: number) => {
        const selection = {
            start: selectionStart,
            end: selectionEnd
        };
        this.text.setValueAndSelection(selection, value);
        this.text.focus();
    }

    bold = () => {
        this.getInputState()
            .insertBefore("**")
            .insertAfter("**")
            .emptySelectionText("Bold text")
            .apply(this.setInputState);
    }

    italic = () => {
        this.getInputState()
            .insertBefore("_")
            .insertAfter("_")
            .emptySelectionText("Italic text")
            .apply(this.setInputState);
    }

    bullet = () => {
        this.getInputState()
            .surroundWithNewlines()
            .insertBefore("- ")
            .emptySelectionText("List item")
            .apply(this.setInputState);
    }

    number = () => {
        this.getInputState()
            .surroundWithNewlines()
            .insertBefore("1. ")
            .emptySelectionText("List item")
            .apply(this.setInputState);
    }

    quote = () => {
        this.getInputState()
            .surroundWithNewlines()
            .insertBefore("> ")
            .insertAfterNewlineInSelection("> ")
            .emptySelectionText("Quoted text")
            .apply(this.setInputState);
    }

    code = () => {
        this.getInputState()
            .surroundWithNewlines()
            .insertBefore("```\n")
            .insertAfter("\n```")
            .emptySelectionText("Code")
            .apply(this.setInputState);
    }

    link = () => {
        const url = prompt("Please enter a url for the link:", "http://");
        if (url) {
            this.getInputState()
                .insertBefore("[")
                .insertAfter("](" + url + ")")
                .emptySelectionText("Enter link description here")
                .apply(this.setInputState);
        }
    }

    image = () => {
        const url = prompt("Please enter a url for the image:", "http://");
        if (url) {
            this.getInputState()
                .insertBefore("![")
                .insertAfter("](" + url + ")")
                .emptySelectionText("Enter image description here")
                .apply(this.setInputState);
        }
    }

    render() {
        const {
            value,
            label,
            validate,
            error,
            onChange,
            onValidate,
            hintText,
            ...otherProps
        } = this.props;

        const errorText = this.state.error || (this.state.showExternalError && error);
        const val = value ? value : "";
        return (
            <div className={cn(styles.markdownEditorContainer, this.props.restrictHeight && styles.restrictedHeight)}>
                {this.createButtons()}
                <Text
                    textInputRef={(text: TextInput) => {
                        this.text = text;
                    }}
                    value={val}
                    onChange={this.handleChange}
                    error={errorText}
                    label={label}
                    hintText={hintText}
                    multiLine={true}
                    rows={3}
                    showBorder={true}
                    {...otherProps}
                />
                <Note>
                    <ExternalLink href="MasteringMarkdown">
                        <em className={`fa fa-pencil ${styles.markdownSupported}`} aria-hidden="true"/>
                        Styling with Markdown is supported
                    </ExternalLink>
                </Note>
            </div>
        );
    }

    private createButtons() {
        return <div className={styles.iconButtonContainer}>
            {[
                this.editAction("Bold", "fa-bold", this.bold),
                this.editAction("Italic", "fa-italic", this.italic),
                this.editAction("Bulleted list", "fa-list-ul", this.bullet),
                this.editAction("Numeric list", "fa-list-ol", this.number),
                this.editAction("Picture", "fa-image", this.image),
                this.editAction("Link", "fa-link", this.link),
                this.editAction("Code", "fa-code", this.code),
                this.editAction("Quotes", "fa-quote-left", this.quote),
            ]}
        </div>;
    }

    private editAction(label: string, faIcon: string, onClick: () => void) {
        return <ToolTip content={label} key={`tooltip_${faIcon}`}>
            <FlatButton tabIndex={-1}
                className={styles.iconButton}
                onClick={onClick}
                label={<em className={`fa ${faIcon}`} aria-hidden="true"/>}/>
        </ToolTip>;
    }
}
