import {ReefResource} from "client/resources/reefResource";
import {CreateReefResource} from "client/resources/createReefResource";
import client from "client/client";
import {CloneReefRequest} from "client/resources/cloneReefRequest";
import {
    ChangeAvailabilityForCustomersRequest
} from "client/resources/changeAvailabilityForCustomersRequest";
import {ChangeInstanceUsageReportingRequest} from "client/resources/changeInstanceUsageReportingRequest";
import {ChangeHostedScriptVersionRequest} from "client/resources/changeHostedScriptVersionRequest";
import {ChangePoolConfigurationRequest} from "client/resources/changePoolConfigurationRequest";
import {ChangeDynamicWorkerServiceRequest} from "client/resources/changeDynamicWorkerServiceRequest";
import {ChangeReefHubRequest} from "client/resources/changeReefHubRequest";
import { CreateSqlServerRequestResource } from "client/resources/createSqlServerRequestResource";
import { CreateKubernetesClusterRequest } from "client/resources/createKubernetesClusterRequest";
import {KubernetesClusterNodeResource} from "../resources/KubernetesClusterNodeResource";
import { DecommissionNodeRequest } from "client/resources/decommissionNodeRequest";
import {UpgradeKubernetesClustersRequest} from "../resources/upgradeKubernetesClustersRequest";
import { MoveFileStoragesRequest } from "client/resources/moveFileStoragesRequest";
import { ChangeSqlServerAdminPasswordRequest } from "client/resources/changeSqlServerAdminLoginRequest";
import {ChangeStorageAccountAvailabilityRequest} from "../resources/changeStorageAccountAvailabilityRequest";
import {ChangeReefDisplayNameRequest} from "../resources/changeReefDisplayNameRequest";
import {CreateStorageAccountRequest} from "../resources/createStorageAccountRequest";
import { KubernetesClusterStateResource } from "../resources/KubernetesClusterStateResource";

export default class ReefRepository {

    private rootUri = "/api/reefs";

    list(): Promise<ReefResource[]> {
        return client.get(this.rootUri);
    }

    async map(): Promise<Map<string, ReefResource>> {
        const all = await this.list();
        return new Map<string, ReefResource>(all.map<[string, ReefResource]>(r => [r.Id, r]));
    }

    get(id: string): Promise<ReefResource> {
        return client.get(`${this.rootUri}/{id}`, {id});
    }

    create(resource: CreateReefResource): Promise<ReefResource> {
        return client.post(`${this.rootUri}`, resource);
    }

    createSqlServer(id: string, resource: CreateSqlServerRequestResource) {
        return client.post(`${this.rootUri}/{id}/sql-server`, resource, {id});
    }

    createStorageAccount(id: string, request: CreateStorageAccountRequest) {
        return client.post(`${this.rootUri}/{id}/storage-account`, request, {id});
    }

    deleteStorageAccount(id: string, storageAccountName: string, deleteOrphanResources: boolean) {
        return client.del(`${this.rootUri}/{id}/storage-account/{storageAccountName}{?deleteOrphanResources}`, {args: {id, storageAccountName, deleteOrphanResources}});
    }

    changeStorageAccountAvailability(id: string, storageAccountName: string, request: ChangeStorageAccountAvailabilityRequest) {
        return client.put(`${this.rootUri}/{id}/storage-account/{storageAccountName}/availability`, request, {id, storageAccountName});
    }

    createKubernetesCluster(id: string, resource: CreateKubernetesClusterRequest) {
        return client.post(`${this.rootUri}/{id}/kubernetes-cluster`, resource, {id});
    }

    deleteKubernetesCluster(id: string, clusterName: string, deleteOrphanResources: boolean) {
        return client.del(`${this.rootUri}/{id}/clusters/{clusterName}{?deleteOrphanResources}`, {args: {id, clusterName, deleteOrphanResources}});
    }

    changeAvailabilityForCustomers(id: string, request: ChangeAvailabilityForCustomersRequest) {
        return client.put(`${this.rootUri}/{id}/availability-for-customers`, request, {id});
    }

    changeInstanceUsageReporting(id: string, request: ChangeInstanceUsageReportingRequest) {
        return client.put(`${this.rootUri}/{id}/instance-usage-reporting`, request, {id});
    }

    bulkUpdateHostedScriptVersions(request: ChangeHostedScriptVersionRequest) {
        return client.put(`${this.rootUri}/hosted-script-version`, request);
    }

    changePoolConfiguration(id: string, request: ChangePoolConfigurationRequest) {
        return client.put(`${this.rootUri}/{id}/pool-configuration`, request, {id});
    }

    changeDynamicWorkerServiceId(id: string, request: ChangeDynamicWorkerServiceRequest) {
        return client.put(`${this.rootUri}/{id}/dynamic-worker-service`, request, {id});
    }

    changeHub(id: string, request: ChangeReefHubRequest) {
        return client.put(`${this.rootUri}/{id}/change-hub`, request, {id});
    }

    changeSqlServerAdminPassword(id: string, sqlServerName: string, request: ChangeSqlServerAdminPasswordRequest) {
        return client.put(`${this.rootUri}/{id}/sql-server/{sqlServerName}/admin-password`, request, {id, sqlServerName});
    }

    remove(resource: ReefResource, deleteOrphanResources: boolean) {
        return client.del(`${this.rootUri}/{id}{?deleteOrphanResources}`, {args: {id: resource.Id, deleteOrphanResources}});
    }

    clone(reef: ReefResource, cloneReefRequest: CloneReefRequest): Promise<ReefResource> {
        return client.post(`${this.rootUri}/{id}/clone`, cloneReefRequest, {id: reef.Id});
    }

    grantAccessToAllEngineers(reef: ReefResource) {
        return client.put(`${this.rootUri}/{id}/access-to-engineering-team`, {}, {id: reef.Id});
    }

    listKubernetesClusterStates(reefId?: string): Promise<KubernetesClusterStateResource[]> {
        return reefId
            ? client.get(`${this.rootUri}/{reefId}/cluster-states`, {reefId})
            : client.get(`${this.rootUri}/cluster-states`);
    }

    async mapKubernetesClusterStates(reefId?: string): Promise<Map<string, KubernetesClusterStateResource>> {
        const all = await this.listKubernetesClusterStates(reefId);
        return new Map<string, KubernetesClusterStateResource>(all.map<[string, KubernetesClusterStateResource]>(c => [c.Name, c]));
    }

    deleteNode(id: string, clusterName: string, nodeName: string, decommissionNodeRequest: DecommissionNodeRequest): Promise<string> {
        return client.del<string>(`${this.rootUri}/{id}/clusters/{clusterName}/nodes/{nodeName}`, {args: {id, clusterName, nodeName}, requestBody: decommissionNodeRequest});
    }

    getNodes(id: string, clusterName: string): Promise<KubernetesClusterNodeResource[]> {
        return client.get(`${this.rootUri}/{id}/clusters/{clusterName}/nodes`, {id, clusterName});
    }

    upgradeKubernetesClusters(id: string, request: UpgradeKubernetesClustersRequest) {
        return client.put(`${this.rootUri}/{id}/upgrade-kubernetes-clusters`, request, {id});
    }

    moveFileStorages(id: string, request: MoveFileStoragesRequest) {
        return client.put(`${this.rootUri}/{id}/move-file-storages`, request, {id});
    }

    reprovision(reef: ReefResource) {
        return client.put(`${this.rootUri}/{id}/reprovisioning`, {id: reef.Id}, {id: reef.Id});
    }
    
    changeDisplayName(id: string, request: ChangeReefDisplayNameRequest){
        return client.put(`${this.rootUri}/{id}/display-name`, request, {id})
    }
}
