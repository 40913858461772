export interface FeatureFlagsResource {
	Features: FeatureToggle[];
}

export interface FeatureToggle {
	Id: string;
	Name: string;
	IsEnabled: boolean;
	MoreInfoLinkUri?: string;
	AppliesTo: FeatureToggleScope;
	Granularity: FeatureFlagScopeLevel;
}

export interface FeatureToggleScope {
	ReefIds: string[];
	InstanceIds: string[];
	Scope: FeatureFlagScopeLevel;
	MinimumVersion?: string;
	ExcludedInstanceIds: string[];
}

export enum FeatureFlagScopeLevel {
    Global = "Global",
	Reef = "Reef",
	Instance = "Instance",
}