import React from "react";
import { Link as ReactRouterLink, LinkProps as ReactRouterLinkProps, RouteComponentProps } from "react-router-dom";

type HTMLAnchorAttributes = React.AnchorHTMLAttributes<HTMLAnchorElement>;

type InternalLinkProps = HTMLAnchorAttributes & RouteComponentProps;

const PortalLink = React.forwardRef<HTMLAnchorElement, InternalLinkProps>(({ className, href, tabIndex, onClick, target, children, ...other }, ref) => {
    return (
        <ReactRouterLink
            className={className}
            innerRef={ref}
            to={href}
            target={target as ReactRouterLinkProps["target"]}
            onClick={onClick}
            /*
            We need to spread unknown props here as the design link could be used
            in situations where the props aren't explicit i.e. material menu list items,
            the basic props for regular usage should still be constrained.
            */
            {...other}
        >
            {children}
        </ReactRouterLink>
    );
});

export default PortalLink;
