import React from "react";
import {themeTokens} from "@octopusdeploy/design-system-tokens";
import IconMenu from "material-ui/IconMenu";
import IconButton from "material-ui/IconButton";
import MaterialMenuItem from "material-ui/MenuItem";
import Divider from "components/Divider/Divider";
import MoreVertIcon from "material-ui/svg-icons/navigation/more-vert";
import {flatten} from "lodash";
import {BaseComponent} from "components/BaseComponent/BaseComponent";
import styles = require("./style.less");
import OverflowMenuLink from "./OverflowMenuLink";
import {DoBusyTask} from "../DataBaseComponent/DataBaseComponent";
import OpenDeleteDialogMenuItem from "components/Menu/OpenDeleteDialogMenuItem";
import OpenDialogMenuItem from "components/Menu/OpenDialogMenuItem";

enum OverflowMenuItemKind {
    Delete,
    Dialog,
    Generic,
    Disabled,
    Navigation,
    Download,
    Remove,
    ExternalNavigation
}

interface Item {
    kind: OverflowMenuItemKind;
}

export interface OverflowMenuDialogItem extends Item {
    text: string;
    wide?: boolean;
    child: JSX.Element;
}

export interface OverflowMenuDeleteItem extends Item {
    text: string;
    title: string;
    onClick: () => Promise<boolean>;
    content: React.ReactNode;
    deleteButtonDisabled: boolean;
}

export interface OverflowMenuRemoveItem extends Item {
    text: string;
    title: string;
    onClick: () => Promise<boolean>;
    content: React.ReactNode;
    removeButtonDisabled: boolean;
}

export interface OverflowMenuNavLink extends Item {
    text: string;
    path: string;
    newTab: boolean;
    queryString?: string;
}

export interface OverflowMenuDownloadItem extends Item {
    text: string;
    link: string;
    filename: string;
}

export interface OverflowMenuDisabledItem extends Item {
    text: string;
    reason: string;
}

export interface OverflowMenuGenericItem extends Item {
    text: string;
    onClick: () => void;
}

function isGroup(item: MenuItem | MenuItem[]): item is MenuItem[] {
    return Array.isArray(item as MenuItem[]);
}

function isOverflowMenuDialogItem(item: MenuItem): item is OverflowMenuDialogItem {
    return (item as OverflowMenuDialogItem).kind === OverflowMenuItemKind.Dialog;
}

function isOverflowMenuDeleteItem(item: MenuItem): item is OverflowMenuDeleteItem {
    return (item as OverflowMenuDeleteItem).kind === OverflowMenuItemKind.Delete;
}

function isOverflowMenuRemoveItem(item: MenuItem): item is OverflowMenuRemoveItem {
    return (item as OverflowMenuRemoveItem).kind === OverflowMenuItemKind.Remove;
}

function isOverflowMenuDownloadItem(item: MenuItem): item is OverflowMenuDownloadItem {
    return (item as OverflowMenuDownloadItem).kind === OverflowMenuItemKind.Download;
}

function isOverflowMenuNavLink(item: MenuItem): item is OverflowMenuNavLink {
    return (item as OverflowMenuNavLink).kind === OverflowMenuItemKind.Navigation;
}

function isOverflowMenuExternalNavLink(item: MenuItem): item is OverflowMenuNavLink {
    return (item as OverflowMenuNavLink).kind === OverflowMenuItemKind.ExternalNavigation;
}

function isOverflowMenuDisabledItem(item: MenuItem): item is OverflowMenuDisabledItem {
    return (item as OverflowMenuDisabledItem).kind === OverflowMenuItemKind.Disabled;
}

function isOverflowMenuGenericItem(item: MenuItem): item is OverflowMenuGenericItem {
    return (item as OverflowMenuGenericItem).kind === OverflowMenuItemKind.Generic;
}

export type MenuItem =
    OverflowMenuDialogItem
    | OverflowMenuDeleteItem
    | OverflowMenuNavLink
    | OverflowMenuDownloadItem
    | OverflowMenuGenericItem
    | OverflowMenuDisabledItem
    | OverflowMenuRemoveItem;

interface ConversionResult {
    menuItem: React.ReactNode;
    dialog?: React.ReactNode;
}

interface OverflowMenuProps {
    menuItems: Array<MenuItem | MenuItem[]>;
    tabIndex?: number;
    menuKey?: string;
    colorOverride?: string;
}

class OverflowMenu extends BaseComponent<OverflowMenuProps, any> {

    static dialogItem(text: string, child: JSX.Element, wide: boolean = null): OverflowMenuDialogItem {
        return {text, child, wide, kind: OverflowMenuItemKind.Dialog};
    }

    static removeItem(text: string, title: string, onClick: () => Promise<boolean>, content: React.ReactNode, removeButtonDisabled: boolean = false): OverflowMenuRemoveItem {
        return {text, title, onClick, content, kind: OverflowMenuItemKind.Remove, removeButtonDisabled};
    }

    static deleteItem(text: string,
                      title: string,
                      onClick: () => Promise<boolean>,
                      content: ((doBusyTask: DoBusyTask) => React.ReactNode) | React.ReactNode,
                      deleteButtonDisabled: boolean = false): OverflowMenuDeleteItem {
        return {text, title, onClick, content, kind: OverflowMenuItemKind.Delete, deleteButtonDisabled};
    }

    static deleteItemDefault(name: string, onClick: () => Promise<boolean>, customMessage?: string, customContent?: JSX.Element, deleteButtonDisabled: boolean = false): OverflowMenuDeleteItem {
        return {
            text: "Delete",
            title: `Are you sure you want to delete this ${name}?`,
            onClick,
            kind: OverflowMenuItemKind.Delete,
            deleteButtonDisabled,
            content: <div>
                <p>Deleting this {name} is permanent, there is no going back.</p>
                {customMessage && <p>{customMessage}</p>}
                {customContent}
                <p>Do you wish to continue?</p>
            </div>
        };
    }

    static navItem(text: string, path: string, queryString?: string): OverflowMenuNavLink {
        return {text, path, queryString, kind: OverflowMenuItemKind.Navigation, newTab: false};
    }

    static externalNavItem(text: string, path: string, queryString?: string): OverflowMenuNavLink {
        return {text, path, queryString, kind: OverflowMenuItemKind.ExternalNavigation, newTab: true};
    }

    static disabledItem(text: string, reason: string): OverflowMenuDisabledItem {
        return {text, reason, kind: OverflowMenuItemKind.Disabled};
    }

    static item(text: string, onClick: () => void): OverflowMenuGenericItem {
        return {text, onClick, kind: OverflowMenuItemKind.Generic};
    }

    static downloadItem(text: string, filename: string, link: string) {
        return {text, link, filename, kind: OverflowMenuItemKind.Download};
    }

    render() {
        // Permissions may cause null entries in our menuItems, so we do null checking before map.
        const result = this.props.menuItems && this.props.menuItems.filter(item => !!item).map((item, index) => {
            if (isGroup(item)) {
                const results = item.filter(subItem => !!subItem).map(groupItem => this.convert(groupItem));
                if (results.length === 0) {
                    return null;
                }
                // This should be smart enough to know if it needs to add a divider at the start or end of a grouping/array.
                if (index > 0 && !isGroup(this.props.menuItems[index - 1])) {
                    // Show the divider at the start of this grouping.
                    // I.e. The last thing wasn't a group, so we're good to create one here to indicate the start of a grouping.
                    results.splice(0, 0, {menuItem: <Divider key={index}/>});
                } else if (index < this.props.menuItems.length - 1) {
                    // Show the divider at the end of this grouping.
                    results.push({menuItem: <Divider key={index}/>});
                }
                return results;
            }
            return [this.convert(item)];
        });

        const dialogs: any[] = [];
        const menuItems = flatten(result).filter(r => !!r).map(r => {
            if (r.dialog) {
                dialogs.push(r.dialog);
            }
            return r.menuItem;
        });

        return <div onClick={this.iconMenuOnClickOverride}>
            {dialogs}
            {menuItems && menuItems.length > 0 &&
            <IconMenu
                iconButtonElement={<IconButton
                    className={styles.menuIcon}
                    tabIndex={this.props.tabIndex}
                    title="Options">
                    <MoreVertIcon
                        tabIndex={this.props.tabIndex}
                        color={this.props.colorOverride ? this.props.colorOverride : themeTokens.color.menuList.icon.primary}/>
                </IconButton>}
                anchorOrigin={{horizontal: "right", vertical: "bottom"}}
                targetOrigin={{horizontal: "right", vertical: "top"}}>
                {menuItems}
            </IconMenu>}
        </div>;
    }

    private iconMenuOnClickOverride(e: any) {
        e.preventDefault();
        e.stopPropagation(); //prevent this click toggling expanders (when IconMenus are used in expanders).
    }

    private convertDeleteMenuItem(item: OverflowMenuDeleteItem) {
        let dialog: any;
        const dialogMenuItem = <OpenDeleteDialogMenuItem ref={d => dialog = d}
                                                         deleteButtonLabel={item.text}
                                                         disabled={false}
                                                         deleteButtonDisabled={item.deleteButtonDisabled}
                                                         dialogTitle={item.title}
                                                         key={`${this.props.menuKey}-${item.text}`}
                                                         onDeleteClick={item.onClick}
                                                         renderContent={(doBusyTask: DoBusyTask) => {
                                                             if (typeof(item.content) === "function") {
                                                                 return item.content(doBusyTask);
                                                             }
                                                             return item.content;
                                                         }}
        />;

        return {
            menuItem: <MaterialMenuItem
                primaryText={item.text}
                key={`${this.props.menuKey}-${item.text}`}
                onClick={() => dialog.onClick()}
            />,
            dialog: dialogMenuItem
        };
    }

    private convert(item: MenuItem): ConversionResult {

        if (isOverflowMenuDialogItem(item)) {
            return this.convertDialogMenuItem(item);
        }

        if (isOverflowMenuDeleteItem(item)) {
            return this.convertDeleteMenuItem(item);
        }

        if (isOverflowMenuRemoveItem(item)) {
            return this.convertRemoveMenuItem(item);
        }

        if (isOverflowMenuDownloadItem(item)) {
            return {
                menuItem: <MaterialMenuItem
                    primaryText={item.text}
                    key={`${this.props.menuKey}-${item.text}`}
                    containerElement={<OverflowMenuLink resolve={false}
                                                        downloadFileName={item.filename}
                                                        to={item.link}
                                                        newTab={false}/>}/>
            };
        }

        if (isOverflowMenuNavLink(item)) {
            return this.linkMenuItem(item, false);
        }

        if (isOverflowMenuExternalNavLink(item)) {
            return this.linkMenuItem(item, true);
        }

        if (isOverflowMenuDisabledItem(item)) {
            return {
                menuItem: <MaterialMenuItem
                    key={`${this.props.menuKey}-${item.text}`}
                    disabled={true}>
                    <a href="#" onClick={e => e.preventDefault()} className={styles.disabledItem}
                       title={item.reason}>{item.text}</a>
                </MaterialMenuItem>
            };
        }

        return {
            menuItem: <MaterialMenuItem
                primaryText={item.text}
                key={`${this.props.menuKey}-${item.text}`}
                onClick={item.onClick}/>
        };
    }

    private linkMenuItem(item: OverflowMenuNavLink, isExternal: boolean) {
        const text = item.newTab
            ? <span>{item.text} <em style={{marginLeft: "0.250rem"}} className="fa fa-external-link" aria-hidden="true"/></span>
            : item.text;

        return {
            menuItem: <MaterialMenuItem
                primaryText={text}
                key={`${this.props.menuKey}-${item.text}`}
                containerElement={<OverflowMenuLink to={{pathname: item.path, search: item.queryString}} newTab={item.newTab} resolve={!isExternal} />}
            />
        };

    }

    private convertRemoveMenuItem(item: OverflowMenuRemoveItem) {
        let dialog: any;
        const dialogMenuItem = <OpenDeleteDialogMenuItem ref={d => dialog = d}
                                                         label={item.text}
                                                         disabled={false}
                                                         deleteButtonDisabled={item.removeButtonDisabled}
                                                         dialogTitle={item.title}
                                                         deleteButtonLabel="Remove"
                                                         deleteButtonBusyLabel="Removing"
                                                         key={`${this.props.menuKey}-${item.text}`}
                                                         onDeleteClick={item.onClick}
                                                         renderContent={() => item.content}
        />;

        return {
            menuItem: <MaterialMenuItem
                primaryText={item.text}
                key={`${this.props.menuKey}-${item.text}`}
                onClick={() => dialog.onClick()}
            />,
            dialog: dialogMenuItem
        };
    }

    private convertDialogMenuItem(item: OverflowMenuDialogItem) {

        let dialog: any;
        const dialogMenuItem = <OpenDialogMenuItem ref={(d: any) => dialog = d} label={item.text}
                                                   key={`${this.props.menuKey}-${item.text}`}
                                                   wide={item.wide} >
            {item.child}
        </OpenDialogMenuItem>;

        return {
            menuItem: <MaterialMenuItem
                primaryText={item.text}
                key={`${this.props.menuKey}-${item.text}`}
                onClick={() => dialog.getWrappedInstance().onClick()}/>,
            dialog: dialogMenuItem
        };
    }

    private alternate(alternate: string) {
        return alternate && {menuItem: <MaterialMenuItem key={alternate} primaryText={alternate}/>};
    }
}

export default OverflowMenu;