import * as React from "react";
import { FormFieldProps } from "components/form";
import { SelectItem } from "components/VirtualListWithKeyboard/SelectItem";
import UpgradeStrategyChip from "shared/UpgradeStrategyChip";
import MultiSelect from "components/MultiSelect/MultiSelect";
import { UpgradeStrategyType, upgradeStrategyTypeDescriptions } from "client/resources/upgradeStrategy";

interface UpgradeStrategyMultiSelectProps extends FormFieldProps<UpgradeStrategyType[]> {
    canAdd?: boolean;
    openOnFocus?: boolean;
    autoFocus?: boolean;
    label?: string | JSX.Element;
    error?: string;
    hideFloatingLabel?: boolean;
    validate?(value: UpgradeStrategyType[]): string;
}

const UpgradeStrategyTypedMultiSelect = MultiSelect<SelectItem>();

const UpgradeStrategyMultiSelect: React.StatelessComponent<UpgradeStrategyMultiSelectProps> = props => {
    const onAdd = (value: UpgradeStrategyType) => {
        props.onChange(props.value.concat(value));
    };

    const chipRenderer = (r: SelectItem, onRequestDelete: (event: object) => void) => {
        return <UpgradeStrategyChip
            onRequestDelete={onRequestDelete}
            upgradeStrategy={r.Id as UpgradeStrategyType}
        />;
    };

    return <UpgradeStrategyTypedMultiSelect
        items={Object.keys(UpgradeStrategyType).map(t => ({Id: t, Name: (upgradeStrategyTypeDescriptions[t as UpgradeStrategyType] as string)}))}
        fieldName="upgrade strategy"
        label={props.label}
        renderChip={chipRenderer}
        onNew={props.canAdd ? onAdd : null}
        value={props.value}
        onChange={props.onChange}
        error={props.error}
        openOnFocus={props.openOnFocus}
        autoFocus={props.autoFocus}
        hideFloatingLabel={props.hideFloatingLabel}
    />;
};

export default UpgradeStrategyMultiSelect;
