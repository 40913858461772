import * as React from "react";
import Summary from "components/form/Sections/Summary";
import ExpandableFormSection from "components/form/Sections/ExpandableFormSection";
import FormPaperLayout from "components/FormPaperLayout/FormPaperLayout";
import OptionalFormBaseComponent, {OptionalFormBaseComponentState} from "components/form/FormBaseComponent";
import repository from "client/repository";
import {InternalRedirect} from "components/Navigation/InternalRedirect";
import {AzureRegion} from "client/resources/azureRegion";
import {cloneDeep} from "lodash";
import FormSectionHeading from "components/form/Sections/FormSectionHeading";
import {RouteComponentProps} from "react-router-dom";
import Select from "components/form/Select/Select";
import {CreateAzureRegionResource} from "client/resources/createAzureRegionResource";
import {azureRegionsRouteLinks} from "./azureRegionsRouteLinks";

type Props = RouteComponentProps;

interface State extends OptionalFormBaseComponentState<CreateAzureRegionResource> {
    redirectTo?: string;
}

class CreateAzureRegion extends OptionalFormBaseComponent<Props, State, CreateAzureRegionResource> {
    constructor(props: Props) {
        super(props);
        const model: CreateAzureRegionResource = {
            Region: undefined,
        };

        this.state = {
            model,
            cleanModel: cloneDeep(model)
        };
    }

    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo}/>;
        }

        return <FormPaperLayout
            title={`Create Azure Region`}
            breadcrumbTitle="Azure Regions"
            breadcrumbPath={azureRegionsRouteLinks.root}
            busy={this.state.busy}
            errors={this.state.errors}
            model={this.state.model}
            cleanModel={this.state.cleanModel}
            saveButtonLabel={`Create Azure Region`}
            saveText="Azure Region Created"
            saveButtonBusyLabel={"Saving"}
            expandAllOnMount={true}
            onSaveClick={this.createAzureRegion}
        >
            {this.getDetails()}
        </FormPaperLayout>;
    }

    private getDetails = () => {
        const model = this.state.model;

        return <>
            <FormSectionHeading title="General"/>
            <ExpandableFormSection
                errorKey="Azure Region"
                title="AzureRegion"
                summary={model.Region ? Summary.summary(model.Region) : Summary.placeholder("No Azure Region")}
                help={<span>Enter the Azure Region.</span>}>

                <Select value={this.state.model.Region && this.state.model.Region.toString()}
                        onChange={(value: string) => this.setModelState({Region: (value as AzureRegion)})}
                        items={Object.entries(AzureRegion).map(e => ({text: e[0], value: e[0]}))}
                        fieldName="AzureRegion"
                        hintText="The Azure Region to be created"
                />
            </ExpandableFormSection>
        </>;
    }

    private createAzureRegion = () => {
        return this.doBusyTask(async () => {
            await repository.AzureRegions.create(this.state.model);
            this.setState({redirectTo: azureRegionsRouteLinks.root});
        });
    };
}

export default CreateAzureRegion;
