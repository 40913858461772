import React from "react";
import {themeTokens} from "@octopusdeploy/design-system-tokens";
import {InstanceStatus} from "client/resources/instanceStatus";
import splitStringIntoWords from "utils/StringHelper/splitStringIntoWords";
import {BetterUptimeStatus} from "client/resources/betterUptimeStatus";
import {getBetterUptimeColorFor} from "shared/BetterUptimeStatuses";
import {SecondaryUptimeStatus} from "../../../client/resources/secondaryUptimeStatus";
import {getSecondaryUptimeColorFor} from "../../../shared/SecondaryUptimeStatuses";

const textColor = themeTokens.color.text;

export function displayInstanceStatus(status: InstanceStatus) {
    const name = splitStringIntoWords(status);
    const color = getColorFor(status);
    return <span style={{color}}>{name}</span>;
}

export function displayBetterUptimeStatus(status: BetterUptimeStatus) {
    const color = getBetterUptimeColorFor(status);
    return <span style={{color}}>{status}</span>;
}

export function displaySecondaryUptimeStatus(status: SecondaryUptimeStatus) {
    const color = getSecondaryUptimeColorFor(status);
    return <span style={{color}}>{status}</span>;
}

function getColorFor(status: InstanceStatus): string {
    switch (status) {
        case InstanceStatus.Provisioning: return textColor.info;
        case InstanceStatus.ProvisioningFailed: return textColor.danger;
        case InstanceStatus.Live: return textColor.success;
        case InstanceStatus.CustomizationFailed: return textColor.danger;
        case InstanceStatus.Deactivating: return textColor.warning;
        case InstanceStatus.Deactivated: return textColor.muted;
        case InstanceStatus.Deleting: return textColor.warning;
        case InstanceStatus.Deleted: return textColor.muted;
        default: return textColor.primary;
    }
}
