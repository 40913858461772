import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent";
import {DynamicWorkerTypeResource} from "client/resources/dynamicWorkerTypeResource";
import {DynamicWorkerServiceResource} from "client/resources/dynamicWorkerServiceResource";
import {RouteComponentProps} from "react-router-dom";
import repository from "client/repository";
import PaperLayout from "components/PaperLayout";
import {FormSectionHeading} from "components/form";
import * as React from "react";
import ActionButton from "components/Button";
import {Refresh} from "components/DataBaseComponent/DataBaseComponent";
import { DataTableRowHeaderColumn, DataTable, DataTableBody, DataTableRow, DataTableRowColumn, DataTableHeader, DataTableHeaderColumn } from "components/DataTable";
import { DataTableRowWithActionColumn } from "components/DataTable/DataTableRowColumn";
import routeLinks from "routeLinks";
import NavigationButton, {NavigationButtonType} from "components/Button/NavigationButton";
import moment = require("moment");
import buildDynamicWorkerServiceBreadcrumbTitle from "../buildDynamicWorkerServiceBreadcrumbTitle";

type Props = RouteComponentProps<{ id: string }>;

interface State extends DataBaseComponentState {
    types: DynamicWorkerTypeResource[];
    service: DynamicWorkerServiceResource;
}

class DynamicWorkerTypes extends DataBaseComponent<Props, State> {
    private refreshInitialised: boolean;
    constructor(props: Props) {
        super(props);
        this.state = {
            types: [],
            service: null,
        };
    }

    componentDidMount() {
        return this.doBusyTask(async () => {
            await this.refreshData();
        });
    }

    render() {
        const isLoaded = !!this.state.types;
        const refreshButton = <ActionButton label="Refresh" onClick={this.doRefresh}/>;
        return isLoaded && <PaperLayout title="Dynamic Worker Types"
                            breadcrumbTitle={buildDynamicWorkerServiceBreadcrumbTitle(this.state.service)}
                            busy={this.state.busy}
                            errors={this.state.errors}
                            sectionControl={refreshButton}
                            fullWidth={true}>
            <div>
            {this.state.types.map((t) => {
                return <div key={t.Id}>
                    <FormSectionHeading title={t.Type} />
                    <DataTable>
                        <DataTableBody>
                            <DataTableRow>
                                <DataTableRowHeaderColumn>Type</DataTableRowHeaderColumn>
                                <DataTableRowWithActionColumn action={
                                    <div>
                                        <NavigationButton label="Edit" href={routeLinks.dynamicWorkerServices.service(this.state.service.Id).workerType(t.Type).root} type={NavigationButtonType.Secondary}/>
                                        <NavigationButton label="Versions" href={routeLinks.dynamicWorkerServices.service(this.state.service.Id).workerSources(t.Id).root} type={NavigationButtonType.Secondary}/>
                                    </div>
                                }>{t.Type} {t.DefaultForOperatingSystem ? ` (default for ${t.OperatingSystem})` : ""}</DataTableRowWithActionColumn>
                            </DataTableRow>
                            <DataTableRow>
                                <DataTableRowHeaderColumn>Description</DataTableRowHeaderColumn>
                                <DataTableRowColumn>{t.Description}</DataTableRowColumn>
                            </DataTableRow>
                            <DataTableRow>
                                <DataTableRowHeaderColumn>Status</DataTableRowHeaderColumn>
                                <DataTableRowColumn>{this.getStatus(t)}</DataTableRowColumn>
                            </DataTableRow>
                        </DataTableBody>
                    </DataTable>
                </div>;
            })}
            </div>
        </PaperLayout>;
    }

    private IsDeprecated = (workerType: DynamicWorkerTypeResource): boolean => {
        if (workerType.DeprecationDateUtc) {
            return moment() > moment(workerType.DeprecationDateUtc);
        }
        return false;
    };

    private IsEndOfLife = (workerType: DynamicWorkerTypeResource): boolean => {
        if (workerType.EndOfLifeDateUtc) {
            return moment() > moment(workerType.EndOfLifeDateUtc);
        }
        return false;
    };

    private IsAvailable = (workerType: DynamicWorkerTypeResource): boolean => {
        if (workerType.StartDateUtc) {
            return moment() > moment(workerType.StartDateUtc);
        }
        return true;
    };

    private getStatus(workerType: DynamicWorkerTypeResource) {
        if (this.IsEndOfLife(workerType))
            return "End of life";
        if (this.IsDeprecated(workerType))
            return "Deprecated";
        if (this.IsAvailable(workerType))
            return "Available";
        return "Not yet available";
    }

    private async loadData() {
        const [types, service] = await Promise.all([
            this.loadWorkersTypes(),
            this.loadService()
        ]);

        return { types, service };
    }

    private async loadWorkersTypes() {
        return repository.DynamicWorkerServices.workerTypes(this.props.match.params.id);
    }

    private async loadService() {
        return repository.DynamicWorkerServices.get(this.props.match.params.id);
    }

    private doRefresh: Refresh = () => Promise.resolve();

    private refreshData = async () => {
        await this.doBusyTask(async () => {
            this.setState(await this.loadData());
        });

        if (this.refreshInitialised) {
            return;
        }

        this.refreshInitialised = true;
        this.doRefresh = await this.startRefreshLoop(() => this.loadData(), 5000);
    };

}

export default DynamicWorkerTypes;
