import React from "react";
import {css} from "@emotion/css";
import moment from "moment";
import {themeTokens} from "@octopusdeploy/design-system-tokens";
import repository from "client/repository";
import {
    DataTable,
    DataTableBody,
    DataTableRow,
    DataTableRowColumn,
    DataTableRowHeaderColumn
} from "components/DataTable";
import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent";
import PaperLayout from "components/PaperLayout";
import {Refresh} from "components/DataBaseComponent/DataBaseComponent";
import {BackgroundTaskResource} from "client/resources/backgroundTaskResource";
import {BackgroundTaskStatus} from "client/resources/backgroundTaskStatus";
import {displayTaskStatus} from "areas/backgroundTasks/displayTaskStatus";
import {BackgroundProcessResource} from "client/resources/backgroundProcessResource";
import {Step} from "client/resources/step";
import ActionButton from "components/Button";
import {getProcessDescription} from "areas/backgroundTasks/getProcessDescription";
import {shortProcessName} from "shared/shortProcessName";
import {FriendlyLocalTime} from "shared/FriendlyLocalTime";
import DurationBetweenLabel from "components/TimeLabels/DurationBetweenLabel";
import {RouteComponentProps} from "react-router-dom";
import {getStepDescription} from "areas/backgroundTasks/getStepDescription";
import splitStringIntoWords from "utils/StringHelper/splitStringIntoWords";
import ExternalLink from "components/Navigation/ExternalLink";
import externalSystemLinks from "externalSystemLinks";
import OverflowMenu from "components/Menu/OverflowMenu";
import routeLinks from "routeLinks";
import Callout, {CalloutType} from "components/Callout";
import {List, ListItem} from "material-ui";
import InternalLink from "components/Navigation/InternalLink/InternalLink";
import ActionList from "components/ActionList";
import {head, uniqBy} from "lodash";
import MoveToStepConfirmationDialogButton from "./MoveToStepConfirmationDialogButton";
import {EnvironmentName} from "client/resources/environmentName";
import {InstanceTaskLockResource} from "../../client/resources/instanceTaskLockResource";

const humanizer = require("humanize-duration").humanizer({
    language: "shortEn",
    languages: { shortEn: { d: () => "d", h: () => "h", m: () => "m", s: () => "s", }, },
    units: ["h", "m"],
    largest: 2,
    round: true,
    spacer: "",
    delimiter: " "
});

const nextStepStyle = css`
    font-weight: bold;
    color: ${themeTokens.color.text.warning};
`;

const currentStepStyle = css`
    font-weight: bold;
    color: ${themeTokens.color.text.success};
`;

const stepsListStyle = css`
    margin-top: -24px;
`;

const sleepTimeStyle = css`
    color: ${themeTokens.color.text.subtle};
`;

interface State extends DataBaseComponentState {
    task?: BackgroundTaskResource;
    processes: BackgroundProcessResource[];
    environment?: EnvironmentName;
    instanceLock?: InstanceTaskLockResource;
}

type Props = RouteComponentProps<{ backgroundTaskId: string }>;

export class BackgroundTaskDetails extends DataBaseComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            processes: []
        };
    }

    componentDidMount() {
        return this.doBusyTask(async () => {
            const appInfo = await repository.Configuration.getAppInfo();
            this.setState({environment: appInfo?.Environment});
            this.doRefresh = await this.startRefreshLoop(() => this.loadData(), 5000);
        });
    }

    async loadData() {
        
        const [task, processes] = await Promise.all([
            repository.BackgroundTasks.get(this.props.match.params.backgroundTaskId), 
            repository.BackgroundTasks.listProcesses()
        ]);

        let lock = null;
        if (task.HostedInstanceIdToLock) {
            const paginatedLocks = await repository.HostedInstances.getInstanceTaskLocks({relatedToHostedInstanceIds: [task.HostedInstanceIdToLock]});
            lock = head(paginatedLocks.Resources);
        }

        return {
            task: task,
            processes: processes,
            instanceLock: lock
        };
    }

    render() {

        return <PaperLayout busy={this.state.busy}
                            errors={this.state.errors}
                            title={this.props.match.params.backgroundTaskId}
                            sectionControl={<ActionList actions={this.state.task ? this.getActions(this.state.task) : []} />}>
            {this.state.task && this.state.processes && this.getBody()}
        </PaperLayout>;
    }

    private getBody() {
        const task = this.state.task;
        const dequeueAfter = task.DequeueAfter ? `(for ${humanizer(moment(task.DequeueAfter).utc().diff(new Date()))})` : null;

        return <div>
            {this.getLockWarning(this.state.instanceLock, this.state.task)}
            <DataTable>
                <DataTableBody>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Id</DataTableRowHeaderColumn>
                        <DataTableRowColumn>{task.Id}</DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Status</DataTableRowHeaderColumn>
                        <DataTableRowColumn>
                            {displayTaskStatus(task.Status)}&nbsp;
                            <span className={sleepTimeStyle}>{dequeueAfter}</span>
                            {task.ErrorMessage && <Callout title="Most recent error" type={CalloutType.Danger}>
                                <pre>
                                    {task.ErrorMessage}
                                </pre>
                            </Callout>}
                        </DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Process</DataTableRowHeaderColumn>
                        <DataTableRowColumn><span title={getProcessDescription(task.ProcessId, this.state.processes)}>{shortProcessName(task.ProcessId)}</span></DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Next Step Id</DataTableRowHeaderColumn>
                        <DataTableRowColumn><span title={getStepDescription(task.ProcessId, task.NextStepId, this.state.processes)}>{splitStringIntoWords(task.NextStepId)}</span>&nbsp;</DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Current Step Id</DataTableRowHeaderColumn>
                        <DataTableRowColumn><span title={getStepDescription(task.ProcessId, task.CurrentStepId, this.state.processes)}>{splitStringIntoWords(task.CurrentStepId)}</span>&nbsp;</DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Created</DataTableRowHeaderColumn>
                        <DataTableRowColumn><FriendlyLocalTime time={task.Created}/></DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Started</DataTableRowHeaderColumn>
                        <DataTableRowColumn>{task.Started && <FriendlyLocalTime time={task.Started}/>}&nbsp;</DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Duration</DataTableRowHeaderColumn>
                        <DataTableRowColumn>{task.Started && task.Started && <DurationBetweenLabel from={task.Started} to={task.Finished || moment().toISOString()}/>}&nbsp;</DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>State</DataTableRowHeaderColumn>
                        <DataTableRowColumn>
                            <pre>
                                {JSON.stringify(task.State, null, 4)}
                            </pre>
                        </DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Related Documents</DataTableRowHeaderColumn>
                        <DataTableRowColumn>
                            <List>
                                {this.findLinksToDocumentsIn(task.State).map(item =>
                                    <ListItem key={item.id}>
                                        <InternalLink to={item.url}>{item.id}</InternalLink>
                                    </ListItem>
                                )}
                            </List>
                        </DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Steps</DataTableRowHeaderColumn>
                        <DataTableRowColumn>{this.getProcessDialog()}</DataTableRowColumn>
                    </DataTableRow>
                </DataTableBody>
            </DataTable>
        </div>;
    }

    private getTaskStepText(step: Step, task: BackgroundTaskResource) {
        const hubDeploymentTask = task.HubDeploymentTasks[step.Id]
        let hubDeploymentTaskLink
        if (hubDeploymentTask) {
            hubDeploymentTaskLink = <> — <ExternalLink href={hubDeploymentTask.WebUrl}>Hub Task</ExternalLink></>
        }
        return <span className={this.getCurrentStepStyling(step, task)}>{splitStringIntoWords(step.Id)} <ExternalLink href={externalSystemLinks.seq.backgroundTaskStep(task, step)} />{hubDeploymentTaskLink}</span>;
    }

    private getActions(task: BackgroundTaskResource) {
        const { environment } = this.state;

        const menuItems = [
            task.NextStepId  && OverflowMenu.item("Process Now", () => this.processNow(task)),
            !task.Finished && OverflowMenu.item("Cancel", () => this.cancel(task)),
            OverflowMenu.externalNavItem("Logs (Seq)", externalSystemLinks.seq.backgroundTask(task)),
            environment && OverflowMenu.externalNavItem("Logs (Sumo)", externalSystemLinks.sumoLogic.backgroundTaskLogs(task, environment)),
            task.TraceId && OverflowMenu.externalNavItem("Trace (Honeycomb)", externalSystemLinks.honeycomb.backgroundTaskTrace(task)),
            OverflowMenu.navItem("Audit Trail", routeLinks.audit.auditTrailFor(task))
        ];

        return [
            <ActionButton label="Refresh" onClick={this.doRefresh}/>,
            <OverflowMenu menuItems={menuItems}/>
        ];
    }

    private getCurrentStateText(step: Step, task: BackgroundTaskResource) {
        // MUI adds inline styles here that override our css specified in style.less. MUI 3.x+ uses a different structure for list items
        // where this hack won't be needed.
        const actionTextStyle = {margin: "0", marginTop: "16px"};

        switch (step.Id) {
            case task.NextStepId: return <div style={actionTextStyle} className={this.getCurrentStepStyling(step, task)}>Next</div>;
            case task.CurrentStepId: return <div style={actionTextStyle} className={this.getCurrentStepStyling(step, task)}>Current</div>;
            default: return <div/>;
        }
    }

    private getCurrentStepStyling(step: Step, task: BackgroundTaskResource) {
        switch (step.Id) {
            case task.NextStepId: return nextStepStyle;
            case task.CurrentStepId: return currentStepStyle;
            default: return "";
        }
    }

    private getAction(step: Step, task: BackgroundTaskResource) {
        if (task.Status === BackgroundTaskStatus.Success || step.Id === task.CurrentStepId || step.Id === task.NextStepId) {
            return <div/>;
        }

        return (
        <div>
            <MoveToStepConfirmationDialogButton
                stepDescription = {splitStringIntoWords(step.Id)}    
                onConfirm={() => this.moveTo(step, task)}/>           
        </div>);
              
    }

    private moveTo(step: Step, task: BackgroundTaskResource) {
        return this.doBusyTask(async () => {
            await repository.BackgroundTasks.nextStep(task, step);
            await this.doRefresh();
            return true;
        });
    }

    private processNow(task: BackgroundTaskResource) {
        return this.doBusyTask(async () => {
            await repository.BackgroundTasks.resume(task);
            await this.doRefresh();
            return true;
        });
    }

    private cancel(task: BackgroundTaskResource) {
        return this.doBusyTask(async () => {
            await repository.BackgroundTasks.cancel(task);
            await this.doRefresh();
            return true;
        });
    }

    private findLinksToDocumentsIn(state: any) {
        const links = Object.keys(state)
            .map(key => {
                const value = state[key];

                if (Array.isArray(value)) {
                    return this.tryFindLinksToDocuments(value);
                }

                return this.tryFindLinksToDocuments([value]);
            })
            .reduce((accumulator, current) => accumulator.concat(current), [])
            .filter(value => !!value);

        return uniqBy(links, l => l.id);
    }

    private tryFindLinksToDocuments(values: any[]) {
        return values.map(value => {
            if (typeof value === "string") {
                const url = this.tryFindLinkToDocument(value);
                if (!url) {
                    return null;
                }
                return {id: value, url};
            }

            return null;
        });
    }

    private tryFindLinkToDocument(value: string) {
        value = value.toLocaleLowerCase();

        if (value.startsWith("hostedinstances-")) {
            return routeLinks.instances.instance(value).root;
        }

        if (value.startsWith("reefs-")) {
            return routeLinks.reefs.reef(value).root;
        }

        if (value.startsWith("backgroundtasks-")) {
            return routeLinks.backgroundTasks.task(value).root;
        }

        if (value.startsWith("hubs-")) {
            return routeLinks.hubs.hub(value).root;
        }

        if (value.startsWith("azureregions-")) {
            return routeLinks.azureRegions.azureRegion(value).root;
        }

        return null;
    }

    private getProcessDialog() {
        const task = this.state.task;
        const process = task ? this.state.processes.filter(p => p.Id === task.ProcessId)[0] : null;

        return process == null
            ? <span>Process not found</span>
            : <List className={stepsListStyle}>
                    {process.Steps.map(step =>
                        <ListItem
                            key={step.Id}
                            id={step.Id}
                            disabled={true}
                            leftIcon={this.getCurrentStateText(step, task)}
                            rightIconButton={this.getAction(step, task)}
                            primaryText={this.getTaskStepText(step, task)}
                            secondaryText={step.Description}/>
                    )}
                </List>;
    }

    private getLockWarning(instanceLock: InstanceTaskLockResource, task: BackgroundTaskResource) {
        return <>{this.showLockWarning(instanceLock, task) && <Callout type={CalloutType.Warning} title={"Task queued"}>
            The <InternalLink to={routeLinks.instanceTaskLocks.filtered({ relatedToHostedInstanceIds: [instanceLock.HostedInstanceId]})}>Instance Lock</InternalLink> for <InternalLink to={routeLinks.instances.instance(instanceLock.HostedInstanceId).root}>{instanceLock.HostedInstanceId}</InternalLink> is currently held by task 
            <InternalLink to={routeLinks.backgroundTasks.task(instanceLock.LockOwnerId).root}> {instanceLock.LockOwnerId}</InternalLink> ({displayTaskStatus(instanceLock.LockOwnerStatus)}).
            This task will not be executed until the lock is released.
        </Callout>}</>
    }
    
    private showLockWarning(instanceLock: InstanceTaskLockResource, task: BackgroundTaskResource) {
        return instanceLock && 
            task.EffectiveLockOwnerId !== instanceLock.LockOwnerId &&
            task.Status === BackgroundTaskStatus.Sleeping;
    }

    private doRefresh: Refresh = () => Promise.resolve();
}
