import {Configuration, EventType, PublicClientApplication, RedirectRequest} from "@azure/msal-browser";
import type { AuthenticationResult, EventMessage } from "@azure/msal-browser";
import Ajax from "./client/ajax";

interface CloudPortalAuthConfiguration 
{
    msalConfiguration: Configuration;
    loginRequestScopes: string[];
}

class AuthTokenProvider {
    private accessToken: string | undefined = undefined;
    private configuration: CloudPortalAuthConfiguration | undefined = undefined;
    private msalInstance: PublicClientApplication | undefined = undefined;

    /**
     * Returns the MSAL React configuration from the server, to specify which
     * Azure Active Directory App Registration to authenticate with, and which
     * Azure Active Directory to authenticate against
     * */
    async getConfiguration(): Promise<CloudPortalAuthConfiguration> {
        if (this.configuration)
            return this.configuration;

        // Load the MSAL React configuration from the server
        return new Promise<CloudPortalAuthConfiguration>((resolve, reject) => {
            const configUrl = "/api/authentication/authConfiguration";
            new Ajax({
                method: "GET",
                url: configUrl,
                error: e => reject(e),
                success: response => {
                    const config = response as CloudPortalAuthConfiguration;
                    if (!config || !config.msalConfiguration.auth) {
                        reject("Failed to load MSAL configuration from " + configUrl);
                        return;
                    }
                    if (!config.msalConfiguration.auth.clientId) {
                        reject("Missing ClientId from MSAL configuration at " + configUrl);
                        return;
                    }

                    this.configuration = config;

                    resolve(config);
                },
                withCredentials: false,
                onUnauthenticated: () => reject("unauthenticated")
            }).execute();
        });
    }

    /**
     * Gets or creates an MSAL PublicClientApplication based on configuration
     * supplied by the server
     * */
    async getMsalInstance() : Promise<PublicClientApplication> {
        if (this.msalInstance)
            return this.msalInstance;

        // Load configuration from the server
        const configuration = await this.getConfiguration();
        const msalInstance = new PublicClientApplication(configuration.msalConfiguration);
        msalInstance.enableAccountStorageEvents();

        // Account selection logic is app dependent. Adjust as needed for different use cases.
        const accounts = msalInstance.getAllAccounts();
        if (accounts.length > 0 && accounts[0]) {
            msalInstance.setActiveAccount(accounts[0]);
        }

        msalInstance.addEventCallback((event: EventMessage) => {
            if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                const payload = event.payload as AuthenticationResult;
                const account = payload.account;
                msalInstance.setActiveAccount(account);
            }
        });

        this.msalInstance = msalInstance;
        return this.msalInstance;
    }

    /**
     * Returns information about the scopes requested for a new login
     */
    async createLoginRequest() {
        const configuration = await this.getConfiguration();

        const loginRequest: RedirectRequest = {
            scopes: configuration.loginRequestScopes,
        };

        return loginRequest;
    }

    /**
     * Gets or creates an Azure Active Directory access token for the current user
     */
    async getAccessToken(ignoreCache: boolean = false): Promise<string | undefined> {
        if (!this.accessToken && !ignoreCache) {
            const msalInstance = await this.getMsalInstance();

            const account = msalInstance.getActiveAccount();
            if (!account) {
                throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
            }

            const loginRequest = await this.createLoginRequest();
            const tokenResponse = await msalInstance.acquireTokenSilent({
                ...loginRequest,
                account: account,
            });

            this.accessToken = tokenResponse.accessToken;
        }

        return this.accessToken;
    }
}

export const authTokenProvider = new AuthTokenProvider();
