import * as React from "react";
import DataBaseComponent, {DataBaseComponentState} from "components/DataBaseComponent";
import PaperLayout from "components/PaperLayout";
import repository from "client/repository";
import SimpleDataTable from "components/SimpleDataTable";
import {FriendlyLocalTime} from "shared/FriendlyLocalTime";
import InternalLink from "components/Navigation/InternalLink";
import {backgroundTasksRouteLinks} from "areas/backgroundTasks/backgroundTasksRouteLinks";
import {
    HostedInstanceSecretRotationHistoryResource, HostedInstanceSecretRotationStatus
} from "../../../client/resources/hostedInstanceSecretRotationHistoryResource";
import OverflowMenu from "components/Menu/OverflowMenu";
import {HostedInstanceResource} from "../../../client/resources/hostedInstanceResource";
import {InstanceStatus} from "../../../client/resources/instanceStatus";
import {RotateMasterKeyDialog} from "./RotateMasterKeyDialog";
import {RotateAdminApiKeyDialog} from "./RotateAdminApiKeyDialog";
import routeLinks from "../../../routeLinks";
import {InternalRedirect} from "../../../components/Navigation/InternalRedirect";
import ActionList from "../../../components/ActionList";
import {RotateSqlLoginsDialog} from "./RotateSqlLoginsDialog";
import {themeTokens} from "@octopusdeploy/design-system-tokens";

const textColor = themeTokens.color.text;
interface Props {
    instanceId: string;
    instance: HostedInstanceResource;
    breadcrumbTitle: string;
}

interface State extends DataBaseComponentState {
    records?: HostedInstanceSecretRotationHistoryResource[];
    redirectTo?: string;
}

class DataTable extends SimpleDataTable<HostedInstanceSecretRotationHistoryResource> {
}

class InstanceSecretRotationHistory extends DataBaseComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {
        await this.loadData();
    }

    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo}/>;
        }

        const records = this.state.records;
        const instance = this.props.instance;
        return (
            <PaperLayout 
                busy={this.state.busy} 
                errors={this.state.errors} 
                title={records && "Secret Rotation History"} 
                breadcrumbTitle={this.props.breadcrumbTitle} 
                sectionControl={<ActionList actions={instance ? this.getActions() : []}/>}>
                <DataTable
                    data={this.state.records}
                    onRow={(item: HostedInstanceSecretRotationHistoryResource) => this.buildRow(item)}
                    headerColumns={["Task ID", "Status", "Secret type", "Created", "Completed"]}
                    onEmpty={<div>No records found</div>}/>
            </PaperLayout>
        );
    }

    private getActions() {
        const instance = this.props.instance;

        const menuItems = [
            this.isNotDeactivatedOrDeleted(instance.Status) && OverflowMenu.dialogItem("Rotate Admin API Key", this.rotateAdminApiKeyDialog()),
            this.isEligibleForRotation(instance.Status) && OverflowMenu.dialogItem("Rotate Master Key", this.rotateMasterKeyDialog()),
            instance && OverflowMenu.dialogItem("Rotate SQL Logins", this.rotateSqlLogins()),
        ];

        return [
            <OverflowMenu menuItems={menuItems}/>
        ];
    }

    buildRow(item: HostedInstanceSecretRotationHistoryResource) {
        const color = this.getColorFor(item.Status);
        return [
            <InternalLink to={backgroundTasksRouteLinks.task(item.BackgroundTaskId).root}>{item.BackgroundTaskId}</InternalLink>,
            <><span style={{color}}>{item.Status}</span></>,
            <><span>{item.RotationType}</span></>,
            <FriendlyLocalTime time={item.Created}/>,
            item.Finished ? <FriendlyLocalTime time={item.Finished}/> : null
        ];
    }

    loadData = async () => {
        await this.doBusyTask(async () => {
            const records = await repository.HostedInstances.getSecretRotationHistory(this.props.instanceId);
            this.setState({ records });
        });
    }

    private rotateMasterKeyDialog() {
        return <RotateMasterKeyDialog instance={this.props.instance}
                                      busy={this.state.busy}
                                      errors={this.state.errors}
                                      onSaveClick={this.redirectToInstanceTasks}/>;
    }

    private rotateAdminApiKeyDialog() {
        return <RotateAdminApiKeyDialog instance={this.props.instance}
                                        busy={this.state.busy}
                                        errors={this.state.errors}
                                        onSaveClick={this.redirectToInstanceTasks}/>;
    }

    private rotateSqlLogins() {
        return <RotateSqlLoginsDialog instance={this.props.instance}
                                      busy={this.state.busy}
                                      errors={this.state.errors}
                                      onSaveClick={this.redirectToInstanceTasks}/>;
    }

    private isEligibleForRotation(status: InstanceStatus) {
        return ( status !== InstanceStatus.Deleting &&
            status !== InstanceStatus.Deleted );
    }

    private isNotDeactivatedOrDeleted(status: InstanceStatus) {
        return ( status !== InstanceStatus.Deactivating &&
            status !== InstanceStatus.Deactivated &&
            status !== InstanceStatus.Deleting &&
            status !== InstanceStatus.Deleted );
    }

    private redirectToInstanceTasks = async () => {
        this.setState({redirectTo: routeLinks.instances.instance(this.props.instanceId).tasks});
        return true;
    };

    private getColorFor(status: HostedInstanceSecretRotationStatus): string {
        switch (status) {
            case HostedInstanceSecretRotationStatus.Pending: return textColor.warning;
            case HostedInstanceSecretRotationStatus.Failed: return textColor.danger;
            case HostedInstanceSecretRotationStatus.Cancelled: return textColor.subtle;
            case HostedInstanceSecretRotationStatus.Success: return textColor.success;
            default: return textColor.primary;
        }
    }
}

export default InstanceSecretRotationHistory;