import HostedInstanceRepository from "client/repositories/hostedInstanceRepository";
import AuthenticationRepository from "client/repositories/authenticationRepository";
import BackgroundTasksRepository from "client/repositories/backgroundTasksRepository";
import InstanceLimitRepository from "client/repositories/instanceLimitRepository";
import DynamicWorkerServicesRepository from "client/repositories/dynamicWorkerServicesRepository";
import ConfigurationRepository from "client/repositories/configurationRepository";
import AuditRepository from "client/repositories/auditRepository";
import ReefRepository from "client/repositories/reefRepository";
import DashboardRepository from "client/repositories/dashboardRepository";
import SupportUserRepository from "./repositories/supportUserRepository";
import MessageBusRepository from "./repositories/messageBusRepository";
import InstanceEnvironmentVariableRepository from "./repositories/instanceEnvironmentVariableRepository";
import AzureRegionRepository from "./repositories/azureRegionRepository";
import HubRepository from "./repositories/cloudPlatformHubRepository";

// Repositories provide a helpful abstraction around the API
const repository = {
    Authentication: new AuthenticationRepository(),
    Dashboard: new DashboardRepository(),
    HostedInstances: new HostedInstanceRepository(),
    Reefs: new ReefRepository(),
    SupportUsers: new SupportUserRepository(),
    InstanceLimits: new InstanceLimitRepository(),
    BackgroundTasks: new BackgroundTasksRepository(),
    DynamicWorkerServices: new DynamicWorkerServicesRepository(),
    Configuration: new ConfigurationRepository(),
    InstanceEnvironmentVariables: new InstanceEnvironmentVariableRepository(),
    Audit: new AuditRepository(),
    MessageBus: new MessageBusRepository(),
    AzureRegions: new AzureRegionRepository(),
    Hubs: new HubRepository()
};

export default repository;
