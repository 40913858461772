import * as React from "react";
import cn from "classnames";
import { History } from "history";
import LocationDescriptor = History.LocationDescriptor;
import Breadcrumb from "components/Breadcrumb";
import BusyFromPromise from "components/BusyFromPromise/BusyFromPromise";
import BusyRefreshIndicator from "components/BusyRefreshIndicator";
const styles = require("./style.less");

interface SectionTitleProps {
    title: React.ReactNode;
    className?: string;
    sectionControl?: React.ReactNode;
    titleLogo?: JSX.Element;
    breadcrumbTitle?: string; // If specified, this text will display above the title.
    breadcrumbPath?: LocationDescriptor; // If specified, this will link your breadcrumb title.
    breadcrumbChip?: JSX.Element; // If specified, this will put the chip next to the  breadcrumb title.
    busy?: Promise<any> | boolean;
    enableLessIntrusiveLoadingIndicator?: boolean;
}

const SectionTitle = (props: SectionTitleProps) => {
    return <div className={cn(styles.container, props.className)}>
        <div className={styles.titleContainer}>
            <div className={styles.breadcrumbContainer}>
                <Breadcrumb title={props.breadcrumbTitle} path={props.breadcrumbPath} />
                {props.breadcrumbChip}
            </div>
            <div className={styles.title}>
                {props.titleLogo && <div className={styles.logo}>{props.titleLogo}</div>}
                <h2 className={styles.header}>{props.title}</h2>
                {/* We show a less intrusive refresh indicator for screens that have auto-refresh implemented (because the main horizontal loading bar is annoying). */}
                {props.enableLessIntrusiveLoadingIndicator &&
                <div className={styles.refreshIndicator}>
                    <BusyFromPromise promise={props.busy}>
                    {(busy: boolean) => {
                        return busy && props.enableLessIntrusiveLoadingIndicator
                        ? <div className={styles.refreshContainer}>
                            <BusyRefreshIndicator show={true} />
                        </div>
                        : null;
                    }}
                    </BusyFromPromise>
                </div>}
            </div>
        </div>
        {props.sectionControl}
    </div>;
};

export default SectionTitle;