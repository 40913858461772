import Callout, {CalloutType} from "../../../components/Callout";
import ExternalLink from "../../../components/Navigation/ExternalLink";
import externalSystemLinks from "../../../externalSystemLinks";
import * as React from "react";
import {InstanceLimitResource} from "../../../client/resources/instanceLimitResource";

export function WarnToCancelViaOctofrontAlert({ action, instanceLimit }: { action: string, instanceLimit: InstanceLimitResource }) {
    if (!!instanceLimit.CloudSubscriptionId) {
        return <div>
            <Callout title="This instance has a valid Cloud Subscription" type={CalloutType.Danger}>
                You should <em>Cancel</em> it via <ExternalLink
                href={externalSystemLinks.octofront.subscriptionDetails(instanceLimit)}>Octofront</ExternalLink>.
                If you {action} it here Octofront and Cloud Portal will be out of sync. <ExternalLink
                href="https://github.com/OctopusDeploy/hosted-docs/blob/master/how-to/delete-a-cloud-instance.md">More
                info</ExternalLink>.
            </Callout>
        </div>
    }
    return null;
}