import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent";
import repository from "client/repository";
import {DynamicWorkersFilterArgs} from "client/repositories/dynamicWorkerServicesRepository";
import DynamicWorkersDataTable from "./DynamicWorkersDataTable";
import * as React from "react";
import PaperLayout from "components/PaperLayout";
import AdvancedFilterLayout, {AdvancedFilterTextInput, FilterSection} from "components/AdvancedFilterLayout";
import {RouteComponentProps} from "react-router-dom";
import DynamicWorkerStateMultiSelect from "shared/DynamicWorkerStateMultiSelect";
import SnapshotMultiSelect from "shared/SnapshotMultiSelect";
import {DynamicWorkerState} from "shared/dynamicWorkerState";
import {IQuery, QueryStringFilters} from "components/QueryStringFilters/QueryStringFilters";
import {arrayValueFromQueryString} from "utils/ParseHelper/ParseHelper";
import {DynamicWorkerTypeResource} from "../../../client/resources/dynamicWorkerTypeResource";
import DynamicWorkerTypeMultiSelect from "../../../shared/DynamicWorkerTypeMultiSelect";
import buildDynamicWorkerServiceBreadcrumbTitle from "../buildDynamicWorkerServiceBreadcrumbTitle";
import {DynamicWorkerServiceResource} from "../../../client/resources/dynamicWorkerServiceResource";
import DynamicWorkerTaskCapMultiSelect from "../../../shared/DynamicWorkerTaskCapMultiSelect";

interface State extends DataBaseComponentState {
    filter: DynamicWorkersFilterArgs;
    workerSnapshots: string[];
    workerTypes?: DynamicWorkerTypeResource[];
    service?: DynamicWorkerServiceResource;
}

type Props = RouteComponentProps<{ id: string }>;

type Filter = DynamicWorkersFilterArgs;

interface Query extends IQuery, Filter { }

const WorkersQueryStringFilters = QueryStringFilters.For<Filter, Query>();

class FilterLayout extends AdvancedFilterLayout<Filter> { }

class DynamicWorkers extends DataBaseComponent<Props, State> {

    private static getEmptyFilter(): Filter {
        return {
            states: [],
            snapshots: [],
            workerTypes: [],
            taskCaps: [],
        };
    }

    constructor(props: Props) {
        super(props);
        this.state = {
            filter: DynamicWorkers.getEmptyFilter(),
            workerTypes: [],
            workerSnapshots: []
        };
    }

    async componentDidMount() {
        await this.loadData();
    }

    render() {
        const isLoaded = !!this.state.workerTypes;
        const filter = this.state.filter;
        const list =
            <div>
                <WorkersQueryStringFilters filter={this.state.filter} getQuery={this.queryFromFilters} getFilter={this.getFilterFromQuery} onFilterChange={this.filterChanged} />
                <DynamicWorkersDataTable
                    id={this.props.match.params.id}
                    doBusyTask={this.doBusyTask}
                    filter={this.state.filter}
                />
            </div>;
        const filterSections: FilterSection[] = [
            {
                render: (<>
                            <DynamicWorkerStateMultiSelect
                                value={filter.states}
                                onChange={states => this.filterChanged({states})}
                            />
                            <SnapshotMultiSelect
                                items={this.state.workerSnapshots}
                                value={filter.snapshots}
                                onChange={snapshots => this.filterChanged({snapshots})}
                            />
                            <DynamicWorkerTypeMultiSelect
                                items={Array.from(this.state.workerTypes.values()).map(t => t.Type)}
                                value={filter.workerTypes}
                                onChange={workerTypes => this.filterChanged({workerTypes})}
                            />
                            <DynamicWorkerTaskCapMultiSelect
                                value={filter.taskCaps}
                                onChange={taskCaps => this.filterChanged({taskCaps})}
                            />
                            <AdvancedFilterTextInput
                                fieldName={"CrowdStrike Agent Id"}
                                value={filter.crowdStrikeAgentId}
                                onChange={crowdStrikeAgentId => this.filterChanged({crowdStrikeAgentId})}
                    />
                        </>)
            }
        ];

        return <PaperLayout title="Dynamic Workers"
                            busy={this.state.busy}
                            breadcrumbTitle={buildDynamicWorkerServiceBreadcrumbTitle(this.state.service)}
                            errors={this.state.errors}
                            fullWidth={true}>
            {isLoaded && <FilterLayout
                filter={this.state.filter}
                defaultFilter={DynamicWorkers.getEmptyFilter()}
                onFilterReset={() => this.setState({filter: DynamicWorkers.getEmptyFilter()})}
                filterSections={filterSections}
                renderContent={() => list}
            />}
        </PaperLayout>;
    }

    async loadData() {
        const id = this.props.match.params.id;
        const workers = await repository.DynamicWorkerServices.workers(id, this.state.filter);
        const workerSnapshots = workers.map(w => w.Source);
        const workerTypes = await repository.DynamicWorkerServices.workerTypes(id);
        const service = await repository.DynamicWorkerServices.get(id);
        this.setState({workerSnapshots, workerTypes, service});
    }

    private filterChanged = (filter: Filter) => {
        this.setState({filter: {...this.state.filter, ...filter}});
    };

    private queryFromFilters = (filter: Filter): Query => {
        return {
            ...filter
        };
    };

    private getFilterFromQuery = (query: Query): Filter => {
        return {
            ...query,
            states: arrayValueFromQueryString(query.states).map(v => v as DynamicWorkerState),
            snapshots: arrayValueFromQueryString(query.snapshots),
            workerTypes: arrayValueFromQueryString(query.workerTypes),
            taskCaps: arrayValueFromQueryString(query.taskCaps)
        };
    };
}

export default DynamicWorkers;