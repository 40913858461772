import {BooleanRadioButton, BooleanRadioButtonGroup} from "@octopusdeploy/design-system-components";
import {DataBaseComponent, DataBaseComponentState, Errors} from "components/DataBaseComponent";
import repository from "client/repository";
import * as React from "react";
import {BusyState} from "components/BusyFromPromise/BusyFromPromise";
import {HostedInstanceResource} from "client/resources/hostedInstanceResource";
import SaveDialogLayout from "components/DialogLayout/SaveDialogLayout";
import {Text} from "components/form";
import {HostedInstanceChangeDnsPrefixRequest} from "../../../client/resources/hostedInstanceChangeDnsPrefixRequest";
import Callout, {CalloutType} from "../../../components/Callout/Callout";
import ExternalLink from "../../../components/Navigation/ExternalLink";
import externalSystemLinks from "../../../externalSystemLinks";
import {InstanceLimitResource} from "../../../client/resources/instanceLimitResource";

interface ChangeDnsPrefixDialogProps {
    errors: Errors;
    busy: BusyState;
    instance: HostedInstanceResource;
    instanceLimit: InstanceLimitResource;
    onSaveClick(): Promise<boolean>;
}

interface ChangeDnsPrefixDialogState extends DataBaseComponentState {
    changeRequest: Partial<HostedInstanceChangeDnsPrefixRequest>;
}

export class ChangeDnsPrefixDialog extends DataBaseComponent<ChangeDnsPrefixDialogProps, ChangeDnsPrefixDialogState> {
    constructor(props: ChangeDnsPrefixDialogProps) {
        super(props);
        this.state = {
            changeRequest: {
                ...{ChangeDuringOutageWindow: true},
                ...this.props.instance,
            }
        };
    }

    render() {
        const request = this.state.changeRequest;
        return <SaveDialogLayout title={"Change DNS Prefix"}
                                 busy={this.state.busy}
                                 errors={this.state.errors}
                                 onSaveClick={() => this.change(request)}>

            {this.isDangerousDnsPrefixChange()  &&
                <Callout type={CalloutType.Danger} title="Dns Prefix Change">
                    If you want to change the dns prefix of an instance that has a valid subscription then please do it in <ExternalLink href={externalSystemLinks.octofront.subscriptionDetails(this.props.instanceLimit)}>Octofront</ExternalLink> using `Change Endpoint` action.
                    An incorrect change to the instance's dns prefix in Cloud Portal can render the instance unusable. <ExternalLink href="https://github.com/OctopusDeploy/hosted-docs/blob/master/how-to%2Fchange-the-subdomain-of-a-cloud-instance.md">More details.</ExternalLink>
                </Callout>
            }

            <BooleanRadioButtonGroup value={request.ChangeDuringOutageWindow} onChange={ChangeDuringOutageWindow => this.updateChangeRequest({ChangeDuringOutageWindow})}>
                <BooleanRadioButton value={true} label="Change During Outage Window" />
                <BooleanRadioButton value={false} label="Change Immediately" />
            </BooleanRadioButtonGroup>

            <Text label="Dns Prefix"
                  hintText="New Dns Prefix for this hosted instance"
                  value={request.DnsPrefix}
                  onChange={DnsPrefix => this.updateChangeRequest({DnsPrefix})}
            />

        </SaveDialogLayout>;
    }

    private updateChangeRequest(update: Partial<HostedInstanceChangeDnsPrefixRequest>) {
        return this.setState(prevState => ({changeRequest: {...prevState.changeRequest, ...update}}));
    }

    private change(request: Partial<HostedInstanceChangeDnsPrefixRequest>) {
        return this.doBusyTask(async () => {
            await repository.HostedInstances.changeDnsPrefix(this.props.instance, request as HostedInstanceChangeDnsPrefixRequest);
            await this.props.onSaveClick();
            return true;
        });
    }

    private isDangerousDnsPrefixChange() {
        return !!this.props.instanceLimit.CloudSubscriptionId && this.props.instance.DnsPrefix !== this.state.changeRequest.DnsPrefix;
    }
}
