import * as React from "react";
const keycode = require("keycode");

export enum Key {
    Esc = "esc",
    Enter = "enter",
    CtrlEnter = "ctrlenter",
}

interface KeyboardHandlerProps {
    className?: string;
    // the return boolean is to indicate if KeyboardHandler should preventDefault()
    registrations: Array<{key: Key, onKeyPressed(e: KeyboardEvent): boolean}>;
}

class KeyboardHandler extends React.Component<KeyboardHandlerProps> {

    private keyboardRegistrations: Array<{key: Key, onKeyPressed(e: KeyboardEvent): boolean}>;
    constructor(props: KeyboardHandlerProps) {
        super(props);
        this.keyboardRegistrations = props.registrations;
    }

    render() {
        return <div onKeyDown={this.handleKey} style={{width: "100%"}} className={this.props.className}>
            {this.props.children}
        </div>;
    }

    componentDidMount() {
        if (this.hasEscRegistration()) {
            window.document.addEventListener("keydown", this.handleEsc);
        }
    }

    componentWillUnmount() {
        if (this.hasEscRegistration()) {
            window.document.removeEventListener("keydown", this.handleEsc);
        }
    }

    hasEscRegistration(): boolean {
        const escBinding = this.keyboardRegistrations.find(r => r.key === Key.Esc);
        return !!escBinding;
    }

    handleEsc = (event: KeyboardEvent): void => {
        if (!event.defaultPrevented) {
            for (const registration of this.keyboardRegistrations) {
                if (keycode.codes.esc === keycode(registration.key) && keycode.codes.esc === keycode(keycode(event))) {
                    if (registration.onKeyPressed(event)) {
                        event.preventDefault();
                    }
                }
            }
        }
    }

    handleKey = (e: React.KeyboardEvent<HTMLDivElement>): void => {
        const event = e.nativeEvent;
        if (!event.defaultPrevented) {
            const keyName = keycode(event);
            for (const registration of this.keyboardRegistrations) {
                if (keyName === keycode(keycode.codes.enter)) {
                    if (event.ctrlKey) {
                        // Ctrl + Enter
                        if (registration.key === Key.CtrlEnter) {
                            if (registration.onKeyPressed(event)) {
                                event.preventDefault();
                            }
                        }
                    } else {
                        // Enter, mac command+enter will fall into here
                        if (registration.key === Key.Enter) {
                            if (registration.onKeyPressed(event)) {
                                event.preventDefault();
                            }
                        }
                    }
                } else {
                    // Any other keys
                    const code = keycode(keyName);
                    if (code !== undefined // don't want to fall through when undefined === undefined
                        && code === keycode(registration.key)) {
                        if (registration.onKeyPressed(event)) {
                            event.preventDefault();
                        }
                    }
                }
            }
        }
    }
}

export default KeyboardHandler;