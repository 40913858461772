import RootLayoutInternal from "areas/root/RootLayoutInternal";
import { connect } from "react-redux";
import {bindActionCreators} from "redux";
import GlobalState from "globalState";
import {clearUnhandledErrorAction} from "areas/root/reducers";

interface RootLayoutDispatchers {
    onErrorClose(): void;
}

const RootLayout = connect(
    (state: GlobalState) => {
        return {
            unhandledError: state.unhandledError
        };
    },
    (dispatch): RootLayoutDispatchers => {
        return {
            onErrorClose: bindActionCreators(clearUnhandledErrorAction, dispatch)
        };
    }
)(RootLayoutInternal);

export default RootLayout;