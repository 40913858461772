import * as React from "react";
import styles = require("./style.less");
import {groupBy, keys, sum} from "lodash";
import {
        DynamicWorkerServiceRuntimeStatusResource
} from "../../client/resources/dynamicWorkerServiceRuntimeStatusResource";

const WorkersBySize: React.FunctionComponent<{status: DynamicWorkerServiceRuntimeStatusResource}> = props => {
    const sizes = groupBy(props.status.WorkerTypesStateDetails, "Size");
    return sizes && <ul className={styles.workerList}>
        {keys(sizes).map(k => <li key={k}><strong>{k}:</strong> {getCountBySize(k, props.status)}</li>)}
    </ul>
}

const getCountBySize = (size: string, status: DynamicWorkerServiceRuntimeStatusResource) =>
    status.WorkerTypesStateDetails
        ? sum(status.WorkerTypesStateDetails.filter(w => w.Size === size).map(w => w.Count))
        : "?";

WorkersBySize.displayName = "WorkersBySize";

export default WorkersBySize;