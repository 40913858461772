import MultiSelect from "components/MultiSelect/MultiSelect";
import FormFieldProps from "components/form/FormFieldProps";
import {KubernetesClusterResource} from "client/resources/KubernetesClusterResource";
import * as React from "react";
import KubernetesClusterChip from "shared/KubernetesClusterChip";

interface KubernetesClusterMultiSelectProps extends FormFieldProps<string[]> {
    items: KubernetesClusterResource[];
    autoFocus?: boolean;
    label?: string | JSX.Element;
    error?: string;
}

const KubernetesClusterTypedMultiSelect = MultiSelect<KubernetesClusterResource>();

const KubernetesClusterMultiSelect: React.StatelessComponent<KubernetesClusterMultiSelectProps> = props => {
    const chipRenderer = (k: KubernetesClusterResource, onRequestDelete: (event: object) => void) => {
        return <KubernetesClusterChip kubernetesCluster={k} onRequestDelete={onRequestDelete} />;
    };

    return <KubernetesClusterTypedMultiSelect
        items={props.items}
        fieldName="Kubernetes Cluster"
        renderChip={chipRenderer}
        {...props} />;
};

export default KubernetesClusterMultiSelect;
