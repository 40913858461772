import * as React from "react";
import { FormFieldProps } from "components/form";
import { SelectItem } from "components/VirtualListWithKeyboard/SelectItem";
import InstanceStatusChip from "shared/InstanceStatusChip";
import MultiSelect from "components/MultiSelect/MultiSelect";
import {InstanceStatus} from "client/resources/instanceStatus";

interface InstanceStatusMultiSelectProps extends FormFieldProps<InstanceStatus[]> {
    canAdd?: boolean;
    openOnFocus?: boolean;
    autoFocus?: boolean;
    label?: string | JSX.Element;
    error?: string;
    hideFloatingLabel?: boolean;
    validate?(value: InstanceStatus[]): string;
}

const InstanceStatusTypedMultiSelect = MultiSelect<SelectItem>();

const InstanceStatusMultiSelect: React.StatelessComponent<InstanceStatusMultiSelectProps> = props => {
    const onAdd = (value: InstanceStatus) => {
        props.onChange(props.value.concat(value));
    };

    const chipRenderer = (r: SelectItem, onRequestDelete: (event: object) => void) => {
        return <InstanceStatusChip
            onRequestDelete={onRequestDelete}
            ring={r.Id as InstanceStatus}
        />;
    };

    return <InstanceStatusTypedMultiSelect
        items={Object.entries(InstanceStatus).map(p => ({Id: p[0], Name: p[1]})).sort((e1, e2) => compare(e1.Name, e2.Name))}
        fieldName="instance status"
        label={props.label}
        renderChip={chipRenderer}
        onNew={props.canAdd ? onAdd : null}
        value={props.value}
        onChange={props.onChange}
        error={props.error}
        openOnFocus={props.openOnFocus}
        autoFocus={props.autoFocus}
        hideFloatingLabel={props.hideFloatingLabel}
    />;
};

function compare(value1: string, value2: string) {
    if (value1 < value2) {
        return -1;
    }
    if (value1 > value2) {
        return 1;
    }

    return 0;
}

export default InstanceStatusMultiSelect;