export enum UpgradeStrategyType {
    NoAutomaticUpgradeStrategy = "NoAutomaticUpgradeStrategy",
    TrackBranchUpgradeStrategy = "TrackBranchUpgradeStrategy",
    AssignedVersionUpgradeStrategy = "AssignedVersionUpgradeStrategy",
    TrackDefaultVersionUpgradeStrategy = "TrackDefaultVersionUpgradeStrategy"
}

export const upgradeStrategyTypeDescriptions: Record<UpgradeStrategyType, string> = {
    [UpgradeStrategyType.NoAutomaticUpgradeStrategy]: "No Automatic Upgrades",
    [UpgradeStrategyType.TrackBranchUpgradeStrategy]: "Track Branch",
    [UpgradeStrategyType.AssignedVersionUpgradeStrategy]: "Tenant Version Assigned",
    [UpgradeStrategyType.TrackDefaultVersionUpgradeStrategy]: "Track Default Version"
};

export type UpgradeStrategy = NoAutomaticUpgradeStrategy | TrackBranchUpgradeStrategy | AssignedVersionUpgradeStrategy | TrackDefaultVersionUpgradeStrategy;

export class NoAutomaticUpgradeStrategy {
    UpgradeStrategyType: UpgradeStrategyType = UpgradeStrategyType.NoAutomaticUpgradeStrategy;
}

export class TrackBranchUpgradeStrategy {
    UpgradeStrategyType: UpgradeStrategyType = UpgradeStrategyType.TrackBranchUpgradeStrategy;
    BranchName: string;
}

export class AssignedVersionUpgradeStrategy {
    UpgradeStrategyType: UpgradeStrategyType = UpgradeStrategyType.AssignedVersionUpgradeStrategy;
    Version: string;
}

export class TrackDefaultVersionUpgradeStrategy {
    UpgradeStrategyType: UpgradeStrategyType = UpgradeStrategyType.TrackDefaultVersionUpgradeStrategy;
}
