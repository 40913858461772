import {MeResource} from "client/resources/meResource";
import {ExternalUrlsResource} from "client/resources/externalUrlsResource";
import { FeatureFlagsResource } from "client/resources/featureFlagsResource";

class Session {
    currentUser: MeResource;
    configuration: ExternalUrlsResource;
    featureFlags: FeatureFlagsResource;

    start(user: MeResource, externalUrls: ExternalUrlsResource, featureFlags: FeatureFlagsResource) {
        this.currentUser = user;
        this.configuration = externalUrls;
        this.featureFlags = featureFlags;
    }

    end() {
        this.currentUser = null;
        this.configuration = null;
        this.featureFlags = null;
    }

    isStarted() {
        return !!this.currentUser;
    }
}

const session = new Session();

export default session;