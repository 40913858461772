import * as React from "react";
import PaperLayout from "components/PaperLayout/PaperLayout";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent";
import { Refresh } from "components/DataBaseComponent/DataBaseComponent";
import AdvancedFilterLayout from "components/AdvancedFilterLayout/AdvancedFilterLayout";
import repository from "client/repository";
import {AdvancedFilterTextInput} from "components/AdvancedFilterLayout";
import {HostedInstanceLookupResource} from "client/resources/hostedInstanceLookupResource";
import ActionButton from "components/Button";
import HostedInstanceMultiSelect from "shared/HostedInstanceMultiSelect";
import { InstanceTaskLockResource, LockStatus } from "client/resources/instanceTaskLockResource";
import { HostedInstanceTaskLockListArgs } from "client/repositories/hostedInstanceRepository";
import { SimpleDataTablePaging, PagingInfo } from "components/SimpleDataTable/SimpleDataTablePaging";
import {FriendlyLocalTime} from "shared/FriendlyLocalTime";
import OverflowMenu from "components/Menu/OverflowMenu";
import { routeLinks } from "routeLinks";
import InternalLink from "components/Navigation/InternalLink/InternalLink";
import { IQuery, QueryStringFilters } from "components/QueryStringFilters/QueryStringFilters";
import { arrayValueFromQueryString } from "utils/ParseHelper/ParseHelper";
import { displayTaskStatus } from "areas/backgroundTasks/displayTaskStatus";
import { getColorAndIconByLockStatus } from "areas/instanceTaskLocks/displayLockStatus";
import DeleteInstanceTaskLockDialog from "areas/instanceTaskLocks/DeleteInstanceTaskLockDialog";

interface State extends DataBaseComponentState {
    locks?: InstanceTaskLockResource[];
    instances?: HostedInstanceLookupResource[];
    filter?: Filter;
    paging: PagingInfo;
}

type Filter = HostedInstanceTaskLockListArgs & { skip?: string; };

interface Query extends IQuery, Filter {
}

const LocksQueryStringFilters = QueryStringFilters.For<Filter, Query>();

class FilterLayout extends AdvancedFilterLayout<Filter> {
}

class InstanceTaskLocks extends DataBaseComponent<{}, State> {

    private static getEmptyFilter(): Filter {
        return {
            relatedToHostedInstanceIds: [],
        };
    }

    constructor(props: {}) {
        super(props);
        this.state = {
            filter: InstanceTaskLocks.getEmptyFilter(),
            paging: {
                skip: 0,
                take: 50,
                total: 0,
            }
        };
    }

    componentDidMount() {
        return this.doBusyTask(async () => {
            this.doRefresh = await this.startRefreshLoop(() => this.loadData(), 10000);
        });
    }

    private doRefresh: Refresh = () => Promise.resolve();

    private refreshData = async () => {
        await this.doBusyTask(async () => {
            this.setState(await this.loadData());
        });
    };

    async loadData() {
        const [instances, pagingLocks] = await Promise.all([
            repository.HostedInstances.lookup(),
            repository.HostedInstances.getInstanceTaskLocks({ ...this.state.filter, skip: this.state.paging.skip, take: this.state.paging.take })
        ]);

        return {
            locks: pagingLocks.Resources,
            paging: {
                ...this.state.paging,
                total: pagingLocks.Total,
            },
            instances,
        };
    }

    render() {
        const state = this.state;
        const filter = state.filter;
        const list = state.locks &&
            <SimpleDataTablePaging<InstanceTaskLockResource>
                data={state.locks}
                onRow={(item: InstanceTaskLockResource) => this.buildRow(item)}
                onRowOverflowMenu={(item: InstanceTaskLockResource) => this.buildRowOverflowMenu(item)}
                headerColumns={["Hosted Instance ID", "Background Task ID", "Background Task Process", "Background Task Status", "Status", "Created"]}
                onEmpty={<div>No instance task locks found.</div>}
                paging={{...state.paging, pageSelected: (skip: number, _) => this.onPageSelect(skip)}}
            />;

        const filterSections = [
            {
                render: <div>
                    <HostedInstanceMultiSelect
                        items={state.instances}
                        value={filter.relatedToHostedInstanceIds}
                        onChange={relatedToHostedInstanceIds => this.onFilterChange({relatedToHostedInstanceIds})}/>
                    <AdvancedFilterTextInput
                        fieldName="related to task ID"
                        value={filter.relatedToTaskId}
                        onChange={relatedToTaskId => this.onFilterChange({relatedToTaskId})}
                    />
                </div>
            }
        ];

        const refreshButton = <ActionButton label="Refresh" disabled={state.busy} onClick={this.doRefresh}/>;

        return <PaperLayout title={`Instance Task Locks (${state.paging.total})`}
                            busy={state.busy}
                            errors={state.errors}
                            sectionControl={refreshButton}
                            fullWidth>
            <LocksQueryStringFilters filter={filter} getQuery={f => ({ ...f })} getFilter={this.getFilterFromQuery} onFilterChange={f => this.onFilterChange(f)} />
            {<FilterLayout
                filter={filter}
                defaultFilter={InstanceTaskLocks.getEmptyFilter()}
                onFilterReset={f => this.onFilterChange(f)}
                filterSections={filterSections}
                renderContent={() => list}
            />}
        </PaperLayout>;
    }

    private buildRow(lock: InstanceTaskLockResource) {
        return [
            <InternalLink to={routeLinks.instances.instance(lock.HostedInstanceId).root}>{lock.HostedInstanceId}</InternalLink>,
            <InternalLink to={routeLinks.backgroundTasks.task(lock.LockOwnerId).root}>{lock.LockOwnerId}</InternalLink>,
            lock.LockOwnerType,
            displayTaskStatus(lock.LockOwnerStatus),
            <span style={{color: getColorAndIconByLockStatus(lock.LockStatus).Color}}>{lock.LockStatus}</span>,
            <FriendlyLocalTime time={lock.Created}/>
        ];
    }

    private buildRowOverflowMenu(lock: InstanceTaskLockResource) {
        return [
            OverflowMenu.dialogItem(`Delete`, <DeleteInstanceTaskLockDialog lock={lock} onDelete={this.doRefresh}/>),
        ];
    }

    private onFilterChange(filter: Filter) {
        const skip = filter.skip ? filter.skip : "0";
        this.setState({
            filter: {
                ...this.state.filter,
                ...filter,
                skip,
            },
            paging: {
                ...this.state.paging,
                skip: Number(skip),
            }
        }, async () => await this.refreshData());
    }

    private onPageSelect(skip: number)
    {
        this.setState({
            paging: {
                ...this.state.paging,
                skip,
            },
            filter: {
                ...this.state.filter,
                skip: skip.toString(),
            }
        }, async () => await this.refreshData());
    }

    private getFilterFromQuery(query: Query): Filter {
        return {
            ...query,
            relatedToHostedInstanceIds: arrayValueFromQueryString(query.relatedToHostedInstanceIds),
        };
    }
}

export default InstanceTaskLocks;