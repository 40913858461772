import {EnvironmentName} from "client/resources/environmentName";
import DashboardMetricType from "./DashboardMetricType";
import {
    preprodMonitoredShortIds,
    preprodSnoozeUptimeShortIds
} from "./preprodAlertSnoozerConfig";
import {
    prodSnoozeUptimeShortIds, 
    SnoozeInstanceAlertUntilTime,
    instancesNearingPlatformLimitsToSnooze
} from "./prodAlertSnoozerConfig";

export interface AlertSnoozer {
    shouldSnoozeAlert(metricType: DashboardMetricType): boolean;
    shouldSnoozeInstanceAlert(metricType: DashboardMetricType, shortId: string): boolean
    shouldSnoozeInstanceNearingPlatformLimitAlert(metricType: string, hostedInstanceId: string, currentTime: Date): SnoozedAlertData
}

export interface SnoozedAlertData {
    isSnoozed: boolean;
    snoozeComment: string;
    linkToCard?: string;
}

const unsnoozedAlertData: SnoozedAlertData = {isSnoozed: false, snoozeComment: ""};

export function getAlertSnoozerForEnvironment(environmentName: EnvironmentName): AlertSnoozer {
    switch (environmentName) {
        case EnvironmentName.Preprod:
          return new PreprodAlertSnoozer(
              preprodMonitoredShortIds,
              preprodSnoozeUptimeShortIds
          );
        case EnvironmentName.Production:
            return new ProdAlertSnoozer(
                prodSnoozeUptimeShortIds, 
                instancesNearingPlatformLimitsToSnooze
            );
        default:
            return new SnoozeNothingAlertSnoozer();
    }
}

export class SnoozeNothingAlertSnoozer implements AlertSnoozer {
    shouldSnoozeAlert(metricType: DashboardMetricType) {
        return false;
    }

    shouldSnoozeInstanceAlert(metricType: DashboardMetricType, dnsPrefix: string) {
        return false;
    }

    shouldSnoozeInstanceNearingPlatformLimitAlert(metricType: string, hostedInstanceId: string, currentTime: Date): SnoozedAlertData {
       return {isSnoozed: false, snoozeComment: "SnoozeNothingAlertSnoozer is used"};
    }
}

export class PreprodAlertSnoozer implements AlertSnoozer {
    private readonly monitoredInstanceShortIds: Set<string>;
    private readonly unmonitoredUptimeInstanceShortIds: Set<string>;

    constructor(
        monitoredInstanceShortIds: string[],
        unmonitoredUptimeInstanceShortIds: string[]
    ) {
        const toLowerCaseSet = (items: string[]) => new Set(items.map(item => item.toLowerCase()));
        this.monitoredInstanceShortIds = toLowerCaseSet(monitoredInstanceShortIds);
        this.unmonitoredUptimeInstanceShortIds = toLowerCaseSet(unmonitoredUptimeInstanceShortIds);
    }

    shouldSnoozeAlert(metricType: DashboardMetricType) {
        return metricType === DashboardMetricType.DynamicWorkersInitialLeaseTime;
    }

    shouldSnoozeInstanceAlert(metricType: DashboardMetricType, shortId: string) {
        return (metricType === DashboardMetricType.InstanceUptime &&
                this.shouldSnoozeUptimeAlertsForInstance(shortId)) ||
            this.isUnmonitoredInstance(shortId);
    }

    private isUnmonitoredInstance(shortId: string) {
        return !this.monitoredInstanceShortIds.has(shortId.toLowerCase());
    }

    private shouldSnoozeUptimeAlertsForInstance(shortId: string) {
        return this.unmonitoredUptimeInstanceShortIds.has(shortId.toLowerCase());
    }

    shouldSnoozeInstanceNearingPlatformLimitAlert(metricType: string, hostedInstanceId: string, currentTime: Date): SnoozedAlertData {        
        return {isSnoozed: true, snoozeComment: "Instances nearing platform limit are always snoozed in pre-prod"};
    }
}

export class ProdAlertSnoozer implements AlertSnoozer {
    private readonly unmonitoredUptimeInstanceShortIds: Set<string>;
    private readonly unmonitoredInstanceNearingPlatformLimitData: SnoozeInstanceAlertUntilTime[];

    constructor(unmonitoredUptimeInstanceShortIds: string[], unmonitoredInstanceNearingPlatformLimitData: SnoozeInstanceAlertUntilTime[]) {
        const toLowerCaseSet = (items: string[]) => new Set(items.map(item => item.toLowerCase()));
        this.unmonitoredUptimeInstanceShortIds = toLowerCaseSet(unmonitoredUptimeInstanceShortIds);
        this.unmonitoredInstanceNearingPlatformLimitData = unmonitoredInstanceNearingPlatformLimitData;
    }

    shouldSnoozeAlert(metricType: DashboardMetricType) {
        return false;
    }

    shouldSnoozeInstanceAlert(metricType: DashboardMetricType, shortId: string) {
        return metricType === DashboardMetricType.InstanceUptime && this.shouldSnoozeUptimeAlertsForInstance(shortId)
    }

    private shouldSnoozeUptimeAlertsForInstance(shortId: string) {
        return this.unmonitoredUptimeInstanceShortIds.has(shortId.toLowerCase());
    }

    shouldSnoozeInstanceNearingPlatformLimitAlert(metricType: string, hostedInstanceId: string, currentTime: Date): SnoozedAlertData {

        const alertSnoozingData = this.unmonitoredInstanceNearingPlatformLimitData
            .find(data => data.hostedInstanceId.toLowerCase() === hostedInstanceId.toLowerCase()
                && data.metricType.find(snoozeDataMerticType => snoozeDataMerticType.toLowerCase() === metricType.toLowerCase()));

        if(!alertSnoozingData) return unsnoozedAlertData;

        const isExpired = currentTime > alertSnoozingData.snoozeUntilDate;

        return {
            isSnoozed: !isExpired,
            snoozeComment: `'${alertSnoozingData.metricType}' alert(s) for instance '${alertSnoozingData.hostedInstanceId}' snoozed until ${alertSnoozingData.snoozeUntilDate.toLocaleDateString("en-AU")} ${ isExpired ? "(snooze expired!)" : ""} with comment: '${alertSnoozingData.comment}'; Follow link (if exists) to related card`,
            linkToCard: alertSnoozingData.linkToCard
        };
    }
}