import * as React from "react";
import {RouteComponentProps, Switch} from "react-router-dom";
import {AzureRegionResource} from "client/resources/azureRegionResource";
import repository from "client/repository";
import NavigationSidebarLayout, {Navigation} from "components/NavigationSidebarLayout";
import {azureRegionsRouteLinks} from "areas/azureRegions/azureRegionsRouteLinks";
import ReloadableRoute from "components/ReloadableRoute/ReloadableRoute";
import RelatedBackgroundTasks from "shared/RelatedBackgroundTasks";
import AzureRegionDetail from "areas/azureRegions/AzureRegionDetail";
import {HubResource} from "../../client/resources/hubResource";
import {hubsRouteLinks} from "./hubsRouteLinks";
import HubDetail from "./HubDetail";
import {ReefResource} from "../../client/resources/reefResource";

type Props = RouteComponentProps<{ id: string }>;

interface State {
    hub?: HubResource;
}

class HubLayout extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    async componentDidMount() {
        this.setState({
            hub: await repository.Hubs.get(this.props.match.params.id)
        });
    }

    render() {
        const hubId = this.props.match.params.id;
        const hubRoute = hubsRouteLinks.hub(hubId);
        const hubBreadcrumbTitle = this.state.hub && `${this.state.hub.DisplayName} (${hubId})`
        const navLinks = [
            Navigation.navItem("Detail", hubRoute.root, true),
            Navigation.navItem("Tasks", hubRoute.tasks, true)
        ];

        const content = <Switch>
            <ReloadableRoute path={hubRoute.root} exact={true} render={_ => <HubDetail hub={this.state.hub} breadcrumbTitle={hubBreadcrumbTitle} updateHub={this.updateHub} />} />
            <ReloadableRoute path={hubRoute.tasks} exact={true} render={_ => <RelatedBackgroundTasks documentId={hubId} breadcrumbTitle={hubBreadcrumbTitle} />}/>
        </Switch>;

        return this.state.hub ? <NavigationSidebarLayout navLinks={navLinks}
                                        preNavbarComponent={this.state.hub && hubId}
                                        content={content}
                                        name={this.state.hub && this.state.hub.DisplayName}/> : <></>;
    }

    private updateHub = (hub: Partial<HubResource>): void => {
        this.setState((prevState: State) => ({hub: {...prevState.hub, ...hub}}));
    }
}

export default HubLayout;