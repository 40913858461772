import React from "react";
import {RadioButton, RadioButtonGroup} from "@octopusdeploy/design-system-components";
import SaveDialogLayout from "../../components/DialogLayout/SaveDialogLayout";
import {DataBaseComponent, DataBaseComponentState} from "../../components/DataBaseComponent";
import {FeatureToggle, FeatureToggleScope, FeatureFlagScopeLevel} from "../../client/resources/featureFlagsResource";
import HostedInstanceMultiSelect from "../../shared/HostedInstanceMultiSelect";
import {HostedInstanceLookupResource} from "../../client/resources/hostedInstanceLookupResource";
import ReefMultiSelect from "../../shared/ReefMultiSelect";
import {ReefResource} from "../../client/resources/reefResource";
import {Text} from "../../components/form";

interface Props {
    featureFlag: FeatureToggle;
    editScope: (id: string, scope: FeatureToggleScope) => Promise<void>;
    instances: HostedInstanceLookupResource[];
    reefs: ReefResource[];
}

interface State extends DataBaseComponentState {
    scope: FeatureFlagScopeLevel;
    reefIds: string[];
    instanceIds: string[];
    minimumVersion?: string;
    excludedInstanceIds: string[];
}

class EditFeatureFlagScopeDialog extends DataBaseComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            scope: props.featureFlag.AppliesTo.Scope,
            reefIds: props.featureFlag.AppliesTo.ReefIds,
            instanceIds: props.featureFlag.AppliesTo.InstanceIds,
            minimumVersion: props.featureFlag.AppliesTo.MinimumVersion,
            excludedInstanceIds: this.props.featureFlag.AppliesTo.ExcludedInstanceIds
        };
    }

    render() {
        const canBeAppliedToReefs = this.props.featureFlag.Granularity === FeatureFlagScopeLevel.Reef || this.props.featureFlag.Granularity === FeatureFlagScopeLevel.Instance;
        const canBeAppliedToInstances = this.props.featureFlag.Granularity === FeatureFlagScopeLevel.Instance;

        return <SaveDialogLayout title={`Edit scope: ${this.props.featureFlag.Name}`}
                                 busy={this.state.busy}
                                 errors={this.state.errors}
                                 onSaveClick={this.editScope}>

            <RadioButtonGroup value={this.state.scope} onChange={scope => this.updateScope(scope)}>
                <RadioButton value={FeatureFlagScopeLevel.Global} label="Global" />
                <RadioButton value={FeatureFlagScopeLevel.Reef} label="Reef" disabled={!canBeAppliedToReefs} />
                <RadioButton value={FeatureFlagScopeLevel.Instance} label="Instance" disabled={!canBeAppliedToInstances} />
            </RadioButtonGroup>
            
            {canBeAppliedToInstances && 
                <Text label={"Minimum version"}
                      value={this.state.minimumVersion}
                      onChange={version => this.updateMinVersion(version)}
                      hintText="Minimum Octopus Server version"/>}

            {canBeAppliedToReefs && this.state.scope === FeatureFlagScopeLevel.Reef  &&
                <ReefMultiSelect label="Reefs"
                                items={this.props.reefs}
                                value={this.state.reefIds}
                                onChange={reefIds => this.updateReefIds(reefIds)}
                                autoFocus={true}/>}

            {canBeAppliedToInstances && this.state.scope === FeatureFlagScopeLevel.Instance &&
                <HostedInstanceMultiSelect label="Instances"
                                           items={this.props.instances}
                                           value={this.state.instanceIds}
                                           onChange={instanceIds => this.updateInstanceIds(instanceIds)}
                                           autoFocus={true}/>}

            {canBeAppliedToInstances && this.state.scope !== FeatureFlagScopeLevel.Instance &&
                <HostedInstanceMultiSelect label="Explicit Instance Exclusion"
                                           items={this.props.instances}
                                           value={this.state.excludedInstanceIds}
                                           onChange={excludedInstanceIds => this.setState({excludedInstanceIds})}
                                           autoFocus={true}/>}

        </SaveDialogLayout>;
    }
    
    private updateScope(update: FeatureFlagScopeLevel) {
        this.setState({scope: update})
    }
    private updateReefIds(update: string[]) {
        this.setState({reefIds: update})
    }
    private updateInstanceIds(update: string[]) {
        this.setState({instanceIds: update})
    }
    private updateMinVersion(update: string) {
        this.setState({minimumVersion: update})
    }

    private editScope = async (): Promise<boolean> => {
        const scope = {
            ReefIds: this.state.reefIds,
            InstanceIds: this.state.instanceIds,
            Scope: this.state.scope,
            MinimumVersion: this.state.minimumVersion,
            ExcludedInstanceIds: this.state.excludedInstanceIds
        }
        return this.doBusyTask(async () => {
            await this.props.editScope(this.props.featureFlag.Id, scope);
        });
    }
}

export default EditFeatureFlagScopeDialog;