import * as React from "react";
import {Checkbox} from "@octopusdeploy/design-system-components";
import {FormBaseComponent, OptionalFormBaseComponentState} from "components/form/FormBaseComponent";
import FormPaperLayout from "components/FormPaperLayout/FormPaperLayout";
import {InternalRedirect} from "components/Navigation/InternalRedirect";
import routeLinks from "routeLinks";
import repository from "client/repository";
import Summary from "components/form/Sections/Summary";
import ExpandableFormSection from "components/form/Sections/ExpandableFormSection";
import {cloneDeep} from "lodash";
import Callout, { CalloutType } from "components/Callout";
import ReefMultiSelect from "shared/ReefMultiSelect";
import {ReefResource} from "client/resources/reefResource";
import {GatherTaskLogMetricsRequest} from "../../../client/resources/GatherTaskLogMetricsRequest";

interface State extends OptionalFormBaseComponentState<Partial<GatherTaskLogMetricsRequest>> {
    model: Partial<GatherTaskLogMetricsRequest>;
    cleanModel: Partial<GatherTaskLogMetricsRequest>;
    redirectTo?: string;
    reefs: ReefResource[];
}


class GatherTaskLogMetrics extends FormBaseComponent<{}, State, Partial<GatherTaskLogMetricsRequest>> {
    constructor(props: {}) {
        super(props);

        const model: Partial<GatherTaskLogMetricsRequest> = {
            ReefIdsToSearch: [],
            FailTaskIfTooManyErrors: true
        };

        this.state = {
            model,
            cleanModel: cloneDeep(model),
            reefs: []
        };
    }

    componentDidMount() {
        return this.doBusyTask(async () => {
            this.setState({
                reefs: await repository.Reefs.list()
            });
        });
    }


    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo}/>;
        }

        return <FormPaperLayout
            title="Gather Task Log Metrics"
            busy={this.state.busy}
            errors={this.state.errors}
            model={this.state.model}
            cleanModel={this.state.cleanModel}
            onSaveClick={() => this.handleProcessButtonClick()}
            saveButtonBusyLabel={"Processing"}
            saveButtonLabel={"Gather metrics"}
            overFlowActions={null}
            expandAllOnMount={true}>
            {this.getBody()}
        </FormPaperLayout>;
    }

    getBody() {
        return <div>
            <Callout type={CalloutType.Warning} title={"Use with caution!!!"}>
                <p>This process could take quite a while to complete and consume instances' resources.</p>
            </Callout>
            <ExpandableFormSection
                errorKey="Reefs"
                title="Reefs To Process"
                summary={Summary.summary("Reefs")}>
                <ReefMultiSelect
                    items={this.state.reefs}
                    value={this.state.model.ReefIdsToSearch}
                    onChange={reefIds => this.setModelState({
                        ReefIdsToSearch: reefIds
                    })}
                />
                <Checkbox value={this.state.model.FailTaskIfTooManyErrors} onChange={v => this.setModelState({ FailTaskIfTooManyErrors: v })} label={"Fail task if too many instances fail to gather metrics"}/>
            </ExpandableFormSection>
        </div>;
    }

    handleProcessButtonClick() {
        return this.doBusyTask(async () => {
            await repository.HostedInstances.gatherTaskLogMetrics(this.state.model as GatherTaskLogMetricsRequest);
            this.setState({redirectTo: routeLinks.backgroundTasks.root});
        });
    }
}

export default GatherTaskLogMetrics;
