import * as React from "react";
import styles = require("./style.less");
import {maxInputWidth} from "utils/sizes";

interface InputWithActionsProps {
    input: JSX.Element;
    actions: JSX.Element;
    applyMaxWidth?: boolean;
    maxWidthInRem?: number;
}

const InputWithActions: React.StatelessComponent<InputWithActionsProps> = props => {
    const inputStyle =  props.applyMaxWidth ? {maxWidth: maxInputWidth} : (props.maxWidthInRem ? {maxWidth: `${props.maxWidthInRem}rem`} : {});

    return <div className={styles.container}>
        <div className={styles.input} style={inputStyle}>
            {props.input}
        </div>
        <div className={styles.actions}>
            {props.actions}
        </div>
    </div>;
};

InputWithActions.displayName = "InputWithActions";
InputWithActions.defaultProps = {
    applyMaxWidth: false
};

export default InputWithActions;