// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_workerList__iEddi {
  margin-block-start: 0;
  margin-block-end: 0;
}
`, "",{"version":3,"sources":["webpack://./app/areas/dynamicWorkerServices/style.less"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,mBAAA;AACF","sourcesContent":[".workerList {\n  margin-block-start: 0;\n  margin-block-end: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"workerList": `style_workerList__iEddi`
};
module.exports = ___CSS_LOADER_EXPORT___;
