/* This file is DEPRECATED
   We are moving to the Octopus Design System
   https://www.octopus.design
  
   When changing component styles:
   1. Do not use variables from this file
   2. Aim to use Emotion `css` + themeTokens.colors
       - see Dashboard.tsx for example
   3. If you need to use Less, reference CSS variables
       - see index.ejs for example
       - for full list see https://github.com/OctopusDeploy/OctopusDeploy/blob/main/frontend/packages/design-system-tokens/src/generated/themeTokens.ts
   4. Remove variables from this file when they are no longer used
 */
import { themeTokens } from "@octopusdeploy/design-system-tokens";

export const primaryText = themeTokens.color.text.primary;
export const secondaryText = themeTokens.color.text.subtle;

export const primary = themeTokens.color.text.link.default;

export const divider = themeTokens.color.border.subtle;
export const secondaryBackground = themeTokens.color.background.subtle;

export const danger = themeTokens.color.text.danger;
export const alert = themeTokens.color.text.warning;
