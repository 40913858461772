// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_heading__ktxYM {
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.style_title__zEWql {
  flex-grow: 1;
}
.style_action__ExLp7 {
  flex-basis: 16.5rem;
}
`, "",{"version":3,"sources":["webpack://./app/shared/FormSectionHeadingWithAction/style.less"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,sBAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;AACJ;AAEA;EACI,YAAA;AAAJ;AAGA;EACI,mBAAA;AADJ","sourcesContent":[".heading {\n    margin-top: -0.5rem;\n    margin-bottom: -0.5rem;\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n}\n\n.title {\n    flex-grow: 1;\n}\n\n.action {\n    flex-basis: 16.5rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heading": `style_heading__ktxYM`,
	"title": `style_title__zEWql`,
	"action": `style_action__ExLp7`
};
module.exports = ___CSS_LOADER_EXPORT___;
