import { AttentionLevel } from "client/resources/attentionLevel";
import Warning from "material-ui/svg-icons/alert/warning";
import Check from "material-ui/svg-icons/navigation/check";
import HourglassEmpty from "material-ui/svg-icons/action/hourglass-empty";
import React = require("react");
import {themeTokens} from "@octopusdeploy/design-system-tokens";

const iconColor = themeTokens.color.icon;
const textColor = themeTokens.color.text;

export function getAttentionIndicator(attentionLevel: AttentionLevel, isSnoozed?: boolean) : AttentionIndicator {
    switch (attentionLevel) {
        case AttentionLevel.Ok: return isSnoozed ? okSnoozed() : ok();
        case AttentionLevel.Expired: return expired();
        case AttentionLevel.Warning: return isSnoozed ? alertOrWarningSnoozed() : warning();
        case AttentionLevel.Alert: return isSnoozed ? alertOrWarningSnoozed() : alert();
    }
}

export function warning() : AttentionIndicator {
    return {color: textColor.warning, icon: <Warning color={iconColor.warning} />};
}

export function alert() : AttentionIndicator {
    return {color: textColor.danger, icon: <Warning color={iconColor.danger} />};
}

export function alertOrWarningSnoozed() : AttentionIndicator {
    return {color: textColor.info, icon: <Warning color={iconColor.info} />}
}

export function ok() : AttentionIndicator {
    return {color: textColor.success, icon: <Check color={iconColor.success} />};
}

export function okSnoozed() : AttentionIndicator {
    return {color: textColor.info, icon: <Check color={iconColor.info} />};
}

export function expired() : AttentionIndicator {
    return {color: textColor.subtle, icon: <HourglassEmpty color={iconColor.subtle} />};
}

export interface AttentionIndicator {
    color: string;
    icon: JSX.Element;
}