import useLocalStorage from "../../hooks/useLocalStorage";
import type { ThemeName } from "@octopusdeploy/design-system-components";

type SetThemeName = (value: ThemeName) => void;

interface ThemeSettingResult {
    themeName: ThemeName,
    setThemeName: SetThemeName,
}

export function useThemeSetting(): ThemeSettingResult {
    const [themeNameFromStorage, setThemeName] = useLocalStorage<ThemeName | "unassigned">("theme", "unassigned");
    const browserPreferredColorScheme = getBrowserPreferredColorScheme("light");

    return {
        themeName: themeNameFromStorage === "unassigned" ? browserPreferredColorScheme : themeNameFromStorage,
        setThemeName,
    };
}

function getBrowserPreferredColorScheme(fallback: ThemeName): ThemeName {
    if (!window.matchMedia) {
        return fallback;
    } else if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
        return "dark";
    } else if (window.matchMedia("(prefers-color-scheme: light)").matches) {
        return "light";
    } else {
        return fallback;
    }
}
