import client from "client/client";
import {TopicSubscriptionResource} from "../resources/topicSubscriptionResource";
import {MessageBusMessageResource} from "../resources/messageBusMessageResource";
import {SendTestEventWithDelayedSuccessRequest} from "../resources/sendTestEventWithDelayedSuccessRequest";

export interface AuditListArgs {
    documentId: string;
}

export default class MessageBusRepository {

    private rootUri = "/api/message-bus";

    listSubscriptions(): Promise<TopicSubscriptionResource[]> {
        return client.get(`${this.rootUri}/subscriptions`);
    }

    previewDeadLetterQueueMessages(subscription: TopicSubscriptionResource, numberOfMessagesToPreview: number): Promise<MessageBusMessageResource[]> {
        return client.get(`${this.rootUri}/{topicName}/subscriptions/{subscriptionName}/dead-letter-queue/messages/{numberOfMessagesToPreview}`, {
            topicName: subscription.TopicName,
            subscriptionName: subscription.Name,
            numberOfMessagesToPreview
        });
    }

    retryDeadLetterQueueMessage(subscription: TopicSubscriptionResource, sequenceNumber: number): Promise<MessageBusMessageResource> {
        return client.post(`${this.rootUri}/{topicName}/subscriptions/{subscriptionName}/dead-letter-queue/message/{sequenceNumber}`, {},
            {
                topicName: subscription.TopicName,
                subscriptionName: subscription.Name,
                sequenceNumber
            });
    }

    async deleteDeadLetterQueueMessage(subscription: TopicSubscriptionResource, sequenceNumber: number) {
        return client.del(`${this.rootUri}/{topicName}/subscriptions/{subscriptionName}/dead-letter-queue/message/{sequenceNumber}`,
            { args: {
                topicName: subscription.TopicName,
                subscriptionName: subscription.Name,
                sequenceNumber
            }});
    }

    testEventMessageWithDelay(request: SendTestEventWithDelayedSuccessRequest) {
        return client.post(`${this.rootUri}/test-event-with-delay`, request);
    }
}
