import {Component, connect} from "react-redux";
import {onDialogClose} from "components/Dialog/reducers/dialog";
import {DialogLayoutDispatchProps} from "components/DialogLayout/DialogLayout";

export class DialogLayoutConnect {
    static to<TProps>(internal: Component<DialogLayoutDispatchProps & TProps>) {
        const mapDispatchToProps = (dispatch: any): DialogLayoutDispatchProps => {
            return {
                close: () => {
                    dispatch(onDialogClose());
                }
            };
        };

        return connect<{}, DialogLayoutDispatchProps, TProps>(
            null,
            mapDispatchToProps
        )(internal);
    }
}