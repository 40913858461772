import * as React from "react";
import {ActionButton, ActionButtonType} from "components/Button/ActionButton";
import {
    DialogLayoutCommonProps,
    DialogLayoutDispatchProps,
    DialogLayout,
} from "components/DialogLayout/DialogLayout";
import {DialogLayoutConnect} from "components/Dialog/DialogLayoutConnect";

interface DeleteDialogLayoutProps extends DialogLayoutCommonProps {
    deleteButtonLabel?: string;
    deleteButtonBusyLabel?: string;
    deleteButtonDisabled?: boolean;
    onDeleteClick(): Promise<boolean>;
}

class DeleteDialogLayoutInternal extends React.Component<DeleteDialogLayoutProps & DialogLayoutDispatchProps> {
    deleteClick = async () => {
        const result = await this.props.onDeleteClick();
        if (result) {
            this.props.close();
        }
    }

    render() {
        const save = <ActionButton key="Delete"
                                   type={ActionButtonType.Delete}
                                   label={this.props.deleteButtonLabel || "Delete"}
                                   busyLabel={this.props.deleteButtonBusyLabel || "Deleting..."}
                                   onClick={this.deleteClick}
                                   disabled={this.isDeleteButtonDisabled()}/>;
        const cancel = <ActionButton key="Cancel" label="Cancel" disabled={this.props.busy}
                                     onClick={() => this.props.close()}/>;
        return <DialogLayout actions={[cancel, save]} {...this.props} closeDialog={this.props.close}/>;
    }

    private isDeleteButtonDisabled(): boolean {
        return !!(this.props.deleteButtonDisabled || this.props.busy);
    }
}

const DeleteDialogLayout = DialogLayoutConnect.to<DeleteDialogLayoutProps>(DeleteDialogLayoutInternal);
DeleteDialogLayout.displayName = "DeleteDialogLayout";
export default DeleteDialogLayout;
