import React from "react";
import {BooleanRadioButton, BooleanRadioButtonGroup} from "@octopusdeploy/design-system-components";
import {DataBaseComponent, DataBaseComponentState, Errors} from "components/DataBaseComponent";
import repository from "client/repository";
import {BusyState} from "components/BusyFromPromise/BusyFromPromise";
import {HostedInstanceResource} from "client/resources/hostedInstanceResource";
import {ReefResource} from "client/resources/reefResource";
import SaveDialogLayout from "components/DialogLayout/SaveDialogLayout";
import {Text} from "components/form";
import {validateCPU, validateMemory} from "areas/instances/Instances/k8sResourceValidation";
import {HostedInstanceReprovisioningRequest} from "client/resources/hostedInstanceReprovisioningRequest";
import {InstanceLimitResource} from "../../../client/resources/instanceLimitResource";

interface ReprovisionDialogProps {
    errors: Errors;
    busy: BusyState;
    instance: HostedInstanceResource;
    instanceLimit: InstanceLimitResource;
    reef: ReefResource;
    onSaveClick(): Promise<boolean>;
}

interface ReprovisionDialogState extends DataBaseComponentState {
    reprovisioningRequest: Partial<HostedInstanceReprovisioningRequest>;
}

export class ReprovisionDialog extends DataBaseComponent<ReprovisionDialogProps, ReprovisionDialogState> {
    constructor(props: ReprovisionDialogProps) {
        super(props);
        this.state = {
            reprovisioningRequest: {
                ...{ReprovisionDuringOutageWindow: true},
                ...this.props.instance,
            }
        };
    }

    render() {

        const request = this.state.reprovisioningRequest;
        return <SaveDialogLayout title={"Reprovision"}
                                 busy={this.state.busy}
                                 errors={this.state.errors}
                                 onSaveClick={() => this.reprovision(request)}>

            <BooleanRadioButtonGroup value={request.ReprovisionDuringOutageWindow} onChange={ReprovisionDuringOutageWindow => this.updateReprovisioningRequest({ReprovisionDuringOutageWindow})}>
                <BooleanRadioButton value={true} label="Reprovision During Outage Window" />
                <BooleanRadioButton value={false} label="Reprovision Immediately" />
            </BooleanRadioButtonGroup>

            <Text label="CPU Request"
                  value={request.CPURequest}
                  onChange={CPURequest => this.updateReprovisioningRequest({CPURequest})}
                  validate={validateCPU} />

            <Text label="CPU Limit"
                  value={request.CPULimit}
                  onChange={CPULimit => this.updateReprovisioningRequest({CPULimit})}
                  validate={validateCPU}/>

            <Text label="Memory Request"
                  value={request.MemoryRequest}
                  onChange={MemoryRequest => this.updateReprovisioningRequest({MemoryRequest})}
                  validate={validateMemory}/>

            <Text label="Memory Limit"
                  value={request.MemoryLimit}
                  onChange={MemoryLimit => this.updateReprovisioningRequest({MemoryLimit})}
                  validate={validateMemory}/>

            <Text label="Cache Max Size"
                  hintText="Cache max size for this instance in GB"
                  value={request.CacheDirectoryMaxSizeInGb.toString()}
                  onChange={maxSize => this.updateReprovisioningRequest({CacheDirectoryMaxSizeInGb: Number(maxSize)})}
            />

            <Text label="Worker Idle Time Before Deletion"
                  value={request.WorkerIdleTimeBeforeDeletion}
                  onChange={WorkerIdleTimeBeforeDeletion => this.updateReprovisioningRequest({WorkerIdleTimeBeforeDeletion})}
                  hintText={"Please enter the worker idle time before deletion"}
                  error={this.getFieldError("WorkerIdleTimeBeforeDeletion")} />

            <Text label="Worker Maximum Lease Duration"
                  value={request.WorkerMaximumLeaseDuration}
                  onChange={WorkerMaximumLeaseDuration => this.updateReprovisioningRequest({WorkerMaximumLeaseDuration})}
                  hintText={"Please enter the worker maximum lease duration"}
                  error={this.getFieldError("WorkerMaximumLeaseDuration")} />

            <Text label="Worker Decommission After"
                  value={request.WorkerDecommissionAfter}
                  onChange={WorkerDecommissionAfter => this.updateReprovisioningRequest({WorkerDecommissionAfter})}
                  hintText={"Please enter the worker decommission after"}
                  error={this.getFieldError("WorkerDecommissionAfter")} />

        </SaveDialogLayout>;
    }

    private updateReprovisioningRequest(update: Partial<HostedInstanceReprovisioningRequest>) {
        return this.setState(prevState => ({reprovisioningRequest: {...prevState.reprovisioningRequest, ...update}}));
    }

    private reprovision(request: Partial<HostedInstanceReprovisioningRequest>) {
        return this.doBusyTask(async () => {
            await repository.HostedInstances.reprovision(this.props.instance, request as HostedInstanceReprovisioningRequest);
            await this.props.onSaveClick();
            return true;
        });
    }
}