import React from "react";
import InternalLink from "components/Navigation/InternalLink/InternalLink";
import {FriendlyLocalTime} from "shared/FriendlyLocalTime";
import {freshnessUnit, getHeaderColor} from "areas/dashboard/getHeaderColor";
import ExternalLink from "../../components/Navigation/ExternalLink";
import * as styles from "./styles";

export interface MetricsProps {
    header: string;
    children?: React.ReactNode;
    lastUpdated: string;
    freshness: freshnessUnit;
}

export const Metrics = (props: MetricsProps) => {
    return <div className={styles.cardContainer}>
        <div className={styles.cardTitle} style={{backgroundColor: getHeaderColor(props.lastUpdated, props.freshness)}}>
            <div>{props.header}</div>
            <div className={styles.lastUpdated}>
                Updated: <FriendlyLocalTime time={props.lastUpdated} />
            </div>
        </div>
        <div className={styles.cardContent}>{props.children}</div>
    </div>;
};

export interface MetricProps {
    name: string;
    value: number;
    color: string;
    icon: JSX.Element;
    link: () => string;
    tooltip?: string;
}

export const MetricItem = (props: MetricProps) => {
    const url = props.link();
    const linkContent = createLinkContent(props);
    if (url == "") {
        // No link
        return linkContent;
    } else if (url.startsWith("/")) {
        // Relative links are internal and opened in the same window
        return <InternalLink to={url}>{linkContent}</InternalLink>
    } else {
        // External links are absolute and opened in a new window
        return <ExternalLink href={url} showIcon={false}>{linkContent}</ExternalLink>;
    }
};

function createLinkContent(props: MetricProps) {
    return <div className={styles.metricItem} title={props.tooltip}>
        <div className={styles.metricIcon} style={{ color: props.color }}>{props.icon}</div>
        <div className={styles.metricTitle}>{props.name}</div>
        <div style={{ color: props.color }}>{props.value}</div>
    </div>;
}
