import { RouteComponentProps } from "react-router-dom";
import DataBaseComponent, { DataBaseComponentState } from "components/DataBaseComponent";
import {DynamicWorkerSourceResource} from "client/resources/dynamicWorkerSourceResource";
import {DynamicWorkerServiceResource} from "client/resources/dynamicWorkerServiceResource";
import repository from "client/repository";
import * as React from "react";
import ActionButton from "components/Button";
import PaperLayout from "components/PaperLayout";
import SimpleDataTable from "components/SimpleDataTable";
import { OverflowMenu } from "components/Menu";
import routeLinks from "../../../routeLinks";

type Props = RouteComponentProps<{ id: string, typeId: string }>;

interface State extends DataBaseComponentState {
    sources: DynamicWorkerSourceResource[];
    service: DynamicWorkerServiceResource;
}

class DataTable extends SimpleDataTable<DynamicWorkerSourceResource> {
}

class DynamicWorkerSources extends DataBaseComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            sources: [],
            service: null,
        };
    }

    componentDidMount() {
        return this.doBusyTask(async () => {
            await this.refreshData();
        });
    }

    render() {
        const isLoaded = !!this.state.service;
        const refreshButton = <ActionButton label="Refresh" onClick={this.refreshData}/>;
        return isLoaded && <PaperLayout title={`${this.props.match.params.typeId} Versions`}
                            breadcrumbTitle="Dynamic Worker Images"
                            breadcrumbPath={routeLinks.dynamicWorkerServices.service(this.props.match.params.id).workerTypes}
                            busy={this.state.busy}
                            errors={this.state.errors}
                            sectionControl={refreshButton}
                            fullWidth={true}>
            <DataTable
                data={this.state.sources}
                onRow={(item: DynamicWorkerSourceResource) => this.buildRow(item)}
                onRowOverflowMenu={source => this.getOverflowMenu(source, this.state.sources.length)}
                headerColumns={["ID", "Version", "Path", "Has CrowdStrike", "Active"]}
                onEmpty={<div>No worker sources found</div>}/>
        </PaperLayout>;
    }

    private buildRow(source: DynamicWorkerSourceResource) {
        return [
            source.Id,
            source.Name,
            source.Path,
            source.SupportsCrowdStrike ? <span>✅</span> : null,
            source.IsActive ? <span>Active</span> : null
        ];
    }

    private getOverflowMenu(source: DynamicWorkerSourceResource, sourceCount: number) {
        const items = [];
        if (!source.IsActive) {
            items.push(OverflowMenu.item("Make active", async () => { await this.makeSourceActive(source); }));
        }
        if (!source.IsActive || sourceCount == 1) {
            items.push(OverflowMenu.item("Delete", async () => { await this.deleteSource(source); }));
        }
        return items;
    }

    private deleteSource(source: DynamicWorkerSourceResource) {
        return this.doBusyTask(async () => {
            await repository.DynamicWorkerServices.deleteWorkerSource(this.state.service.Id, source.Id);
            await this.refreshData();
        });
    }

    private makeSourceActive(source: DynamicWorkerSourceResource) {
        return this.doBusyTask(async () => {
            await repository.DynamicWorkerServices.setWorkerSourceActive(this.state.service.Id, source);
            await this.refreshData();
        });
    }

    private refreshData = async () => {
        await this.doBusyTask(async () => {
            this.setState(await this.loadData());
        });
    };

    private async loadData() {
        const [sources, service] = await Promise.all([
            this.loadWorkersSources(),
            this.loadService()
        ]);

        return { sources, service };
    }

    private async loadWorkersSources() {
        return repository.DynamicWorkerServices.workerSources(this.props.match.params.id, this.props.match.params.typeId);
    }

    private async loadService() {
        return repository.DynamicWorkerServices.get(this.props.match.params.id);
    }

}

export default DynamicWorkerSources;
