import * as React from "react";
import SaveDialogLayout from "components/DialogLayout/SaveDialogLayout";
import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent";
import repository from "client/repository";
import {ReefResource} from "client/resources/reefResource";

interface Props {
    reefId: string;
    isAvailableToCustomers: boolean;
    onChange: (reef: Partial<ReefResource>) => void;
}

type State = DataBaseComponentState;

class ChangeAvailabilityForCustomersDialog extends DataBaseComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    render() {
        return <SaveDialogLayout title={"Availability for Customers"}
            busy={this.state.busy}
            errors={this.state.errors}
            saveButtonLabel={"Yes"}
            cancelButtonLabel={"No"}
            onSaveClick={() => this.changeAvailabilityForCustomers()}>
            <p>Are you sure you want to make this reef <b>{`${!this.props.isAvailableToCustomers ? "Available" : "Unavailable"}`}</b> for customers?</p>
        </SaveDialogLayout>;
    }

    private changeAvailabilityForCustomers = async (): Promise<boolean> => {
        return this.doBusyTask(async () => {
            const availabilityToChange: boolean = !this.props.isAvailableToCustomers;
            await repository.Reefs.changeAvailabilityForCustomers(this.props.reefId, {IsAvailableToCustomers: availabilityToChange});
            this.props.onChange({AvailableToCustomers: availabilityToChange});
        });
    }
}

export default ChangeAvailabilityForCustomersDialog;
