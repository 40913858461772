export enum OutageWindowFrequency {
	Daily = "Daily",
	FirstMondayOfEachMonth = "FirstMondayOfEachMonth",
	SecondMondayOfEachMonth = "SecondMondayOfEachMonth",
	ThirdMondayOfEachMonth = "ThirdMondayOfEachMonth"
}

export const outageWindowFrequencyDescriptions: Record<OutageWindowFrequency, string> = {
    [OutageWindowFrequency.Daily]: "Daily",
    [OutageWindowFrequency.FirstMondayOfEachMonth]: "First Monday of each month",
    [OutageWindowFrequency.SecondMondayOfEachMonth]: "Second Monday of each month",
    [OutageWindowFrequency.ThirdMondayOfEachMonth]: "Third Monday of each month",
}