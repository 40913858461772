import { curry } from "lodash";
import {number} from "prop-types";

export const required = (message: string) => {
    return (value: string) => {
        if (!value || value.length < 1) {
            return message;
        }
        return "";
    };
};

export const email = (message: string) => {
    return (value: string) => {
        if (!value || !value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
            return message;
        }
        return "";
    };
};

export const minimum = (message: string, min: number) => {
    return (value: string) => {
        const result: number = Number(value);
        if (isNaN(result)) {
            return message;
        }
        return result < min ? message : "";
    };
};