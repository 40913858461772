import * as React from "react";
import {SelectItem} from "components/VirtualListWithKeyboard/SelectItem";
import {FormFieldProps} from "components/form";
import {DynamicWorkerState} from "shared/dynamicWorkerState";
import MultiSelect from "components/MultiSelect/MultiSelect";
import DynamicWorkerStateChip from "shared/WorkerStateChip";

interface DynamicWorkerStateMultiSelectProps extends FormFieldProps<DynamicWorkerState[]> {
    canAdd?: boolean;
    openOnFocus?: boolean;
    autoFocus?: boolean;
    label?: string | JSX.Element;
    error?: string;
    hideFloatingLabel?: boolean;
    validate?(value: DynamicWorkerState[]): string;
}

const DynamicWorkerStateTypedMultiSelect = MultiSelect<SelectItem>();

const DynamicWorkerStateMultiSelect: React.StatelessComponent<DynamicWorkerStateMultiSelectProps> = props => {
    const onAdd = (value: DynamicWorkerState) => {
        props.onChange(props.value.concat(value));
    };

    const chipRenderer = (state: SelectItem, onRequestDelete: (event: object) => void) => {
        return <DynamicWorkerStateChip
            onRequestDelete={onRequestDelete}
            state={state.Id as DynamicWorkerState}
        />;
    };

    return <DynamicWorkerStateTypedMultiSelect
        items={Object.entries(DynamicWorkerState).map(p => ({Id: p[0], Name: p[1]})).sort((e1, e2) => compare(e1.Name, e2.Name))}
        fieldName="worker state"
        label={props.label}
        renderChip={chipRenderer}
        onNew={props.canAdd ? onAdd : null}
        value={props.value}
        onChange={props.onChange}
        error={props.error}
        openOnFocus={props.openOnFocus}
        autoFocus={props.autoFocus}
        hideFloatingLabel={props.hideFloatingLabel}
    />;
};

function compare(value1: string, value2: string) {
    if (value1 < value2) {
        return -1;
    }
    if (value1 > value2) {
        return 1;
    }

    return 0;
}

export default DynamicWorkerStateMultiSelect;