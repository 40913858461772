import client from "client/client";
import {ExternalUrlsResource} from "client/resources/externalUrlsResource";
import {ReefTemplate} from "client/resources/reefTemplate";
import {AppInfoResource} from "client/resources/appInfoResource";
import {FeatureFlagsResource} from "client/resources/featureFlagsResource";
import {EditFeatureFlagRequest} from "client/resources/editFeatureFlagRequest";
import {EditFeatureFlagScopeRequest} from "../resources/editFeatureFlagScopeRequest";

export default class ConfigurationRepository {

    private rootUri = "/api/configuration";

    getAppInfo(): Promise<AppInfoResource> {
        return client.get(`${this.rootUri}/app-info`);
    }

    getFeatureFlags(): Promise<FeatureFlagsResource> {
        return client.get(`${this.rootUri}/feature-flags`);
    }

    editFeatureFlag(id: string, request: EditFeatureFlagRequest) {
        return client.put(`${this.rootUri}/feature-flags/{id}`, request, {id});
    }

    editFeatureFlagScope(id: string, request: EditFeatureFlagScopeRequest) {
        return client.put(`${this.rootUri}/feature-flags/{id}/scope/`, request, {id});
    }

    getExternalUrls(): Promise<ExternalUrlsResource> {
        return client.get(`${this.rootUri}/external-urls`);
    }

    getReefTemplates(): Promise<ReefTemplate[]> {
        return client.get(`${this.rootUri}/reef-templates`);
    }
}