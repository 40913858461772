import * as React from "react";
import {FunctionComponent} from "react";
import ReloadableRoute from "components/ReloadableRoute/ReloadableRoute";
import {Switch} from "react-router-dom";
import routeLinks from "routeLinks";
import Audit from "areas/auditing/Audit";

const AuditLayout: FunctionComponent<{}> = props =>
    <Switch>
        <ReloadableRoute path={routeLinks.audit.root} exact={true} component={Audit} />
    </Switch>;

AuditLayout.displayName = "AuditLayout";

export default AuditLayout;