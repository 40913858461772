import * as React from "react";

interface ExternalLinkProps extends React.HTMLProps<HTMLElement> {
    href: string;
    showIcon?: boolean;
    openInSelf?: boolean;
    onClick?: () => void;
    className?: string;
}

const ExternalLink: React.SFC<ExternalLinkProps> = (props: ExternalLinkProps) => {
    if (props.href == null) {
        return null;
    }

    const parseUrl = (href: string): boolean => {
        try {
            const ignored = new URL(href);
            return true;
        } catch (error) {
            return false;
        }
    };

    const formatUrl = (href: string) => {

        if (parseUrl(href)) {
            return href;
        }

        return "https://g.octopushq.com/" + href;
    };
    const onClick = (e: any) => {
        if (props.onClick) {
            props.onClick();
        }
        e.stopPropagation();
    };

    return <a href={formatUrl(props.href)} className={props.className} target={props.openInSelf ? "_self" : "_blank"} onClick={onClick}>{props.children}
        {props.showIcon && <em style={{marginLeft: "0.250rem"}} className="fa fa-external-link" aria-hidden="true" />}</a>;
};

ExternalLink.defaultProps = {
    showIcon: true,
    openInSelf: false
};

export default ExternalLink;