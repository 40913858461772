import * as React from "react";
import {DataBaseComponentState} from "components/DataBaseComponent";
import repository from "client/repository";
import { BackgroundTaskResource } from "client/resources/backgroundTaskResource";
import ActionButton, {ActionButtonType} from "components/Button";
import {BackgroundTaskStatus} from "client/resources/backgroundTaskStatus";
import OverflowMenu from "components/Menu/OverflowMenu";
import BulkCancelBackgroundTaskDialog from "./BulkCancelBackgroundTaskDialog";
import FilteredBackgroundTasks from "../../shared/FilteredBackgroundTasks";

interface State extends DataBaseComponentState {
    latestAutoUpgradeTask?: BackgroundTaskResource;
}

class BackgroundTasks extends React.Component<{}, State> {
    
    private filteredBackgroundTasks = React.createRef<FilteredBackgroundTasks>();

    constructor(props: {}) {
        super(props);
        this.state = { };
    }
    
    changeHandler() {
        return this.filteredBackgroundTasks.current.doBusyTask(async () => {
            const autoUpgradeTask = await repository.BackgroundTasks.autoUpgradeGet();
            this.setState({latestAutoUpgradeTask: autoUpgradeTask});
            return {autoUpgradeTask};
        });
    }

    render() {
        const excludeRecurring: boolean = false;

        return <FilteredBackgroundTasks
                excludeRecurring={excludeRecurring} 
                ref={this.filteredBackgroundTasks} 
                onChange={this.changeHandler.bind(this)}
                getActions={this.getActions.bind(this)}
                ></FilteredBackgroundTasks>;
    }
    
    public getActions() {

        const menuItems = [
            OverflowMenu.item("Cancel Recurring Tasks", () => this.updateAndRefreshData(BackgroundTasks.recurringCancel)),
            OverflowMenu.item("Resume Recurring Tasks", () => this.updateAndRefreshData(BackgroundTasks.recurringResume)),
            this.filteredBackgroundTasks && this.filteredBackgroundTasks.current.getTasks() && OverflowMenu.dialogItem("Bulk cancel tasks", this.getBulkCancelBackgroundTasks()),
        ];
        
        return [
            this.state.latestAutoUpgradeTask 
                && this.state.latestAutoUpgradeTask.Status !== BackgroundTaskStatus.Cancelled 
                && <ActionButton type={ActionButtonType.Primary} label="Stop Auto Upgrade" onClick={() => this.updateAndRefreshData(BackgroundTasks.autoUpgradeStop)} />,
            this.state.latestAutoUpgradeTask 
                && this.state.latestAutoUpgradeTask.Status === BackgroundTaskStatus.Cancelled 
                && <ActionButton type={ActionButtonType.Primary} label="Start Auto Upgrade" onClick={() => this.updateAndRefreshData(BackgroundTasks.autoUpgradeStart)} />,            
            <OverflowMenu menuItems={menuItems}/>
        ]        
    }
    
    private doRefresh() {
        this.filteredBackgroundTasks.current.doRefresh();
    }

    private async updateAndRefreshData(dataUpdateFunction: () => Promise<any>){
        return this.filteredBackgroundTasks.current.doBusyTask(async () => {
            await dataUpdateFunction();
            this.doRefresh();
            return true;
        });
    }

    
    private static async autoUpgradeStop () {
        await repository.BackgroundTasks.autoUpgradeStop();
    }

    private static async autoUpgradeStart() {
        await repository.BackgroundTasks.autoUpgradeStart();
    }
    
    private static async recurringCancel(){
        await repository.BackgroundTasks.recurringCancel();
    }
       
    private static async recurringResume() {
        await repository.BackgroundTasks.recurringResume();
    } 
    
    private getBulkCancelBackgroundTasks(){
        const filteredBackgroundTasks = this.filteredBackgroundTasks.current;
        const tasks = filteredBackgroundTasks.getTasks();
        const isDefaultFilter = filteredBackgroundTasks.isDefaultFilter();
        return <BulkCancelBackgroundTaskDialog 
            importedBackgroundTaskIds={tasks.map(t => t.Id)} 
            isDefaultFilter={isDefaultFilter} 
        />;
    }
    
}

export default BackgroundTasks;