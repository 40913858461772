import * as React from "react";
import SliderImpl from "material-ui/Slider";
import FormFieldProps from "../FormFieldProps";
import styles = require("./style.less");

type Props = FormFieldProps<number> & {
    disabled?: boolean;
    max?: number;
    min?: number;
    label?: string;
    step?: number;
}

const Slider = (props: Props) => {
    const {onChange, label, ...otherProps} = props;
    return <div className={styles.container}>
        {label && <label className={styles.label}>{label}</label>}
        <SliderImpl {...otherProps} 
                    onChange={(e, number) => props.onChange(number)}
                    style={{ flexGrow: 1}}
        />
        <span className={styles.value}>{props.value}%</span>
    </div>
};


export default Slider;