import React from "react";
import {BooleanRadioButton, BooleanRadioButtonGroup} from "@octopusdeploy/design-system-components";
import {DataBaseComponent, DataBaseComponentState, Errors} from "../../../components/DataBaseComponent";
import {BusyState} from "../../../components/BusyFromPromise/BusyFromPromise";
import {HostedInstanceResource} from "../../../client/resources/hostedInstanceResource";
import {HostedInstanceDatabasePoolSizeRequest} from "../../../client/resources/HostedInstanceDatabasePoolSizeRequest";
import SaveDialogLayout from "../../../components/DialogLayout/SaveDialogLayout";
import {Text} from "../../../components/form";
import repository from "../../../client/repository";

interface Props {
    errors: Errors;
    busy: BusyState;
    instance: HostedInstanceResource;
    onSaveClick(): Promise<boolean>;
}

interface State extends DataBaseComponentState {
    changeDatabasePoolSizeRequest: Partial<HostedInstanceDatabasePoolSizeRequest>;
}


export class EditDatabaseMaxPoolSizeDialog extends DataBaseComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            changeDatabasePoolSizeRequest: {
                PoolSize: props.instance.DatabaseConnectionMaxPoolSize,
                ApplyDuringOutageWindow: true
            }
        };
    }

    render() {
        const request = this.state.changeDatabasePoolSizeRequest;
        return <SaveDialogLayout title={"Change Database Connection Max Pool Size"}
                                 errors={this.state.errors}
                                 busy={this.state.busy}
                                 onSaveClick={() => this.configureDatabasePoolSize(request)}>

            <BooleanRadioButtonGroup value={this.state.changeDatabasePoolSizeRequest.ApplyDuringOutageWindow}
                              onChange={ApplyDuringOutage => this.updateDatabaseMaxPoolSizeRequest({ApplyDuringOutageWindow: ApplyDuringOutage})}>
                <BooleanRadioButton value={true} label="Apply During Outage Window"/>
                <BooleanRadioButton value={false} label="Apply Immediately"/>
            </BooleanRadioButtonGroup>

            <Text label="Max Pool Size"
                  hintText="Database Connection Max Pool Size"
                  value={this.state.changeDatabasePoolSizeRequest.PoolSize.toString()}
                  onChange={ApplyPoolSize => this.updateDatabaseMaxPoolSizeRequest({PoolSize: Number(ApplyPoolSize)})}
                  autoFocus={true}
            />
        </SaveDialogLayout>;
    }

    private updateDatabaseMaxPoolSizeRequest(update: Partial<HostedInstanceDatabasePoolSizeRequest>) {
        return this.setState(prevState => ({changeDatabasePoolSizeRequest: {...prevState.changeDatabasePoolSizeRequest, ...update}}));
    }

    private async configureDatabasePoolSize(request: Partial<HostedInstanceDatabasePoolSizeRequest>) {
        return await this.doBusyTask(async () => {
            await repository.HostedInstances.configureDatabasePoolSize(this.props.instance, request as HostedInstanceDatabasePoolSizeRequest);
            await this.props.onSaveClick();
            return true;
        });
    }
}