import * as React from "react";
import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent";
import PaperLayout from "components/PaperLayout";
import ActionButton, {ActionButtonType} from "components/Button";
import {
    DataTable,
    DataTableBody,
    DataTableRow,
    DataTableRowColumn,
    DataTableRowHeaderColumn
} from "components/DataTable";
import repository from "client/repository";
import WorkersByState from "./WorkersByState";
import WorkersBySize from "./WorkersBySize";
import {DynamicWorkerServiceResource} from "../../client/resources/dynamicWorkerServiceResource";
import {
    DynamicWorkerServiceRuntimeStatusResource
} from "../../client/resources/dynamicWorkerServiceRuntimeStatusResource";
import {RouteComponentProps} from "react-router-dom";
import Callout, {CalloutType} from "../../components/Callout";
import ActionList from "../../components/ActionList/ActionList";
import buildDynamicWorkerServiceBreadcrumbTitle from "./buildDynamicWorkerServiceBreadcrumbTitle";

type Props = RouteComponentProps<{ id: string }>;

interface State extends DataBaseComponentState {
    dynamicWorkerService?: DynamicWorkerServiceResource;
    runtimeStatus?: DynamicWorkerServiceRuntimeStatusResource;
}

class DynamicWorkerServiceLiveInformation extends DataBaseComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {
        this.refresh();
    }

    render() {

        const refreshButton = <ActionButton type={ActionButtonType.Primary}
                                            label="Refresh"
                                            busyLabel="Refreshing"
                                            disabled={this.state.busy}
                                            onClick={() => this.refresh()}/>;

        return <PaperLayout busy={this.state.busy}
                            errors={this.state.errors}
                            sectionControl={
                                <ActionList actions={[
                                    refreshButton
                                ]} />}
                            title="Live Information"
                            breadcrumbTitle={buildDynamicWorkerServiceBreadcrumbTitle(this.state.dynamicWorkerService)}>
            {this.state.dynamicWorkerService && this.getBody()}
        </PaperLayout>;
    }

    private refresh() {
        return this.doBusyTask(async () => {
            this.setState({dynamicWorkerService: null, runtimeStatus: null});
            
            const id = this.props.match.params.id;
            const dynamicWorkerService =await repository.DynamicWorkerServices.get(id);
            const runtimeStatus = await repository.DynamicWorkerServices.getRuntimeStatus(id)

            this.setState({
                dynamicWorkerService,
                runtimeStatus
            });
        });
    }
    
    private getBody() {
            const dynamicWorkerService = this.state.dynamicWorkerService;
            const runtimeStatus = this.state.runtimeStatus;
            return dynamicWorkerService && runtimeStatus && <>
                <Callout type={CalloutType.Information}>This data is retrieved directly from the live dynamic worker instance.</Callout>
                <DataTable>
                    <DataTableBody>
                        <DataTableRow>
                            <DataTableRowHeaderColumn>Azure Region</DataTableRowHeaderColumn>
                            <DataTableRowColumn>{runtimeStatus.WorkerRegion}</DataTableRowColumn>
                        </DataTableRow>
                        <DataTableRow>
                            <DataTableRowHeaderColumn>InstanceId</DataTableRowHeaderColumn>
                            <DataTableRowColumn>{runtimeStatus.InstanceId}</DataTableRowColumn>
                        </DataTableRow>
                    </DataTableBody>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Version</DataTableRowHeaderColumn>
                        <DataTableRowColumn>{runtimeStatus.InformationalVersion}</DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Uptime</DataTableRowHeaderColumn>
                        <DataTableRowColumn>{runtimeStatus.Uptime}</DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Workers By State</DataTableRowHeaderColumn>
                        <DataTableRowColumn>{<WorkersByState status={runtimeStatus} />}</DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Workers By Size</DataTableRowHeaderColumn>
                        <DataTableRowColumn>{<WorkersBySize status={runtimeStatus} /> }</DataTableRowColumn>
                    </DataTableRow>
                </DataTable>
            </>;
    }
}

export default DynamicWorkerServiceLiveInformation;