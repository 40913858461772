// The visibility of dialogs is stored in the Redux store so we need make sure each dialog is identified by
// a unique value. Otherwise, we risk opening multiple unrelated dialogs at the same time.
import {UniqueSequenceGenerator} from "../../utils/UniqueSequenceGenerator/UniqueSequenceGenerator";

class DialogIdGenerator {
    private sequence: UniqueSequenceGenerator;

    constructor() {
        this.sequence = new UniqueSequenceGenerator();
    }

    next() {
        return this.sequence.next().toString();
    }
}

const dialogIdGenerator = new DialogIdGenerator();

export default dialogIdGenerator;