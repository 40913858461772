import * as React from "react";
import {IconButton, Snackbar} from "material-ui";
import CloseIcon from "material-ui/svg-icons/navigation/close";
import InternalNavLink from "components/Navigation/InternalNavLink/InternalNavLink";

interface TaskSnackbarProps {
    open: boolean;
    getLink: () => string;
    onRequestClose: () => void;
}

const TaskSnackbar: React.SFC<TaskSnackbarProps> = (props: TaskSnackbarProps) => {
    return <Snackbar
        open={props.open}
        autoHideDuration={3000}
        onRequestClose={props.onRequestClose}
        message={<InternalNavLink to={props.getLink()}>New Task Queued</InternalNavLink>}
        action={[
            <IconButton
                key="close"
                color="inherit"
                onClick={() => props.onRequestClose}>
                <CloseIcon color="#fff" />
            </IconButton>
        ]}/>;
};

export default TaskSnackbar;