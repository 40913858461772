// https://github.com/OctopusDeploy/OctopusDeploy/blob/main/frontend/apps/portal/app/hooks/localStorageHelpers.ts
type Subscriber = (value: string) => void;
type StorageKey = string;

const subscriptions: Record<StorageKey, Subscriber[]> = {};

export function subscribeToStorageChanges(key: StorageKey, subscriber: Subscriber) {
    subscriptions[key] = key in subscriptions ? [...subscriptions[key], subscriber] : [subscriber];

    const unsubscribe = () => {
        subscriptions[key] = subscriptions[key].filter((s) => s !== subscriber);
    };

    return unsubscribe;
}

export function setValueInStorage(key: StorageKey, value: string) {
    try {
        localStorage.setItem(key, value);
        subscriptions[key]?.forEach((s) => s(value));
    } catch (e) {
        console.warn(e, "Failed to update local storage value for key {key}", { key });
    }
}

export function getValueInStorage(key: StorageKey): string | null {
    try {
        return localStorage.getItem(key);
    } catch (e) {
        console.warn(e, "Failed to fetch item from local storage for key {key}", { key });
    }
    return null;
}
