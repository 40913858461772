import React from "react";
import {BooleanRadioButton, BooleanRadioButtonGroup, Checkbox} from "@octopusdeploy/design-system-components";
import {DataBaseComponent, DataBaseComponentState, Errors} from "components/DataBaseComponent";
import repository from "client/repository";
import {BusyState} from "components/BusyFromPromise/BusyFromPromise";
import DeleteDialogLayout from "components/DialogLayout/DeleteDialogLayout";
import Callout, {CalloutType} from "../../../components/Callout";

interface RestartConfirmDialogProps {
    errors: Errors;
    busy: BusyState;
    instanceId: string;
    onDone: (taskId: string) => Promise<void>;
}

interface RestartConfirmDialogState extends DataBaseComponentState {
    terminateGracefully: boolean;
    moveDuringOutageWindow: boolean;
}

export class RestartConfirmDialog extends DataBaseComponent<RestartConfirmDialogProps, RestartConfirmDialogState> {
    constructor(props: RestartConfirmDialogProps) {
        super(props);
        this.state = {
            terminateGracefully: true,
            moveDuringOutageWindow: true
        };
    }

    render() {
        return <DeleteDialogLayout title="Restart Instance"
                                   busy={this.props.busy}
                                   deleteButtonLabel="Restart"
                                   deleteButtonBusyLabel="Restarting..."
                                   errors={this.props.errors}
                                   onDeleteClick={() => this.handleRestartConfirm()}>
            <Callout type={CalloutType.Information} title="Terminate Gracefully">
                Terminating gracefully will allow the Octopus Server to finish all of its tasks that are currently running before the pod is restarted.
            </Callout>
            <Checkbox label="Terminate Gracefully"
                      value={this.state.terminateGracefully}
                      onChange={terminateGracefully => this.setState(prevState => ({terminateGracefully}))}
                      error={this.getFieldError("Terminate Gracefully")}
            />
            <BooleanRadioButtonGroup value={this.state.moveDuringOutageWindow} onChange={moveDuringOutageWindow => this.setState(prevState => ({moveDuringOutageWindow}))}>
                <BooleanRadioButton value={true} label="Restart During Outage Window"/>
                <BooleanRadioButton value={false} label="Restart Immediately"/>
            </BooleanRadioButtonGroup>
        </DeleteDialogLayout>;
    }

    private handleRestartConfirm = async () => {
        const taskId = await repository.HostedInstances.restart(this.props.instanceId, { TerminateGracefully: this.state.terminateGracefully, RestartDuringOutageWindow: this.state.moveDuringOutageWindow});
        await this.props.onDone(taskId);
        return true;
    };
}
