import * as React from "react";
import SaveDialogLayout from "components/DialogLayout/SaveDialogLayout";
import repository from "client/repository";
import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent";
import {ReefResource} from "client/resources/reefResource";
import {Number} from "shared/Number";
import Select from "components/form/Select/Select";
import {HostedInstanceTemplate} from "client/resources/hostedInstanceTemplate";

interface Props {
    reefId: string;
    poolConfiguration: PoolConfiguration;
    onChange: (reef: Partial<ReefResource>) => void;
}

interface State extends DataBaseComponentState {
    poolInstanceTemplates: HostedInstanceTemplate[];
    poolConfigurationToChange: PoolConfiguration;
}

interface PoolConfiguration {
    poolSize: number;
    poolInstanceTemplate: string;
}

class ChangePoolConfigurationDialog extends DataBaseComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            poolConfigurationToChange: props.poolConfiguration,
            poolInstanceTemplates: []
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            this.setState({poolInstanceTemplates: await repository.HostedInstances.getInstanceTemplates()});
        });
    }

    render() {
        return <SaveDialogLayout title={"Change Pool Configuration"}
            onSaveClick={() => this.changePoolConfiguration()}
            errors={this.state.errors}
            saveButtonDisabled={JSON.stringify(this.state.poolConfigurationToChange) === JSON.stringify(this.props.poolConfiguration)}
            busy={this.state.busy}>
            <Number label="Pool Size"
                value={this.state.poolConfigurationToChange.poolSize}
                min={0}
                onChange={poolSize => this.modifyPoolConfigurationState({poolSize})}
            />
            <Select value={this.state.poolConfigurationToChange.poolInstanceTemplate}
                    onChange={poolInstanceTemplate => this.modifyPoolConfigurationState({poolInstanceTemplate})}
                    items={this.state.poolInstanceTemplates.map(t => ({text: t.Name, value: t.Name}))}
                    fieldName="PoolInstanceTemplate"
                    hintText="The template used to create instances in the pool"
            />
        </SaveDialogLayout>;
    }

    private modifyPoolConfigurationState(poolConfiguration: Partial<PoolConfiguration>): void {
        this.setState((prevState: State) => ({poolConfigurationToChange: {...prevState.poolConfigurationToChange, ...poolConfiguration}}));
    }

    private async changePoolConfiguration(): Promise<boolean> {
        return await this.doBusyTask(async () => {
            const poolConfigurationToChange: PoolConfiguration = this.state.poolConfigurationToChange;
            await repository.Reefs.changePoolConfiguration(this.props.reefId, {
                PoolSize: poolConfigurationToChange.poolSize,
                PoolInstanceTemplate: poolConfigurationToChange.poolInstanceTemplate
            });
            this.props.onChange({
                PoolSize: poolConfigurationToChange.poolSize,
                PoolInstanceTemplate: poolConfigurationToChange.poolInstanceTemplate
            });
        });
    }
}

export default ChangePoolConfigurationDialog;