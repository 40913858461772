import * as React from "react";
import SaveDialogLayout from "components/DialogLayout/SaveDialogLayout";
import repository from "client/repository";
import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent";
import {ReefResource} from "client/resources/reefResource";
import {Callout, CalloutType} from "../../components/Callout";
import {HubResource} from "../../client/resources/hubResource";
import Select from "components/form/Select/Select";
import Sensitive from "../../components/Sensitive/Sensitive";
import {SensitiveValue} from "../../client/resources/sensitiveValue";
import {createEmptySensitiveValue} from "../../shared/sensitiveValueHelper";
import {Text} from "../../components/form";

interface Props {
    hub: HubResource
    onChange: (hub: Partial<HubResource>) => void;
}

interface State extends DataBaseComponentState {
    clientId: string;
    clientSecret: SensitiveValue;
}

class ChangeAzureAdConfigurationDialog extends DataBaseComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            clientId: props.hub.AzureAdClientId,
            clientSecret: createEmptySensitiveValue()
        };
    }

    render() {
        return <SaveDialogLayout title={"Hub Azure AD configuration"}
                                 onSaveClick={() => this.changeConfiguration()}
                                 errors={this.state.errors}
                                 saveButtonDisabled={this.state.clientId === this.props.hub.AzureAdClientId && !this.state.clientSecret.HasValue}
                                 busy={this.state.busy}>
           <Text label="Azure AD client ID"
                 value={this.state.clientId} 
                 onChange={clientId => this.setState({clientId})}/>
            <Sensitive label="Azure AD client secret"
                  value={this.state.clientSecret}
                  onChange={clientSecret => this.setState({clientSecret})}/>
        </SaveDialogLayout>;
    }

    private async changeConfiguration(): Promise<boolean> {
        return await this.doBusyTask(async () => {
            const request = { AzureAdClientId: this.state.clientId, AzureAdClientSecret: this.state.clientSecret };

            await repository.Hubs.changeAzureAdConfiguration(this.props.hub.Id, request);
            this.props.onChange({AzureAdClientId: this.state.clientId});
        });
    }


}

export default ChangeAzureAdConfigurationDialog;