import MultiSelect from "components/MultiSelect/MultiSelect";
import FormFieldProps from "components/form/FormFieldProps";
import * as React from "react";
import {SelectItem} from "components/VirtualListWithKeyboard/SelectItem";
import {BackgroundProcessResource} from "client/resources/backgroundProcessResource";
import DefaultChip from "shared/DefaultChip";
import {shortProcessName} from "shared/shortProcessName";

interface HostedInstanceMultiSelectProps extends FormFieldProps<string[]> {
    items: BackgroundProcessResource[];
    autoFocus?: boolean;
    label?: string | JSX.Element;
    error?: string;
}

const ProcessMultiSelectTypedMultiSelect = MultiSelect<SelectItem>();

const ProcessMultiSelect: React.StatelessComponent<HostedInstanceMultiSelectProps> = props => {
    const chipRenderer = (i: SelectItem, onRequestDelete: (event: object) => void) => {
        return <DefaultChip item={i} onRequestDelete={onRequestDelete}/>;
    };
    const items = props.items.map(i => ({Id: i.Id, Name: shortProcessName(i)}));
    return <ProcessMultiSelectTypedMultiSelect
        {...props}
        renderItem={i => ({primaryText: shortProcessName(i.Id)})}
        fieldName="process"
        renderChip={chipRenderer}
        items={items}/>;
};

export default ProcessMultiSelect;