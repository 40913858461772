import React from "react";
import {themeTokens} from "@octopusdeploy/design-system-tokens";
import {BetterUptimeStatus} from "client/resources/betterUptimeStatus";

import Heart from "material-ui/svg-icons/action/favorite-border";
import Error from "material-ui/svg-icons/alert/error-outline";
import Clock from "material-ui/svg-icons/device/access-time";

const iconColor = themeTokens.color.icon;
const textColor = themeTokens.color.text;

export function getBetterUptimeColorFor(status: BetterUptimeStatus, active = true): string {
    if (!active)
        return textColor.subtle;
    
    switch (status) {
        case BetterUptimeStatus.Up:
            return textColor.success;
        case BetterUptimeStatus.Down:
        case BetterUptimeStatus.Paused:
        case BetterUptimeStatus.Unknown:
            return textColor.danger;
        case BetterUptimeStatus.Pending:
        case BetterUptimeStatus.Maintenance:
        case BetterUptimeStatus.Validating:
            return textColor.warning;
    }
}

export function getBetterUptimeIconFor(status: BetterUptimeStatus, active: boolean): JSX.Element {
    switch (status) {
        case BetterUptimeStatus.Up:
            return <Heart color={active ? iconColor.success : iconColor.subtle}/>
        case BetterUptimeStatus.Down:
        case BetterUptimeStatus.Paused:
        case BetterUptimeStatus.Unknown:
            return <Error color={active ? iconColor.danger : iconColor.subtle}/>
        case BetterUptimeStatus.Pending:
        case BetterUptimeStatus.Maintenance:
        case BetterUptimeStatus.Validating:
            return <Clock color={active ? iconColor.warning : iconColor.subtle}/>
    }
}