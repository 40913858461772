import client from "client/client";
import {InstanceLimitResource} from "client/resources/instanceLimitResource";

export default class InstanceLimitRepository {

    private rootUri = "/api/instance-limits";

    get(id: string): Promise<InstanceLimitResource> {
        return client.get(`${this.rootUri}/{id}`, {id});
    }

    getForInstance(instanceId: string): Promise<InstanceLimitResource> {
        return client.get(`${this.rootUri}/hosted-instance/{instanceId}`, {instanceId});
    }
}