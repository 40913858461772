import { createStore, applyMiddleware, compose } from "redux";
import asyncActionsMiddleware from "redux-thunk";
import { reducers } from "./reducers";

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
    reducers,
    composeEnhancers(
        applyMiddleware(asyncActionsMiddleware)
    )
);

export default store;