import * as React from "react";
import {FunctionComponent} from "react";
import ReloadableRoute from "components/ReloadableRoute/ReloadableRoute";
import {Switch} from "react-router-dom";
import routeLinks from "routeLinks";
import InstanceTaskLocks from "areas/instanceTaskLocks/InstanceTaskLocks";

const InstanceTaskLocksLayout: FunctionComponent<{}> = _ =>
    <Switch>
        <ReloadableRoute path={routeLinks.instanceTaskLocks.root} exact={true} component={InstanceTaskLocks} />
    </Switch>;

InstanceTaskLocksLayout.displayName = "InstanceTaskLocksLayout";

export default InstanceTaskLocksLayout;