import * as React from "react";
import { FunctionComponent } from "react";
import moment from "moment";
import { friendlyLocalTime } from "shared/timeFormats";

const styles = require("./style.less");

interface FriendlyLocalTimeProps {
    time: string;
}

export const FriendlyLocalTime: FunctionComponent<FriendlyLocalTimeProps> = (props) => {
    const time = moment(props.time);
    const longTime = time.format("LLLL");
    const friendlyTime = friendlyLocalTime(time, moment());

    return (
        <span className={styles.when}>
            <abbr title={longTime}>{friendlyTime}</abbr>
        </span>
    );
};