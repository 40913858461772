import * as React from "react";
import {CSSProperties, SFC, StyleHTMLAttributes} from "react";
import BusyIndicator from "components/BusyIndicator/BusyIndicator";
import BusyFromPromise, {BusyState} from "components/BusyFromPromise/BusyFromPromise";
import ErrorPanel from "components/ErrorPanel/ErrorPanel";
import {Errors} from "components/DataBaseComponent/DataBaseComponent";

import styles = require("./style.less");
import EventListener, {withOptions} from "react-event-listener";
import {throttle} from "lodash";
import KeyboardHandler, {Key} from "../KeyboardHandler/KeyboardHandler";

export interface DialogLayoutDispatchProps {
    close(): void;
}

export interface DialogLayoutCommonProps {
    title: string;
    titleIcon?: React.ReactNode;
    extraHeaderContent?: (alignmentClassName: string) => React.ReactNode;
    additionalActions?: React.ReactNode;
    secondaryActions?: React.ReactNode;
    errors: Errors;
    busy: BusyState;
    headerClassName?: string;
}

interface DialogLayoutProps extends DialogLayoutCommonProps {
    actions: React.ReactNode;
    closeDialog(): void;
}

interface DialogLayoutState {
    style?: any;
}

export class DialogLayout extends React.Component<DialogLayoutProps, DialogLayoutState> {
    topLevel: HTMLDivElement;
    onResizeThrottled: any;
    ie11: boolean;

    constructor(props: DialogLayoutProps) {
        super(props);
        this.ie11 = typeof(Event) !== "function";
        this.state = {style: {}};
        this.onResizeThrottled = throttle(this.onResize, 100);
    }

    componentDidMount() {
        if (this.ie11) {
            window.setTimeout(() => this.onResize(), 250);
        }
    }

    render() {

        if (this.props.busy && (typeof this.props.busy) !== "boolean") {
            // Workaround for a bug in material-ui dialog: https://github.com/callemall/material-ui/issues/5793#issuecomment-301282845
            (this.props.busy as Promise<any>).then(() => setTimeout(() => {
                if (this.ie11) {
                    const event = document.createEvent("Event");
                    event.initEvent("resize", true, true);
                    window.dispatchEvent(event);
                } else {
                    window.dispatchEvent(new Event("resize"));
                }
            }, 10));
        }

        const keyboardRegistrations = [{key: Key.Esc, onKeyPressed: this.onEscKeyPressed}];
        return <div className={styles.layout} ref={r => this.topLevel = r} style={this.state.style}>
            <KeyboardHandler registrations={keyboardRegistrations} className={styles.dialogWrapper}>
                    {this.ie11 && <EventListener
                        target="window"
                        onResize={withOptions(this.onResize, {passive: true})}
                    />}
                    <div className={this.props.headerClassName}>
                        <BusyFromPromise promise={this.props.busy}>
                            {(busy: boolean) => <BusyIndicator show={busy}/>}
                        </BusyFromPromise>
                        <div className={styles.title}>
                            {this.props.titleIcon && <div className={styles.titleIcon}>{this.props.titleIcon}</div>}
                            {this.props.title}
                        </div>
                        {this.props.extraHeaderContent && this.props.extraHeaderContent(styles.headerContentAlignment)}
                    </div>
                    {this.props.errors &&
                    <ErrorPanel message={this.props.errors.message} details={this.props.errors.details}/>}
                    <div className={styles.body}>
                        {this.props.children}
                    </div>
                    <div className={styles.actions}>
                        <div className={styles.secondaryActions}>
                            {this.props.additionalActions}
                        </div>
                        <div className={styles.primaryActions}>
                            {this.props.actions}
                        </div>
                    </div>
                </KeyboardHandler>
        </div>
        ;
    }

    onResize = () => {
        // ie11 flexbox doesn't work correctly if the parent element is
        // having its size set by max-height, but the parent element to this
        // is material-ui and maxHeight is how they dynamically resize dialogs
        // so rather than change that, if we're in ie11 set the contents
        // max-height.
        if (this.topLevel) {
            const parentHeight = this.topLevel.parentElement.style.maxHeight;
            this.setState({style: {maxHeight: parentHeight}});
        }
    }
    onEscKeyPressed = () => {
        if (this.props.closeDialog) {
            this.props.closeDialog();
            return true;
        }
        return false;
    }
}