import * as React from "react";
import routeLinks from "routeLinks";
import PaperLayout from "components/PaperLayout/PaperLayout";
import SimpleDataTable from "components/SimpleDataTable/SimpleDataTable";
import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent";
import NavigationButton, {NavigationButtonType} from "components/Button/NavigationButton";
import repository from "client/repository";
import {DynamicWorkerServiceResource} from "client/resources/dynamicWorkerServiceResource";
import ExternalLink from "components/Navigation/ExternalLink/ExternalLink";
import ActionList from "components/ActionList/ActionList";
import externalSystemLinks from "externalSystemLinks";
import InternalLink from "../../components/Navigation/InternalLink/InternalLink";
import {DynamicWorkerServiceRuntimeStatusResource} from "../../client/resources/dynamicWorkerServiceRuntimeStatusResource";
import WorkersByState from "./WorkersByState";
import WorkersBySize from "./WorkersBySize";

interface State extends DataBaseComponentState {
    environment: string;
    dynamicWorkerServices?: DynamicWorkerServiceResource[];
}

class DynamicWorkerServicesDataTable extends SimpleDataTable<DynamicWorkerServiceResource> {
}

export class DynamicWorkerServices extends DataBaseComponent<{}, State> {
    constructor(props: {}) {
        super(props);
        this.state = {
            environment: ""
        };
    }

    componentDidMount() {
        return this.doBusyTask(async () => {
            const dynamicWorkerServices = await repository.DynamicWorkerServices.list();
            const environment = (await repository.Configuration.getAppInfo()).Environment;

            this.setState({environment, dynamicWorkerServices});
        });
    }

    render() {
        return <PaperLayout title="Dynamic Worker Services"
                            busy={this.state.busy}
                            errors={this.state.errors}
                            sectionControl={<ActionList actions={this.getActions()}/>}
                            fullWidth={true}>
            {this.state.dynamicWorkerServices && <DynamicWorkerServicesDataTable
                data={this.state.dynamicWorkerServices}
                onRow={this.buildRow}
                headerColumns={["Id", "Name", "Url"]}
                onRowRedirectUrl={r => routeLinks.dynamicWorkerServices.service(r.Id).root}
                onEmpty={<div>No dynamicWorkerServices found</div>}/>}
        </PaperLayout>;
    }
    
    private getActions(){
        return [
            <span style={{marginRight: "1rem"}}>
                <ExternalLink
                    href={externalSystemLinks.seq.dashboard(
                        this.state.environment === "Production" ? "dashboard-9110" : "dashboard-1924"
                    ) + "&range=1d&group=5m"}                
                > 
                    Seq Dashboard
                </ExternalLink>
            </span>,
            <NavigationButton label="New Service" href={routeLinks.dynamicWorkerServices.create} type={NavigationButtonType.Primary}/>  
        ];
    }

    private buildRow = (service: DynamicWorkerServiceResource) => {
        
        return [
            <InternalLink to={routeLinks.dynamicWorkerServices.service(service.Id).root}>{service.Id}</InternalLink>,
            <InternalLink to={routeLinks.dynamicWorkerServices.service(service.Id).root}>{service.Name}</InternalLink>,
            <ExternalLink href={service.Url}>{service.Url}</ExternalLink>
        ];
    };
}

export default DynamicWorkerServices;
