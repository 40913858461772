import React from "react";
import {BooleanRadioButton, BooleanRadioButtonGroup} from "@octopusdeploy/design-system-components";
import {DataBaseComponent, DataBaseComponentState, Errors} from "../../../components/DataBaseComponent";
import {BusyState} from "../../../components/BusyFromPromise/BusyFromPromise";
import {HostedInstanceResource} from "../../../client/resources/hostedInstanceResource";
import SaveDialogLayout from "../../../components/DialogLayout/SaveDialogLayout";
import repository from "../../../client/repository";
import {HostedInstanceRotateAdminApiKeyRequest} from "../../../client/resources/HostedInstanceRotateAdminApiKeyRequest";
import Callout, {CalloutType} from "../../../components/Callout";

interface Props {
    errors: Errors;
    busy: BusyState;
    instance: HostedInstanceResource;
    onSaveClick(): Promise<boolean>;
}

interface State extends DataBaseComponentState {
    rotateAdminApiKeyRequest: Partial<HostedInstanceRotateAdminApiKeyRequest>;
}


export class RotateAdminApiKeyDialog extends DataBaseComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            rotateAdminApiKeyRequest: {
                ApplyDuringMaintenanceWindow: true
            }
        };
    }

    render() {
        const request = this.state.rotateAdminApiKeyRequest;
        return <SaveDialogLayout title={"Rotate Admin API Key"}
                                 errors={this.state.errors}
                                 busy={this.state.busy}
                                 onSaveClick={() => this.rotateAdminApiKey(request)}>

            <BooleanRadioButtonGroup value={this.state.rotateAdminApiKeyRequest.ApplyDuringMaintenanceWindow}
                              onChange={ApplyDuringOutage => this.updateState({ApplyDuringMaintenanceWindow: ApplyDuringOutage})}>
                <BooleanRadioButton value={true} label="Apply During Outage Window"/>
                <BooleanRadioButton value={false} label="Apply Immediately"/>
            </BooleanRadioButtonGroup>
        </SaveDialogLayout>;
    }

    private updateState(update: Partial<HostedInstanceRotateAdminApiKeyRequest>) {
        return this.setState(prevState => ({rotateAdminApiKeyRequest: {...prevState.rotateAdminApiKeyRequest, ...update}}));
    }

    private async rotateAdminApiKey(request: Partial<HostedInstanceRotateAdminApiKeyRequest>) {
        return await this.doBusyTask(async () => {
            await repository.HostedInstances.rotateAdminApiKey(this.props.instance, request as HostedInstanceRotateAdminApiKeyRequest);
            await this.props.onSaveClick();
            return true;
        });
    }
}