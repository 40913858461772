// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* This file is DEPRECATED
   We are moving to the Octopus Design System
   https://www.octopus.design
  
   When changing component styles:
   1. Do not use variables from this file
   2. Aim to use Emotion \`css\` + themeTokens.colors
       - see Dashboard.tsx for example
   3. If you need to use Less, reference CSS variables
       - see index.ejs for example
       - for full list see https://github.com/OctopusDeploy/OctopusDeploy/blob/main/frontend/packages/design-system-tokens/src/generated/themeTokens.ts
   4. Remove variables from this file when they are no longer used
 */
.style_menuIcon__OhPdp {
  font-size: 1.375rem;
  height: 2.25rem !important;
  padding: 0!important;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--colorTextPrimary);
}
.style_menuIcon__OhPdp:visited {
  color: var(--colorTextPrimary);
}
.style_menuIcon__OhPdp:hover {
  opacity: 0.5;
}
.style_disabledItem__jnlPz {
  cursor: not-allowed;
  color: var(--colorTextSubtle);
}
`, "",{"version":3,"sources":["webpack://./app/components/Menu/style.less"],"names":[],"mappings":"AAAA;;;;;;;;;;;;EAYE;AAVF;EACI,mBAAA;EACA,0BAAA;EACA,oBAAA;EACA,aAAA;EACA,mBAAA;EACA,qBAAA;EACA,8BAAA;AAYJ;AAVI;EACI,8BAAA;AAYR;AATI;EACI,YAAA;AAWR;AAPA;EACI,mBAAA;EACA,6BAAA;AASJ","sourcesContent":["@import \"../../resources/styles/colors.less\";\n\n.menuIcon {\n    font-size: 1.37500rem;\n    height: 2.25rem!important;\n    padding: 0!important;\n    display:flex;\n    align-items: center;\n    text-decoration: none;\n    color: @primaryText;\n\n    &:visited {\n        color: @primaryText;\n    }\n\n    &:hover {\n        opacity: 0.5;\n    }\n}\n\n.disabledItem {\n    cursor: not-allowed;\n    color: @secondaryText;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menuIcon": `style_menuIcon__OhPdp`,
	"disabledItem": `style_disabledItem__jnlPz`
};
module.exports = ___CSS_LOADER_EXPORT___;
