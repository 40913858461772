import { ChevronDownIcon, NavigationBar, SimpleMenu, SimpleMenuItem, useMenuState } from "@octopusdeploy/design-system-components";
import * as React from "react";
import {EnvironmentName} from "../../../client/resources/environmentName";
import externalSystemLinks from "../../../externalSystemLinks";

interface Props {
    environment: EnvironmentName;
}

export default function Logs({ environment }: Props) {
    const [openMenu, menuState, buttonAriaAttributes] = useMenuState();
    const logsMenuItems: SimpleMenuItem[] = [
        {type: "external-link", label: "Seq", href: externalSystemLinks.seq.portalLogs(environment)},
        {type: "external-link", label: "Sumo", href: externalSystemLinks.sumoLogic.portalLogs(environment)},
        {type: "external-link", label: "Sumo (Octopus org)", href: externalSystemLinks.sumoLogic.sharedPortalLogs(environment)},
        {type: "external-link", label: "Sumo MQS dashboard", href: externalSystemLinks.sumoLogic.mqsDashboard()},
        {type: "external-link", label: "Honeycomb Traces", href: externalSystemLinks.honeycomb.allTraces()},
    ];

    return (
        <div>
            <NavigationBar.Button label="Logs & Telemetry" icon={<ChevronDownIcon />} accessibleName="Open Logs & Telemetry menu" onClick={openMenu} {...buttonAriaAttributes} />
            <SimpleMenu menuState={menuState} items={logsMenuItems} accessibleName={"Logs & Telemetry"} />
        </div>
    );
}