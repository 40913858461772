import * as React from "react";
import {CommonChipProps} from "components/Chips";
import {chipWithAvatar} from "components/Chips/Chip";
import SvgIconProps = __MaterialUI.SvgIconProps;
import {UpgradeStrategyType, upgradeStrategyTypeDescriptions} from "../client/resources/upgradeStrategy";

const icon = (props: SvgIconProps) =>
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width || 24} height={props.height || 24} viewBox="0 0 24 24" fillRule="evenodd" clipRule="evenodd">
        <path d="M24 12c0 6.623-5.377 12-12 12s-12-5.377-12-12 5.377-12 12-12 12 5.377 12 12zm-1 0c0 6.071-4.929 11-11 11s-11-4.929-11-11 4.929-11 11-11 11 4.929 11 11zm-11.5-4.828l-3.763 4.608-.737-.679 5-6.101 5 6.112-.753.666-3.747-4.604v11.826h-1v-11.828z"/>
    </svg>;

const UpgradeStrategyChip: React.StatelessComponent<{ upgradeStrategy: UpgradeStrategyType; } & CommonChipProps> = props => {
    const { upgradeStrategy, ...rest } = props;
    return chipWithAvatar(upgradeStrategyTypeDescriptions[upgradeStrategy], icon, { description: upgradeStrategy, ...rest });
};

export default UpgradeStrategyChip;
