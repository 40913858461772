import * as React from "react";
import {Switch} from "react-router-dom";
import ReloadableRoute from "components/ReloadableRoute/ReloadableRoute";
import {azureRegionsRouteLinks} from "areas/azureRegions/azureRegionsRouteLinks";
import {InternalRedirect} from "components/Navigation/InternalRedirect";
import {AzureRegions} from "areas/azureRegions/AzureRegions";
import AzureRegionLayout from "areas/azureRegions/AzureRegionLayout";
import CreateHub from "./CreateHub";
import {hubsRouteLinks} from "./hubsRouteLinks";
import HubLayout from "./HubLayout";
import {Hubs} from "./Hubs";

const ConfigurationLayout: React.FunctionComponent<{}> = _ =>
    <Switch>
        <ReloadableRoute path={hubsRouteLinks.create} exact={true} component={CreateHub}/>
        <ReloadableRoute path={hubsRouteLinks.root} exact={true} component={Hubs}/>
        <ReloadableRoute path={hubsRouteLinks.hub(":id").root} component={HubLayout}/>
        <ReloadableRoute render={() => <InternalRedirect to={hubsRouteLinks.root}/>}/>
    </Switch>;

export default ConfigurationLayout;
