import React from "react";
import { RadioButton, RadioButtonGroup } from "@octopusdeploy/design-system-components";
import SaveDialogLayout from "components/DialogLayout/SaveDialogLayout";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent";
import HostedInstanceMultiSelect from "shared/HostedInstanceMultiSelect";
import { HostedInstanceLookupResource } from "client/resources/hostedInstanceLookupResource";
import ReefMultiSelect from "shared/ReefMultiSelect";
import { ReefResource } from "client/resources/reefResource";
import { Slider, Text } from "components/form";
import Callout, { CalloutType } from "components/Callout";
import { EnvironmentVariableScope, InstanceEnvironmentVariable } from "client/resources/instanceEnvironmentVariablesResource";

interface Props {
    environmentVariable: InstanceEnvironmentVariable;
    otherEnvironmentVariablesWithSameNameExist: boolean;
    editScope: (id: string, scope: EnvironmentVariableScope) => Promise<boolean>;
    instances: HostedInstanceLookupResource[];
    reefs: ReefResource[];
}

interface State extends DataBaseComponentState {
    scope: string;
    rolloutPercent?: number;
    reefIds: string[];
    includedInstanceIds: string[];
    excludedInstanceIds: string[];
    minimumVersion?: string;
    maximumVersion?: string
}

class EditInstanceEnvironmentVariableScopeDialog extends DataBaseComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            scope: props.environmentVariable.AppliesTo.ScopeLevel,
            reefIds: props.environmentVariable.AppliesTo.ReefIds,
            includedInstanceIds: props.environmentVariable.AppliesTo.IncludedInstanceIds,
            excludedInstanceIds: props.environmentVariable.AppliesTo.ExcludedInstanceIds,
            minimumVersion: props.environmentVariable.AppliesTo.MinimumVersion,
            maximumVersion: props.environmentVariable.AppliesTo.MaximumVersion,
            rolloutPercent: props.environmentVariable.AppliesTo.RolloutPercentage,
        };
    }

    render() {
        return <SaveDialogLayout title={`Edit scope: ${this.props.environmentVariable.Name}`}
            busy={this.state.busy}
            errors={this.state.errors}
            onSaveClick={this.editScope}>

            {this.props.otherEnvironmentVariablesWithSameNameExist && 
                <Callout type={CalloutType.Warning} title="Warning">
                    <p>
                        <strong>There are multiple instance environment variables defined with this name</strong>. 
                    </p>
                    <p>
                        Please carefully review the scope of this and the other variables as only one value can be applied to each instance.
                        You may be prevented from updating this scope if it is determined that your changes will result in multiple values being applied to one or more instances.
                    </p> 
                </Callout>
            }

            <p>
                See&nbsp;<a
                    href="https://github.com/OctopusDeploy/hosted-docs/blob/master/portal/configure-environment-variables.md"
                    target="_blank">Configure Environment Variables</a> for more information on the rollout process.
            </p>

            <div style={{ display: "flex", flexDirection: "row", gap: "24px", flexWrap: "wrap", alignContent: "space-evenly" }}>
                <div style={{ flexGrow: 1}}>
                    <Text label={"Minimum version"}
                          value={this.state.minimumVersion}
                          onChange={minimumVersion => this.setState({ minimumVersion })}
                          hintText="Minimum Octopus Server version" />
                </div>
                <div style={{ flexGrow: 1}}>
                    <Text label={"Maximum version"}
                          value={this.state.maximumVersion}
                          onChange={maximumVersion => this.setState({ maximumVersion })}
                          hintText="Maximum Octopus Server version" />
                </div>
            </div>
            <div>If a minimum or maximum version is specified here, the Environment Variable will only be
                rolled out to instances inclusively within that range. Any instances outside that version range will not be included in the rollout.</div>
            <br />
            <div style={{ display: "flex", flexDirection: "row", gap: "24px", flexWrap: "wrap", alignContent: "space-evenly" }}>
                <div style={{ flexGrow: 0, flexBasis: "400px" }}>
                    <h2>Implicit Selection</h2>
                    <RadioButtonGroup value={this.state.scope}
                        onChange={scope => this.setState({ scope })}>
                        <RadioButton value={"Global"} label="Global" />
                        <RadioButton value={"Reef"} label="Reef" />
                    </RadioButtonGroup>
                    {this.state.scope == "Reef" && <ReefMultiSelect label="Reefs"
                        items={this.props.reefs}
                        value={this.state.reefIds}
                        onChange={reefIds => this.setState({ reefIds })}
                        autoFocus={true}
                    />}

                    <Slider value={this.state.rolloutPercent ?? 0} step={5} min={0} max={100} label={"Rollout %"}
                        onChange={(rolloutPercent) => this.setState({ rolloutPercent })} />
                    <p>
                        Setting the <b>Rollout %</b> to 0% will only stop the variable from being rolled out
                        to more instances. However, it will not remove the Environment Variable from existing
                        instances that it has already been rolled out to.
                    </p>
                </div>

                <div style={{ flexGrow: 1, flexBasis: "400px" }}>
                    <h2>Explicit Selection</h2>
                    <HostedInstanceMultiSelect label="Instances to always include"
                        items={this.props.instances}
                        value={this.state.includedInstanceIds}
                        onChange={includedInstanceIds => this.setState({ includedInstanceIds })}
                        autoFocus={true} />
                    <p>
                        Instances specified here for inclusion will always have the Environment Variable set
                        to this value, even if <b>Rollout %</b> is 0%.
                    </p>

                    <HostedInstanceMultiSelect label="Instances to always exclude"
                        items={this.props.instances}
                        value={this.state.excludedInstanceIds}
                        onChange={excludedInstanceIds => this.setState({ excludedInstanceIds })}
                        autoFocus={true} />
                    <p>
                        Instances specified here for exclusion will never have the Environment Variable set
                        to this value, even if <b>Rollout %</b> is 100%.
                    </p>
                </div>
            </div>

        </SaveDialogLayout>;
    }

    private editScope = async (): Promise<boolean> => {
        const scope: EnvironmentVariableScope = {
            ReefIds: this.state.reefIds,
            IncludedInstanceIds: this.state.includedInstanceIds,
            ExcludedInstanceIds: this.state.excludedInstanceIds,
            ScopeLevel: this.state.scope,
            MinimumVersion: this.state.minimumVersion,
            MaximumVersion: this.state.maximumVersion,
            RolloutPercentage: this.state.rolloutPercent,
        };

        return this.doBusyTask(async () => {
            return this.props.editScope(this.props.environmentVariable.Id, scope);
        });
    }
}

export default EditInstanceEnvironmentVariableScopeDialog;