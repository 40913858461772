import * as React from "react";
import {Component} from "react";
import {RouteComponentProps} from "react-router-dom";
import {LinearProgress} from "@octopusdeploy/design-system-components";
import {instancesRouteLinks} from "areas/instances/instancesRouteLinks";
import {HostedInstanceResource} from "client/resources/hostedInstanceResource";
import repository from "client/repository";
import {InternalRedirect} from "../../../components/Navigation/InternalRedirect";

type Props = RouteComponentProps<{ cloudSubscriptionId: string }>;

interface State {
    instance?: HostedInstanceResource;
}

class CloudSubscriptionLayout extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {
        const instance = await repository.HostedInstances.getForSubscription(this.props.match.params.cloudSubscriptionId);
        this.setState({instance});
    }

    render() {
        if (!this.state.instance) return <LinearProgress variant="indeterminate" show={true} />;
        return <InternalRedirect to={instancesRouteLinks.instance(this.state.instance.Id).root}/>;
    }
}

export default CloudSubscriptionLayout;