// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_notVisible__V4oLK {
  opacity: 0;
}
.style_fadeIn__emzsN {
  opacity: 1;
  transition: opacity 300ms ease-in;
  -moz-transition: opacity 300ms ease-in;
  -webkit-transition: opacity 300ms ease-in;
}
`, "",{"version":3,"sources":["webpack://./app/components/TransitionAnimation/style.less"],"names":[],"mappings":"AAAA;EACI,UAAA;AACJ;AACA;EACI,UAAA;EACA,iCAAA;EACA,sCAAA;EACA,yCAAA;AACJ","sourcesContent":[".notVisible {\n    opacity: 0;\n}\n.fadeIn {\n    opacity: 1;\n    transition: opacity 300ms ease-in;\n    -moz-transition: opacity 300ms ease-in;\n    -webkit-transition: opacity 300ms ease-in;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notVisible": `style_notVisible__V4oLK`,
	"fadeIn": `style_fadeIn__emzsN`
};
module.exports = ___CSS_LOADER_EXPORT___;
