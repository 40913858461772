import * as React from "react";
import {DataTableRow, DataTableRowColumn, DataTableRowHeaderColumn} from "../components/DataTable";

interface Props {
    message: string;
}

const EmptyDataTableRow: React.StatelessComponent<Props> = (props) => {
    return <DataTableRow>
        <DataTableRowHeaderColumn />
        <DataTableRowColumn>
            {props.message}
        </DataTableRowColumn>
    </DataTableRow>;
};

export default  EmptyDataTableRow;