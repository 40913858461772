import {combineReducers} from "redux";
import {chainReducers} from "@shoutem/redux-composers";
import expanders from "components/form/Sections/reducers/expanders";
import dialogs from "components/Dialog/reducers/dialog";
import raiseUnhandledError from "components/BaseComponent/reducers";
import clearUnhandledError from "areas/root/reducers";

interface Action {
    type: string;
}

const reducers = combineReducers({
    expanders,
    dialogs,
    unhandledError: chainReducers([raiseUnhandledError, clearUnhandledError]),
});

export {reducers, Action};