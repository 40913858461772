import * as React from "react";
import {Checkbox} from "@octopusdeploy/design-system-components";
import {Text, required} from "components/form";
import Summary from "components/form/Sections/Summary";
import ExpandableFormSection from "components/form/Sections/ExpandableFormSection";
import FormPaperLayout from "components/FormPaperLayout/FormPaperLayout";
import {OptionalFormBaseComponentState} from "components/form/FormBaseComponent";
import {FormBaseComponent} from "components/form/FormBaseComponent/FormBaseComponent";
import Select from "components/form/Select/Select";
import repository from "client/repository";
import {UpgradeStrategyType} from "client/resources/upgradeStrategy";
import {ReefResource} from "client/resources/reefResource";
import {DefaultInstanceTemplateName} from "shared/defaultInstanceTemplateName";
import routeLinks from "routeLinks";
import {InternalRedirect} from "components/Navigation/InternalRedirect";
import {CreateHostedInstanceRequest} from "client/resources/createHostedInstanceRequest";
import UnstructuredFormSection from "components/form/Sections/UnstructuredFormSection";
import ActionButton, {ActionButtonType} from "components/Button";
import styles = require("./style.less");
import {validateCPU, validateMemory} from "areas/instances/Instances/k8sResourceValidation";
import ExternalLink from "components/Navigation/ExternalLink";
import {HostedInstanceTemplate} from "client/resources/hostedInstanceTemplate";
import FormSectionHeading from "components/form/Sections/FormSectionHeading";
import Sensitive, {ObfuscatedPlaceholder} from "components/Sensitive/Sensitive";
import {Number} from "shared/Number";
import {reefDisplayLabel} from "../../reefs/ReefDisplayLabel";

interface State extends OptionalFormBaseComponentState<Partial<CreateHostedInstanceRequest>> {
    model: Partial<CreateHostedInstanceRequest>;
    cleanModel: Partial<CreateHostedInstanceRequest>;
    reefs?: ReefResource[];
    templates?: HostedInstanceTemplate[];
    redirectTo?: string;
    templateSelected: boolean;
    templateName?: string;
}

class CreateInstance extends FormBaseComponent<{}, State, Partial<CreateHostedInstanceRequest>> {
    constructor(props: {}) {
        super(props);
        this.state = {
            templateSelected: false,
            model: {
                UpgradeStrategy: {
                    UpgradeStrategyType: UpgradeStrategyType.TrackDefaultVersionUpgradeStrategy
                }, UsePool: true},
            cleanModel: {UpgradeStrategy: {
                    UpgradeStrategyType: UpgradeStrategyType.TrackDefaultVersionUpgradeStrategy
                }, UsePool: true},
        };
    }

    async componentDidMount() {
        return await this.doBusyTask(async () => {
            const [templates, reefs] = await Promise.all([repository.HostedInstances.getInstanceTemplates(), repository.Reefs.list()]);
            this.setState({templates, reefs, templateName: DefaultInstanceTemplateName});
        });
    }

    render() {

        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo}/>;
        }

        return <FormPaperLayout
            title="Create Instance"
            busy={this.state.busy}
            errors={this.state.errors}
            model={this.state.model}
            cleanModel={this.state.cleanModel}
            saveButtonLabel={"Queue Instance Creation"}
            saveText="Create instance task queued"
            saveButtonBusyLabel={"Queuing"}
            forceDisableFormSaveButton={!this.canSave()}
            expandAllOnMount={true}
            overFlowActions={null}
            onSaveClick={() => this.handleSaveClick()}>
            {this.state.reefs && (!this.state.templateSelected ? this.getTemplate() : this.getBody())}
        </FormPaperLayout>;
    }

    canSave() {
        return this.state.templateSelected;
    }

    getTemplate() {
        return <div>
            {this.getPublicDNSFormElement()}
            {this.getApiKeyFormElement()}
            {this.getReefFormElement()}
            <ExpandableFormSection
                errorKey="UsePool"
                title="Use Pool"
                summary={this.state.model.UsePool ? Summary.summary("Use an existing instance") : Summary.placeholder("Create a new instance")}
                help={<span>Select whether or not to use an already created instance from a pool of generic instances</span>}>
                <Checkbox label="Use Pool"
                          value={this.state.model.UsePool}
                          onChange={UsePool => this.setModelState({UsePool})}
                          error={this.getFieldError("UsePool")}
                />
            </ExpandableFormSection>
            <ExpandableFormSection
                errorKey="Template"
                title="Template"
                summary={this.state.templateName ? Summary.summary(this.state.templateName) : Summary.placeholder("No Template")}
                help={<span>Select the template for this hosted instance.</span>}>

                <Select value={this.state.templateName}
                        onChange={(templateName: string) => this.setState({templateName})}
                        items={this.state.templates.map(t => ({text: t.Name, value: t.Name}))}
                        fieldName="Template"
                        hintText="The Template that the instance will be based on"
                />
            </ExpandableFormSection>
            {this.state.templateName && <UnstructuredFormSection>
                <pre>
                    {JSON.stringify(this.getSelectedTemplate(), null, 4)}
                </pre>
            </UnstructuredFormSection>}

            <UnstructuredFormSection>
                <div className={styles.next}>
                    <ActionButton type={ActionButtonType.Primary} disabled={!this.state.model.DnsPrefix || !this.state.templateName} label="Next" onClick={this.applyTemplate}/>
                </div>
            </UnstructuredFormSection>
        </div>;
    }

    getApiKeyFormElement() {
        if (this.state.model.UsePool) {
            return <ExpandableFormSection
                errorKey="AdminApiKey"
                title="Admin Api Key"
                summary={this.state.model.AdminApiKey && this.state.model.AdminApiKey.HasValue ? Summary.summary(ObfuscatedPlaceholder) : Summary.placeholder("No admin api key")}
                help={<span>Enter the admin api key for this instance.</span>}>
                <Sensitive label="Admin Api Key (optional)"
                           value={this.state.model.AdminApiKey}
                           onChange={AdminPassword => this.setModelState({AdminApiKey: AdminPassword})}
                           error={this.getFieldError("AdminApiKey")}
                />
            </ExpandableFormSection>;
        }

        return null;
    }

    getPublicDNSFormElement() {
        const model = this.state.model;
        return <ExpandableFormSection
            errorKey="DnsPrefix"
            title="DNS Prefix"
            summary={model.DnsPrefix ? Summary.summary(model.DnsPrefix) : Summary.placeholder("No DNS prefix")}
            help={<span>Enter the public DNS prefix for this instance.</span>}>
            <Text label="Public DNS Prefix"
                  value={model.DnsPrefix}
                  onChange={DnsPrefix => this.setModelState({DnsPrefix})}
                  validate={required("Please enter the DNS prefix to use for this instance")}
                  error={this.getFieldError("DnsPrefix")}
                  autoFocus={true}
            />
        </ExpandableFormSection>;
    }

    getReefFormElement() {
        const model = this.state.model;

        const handleReefChanged = (reefId: string) => {
            this.doBusyTask(async () => {
                const reef = this.state.reefs.filter(r => r.Id === reefId)[0];
                const templateName = reef.PoolInstanceTemplate;

                this.setState({templateName});
                this.setModelState({ReefId: reefId});

            });
        };

        return <React.Fragment>
            <ExpandableFormSection
                errorKey="ReefId"
                title="Reef"
                summary={model.ReefId ? Summary.summary(model.ReefId) : Summary.placeholder("No reef")}
                help={<span>Select the reef in which to provision this instance.</span>}>
                <Select value={this.state.model.ReefId}
                        onChange={handleReefChanged}
                        items={this.state.reefs.map(r => ({text: reefDisplayLabel(r), value: r.Id}))}
                        fieldName="ReefId"
                        hintText="The reef that the instance is to be created in"
                />
            </ExpandableFormSection>
        </React.Fragment>;
    }

    getStorageAccountFormElement() {
        const model = this.state.model;

        const handleStorageAccountChanged = (storageAccount: string) => {
                this.setModelState({StorageAccountName: storageAccount});
        };

        return <React.Fragment>
            <ExpandableFormSection
                errorKey="StorageAccount"
                title="Storage Account (Optional)"
                summary={model.StorageAccountName ? Summary.summary(model.StorageAccountName) : Summary.placeholder("The storage account that the instance is to be created in")}
                help={<span>Select the storage account in which to provision this instance.</span>}>
                <Select value={this.state.model.StorageAccountName}
                        onChange={handleStorageAccountChanged}
                        items={[{text: "", value: null}, ...this.state.reefs.find(r => r.Id == this.state.model.ReefId).StorageAccounts.map(s => ({text: `${s.Name}${s.IsPremium ? " - premium account" : ""}`, value: s.Name}))]}
                        fieldName="StorageAccount"
                        hintText="Automatically Assign"
                />
            </ExpandableFormSection>
        </React.Fragment>;
    }


    getBody() {
        const model = this.state.model;

        return <div>
            {this.getPublicDNSFormElement()}
            {this.getApiKeyFormElement()}
            {this.getReefFormElement()}
            <ExpandableFormSection
                errorKey="InstallLegacyCloudAuthProviders"
                title="Authentication"
                summary={this.state.model.InstallLegacyCloudAuthProviders ? Summary.summary("Install legacy Cloud auth providers (password, Google, AzureAD, Okta), and Octopus ID") : Summary.placeholder("Only install Octopus ID auth provider")}
                help={<span>Select whether to install legacy Cloud auth providers in addition to Octopus ID.</span>}>
                <Checkbox label="Install legacy Cloud auth providers"
                          value={this.state.model.InstallLegacyCloudAuthProviders}
                          onChange={InstallLegacyCloudAuthProviders => this.setModelState({InstallLegacyCloudAuthProviders})}
                          error={this.getFieldError("InstallLegacyCloudAuthProviders")}
                />
            </ExpandableFormSection>
            <ExpandableFormSection
                errorKey="CloudSubscriptionSerial"
                title="License Serial"
                summary={model.CloudSubscriptionSerial ? Summary.summary(model.CloudSubscriptionSerial) : Summary.placeholder("No license serial")}
                help={<span>Enter the license serial.</span>}>
                <Text label="License Serial (Optional)"
                      value={model.CloudSubscriptionSerial}
                      onChange={CloudSubscriptionSerial => this.setModelState({CloudSubscriptionSerial})}
                      hintText={"Please enter the license serial to associate with this instance. If blank, the test license will be used."}
                      error={this.getFieldError("CloudSubscriptionSerial")} />
            </ExpandableFormSection>

            <ExpandableFormSection
                errorKey="LicenseXml"
                title="License XML"
                summary={model.LicenseXml ? Summary.summary(model.LicenseXml) : Summary.placeholder("No license XML")}
                help={<span>Enter the license XML.</span>}>
                <Text label="License XML (Optional)"
                      value={model.LicenseXml}
                      onChange={LicenseXml => this.setModelState({LicenseXml})}
                      hintText={"Please enter the license XML to associate with this instance. If blank, the test license will be used."}
                      error={this.getFieldError("LicenseXml")} />
            </ExpandableFormSection>
            <FormSectionHeading title="Resources"/>

            <ExpandableFormSection
                errorKey="ResourceAllocation.CPU"
                title="CPU Allocation"
                summary={Summary.summary(model.CPURequest)}
                help={<span>Select the CPU allocated to each container in this instance's K8S pod measured in CPU units.</span>}>
                <Text label="Request"
                      value={model.CPURequest}
                      onChange={CPURequest => this.setModelState({CPURequest})}
                      validate={validateCPU}
                      error={this.getFieldError("CPURequest")} />
                <Text label="Limit"
                      value={model.CPULimit}
                      onChange={CPULimit => this.setModelState({CPULimit})}
                      validate={validateCPU}
                      error={this.getFieldError("CPULimit")} />
                <ExternalLink href="https://kubernetes.io/docs/concepts/configuration/manage-compute-resources-container/#meaning-of-cpu">Kubernetes CPU</ExternalLink>
            </ExpandableFormSection>

            <ExpandableFormSection
                errorKey="ResourceAllocation.Memory"
                title="Memory Allocation"
                summary={Summary.summary(model.MemoryRequest)}
                help={<span>Select the memory allocated to each container in this instance's K8S pod measured in bytes.</span>}>
                <Text label="Request"
                      value={model.MemoryRequest}
                      onChange={MemoryRequest => this.setModelState({MemoryRequest})}
                      validate={validateMemory}
                      error={this.getFieldError("MemoryRequest")} />
                <Text label="Limit"
                      value={model.MemoryLimit}
                      onChange={MemoryLimit => this.setModelState({MemoryLimit})}
                      validate={validateMemory}
                      error={this.getFieldError("MemoryLimit")} />
                <ExternalLink href="https://kubernetes.io/docs/concepts/configuration/manage-compute-resources-container/#meaning-of-memory">Kubernetes Memory</ExternalLink>
            </ExpandableFormSection>

            {!this.state.model.UsePool && <ExpandableFormSection
                errorKey="ResourceAllocation.SharesQuotaInGb"
                title="File Shares Quota"
                summary={Summary.summary(model.SharesQuotaInGb)}
                help={<span>Set the quota size for the Azure file shares in GB.</span>}>
                <Number label="File Shares Quota"
                      value={model.SharesQuotaInGb}
                      min={50}
                      onChange={SharesQuotaInGb => this.setModelState({SharesQuotaInGb})}
                      error={this.getFieldError("SharesQuotaInGb")} />
            </ExpandableFormSection>}

            {this.getStorageAccountFormElement()}

            <FormSectionHeading title="Dynamic Worker"/>

            <ExpandableFormSection
                errorKey="WorkerIdleTimeBeforeDeletion"
                title="Worker Idle Time Before Deletion"
                summary={model.WorkerIdleTimeBeforeDeletion ? Summary.summary(model.WorkerIdleTimeBeforeDeletion) : Summary.placeholder("No worker idle time before deletion")}
                help={<span>Enter the worker idle time before deletion.</span>}>
                <Text label="Worker Idle Time Before Deletion"
                      value={model.WorkerIdleTimeBeforeDeletion}
                      onChange={WorkerIdleTimeBeforeDeletion => this.setModelState({WorkerIdleTimeBeforeDeletion})}
                      hintText={"Please enter the worker idle time before deletion"}
                      error={this.getFieldError("WorkerIdleTimeBeforeDeletion")} />
            </ExpandableFormSection>

            <ExpandableFormSection
                errorKey="WorkerMaximumLeaseDuration"
                title="Worker Maximum Lease Duration"
                summary={model.WorkerIdleTimeBeforeDeletion ? Summary.summary(model.WorkerMaximumLeaseDuration) : Summary.placeholder("No worker maximum lease duration")}
                help={<span>Enter the worker maximum lease duration.</span>}>
                <Text label="Worker Maximum Lease Duration"
                      value={model.WorkerMaximumLeaseDuration}
                      onChange={WorkerMaximumLeaseDuration => this.setModelState({WorkerMaximumLeaseDuration})}
                      hintText={"Please enter the worker maximum lease duration"}
                      error={this.getFieldError("WorkerMaximumLeaseDuration")} />
            </ExpandableFormSection>

            <ExpandableFormSection
                errorKey="WorkerDecommissionAfter"
                title="Worker Decommission After"
                summary={model.WorkerDecommissionAfter ? Summary.summary(model.WorkerDecommissionAfter) : Summary.placeholder("No worker decommission after")}
                help={<span>Enter the worker decommission after.</span>}>
                <Text label="Worker Decommission After"
                      value={model.WorkerDecommissionAfter}
                      onChange={WorkerDecommissionAfter => this.setModelState({WorkerDecommissionAfter})}
                      hintText={"Please enter the worker decommission after"}
                      error={this.getFieldError("WorkerDecommissionAfter")} />
            </ExpandableFormSection>

        </div>;
    }

    private applyTemplate = () => {
        const template = this.getSelectedTemplate();
        const model = {...this.state.model, ...template};
        this.setState({templateSelected: true, model});
    };

    private handleSaveClick() {
        return this.doBusyTask(async () => {
            const model = this.state.model;
            const instance = await repository.HostedInstances.create(model as CreateHostedInstanceRequest);
            this.setState({redirectTo: routeLinks.instances.instance(instance.Id).root, model});
        });
    }

    private getSelectedTemplate() {
        return this.state.templates.find(t => t.Name === this.state.templateName);
    }
}

export default CreateInstance;
