import * as React from "react";
import {Checkbox} from "@octopusdeploy/design-system-components";
import SaveDialogLayout from "components/DialogLayout/SaveDialogLayout";
import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent";
import {InstanceEnvironmentVariable} from "client/resources/instanceEnvironmentVariablesResource";
import Callout, {CalloutType} from "components/Callout";
import styles = require("../style.less");

interface Props {
    environmentVariable: InstanceEnvironmentVariable;
    forceAllocation(reprovisionDuringWeekends: boolean): Promise<void>;
}

interface State extends DataBaseComponentState {
    reprovisionDuringWeekends: boolean
}

class ForceApplyInstanceEnvironmentVariableDialog extends DataBaseComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            reprovisionDuringWeekends: false,
        };
    }

    render() {
        return <SaveDialogLayout title={`Update All Applicable Instances: "${this.props.environmentVariable.Name}"`}
                                 busy={this.state.busy}
                                 errors={this.state.errors}
                                 onSaveClick={this.forceAllocation}
                                 saveButtonLabel={"Update"}>

            <p>This will call Bulk Reprovision to reprovision all instances that do not have this environment variable applied during their next outage window. See <a href={"https://github.com/OctopusDeploy/hosted-docs/blob/master/portal/configure-environment-variables.md"}>Configure Environment Variables</a> for additional details on adding/removing environment variables.</p>

            <Callout type={CalloutType.Warning} title="Think Before Using This Option!">
                <ul className={styles.calloutList}>
                    <li>Using this option could potentially cause <strong>all</strong> instances to reprovision during their next window if they do not have this environment variable applied.</li>
                    <li>This will not remove the variable from any instance which has already had the variable assigned.</li>
                    <li>Reprovisioning any instance for the purpose of this environment variable will also result in any other environment variables for that instance to then also take effect.</li>
                </ul>
            </Callout>

            <Callout type={CalloutType.Information} title="Further Action">
                Instances excluded from the <strong>Participate in Force Apply Instance Environment Variables</strong> Portal Feature Flag will not be reprovisioned. These instances will need to be reprovisioned manually.
            </Callout>

            <Checkbox 
                value={this.state.reprovisionDuringWeekends} 
                onChange={v => this.setState({reprovisionDuringWeekends: v})} 
                label="Reprovision instances during weekends"
                note = "If unticked, instances will be reprovisioned only on weekdays. If ticked, instances will also be reprovisioned during the weekend."
            />            
        </SaveDialogLayout>;
    }

    private forceAllocation = async () => {
        return this.doBusyTask(async () => {
            await this.props.forceAllocation(this.state.reprovisionDuringWeekends);
        });
    }
}

export default ForceApplyInstanceEnvironmentVariableDialog;