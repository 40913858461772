class Logger {
    private static consoleWrapper(name: string) {
        if (window && window.console && (window.console as any)[name]) {
            const console: any = window.console;
            if (console[name].apply) {
                return function(){
                    console[name].apply(console, arguments);
                };
            } else {
                return function() {
                    const message = Array.prototype.join.call(arguments, ", ");
                    console[name](message);
                };
            }
        } else {
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            return () => {};
        }
    }
    public static log: any = Logger.consoleWrapper("log");
    public static info: any = Logger.consoleWrapper("info");
    public static warn: any = Logger.consoleWrapper("warn");
    public static error: any = Logger.consoleWrapper("error");
}

export default Logger;
