import React, { Component } from "react";
import { css } from "@emotion/css";
import { colorScales } from "@octopusdeploy/design-system-tokens";

class AppVersion extends Component<{version: string}> {

    constructor(props: {version: string}) {
        super(props);
    }

    render() {
        if (!this.props.version) {
            return <span>...</span>;
        }

        return <div className={appVersionStyles}>
            Version {this.props.version}
        </div>;
    }
}

const appVersionStyles = css({
    color: colorScales.grey["300"], // navbar doesn't change themes
})

export default AppVersion;