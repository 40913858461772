export enum CleanUpStrategyType {
    LimitedLifetimeCleanUpStrategy = "LimitedLifetimeCleanUpStrategy",
    ExternallyManagedNoCleanupStrategy = "ExternallyManagedNoCleanupStrategy",
    NeverCleanUpStrategy = "NeverCleanUpStrategy",
}

export const cleanUpStrategyTypeDescriptions: Record<CleanUpStrategyType, string> = {
    [CleanUpStrategyType.LimitedLifetimeCleanUpStrategy]: "Limited lifetime cleanup strategy",
    [CleanUpStrategyType.ExternallyManagedNoCleanupStrategy]: "Externally managed no cleanup strategy",
    [CleanUpStrategyType.NeverCleanUpStrategy]: "Never cleanup"
};

export type CleanUpStrategy = LimitedLifetimeCleanUpStrategy | ExternallyManagedNoCleanupStrategy | NeverCleanUpStrategy;

export interface LimitedLifetimeCleanUpStrategy {
    CleanUpStrategyType: CleanUpStrategyType.LimitedLifetimeCleanUpStrategy;
    CreatedOn: string;
    DeactivationDate: string;
    DeletionDate: string;
}

export interface ExternallyManagedNoCleanupStrategy {
    CleanUpStrategyType: CleanUpStrategyType.ExternallyManagedNoCleanupStrategy;
}

export interface NeverCleanUpStrategy {
    CleanUpStrategyType: CleanUpStrategyType.NeverCleanUpStrategy;
    CreatedOn: string;
}

export function isBranchInstance(CleanUpStrategy: CleanUpStrategy) {
    return CleanUpStrategy.CleanUpStrategyType === CleanUpStrategyType.NeverCleanUpStrategy || CleanUpStrategy.CleanUpStrategyType === CleanUpStrategyType.LimitedLifetimeCleanUpStrategy;
}
