import * as React from "react";
import {Link, RouteComponentProps, withRouter} from "react-router-dom";
import {History} from "history";
import LocationDescriptor = History.LocationDescriptor;
import styles = require("./style.less");
import {exposeComponentAsClass} from "../exposeComponentAsClass";
import {resolvePathWithSpaceId} from "../resolvePathWithSpaceId";
import cn from "classnames";

type FontWeight = "normal" | "bold" | "bolder" | "lighter" | 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;

interface InternalLinkComponentProps  {
    size?: number;
    weight?: FontWeight;
    width?: string;
    color?: string;
    className?: string;
    to: LocationDescriptor;
    openInSelf?: boolean;
    onClick?: () => void;
    children?: React.ReactNode;
    spaceId?: string;
}

type InternalLinkProps = InternalLinkComponentProps & RouteComponentProps<{spaceId: string}>;

const InternalLink: React.SFC<InternalLinkProps> = (props: InternalLinkProps) => {
    const onClick = (e: any) => {
        if (props.onClick) {
            props.onClick();
        }
        e.stopPropagation();
    };

    const style: React.CSSProperties = {};
    if (props.size) {
        style.fontSize = props.size + "rem";
    }
    if (props.weight) {
        style.fontWeight = props.weight;
    }
    if (props.width) {
        style.width = props.width;
    }
    if (props.color) {
        style.color = props.color;
    }

    /* eslint-disable react/forbid-elements */
    return <Link className={cn(styles.internalLink, props.className)}
                 style={style}
                 to={props.to}
                 target={props.openInSelf ? null : "_blank"}
                 onClick={onClick}>
        {props.children}
    </Link>;
};

InternalLink.defaultProps = {
    openInSelf: true
};

export default exposeComponentAsClass(withRouter(InternalLink));