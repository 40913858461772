import { Step } from "client/resources/step";
import { BackgroundProcessResource } from "client/resources/backgroundProcessResource";
import { getProcessesByIds } from "./getProcessesByIds";

export function getStepsByProcessIds(processIds: string[], processes: BackgroundProcessResource[]): Step[] {

    const filteredProcesses = getProcessesByIds(processIds, processes);

    const allSteps = filteredProcesses.reduce<Step[]>((i, p) => [...i, ...p.Steps], []);
    const steps = [
        ...new Map(allSteps.map((item) => [item["Id"], item])).values(),
    ];

    return steps;
}