import {ExpanderContainer} from "globalState";
import { isEqual } from "lodash";

const ExpanderActions = {
    EXPANDER_STATE_CHANGED: "EXPANDER_STATE_CHANGED",
    EXPANDER_CONTAINER_DESTROYED: "EXPANDER_CONTAINER_DESTROYED",
    EXPANDER_CONTAINER_INITIAL_STATE: "EXPANDER_CONTAINER_INITIAL_STATE",
    EXPANDER_EXPAND_ERRORS: "EXPANDER_EXPAND_ERRORS",
    EXPANDER_TOGGLE_ALL: "EXPANDER_TOGGLE_ALL",
    EXPANDER_CREATED: "EXPANDER_CREATED",
    ALL_EXPANDERS_CREATED: "ALL_EXPANDERS_CREATED",
};

export function onExpandErrors(containerKey: string, expandersWithErrors: ReadonlyArray<string>) {
    return { type: ExpanderActions.EXPANDER_EXPAND_ERRORS, containerKey, expandersWithErrors };
}

export function onToggleAll(containerKey: string, expanded: boolean) {
    return { type: ExpanderActions.EXPANDER_TOGGLE_ALL, containerKey, expanded };
}

export function onExpanderCreated(containerKey: string, key: string, expanded: boolean) {
    return { type: ExpanderActions.EXPANDER_CREATED, containerKey, key, expanded };
}

export function onAllExpandersCreated(containerKey: string, keys: string[], expanded: boolean) {
    return { type: ExpanderActions.ALL_EXPANDERS_CREATED, containerKey, keys, expanded };
}

export function onExpanderContainerDestroyed(containerKey: string) {
    return { type: ExpanderActions.EXPANDER_CONTAINER_DESTROYED, containerKey };
}

export function onExpanderStateChanged(containerKey: string, key: string, expanded: boolean) {
    return { type: ExpanderActions.EXPANDER_STATE_CHANGED, containerKey, key, expanded };
}

export function onExpanderSetInitialState(containerKey: string, initialState: boolean) {
    return { type: ExpanderActions.EXPANDER_CONTAINER_INITIAL_STATE, containerKey, initialState };
}

export const defaultContainerKey = "default";

const expanders = (state = {}, action: any): {} => {
    const containerKey = action.containerKey || defaultContainerKey;

    switch (action.type) {
        case ExpanderActions.EXPANDER_EXPAND_ERRORS:
            return expanderExpandErrors(state, action, containerKey);

        case ExpanderActions.EXPANDER_TOGGLE_ALL:
            return expanderToggleAll(state, action, containerKey);

        case ExpanderActions.EXPANDER_STATE_CHANGED:
            return expanderStateChanged(state, action, containerKey);

        case ExpanderActions.EXPANDER_CONTAINER_DESTROYED:
            return expanderContainerDestroyed(state, action, containerKey);

        case ExpanderActions.EXPANDER_CONTAINER_INITIAL_STATE:
            return expanderContainerInitialState(state, action, containerKey);

        case ExpanderActions.EXPANDER_CREATED:
            return expanderCreated(state, action, containerKey);

        case ExpanderActions.ALL_EXPANDERS_CREATED:
            return allExpandersCreated(state, action, containerKey);

        default:
            return state;
    }
};

function expanderExpandErrors(state: any, action: {expandersWithErrors: string[]}, containerKey: string) {
    const container: ExpanderContainer = state[containerKey] || defaultValue();
    const errored = action.expandersWithErrors
        .map(e => findMatchingKey(container.expanderValues, e))
        .filter(e => e !== null)
        .reduce((p, c) => ({...p, [c]: true}), {});

    const updatedContainer: ExpanderContainer = {
        ...container,
        expanderValues: {
            ...container.expanderValues,
            ...errored
        }
    };
    if (isEqual(container, updatedContainer)) { // don't mutate state if we aren't changing anything to avoid infinite update loops
        return state;
    }
    return {
        ...state,
        [containerKey]: updatedContainer
    };
}

function findMatchingKey(expanderValues: {}, key: string): string {
    // given an object with some pipe-delimited keys like:
    // {
    //     "first|second": true
    // }
    // given "first" return "first|second". This allows us to set errorkeys that
    // match multiple possible errors (ie if you have an expander with mutiple controls)

    // remove "Steps[12]." and "Actions[0]." from the start of the error key. Our validation adds that
    // to indicate which step the error is in
    let modKey = key.replace(/^Steps\[[0-9]+\]\./, "");
    modKey = modKey.replace(/^Actions\[[0-9]+\]\./, "");
    return Object.keys(expanderValues).find(k => k.split("|").some(f => f === modKey));
}

function expanderToggleAll(state: any, action: any, containerKey: string) {
    const container: ExpanderContainer = state[containerKey] || defaultValue();
    const updatedContainer: ExpanderContainer = {
        ...container,
        expandingAll: action.expanded,
        expanderValues: setAllToValue(container.expanderValues, action.expanded)
    };
    return {
        ...state,
        [containerKey]: updatedContainer
    };
}

function expanderStateChanged(state: any, action: any, containerKey: string) {
    const container: ExpanderContainer = state[containerKey] || defaultValue();
    const updatedContainer: ExpanderContainer = {
        ...container,
        expandingAll: false,
        expanderValues: {
            ...container.expanderValues,
            [action.key]: action.expanded
        }
    };
    return {
        ...state,
        [containerKey]: updatedContainer
    };
}

function expanderContainerDestroyed(state: any, action: any, containerKey: string) {
    const copy = {...state};
    delete copy[containerKey];
    return copy;
}

function expanderContainerInitialState(state: any, action: any, containerKey: string) {
    // set the initial state of the expander container, if there are already
    // expanders attached, set them to that state as well
    const container: ExpanderContainer = state[containerKey] || defaultValue();
    const updatedContainer: ExpanderContainer = {
        expanderValues: setAllToValue(container.expanderValues, null),
        initialState: action.initialState,
        expandingAll: false
    };
    return {
        ...state,
        [containerKey]: updatedContainer
    };
}

function expanderCreated(state: any, action: any, containerKey: string) {
    const container: ExpanderContainer = state[containerKey] || defaultValue();

    const existingValue = container.expanderValues[action.key];
    const expanderValue = existingValue !== undefined ? existingValue : action.expanded;
    const updatedContainer = {
        ...container,
        expanderValues: {
            ...container.expanderValues,
            [action.key]: expanderValue
        }
    };
    return {
        ...state,
        [containerKey]: updatedContainer
    };
}

function allExpandersCreated(state: any, action: any, containerKey: string) {
    // add a full set of expanders to a container. overwrite any existing.
    const container: ExpanderContainer = state[containerKey] || defaultValue();
    const expanderValues = action.keys.reduce((acc: { [key: string]: boolean }, key: string) => {
        acc[key] = action.expanded;
        return acc;
    }, {});

    return {
        ...state,
        [containerKey]: {
            ...container,
            expanderValues
        }
    };
}

function defaultValue(): ExpanderContainer {
    return {initialState: false, expanderValues: {}, expandingAll: false};
}

function setAllToValue(expanderValues: { [errorKey: string]: boolean | undefined | null },
                       to: boolean | undefined | null): { [errorKey: string]: boolean | undefined | null } {
    const newExpanderValues: any = {};
    Object.keys(expanderValues).forEach(k => {newExpanderValues[k] = to; });
    return newExpanderValues;
}

export default expanders;