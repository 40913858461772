import * as React from "react";
import {FormFieldProps} from "components/form";
import MultiSelect from "components/MultiSelect/MultiSelect";
import {SelectItem} from "components/VirtualListWithKeyboard/SelectItem";
import DefaultChip from "shared/DefaultChip";
import { IntentResource } from "client/resources/intentResource";

interface BackgroundTaskIntentMultiSelectProps extends FormFieldProps<string[]> {
    autoFocus?: boolean;
    label?: string | JSX.Element;
    error?: string;
    items: IntentResource[];
}

const BackgroundTaskIntentTypedMultiSelect = MultiSelect<SelectItem>();

const BackgroundTaskIntentMultiSelect: React.StatelessComponent<BackgroundTaskIntentMultiSelectProps> = props => {
    const items = props.items.map(s => ({ ...s, Name: s.Id }));
    const chipRenderer = (i: SelectItem, onRequestDelete: (event: object) => void) => {
        return <DefaultChip item={i} onRequestDelete={onRequestDelete}/>;
    };
    return <BackgroundTaskIntentTypedMultiSelect
        {...props}
        fieldName="intent"
        renderChip={chipRenderer}
        items={items}/>;
};

export default BackgroundTaskIntentMultiSelect;