import client from "client/client";
import {
    EnvironmentVariableScope, InstanceEnvironmentVariable,
    InstanceEnvironmentVariableUsage
} from "../resources/instanceEnvironmentVariablesResource";

export default class InstanceEnvironmentVariableRepository {

    private rootUri = "/api/instance-environment-variables";
    
    getInstanceEnvironmentVariables(): Promise<InstanceEnvironmentVariable[]> {
        return client.get(`${this.rootUri}`);
    }

    addInstanceEnvironmentVariable(name: string, value: string, description: string): Promise<void> {
        return client.post(`${this.rootUri}`, {name, value, description});
    }

    forceAllocation(id: string, reprovisionDuringWeekends: boolean): Promise<void> {
        return client.post(`${this.rootUri}/{id}/force`, {reprovisionDuringWeekends}, {id})        
    }

    async deleteInstanceEnvironmentVariable(id: string): Promise<void> {
        await client.del(`${this.rootUri}/{id}`, {args: {id}});
    }

    editInstanceEnvironmentVariable(id: string, isEnabled: boolean) {
        return client.put(`${this.rootUri}/{id}`, {isEnabled}, {id});
    }

    editInstanceEnvironmentVariableScope(id: string, request: EnvironmentVariableScope) {
        return client.put(`${this.rootUri}/{id}/scope/`, request, { id });
    }

    getInstanceEnvironmentVariableByHostedInstance(hostedInstanceId: string): Promise<ApplicableInstanceEnvironmentVariable[]> {
        return client.get(`${this.rootUri}/by-instance/{hostedInstanceId}`, {hostedInstanceId});
    }

    getInstanceAssignedEnvironmentVariableByHostedInstance(hostedInstanceId: string): Promise<ApplicableInstanceEnvironmentVariable[]> {
        return client.get(`${this.rootUri}/assigned-environment-variables/by-instance/{hostedInstanceId}`, {hostedInstanceId});
    }
    
    getUsageStats(): Promise<InstanceEnvironmentVariableUsage[]> {
        return client.get(`${this.rootUri}/rollout-stats`); 
    }
    
}

interface ApplicableInstanceEnvironmentVariable {
    AppliedState: ApplicableInstanceEnvironmentVariableAppliedState;
    InstanceEnvironmentVariableId: string;
    Name: string;
    Description: string;
    Value: string
}
enum ApplicableInstanceEnvironmentVariableAppliedState {
    Applied = "Applied",
    AppliedOnNextProvision = "AppliedOnNextProvision",
    RemovedOnNextProvision = "RemovedOnNextProvision"
}

interface EnvironmentVariable {
    Name: string;
    Value: string
}

export {ApplicableInstanceEnvironmentVariable, ApplicableInstanceEnvironmentVariableAppliedState, EnvironmentVariable};