import MultiSelect from "components/MultiSelect/MultiSelect";
import FormFieldProps from "components/form/FormFieldProps";
import {ReefResource} from "client/resources/reefResource";
import * as React from "react";
import ReefChip from "shared/ReefChip";
import {reefDisplayLabel} from "../areas/reefs/ReefDisplayLabel";
import type {Item} from "../components/VirtualListWithKeyboard/VirtualListWithKeyboard";

interface ReefMultiSelectProps extends FormFieldProps<string[]> {
    items: ReefResource[];
    autoFocus?: boolean;
    label?: string | JSX.Element;
    error?: string;
}

const ReefTypedMultiSelect = MultiSelect<ReefResource>();

const ReefMultiSelect: React.StatelessComponent<ReefMultiSelectProps> = props => {
    const chipRenderer = (r: ReefResource, onRequestDelete: (event: object) => void) => {
        return <ReefChip reef={r} onRequestDelete={onRequestDelete}/>;
    };
    const itemRenderer = (r: ReefResource): Item => {
        return {primaryText: reefDisplayLabel(r)}
    }
    return <ReefTypedMultiSelect
        items={props.items}
        fieldName="Reef"
        renderChip={chipRenderer}
        renderItem={itemRenderer}
        {...props} />;
};

export default ReefMultiSelect;