import * as React from "react";
import styles = require("./style.less");

interface ActionListProps {
    actions: any[];
}

const ActionList: React.StatelessComponent<ActionListProps> = props => {
    return <div className={styles.actionList}>
        {props.actions.map((action, index) => !action ? null : React.cloneElement(action, { ...action.props, key: index }))}
    </div>;
};

ActionList.displayName = "ActionList";

export default ActionList;