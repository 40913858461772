import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent";
import SaveDialogLayout from "components/DialogLayout/SaveDialogLayout";
import {Text} from "components/form";
import repository from "client/repository";
import * as React from "react";
import Callout, {CalloutType} from "components/Callout";
import {ActionButton, ActionButtonType} from "components/Button/ActionButton";
import {BulkCancelBackgroundTaskRequest} from "../../client/resources/bulkCancelBackgroundTaskRequest";

interface Props {
    importedBackgroundTaskIds: string[];
    isDefaultFilter: boolean;
}

interface State extends DataBaseComponentState {
    cancelRequest: BulkCancelBackgroundTaskRequest;
    backgroundTaskIdsDelimitedByLine: string;
}

class BulkCancelBackgroundTaskDialog extends DataBaseComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            cancelRequest: {
                backgroundTaskIds: []
            },
            backgroundTaskIdsDelimitedByLine: "",
        };
    }

    render() {
        const cancelRequest = this.state.cancelRequest;
        return <SaveDialogLayout title={`Bulk Cancel Background Tasks`}
                                 onSaveClick={() => this.bulkCancelBackgroundTasks()}
                                 errors={this.state.errors}
                                 saveButtonDisabled={!cancelRequest.backgroundTaskIds.length}
                                 busy={this.state.busy}
                                 extraHeaderContent={className => (<div className={className}><strong>Specified Tasks: {cancelRequest.backgroundTaskIds.length}</strong></div>)}>
            {this.props.isDefaultFilter && <Callout type={CalloutType.Danger} title="!!! No filter applied !!!">
                No task filter is currently applied, which is probably not intended. Are you sure you want to cancel these background tasks?
            </Callout>}
            {!this.props.isDefaultFilter && <Callout type={CalloutType.Warning} title="Use with caution">
                Review filtered list of background tasks to ensure only expected tasks are listed.
            </Callout>}
            
            <br/>
            <Callout type={CalloutType.Information} title="Import your data">
                <p>All of the background tasks displayed on the current page will be imported.</p>
                <p><strong>Note</strong>: This will override the existing ids.</p>
                <ActionButton key="Import"
                              type={ActionButtonType.CreateRelease}
                              label={"Import"}
                              onClick={() => this.updateBackgroundTaskIds(this.props.importedBackgroundTaskIds.join("\n"))}/>
            </Callout>

            <Text label="Ids, one per line"
                  value={this.state.backgroundTaskIdsDelimitedByLine}
                  onChange={ids => this.updateBackgroundTaskIds(ids)}
                  multiLine={true}
                  hintText={"Ids, e.g. BackgroundTasks-1"}
            />
        </SaveDialogLayout>;
    }

    private updateBackgroundTaskIds(idsDelimitedByLine: string) {
        return this.setState(prevState => ({
            backgroundTaskIdsDelimitedByLine: idsDelimitedByLine,
            cancelRequest: { ...prevState.cancelRequest, backgroundTaskIds: idsDelimitedByLine.split("\n").filter(id => id.trim() !== "") },
        }));
    }

    private async bulkCancelBackgroundTasks(): Promise<boolean> {
        return await this.doBusyTask(async () => {
            await repository.BackgroundTasks.bulkCancel({...this.state.cancelRequest});
        });
    }
}

export default BulkCancelBackgroundTaskDialog;
