import { IntentResource } from "client/resources/intentResource";
import { BackgroundProcessResource } from "client/resources/backgroundProcessResource";
import { getProcessesByIds } from "./getProcessesByIds";

export function getIntentsByProcessIds(processIds: string[], processes: BackgroundProcessResource[]): IntentResource[] {

    const filteredProcesses = getProcessesByIds(processIds, processes);

    const allIntents = filteredProcesses.reduce<IntentResource[]>((i, p) => [...i, ...p.Intents], []);
    const intents = [
        ...new Map(allIntents.map((item) => [item["Id"], item])).values(),
    ];

    return intents;
}