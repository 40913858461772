import { OctopusRoutingProvider, DesignSystemLinkHref } from "@octopusdeploy/design-system-components";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import PortalLink from "./PortalLink";

class PortalOctopusRoutingProvider extends React.PureComponent<React.PropsWithChildren<RouteComponentProps>> {
    render() {
        return (
            <OctopusRoutingProvider Link={PortalLink} useIsUrlActive={() => this.useIsUrlActive}>
                {this.props.children}
            </OctopusRoutingProvider>
        )
    }

    private useIsUrlActive = (path: DesignSystemLinkHref, exact: boolean | undefined) => {
        if (typeof(path) !== "string") {
            // Server uses a path object to handle new vs old routing; should never occur in CloudPortal. 
            return false;
        }
        const currentUrl = this.props.location.pathname;
        if (exact) {
            return currentUrl === path;
        }
        const pathWithoutQueryString = path.split("?", 2)[0];
        return currentUrl.startsWith(pathWithoutQueryString);
    }
}

export default withRouter(PortalOctopusRoutingProvider);
