// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* This file is DEPRECATED
   We are moving to the Octopus Design System
   https://www.octopus.design
  
   When changing component styles:
   1. Do not use variables from this file
   2. Aim to use Emotion \`css\` + themeTokens.colors
       - see Dashboard.tsx for example
   3. If you need to use Less, reference CSS variables
       - see index.ejs for example
       - for full list see https://github.com/OctopusDeploy/OctopusDeploy/blob/main/frontend/packages/design-system-tokens/src/generated/themeTokens.ts
   4. Remove variables from this file when they are no longer used
 */
.style_note__lkx2d {
  font-family: Roboto;
  font-size: 0.75rem;
  text-align: left;
  font-weight: 300;
  margin-bottom: 0.5rem;
  color: var(--colorTextMuted);
}
.style_note__lkx2d a {
  color: var(--colorTextLinkDefault);
}
`, "",{"version":3,"sources":["webpack://./app/components/form/Note/style.less"],"names":[],"mappings":"AAAA;;;;;;;;;;;;EAYE;AATF;EACI,mBAAA;EACA,kBAAA;EACA,gBAAA;EACA,gBAAA;EACA,qBAAA;EACA,4BAAA;AAWJ;AAjBA;EAQQ,kCAAA;AAYR","sourcesContent":["@import \"../../../resources/styles/colors\";\n@import \"../../../resources/styles/variables\";\n\n.note {\n    font-family: Roboto;\n    font-size: 0.75rem;\n    text-align: left;\n    font-weight: @light;\n    margin-bottom: 0.5rem;\n    color: @ternaryText;\n    a {\n        color: @primary;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"note": `style_note__lkx2d`
};
module.exports = ___CSS_LOADER_EXPORT___;
