// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_main__grl4g {
  padding: 1rem;
}
.style_login__pULYj {
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./app/areas/root/style.less"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;AAEA;EACI,aAAA;EACA,uBAAA;EACA,aAAA;EACA,mBAAA;AAAJ","sourcesContent":[".main {\n    padding: 1rem\n}\n\n.login {\n    display: flex;\n    justify-content: center;\n    height: 100vh;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `style_main__grl4g`,
	"login": `style_login__pULYj`
};
module.exports = ___CSS_LOADER_EXPORT___;
