import * as React from "react";
import cn from "classnames";
import {DataTableRowHeaderColumn} from "./DataTableRowHeaderColumn";
import {DataTableRowColumn} from "./DataTableRowColumn";
import styles = require("./style.less");

interface DataTableRowProps {
    displayNoBorder?: boolean;
}

export class DataTableRow extends React.Component<any, any> {
    render() {
        const {
            displayNoBorder,
            className,
            ...otherProps
        } = this.props;

        return (
            <tr {...otherProps} className={cn(className, styles.dataTableRow, displayNoBorder ? styles.noBorder : "")} >
                {this.props.children}
            </tr>
        );
    }
}