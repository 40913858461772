import MultiSelect from "components/MultiSelect/MultiSelect";
import FormFieldProps from "components/form/FormFieldProps";
import {StorageAccountResource} from "../client/resources/StorageAccountResource";
import * as React from "react";
import StorageAccountClusterChip from "./StorageAccountClusterChip";

interface StorageAccountMultiSelectProps extends FormFieldProps<string[]> {
    items: StorageAccountResource[];
    autoFocus?: boolean;
    label?: string | JSX.Element;
    error?: string;
}

const StorageAccountTypedMultiSelect = MultiSelect<StorageAccountResource>();

const StorageAccountMultiSelect: React.StatelessComponent<StorageAccountMultiSelectProps> = props => {
    const chipRenderer = (s: StorageAccountResource, onRequestDelete: (event: object) => void) => {
        return <StorageAccountClusterChip storageAccount={s} onRequestDelete={onRequestDelete} />;
    };

    return <StorageAccountTypedMultiSelect
        items={props.items}
        fieldName="Storage Account"
        renderChip={chipRenderer}
        {...props} />;
};

export default StorageAccountMultiSelect;
