import * as React from "react";
import routeLinks from "routeLinks";
import PaperLayout from "components/PaperLayout/PaperLayout";
import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent";
import NavigationButton, {NavigationButtonType} from "components/Button/NavigationButton";
import AdvancedFilterLayout from "components/AdvancedFilterLayout/AdvancedFilterLayout";
import repository from "client/repository";
import {HostedInstanceResource} from "client/resources/hostedInstanceResource";
import {AdvancedFilterTextInput} from "components/AdvancedFilterLayout";
import ReefMultiSelect from "shared/ReefMultiSelect";
import KubernetesClusterMultiSelect from "shared/KubernetesClusterMultiSelect";
import StorageAccountMultiSelect from "shared/StorageAccountMultiSelect";
import {ReefResource} from "client/resources/reefResource";
import {KubernetesClusterResource} from "client/resources/KubernetesClusterResource";
import {StorageAccountResource} from "client/resources/StorageAccountResource";
import InstanceStatusMultiSelect from "shared/InstanceStatusMultiSelect";
import {linkToInstance} from "areas/instances/linkToInstance";
import OverflowMenu, {OverflowMenuNavLink} from "components/Menu/OverflowMenu";
import externalSystemLinks from "externalSystemLinks";
import {HostedInstanceListArgs} from "client/repositories/hostedInstanceRepository";
import {IQuery, QueryStringFilters} from "components/QueryStringFilters/QueryStringFilters";
import {arrayValueFromQueryString} from "utils/ParseHelper/ParseHelper";
import {InstanceStatus} from "client/resources/instanceStatus";
import ActionButton from "components/Button";
import ActionList from "components/ActionList";
import {Refresh} from "components/DataBaseComponent/DataBaseComponent";
import InternalLink from "components/Navigation/InternalLink/InternalLink";
import {displayBetterUptimeStatus, displayInstanceStatus} from "areas/instances/Instances/displayInstanceStatus";
import MenuItem from "material-ui/MenuItem";
import SelectField from "components/form/Select/SelectField";
import FilterSearchBox from "components/FilterSearchBox/FilterSearchBox";
import moment from "moment";
import NextOutageWindowDisplay from "areas/instances/Instances/NextOutageWindowDisplay";
import { SimpleDataTablePaging, PagingInfo } from "components/SimpleDataTable/SimpleDataTablePaging";
import {
    AssignedVersionUpgradeStrategy,
    UpgradeStrategyType
} from "../../../client/resources/upgradeStrategy";
import UpgradeStrategyMultiSelect from "../../../shared/UpgradeStrategyMultiSelect";
import BulkUpgradeHostedInstanceVersionDialog from "areas/instances/Instances/BulkUpgradeHostedInstanceVersionDialog";
import _ = require("lodash");
import {InstanceEnvironmentVariable} from "../../../client/resources/instanceEnvironmentVariablesResource";
import BulkReprovisionHostedInstanceDialog from "./BulkReprovisionHostedInstanceDialog";
import Callout, { CalloutType } from "../../../components/Callout";
import OkDialogLayout from "../../../components/DialogLayout/OkDialogLayout";
import ExternalLink from "../../../components/Navigation/ExternalLink";
import InternalRedirect from "../../../components/Navigation/InternalRedirect";
import CleanUpStrategyMultiSelect from "../../../shared/CleanUpStrategyMultiSelect";
import {CleanUpStrategyType} from "../../../client/resources/cleanUpStrategy";
import TaskCapMultiSelect from "../../../shared/TaskCapMultiSelect";
import BulkRevokeUsersSessionsDialog from "./BulkRevokeUsersSessionsDialog";
import {reefDisplayLabel} from "../../reefs/ReefDisplayLabel";
import BulkRotateAdminApiKeyDialog from "./BulkRotateAdminApiKeyDialog";
import BulkRotateSqlLoginsDialog from "./BulkRotateSqlLoginsDialog";
import { displayInstanceVersion } from "../displayInstanceVersion";
import BulkRotateMasterKeyDialog from "./BulkRotateMasterKeyDialog";

interface State extends DataBaseComponentState {
    instances: HostedInstanceResource[];
    reefs: Map<string, ReefResource>;
    instanceEnvironmentVariables: InstanceEnvironmentVariable[]
    kubernetesClusters: KubernetesClusterResource[];
    storageAccounts: StorageAccountResource[];
    filter: Filter;
    paging: PagingInfo;
    redirectTo?: string;
    environment?: string;
}

type Filter = Partial<HostedInstanceListArgs>;

interface Query extends IQuery, Filter {
}

class FilterLayout extends AdvancedFilterLayout<Filter> {
}

export function notSet() {
    return "[Not Set]";
}

const InstancesQueryStringFilters = QueryStringFilters.For<Filter, Query>();

class Instances extends DataBaseComponent<{}, State> {

    private static getEmptyFilter(): HostedInstanceListArgs {
        return {
            reefs: [],
            kubernetesClusters: [],
            storageAccounts: [],
            upgradeStrategies: [],
            statuses: [],
            cleanUpStrategies: [],
            taskCaps: [],
            skip: "0",
        };
    }

    private refreshInitialised: boolean;

    constructor(props: {}) {
        super(props);

        this.state = {
            filter: Instances.getEmptyFilter(),
            instances: [],
            reefs: new Map<string, ReefResource>(),
            kubernetesClusters: [],
            storageAccounts: [],
            instanceEnvironmentVariables: [],
            paging: {
                take: 50,
                skip: 0,
                total: 0,
            }
        };
    }

    componentDidMount() {
        return this.doBusyTask(async () => {
            const reefs = await repository.Reefs.map();
            const kubernetesClusters = _.flatMap(Array.from(reefs.values()), reef => reef.KubernetesClusters).map(k => ({ Id: k.Name, ...k }));
            const storageAccounts = _.flatMap(Array.from(reefs.values()), reef => reef.StorageAccounts).map(s => ({ Id: s.Name, ...s }));
            const getInstanceEnvironmentVariables = repository.InstanceEnvironmentVariables.getInstanceEnvironmentVariables();
            const getAppInfo = (repository.Configuration.getAppInfo());

            const [instanceEnvironmentVariables, appInfo] = await Promise.all([getInstanceEnvironmentVariables, getAppInfo]);

            this.setState({
                instanceEnvironmentVariables,
                reefs,
                kubernetesClusters,
                storageAccounts,
                environment: appInfo.Environment
            });
        });
    }

    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo} push={true} />;
        }

        const filter = this.state.filter;
        const localOffset = moment().format("Z");
        const list = this.state.instances &&
            <SimpleDataTablePaging<HostedInstanceResource>
                data={this.state.instances}
                onRow={(item: HostedInstanceResource) => this.buildRow(item)}
                onRowOverflowMenu={(item: HostedInstanceResource) => this.buildRowOverflowMenu(item)}
                onRowRedirectUrl={(instance: HostedInstanceResource) => instance.Status === InstanceStatus.Deleted ? null : routeLinks.instances.instance(instance.Id).root}
                headerColumns={["Short ID", "Url", "Status", "Better Uptime Status", `Next Outage Window (${localOffset})`, "Version", "Reef", "Pool Instance"]}
                onEmpty={<div>No instances found</div>}
                paging={{...this.state.paging, pageSelected: (skip: number, page: number) => this.pageSelected(skip, page)}}
            /> ;

        const filterSections = [
            {
                render: <div>
                    <AdvancedFilterTextInput
                        fieldName={"Id"}
                        value={filter.shortId}
                        onChange={shortId => this.filterChanged({shortId})}
                    />
                    <AdvancedFilterTextInput
                        fieldName={"DNS Prefix"}
                        value={filter.dnsPrefix}
                        onChange={dnsPrefix => this.filterChanged({dnsPrefix})}
                    />
                    <InstanceStatusMultiSelect
                        value={filter.statuses}
                        onChange={statuses => this.filterChanged({statuses})}
                    />
                    <SelectField floatingLabelText="Better Uptime Status" value={filter.betterUptimeStatus} onChange={(s: any, d: any, v: string) => this.filterChanged({betterUptimeStatus: v})} >
                        <MenuItem primaryText="" />
                        <MenuItem value="Down" primaryText="Down" />
                        <MenuItem value="Unknown" primaryText="Unknown" />
                        <MenuItem value="Maintenance" primaryText="Maintenance" />
                        <MenuItem value="Validating" primaryText="Validating" />
                        <MenuItem value="Pending" primaryText="Pending" />
                        <MenuItem value="Paused" primaryText="Paused" />
                        <MenuItem value="Up" primaryText="Up" />
                    </SelectField>
                    <SelectField floatingLabelText="Secondary Uptime Status" value={filter.instanceSecondaryUptimeStatus} onChange={(s: any, d: any, v: string) => this.filterChanged({instanceSecondaryUptimeStatus: v})} >
                        <MenuItem primaryText="" />
                        <MenuItem value="Down" primaryText="Down" />
                        <MenuItem value="Unknown" primaryText="Unknown" />
                        <MenuItem value="Up" primaryText="Up" />
                    </SelectField>
                    <ReefMultiSelect
                        items={this.state.reefs ? Array.from(this.state.reefs.values()) : []}
                        value={filter.reefs}
                        onChange={reefs => this.filterChanged({reefs})}
                    />
                    <SelectField floatingLabelText="By Environment Variable"
                                 value={filter?.envVariable}
                                 onChange={(s: any, d: any, v: string) => this.filterChanged({envVariable: v})} >
                        <MenuItem value="" primaryText="" />
                        {this.state.instanceEnvironmentVariables
                            .map(c => <MenuItem key={c.Name} value={c.Id} primaryText={c.Name} /> )
                        }
                    </SelectField>
                    <KubernetesClusterMultiSelect
                        items={this.state.kubernetesClusters ? this.state.kubernetesClusters : []}
                        value={filter.kubernetesClusters}
                        onChange={kubernetesClusters => this.filterChanged({kubernetesClusters})}
                    />
                    <StorageAccountMultiSelect
                        items={this.state.storageAccounts ? this.state.storageAccounts : []}
                        value={filter.storageAccounts}
                        onChange={storageAccounts => this.filterChanged({storageAccounts})}
                    />
                    <UpgradeStrategyMultiSelect
                        value={filter.upgradeStrategies}
                        onChange={upgradeStrategies => this.filterChanged({upgradeStrategies})}
                    />
                    <AdvancedFilterTextInput
                        fieldName={"Version"}
                        value={filter.version}
                        onChange={version => this.filterChanged({version})}
                        hintText={"e.g. 2025 or >= 2025 or [2025,)"}
                        floatingLabelFixed={false}
                    />
                    <CleanUpStrategyMultiSelect
                      value={filter.cleanUpStrategies}
                      onChange={cleanUpStrategies => this.filterChanged({cleanUpStrategies})}
                    />
                    <TaskCapMultiSelect
                        value={filter.taskCaps}
                        onChange={taskCaps => this.filterChanged({taskCaps})}
                    />
                    <SelectField floatingLabelText="Pooled Instances" value={filter.isInPool === null ? "" : filter.isInPool}
                                 onChange={(s: any, d: any, v: string) => this.filterChanged({isInPool: v === "" ? null : v})} >
                        <MenuItem value="" primaryText="" />
                        <MenuItem value="true" primaryText="Yes" />
                        <MenuItem value="false" primaryText="No" />
                    </SelectField>
                    <SelectField floatingLabelText="Should Be Monitored" value={filter.shouldBeMonitored === null ? "" : filter.shouldBeMonitored}
                        onChange={(s: any, d: any, v: string) => this.filterChanged({ shouldBeMonitored: v === "" ? null : v })} >
                        <MenuItem value="" primaryText="" />
                        <MenuItem value="true" primaryText="Yes" />
                        <MenuItem value="false" primaryText="No" />
                    </SelectField>
                </div>
            }
        ];

        return <PaperLayout title={this.calculateTitle()}
                            busy={this.state.busy}
                            errors={this.state.errors}
                            sectionControl={<ActionList actions={[
                                <ActionButton label="Refresh" onClick={this.doRefresh}/>,
                                this.state.environment!= null && this.state.environment !== "Production" && <NavigationButton label="New Branch Instance" href={routeLinks.instances.createBranch} type={NavigationButtonType.Primary}/>,
                                <OverflowMenu menuItems={[
                                    OverflowMenu.dialogItem("New Instance", this.createInstanceDialog()),
                                    OverflowMenu.navItem("Bulk Change Upgrade Strategy", routeLinks.instances.bulkChangeUpgradeStrategy),
                                    OverflowMenu.navItem("Move Hosted Instances", routeLinks.instances.moveHostedInstances),
                                    OverflowMenu.dialogItem("Bulk update hosted instance versions", this.getBulkUpdateHostedInstanceVersion()),
                                    OverflowMenu.dialogItem("Bulk Reprovision Instances", this.getBulkReprovisionHostedInstance()),
                                    OverflowMenu.dialogItem("Bulk Revoke Users Sessions", this.getBulkRevokeUsersSessions()),
                                    OverflowMenu.dialogItem("Bulk Rotate Admin API Keys", this.getBulkRotateAdminApiKeys()),
                                    OverflowMenu.dialogItem("Bulk Rotate Master Keys", this.getBulkRotateMasterKeys()),
                                    OverflowMenu.dialogItem("Bulk Rotate SQL Logins", this.getBulkRotateSqlLogins()),
                                    OverflowMenu.navItem("Search Task Logs", routeLinks.instances.searchTaskLog),
                                    OverflowMenu.navItem("Gather Task Log Metrics", routeLinks.instances.gatherTaskLogMetrics),
                                    OverflowMenu.navItem("Download instance uptime metrics (last 30 days)", "/api/reports/uptime"),
                                    OverflowMenu.navItem("Download filtered instance CSV", "/api/hosted-instances/csv", repository.HostedInstances.getQueryStringForCsv({...this.state.filter})),
                                ]}/>
                            ]}/>}
                            fullWidth={true}>
                <InstancesQueryStringFilters filter={this.state.filter} getQuery={this.queryFromFilters} getFilter={this.getFilterFromQuery} onFilterChange={this.filterChanged} />

                <FilterLayout
                    filter={this.state.filter}
                    defaultFilter={Instances.getEmptyFilter()}
                    onFilterReset={() => this.filterChanged(Instances.getEmptyFilter())}
                    filterSections={filterSections}
                    renderContent={() => list}
                    additionalHeaderFilters={[
                        <FilterSearchBox
                            hintText={"Search by dns prefix..."}
                            value={filter.dnsPrefix}
                            onChange={dnsPrefix => this.filterChanged({dnsPrefix})}
                            autoFocus={true}
                        />,
                        <FilterSearchBox
                            hintText={"Search by short Id..."}
                            value={filter.shortId}
                            onChange={shortId => this.filterChanged({shortId})}
                        />,
                    ]}
                />
        </PaperLayout>;
    }

    private async loadData() {
        const pagingResource = await repository.HostedInstances.list({...this.state.filter, take: this.state.paging.take, skip: this.state.filter.skip});
        return {
            instances: pagingResource.Resources,
            paging: {
                ...this.state.paging,
                total: pagingResource.Total,
            }
        };
    }

    private doRefresh: Refresh = () => Promise.resolve();

    private refreshData = async () => {
        await this.doBusyTask(async () => {
            this.setState(await this.loadData());
        });

        if (this.refreshInitialised) {
            return;
        }

        this.refreshInitialised = true;
        this.doRefresh = await this.startRefreshLoop(() => this.loadData(), 10000);
    };

    private filterChanged = (filter: Filter) => {
        this.setState({
            filter: {...this.state.filter, ...filter, skip: filter.skip ? filter.skip : "0"},
            paging: {
                ...this.state.paging,
                skip: filter.skip ? Number(filter.skip) : 0
            }
        }, async () => await this.refreshData());
    };

    private queryFromFilters = (filter: Filter): Query => {
        return {
            ...filter
        };
    };

    private getFilterFromQuery = (query: Query): Filter => {
        return {
            ...query,
            upgradeStrategies: arrayValueFromQueryString(query.upgradeStrategies).map(v => v as UpgradeStrategyType),
            statuses: arrayValueFromQueryString(query.statuses).map(v => v as InstanceStatus),
            reefs: arrayValueFromQueryString(query.reefs),
            envVariable: query.envVariable,
            kubernetesClusters: arrayValueFromQueryString(query.kubernetesClusters),
            taskCaps: arrayValueFromQueryString(query.taskCaps),
            storageAccounts: arrayValueFromQueryString(query.storageAccounts),
            cleanUpStrategies: arrayValueFromQueryString(query.cleanUpStrategies).map(v => v as CleanUpStrategyType)
        };
    };

    private pageSelected(skip: number, page: number)
    {
        this.setState({
            paging: {
                ...this.state.paging,
                skip
            },
            filter: {
                ...this.state.filter,
                skip: skip.toString()
            }
        }, () => this.refreshData());
    }

    private buildRow(instance: HostedInstanceResource) {
        const reef = this.getReefFor(instance);

        return [
            <InternalLink to={routeLinks.instances.instance(instance.Id).root}>{instance.ShortId}</InternalLink>,
            reef ? linkToInstance(instance, reef) : instance.DnsPrefix,
            displayInstanceStatus(instance.Status),
            displayBetterUptimeStatus(instance.BetterUptimeStatus),
            <NextOutageWindowDisplay instance={instance} compact/>,
            displayInstanceVersion(instance),
            this.renderReef(reef),
            instance.IsInPool ? "Yes" : "No"
        ];
    }

    private getBulkUpdateHostedInstanceVersion(){
        return <BulkUpgradeHostedInstanceVersionDialog importedHostedInstanceIds={this.state.instances.map(i => i.Id)} />;
    }

    private getBulkReprovisionHostedInstance() {
        return <BulkReprovisionHostedInstanceDialog />;
    }

    private getBulkRotateAdminApiKeys() {
        return <BulkRotateAdminApiKeyDialog/>;
    }

    private getBulkRotateMasterKeys() {
        return <BulkRotateMasterKeyDialog/>;
    }

    private getBulkRotateSqlLogins() {
        return <BulkRotateSqlLoginsDialog/>;
    }

    private getBulkRevokeUsersSessions() {
        return <BulkRevokeUsersSessionsDialog importedHostedInstanceIds={this.state.instances.map(i => i.Id)} />;
    }

    private buildRowOverflowMenu(instance: HostedInstanceResource) {
        const reef = this.getReefFor(instance);
        const items: OverflowMenuNavLink[] = [];

        if (!reef) {
           return items;
        }
        if (instance.HubTenantId) {
            items.push(OverflowMenu.externalNavItem("Open Hub", externalSystemLinks.hub.root(reef)));
        }

        items.push(OverflowMenu.externalNavItem("Open Seq", externalSystemLinks.seq.instanceLogs(instance, reef)));

        if (instance.BetterUptimeAlertingMonitorId) {
            items.push(OverflowMenu.externalNavItem("Open Better Uptime", externalSystemLinks.betterUptime.alertingLinks.status(instance)));
        }
        items.push(OverflowMenu.navItem("Tasks", routeLinks.instances.instance(instance.Id).tasks));
        return items;
    }

    private getReefFor(instance: HostedInstanceResource) {
        return this.state.reefs.get(instance.ReefId);
    }

    private renderReef(reef: ReefResource) {
        if (!reef) {
            return notSet();
        }

        return <InternalLink to={routeLinks.reefs.reef(reef.Id).root}>{reefDisplayLabel(reef)}</InternalLink>;
    }

    private calculateTitle() {
        if (!this.state.instances) return "Instances";
        return `Instances (${this.state.paging.total})`;
    }

    private createInstanceDialog() {
        return <OkDialogLayout title={"Create Instance"}
            errors={this.state.errors}
            busy={this.state.busy}
            okButtonLabel="Create" onOkClick={() => this.setState({ redirectTo: routeLinks.instances.create })}>

            <Callout type={CalloutType.Warning} title="Warning">
                <p>Instances should not be created via Cloud Portal as it will require manual maintenance. This should only be used for exceptions.
                    Do not create instances via Cloud Portal if the impact is unclear.</p>

                <p>Instances should be created via Octofront
                (see <ExternalLink href={`https://github.com/OctopusDeploy/hosted-docs/blob/master/how-to/create-a-cloud-instance.md`}>How to Create a Cloud Instance</ExternalLink>).</p>
            </Callout>
        </OkDialogLayout>
    }
}

export default Instances;
