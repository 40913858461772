// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_layout__zKpQ4 {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.style_headerContentAlignment__wg83z {
  padding-left: 1rem;
  padding-right: 1rem;
}
.style_title__vru2H {
  padding: 1rem;
  font-size: 1.063rem;
  font-weight: 500;
}
.style_titleIcon__pI1M9 {
  margin-right: 0.5rem;
  display: inline-block;
}
.style_body__C4O7X {
  margin-top: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
  overflow-y: auto;
  overflow-x: hidden;
  flex-direction: column;
}
.style_actions__Q1d0j {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  flex: 0 0 auto;
  flex-wrap: wrap;
}
.style_materialDialogBody__ADF99 {
  padding: 0;
}
.style_primaryActions__YQNIQ > * {
  margin-left: 0.5rem;
}
.style_secondaryActions__SLDJa > * {
  margin-right: 0.5rem;
}
.style_dialogWrapper__GpnTo {
  display: flex;
  flex-direction: column;
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./app/components/DialogLayout/style.less"],"names":[],"mappings":"AAEA;EAEE,aAAA;EACA,sBAAA;EACA,WAAA;AAFF;AAKA;EACE,kBAAA;EACA,mBAAA;AAHF;AAMA;EACE,aAAA;EACA,mBAAA;EACA,gBAAA;AAJF;AAOA;EACE,oBAAA;EACA,qBAAA;AALF;AAQA;EACE,kBAAA;EACA,mBAAA;EACA,oBAAA;EACA,kBAAA;EACA,mBAAA;EACA,oBAAA;EACA,gBAAA;EAEA,kBAAA;EACA,sBAAA;AAPF;AAUA;EACE,aAAA;EACA,aAAA;EACA,8BAAA;EACA,cAAA;EACA,eAAA;AARF;AAWA;EACE,UAAA;AATF;AAYA;EAEI,mBAAA;AAXJ;AAeA;EAEI,oBAAA;AAdJ;AAiBA;EACE,aAAA;EACA,sBAAA;EACA,YAAA;AAfF","sourcesContent":["@import \"../../resources/styles/variables\";\n\n.layout\n{\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n}\n\n.headerContentAlignment {\n  padding-left: 1rem;\n  padding-right: 1rem;\n}\n\n.title {\n  padding: 1rem;\n  font-size: 1.063rem;\n  font-weight: @semibold;\n}\n\n.titleIcon {\n  margin-right: 0.5rem;\n  display: inline-block;\n}\n\n.body {\n  margin-top: 0.5rem;\n  font-size: 0.875rem;\n  line-height: 1.25rem;\n  padding-left: 1rem;\n  padding-right: 1rem;\n  padding-bottom: 1rem;\n  overflow-y: auto;\n  // TODO: Is this a valid solution to scrolling dialogs?\n  overflow-x: hidden;\n  flex-direction: column;\n}\n\n.actions {\n  padding: 1rem;\n  display: flex;\n  justify-content: space-between;\n  flex: 0 0 auto;\n  flex-wrap: wrap; // Need this to wrap for mobile.\n}\n\n.materialDialogBody {\n  padding: 0;\n}\n\n.primaryActions {\n  > * {\n    margin-left: 0.5rem;\n  }\n}\n\n.secondaryActions {\n  > * {\n    margin-right: 0.5rem;\n  }\n}\n.dialogWrapper {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout": `style_layout__zKpQ4`,
	"headerContentAlignment": `style_headerContentAlignment__wg83z`,
	"title": `style_title__vru2H`,
	"titleIcon": `style_titleIcon__pI1M9`,
	"body": `style_body__C4O7X`,
	"actions": `style_actions__Q1d0j`,
	"materialDialogBody": `style_materialDialogBody__ADF99`,
	"primaryActions": `style_primaryActions__YQNIQ`,
	"secondaryActions": `style_secondaryActions__SLDJa`,
	"dialogWrapper": `style_dialogWrapper__GpnTo`
};
module.exports = ___CSS_LOADER_EXPORT___;
