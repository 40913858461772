export const dynamicWorkerServicesRouteLinks = {
    root: "/dynamic-worker-services",
    create: "/dynamic-worker-services/create",
    service: (id: string) => ({
        root: `${dynamicWorkerServicesRouteLinks.root}/${id}`,
        edit: `${dynamicWorkerServicesRouteLinks.root}/${id}/edit`,
        workers: `${dynamicWorkerServicesRouteLinks.root}/${id}/workers`,
        live: `${dynamicWorkerServicesRouteLinks.root}/${id}/live`,
        workerTypes: `${dynamicWorkerServicesRouteLinks.root}/${id}/types`,
        workerType: (type: string) => ({
            root: `${dynamicWorkerServicesRouteLinks.root}/${id}/types/${type}`
        }),
        workerSources: (typeId: string) => ({
            root: `${dynamicWorkerServicesRouteLinks.root}/${id}/sources/${typeId}`
        })
    })
};
