// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_container__WW0Hd {
  display: flex;
  align-items: center;
}
.style_separator__Edh3u {
  margin: 0 0.125rem;
}
.style_ampm__A6bhp {
  margin-left: 0.1875rem;
}
`, "",{"version":3,"sources":["webpack://./app/components/form/DatePicker/style.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;AACF;AAEA;EACE,kBAAA;AAAF;AAGA;EACE,sBAAA;AADF","sourcesContent":[".container {\n  display: flex;\n  align-items: center;\n}\n\n.separator {\n  margin: 0 2/16rem;\n}\n\n.ampm {\n  margin-left: 3/16rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `style_container__WW0Hd`,
	"separator": `style_separator__Edh3u`,
	"ampm": `style_ampm__A6bhp`
};
module.exports = ___CSS_LOADER_EXPORT___;
