import {Component, connect} from "react-redux";
import {openDialog} from "components/Dialog/reducers/dialog";
import GlobalState from "globalState";

export interface DispatchProps {
    openDialog(id: string): void;
}

export interface StateProps {
    openDialogs: {[key: string]: boolean};
}

export type OpenDialogProps = DispatchProps & StateProps;

export class OpenDialogConnect {
    static to<TProps>(internal: Component<OpenDialogProps & TProps>, withRef: boolean = false): Component<TProps> {
        const mapDispatchToProps = (dispatch: any) => {
            return {
                openDialog: (key: string) => {
                    dispatch(openDialog(key));
                }
            };
        };

        const mapStateToProps = (state: GlobalState) => ({
            openDialogs: state.dialogs || {}
        });

        return connect<StateProps, DispatchProps, TProps>(
            mapStateToProps,
            mapDispatchToProps,
            null,
            {withRef}
        )(internal);
    }
}