import * as React from "react";
import ReloadableRoute from "components/ReloadableRoute/ReloadableRoute";
import {Switch} from "react-router-dom";
import {instancesRouteLinks} from "areas/instances/instancesRouteLinks";
import Instances from "areas/instances/Instances/Instances";
import CreateInstance from "areas/instances/Instances/CreateInstance";
import InstanceLayout from "areas/instances/Instances/InstanceLayout";
import MoveHostedInstances from "areas/instances/Instances/MoveHostedInstances";
import SearchTaskLogs from "areas/instances/Instances/SearchTaskLogs";
import GatherTaskLogMetrics from "./Instances/GatherTaskLogMetrics";
import CreateBranchInstance from "./Instances/CreateBranchInstance";
import BulkChangeUpgradeStrategy from "../bulkChangeUpgradeStrategy/BulkChangeUpgradeStrategy";

const InstancesLayout: React.FunctionComponent<{}> = _ =>
    <Switch>
        <ReloadableRoute path={instancesRouteLinks.create} exact={true} component={CreateInstance} />
        <ReloadableRoute path={instancesRouteLinks.createBranch} exact={true} component={CreateBranchInstance} />       
        <ReloadableRoute path={instancesRouteLinks.root} exact={true} component={Instances} />
        <ReloadableRoute path={instancesRouteLinks.bulkChangeUpgradeStrategy} exact={true} component={BulkChangeUpgradeStrategy} />
        <ReloadableRoute path={instancesRouteLinks.moveHostedInstances} exact={true} component={MoveHostedInstances} />
        <ReloadableRoute path={instancesRouteLinks.searchTaskLog} exact={true} component={SearchTaskLogs} />
        <ReloadableRoute path={instancesRouteLinks.gatherTaskLogMetrics} exact={true} component={GatherTaskLogMetrics} />
        <ReloadableRoute path={instancesRouteLinks.instance(":instanceId").root} component={InstanceLayout} />
    </Switch>;

InstancesLayout.displayName = "InstancesLayout";

export default InstancesLayout;