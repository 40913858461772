import React, {FC, useCallback, useEffect, useMemo, useState} from "react";
import {css} from "@emotion/css";
import {
    ChevronDownIcon,
    NavigationBar,
    SimpleMenu,
    SimpleMenuItem,
    useMenuState
} from "@octopusdeploy/design-system-components";
import {themeTokens} from "@octopusdeploy/design-system-tokens";
import repository from "client/repository";
import {MeResource} from "client/resources/meResource";
import {useThemeSetting} from "components/Theme";

const CurrentUserMenu: FC = () => {
    const [me, setMe] = useState<MeResource>({
        Name: "Loading...",
        Email: "",
    });
    useEffect(() => {
        repository.Authentication.me().then(setMe);
    }, []);

    const [openMenu, menuState, buttonAriaAttributes] = useMenuState();
    const menuItems = useMenuItems(me);

    return (
        <div>
            <NavigationBar.Button label={me.Name} icon={<ChevronDownIcon />} accessibleName="Open User Profile menu"  onClick={openMenu} {...buttonAriaAttributes} />
            <SimpleMenu menuState={menuState} items={menuItems} accessibleName="User Profile" />
        </div>
    );
};

const infoItemStyle = css`
    color: ${themeTokens.color.text.muted};
`;

const InfoItem: FC<{ label: string, value: string }> = ({ label, value }) => (
    <span title={label} className={infoItemStyle}>{value}</span>
);

function useMenuItems(me: MeResource): SimpleMenuItem[] {
    return [
        {type: "information", content: <InfoItem label="Email" value={me.Email}/>},
        {type: "divider"},
        useDarkThemeMenuItem(),
    ];
}

function useDarkThemeMenuItem(): SimpleMenuItem {
    const { themeName, setThemeName } = useThemeSetting();
    const toggleIsDarkModeEnabled = useCallback((isDarkModeEnabled: boolean) => setThemeName(isDarkModeEnabled ? "dark" : "light"), [setThemeName]);
    const isDarkModeEnabled = themeName === "dark";
    return useMemo(
        () => ({
            type: "toggle",
            label: "Dark theme",
            isEnabled: isDarkModeEnabled,
            setIsEnabled: toggleIsDarkModeEnabled,
        }),
        [isDarkModeEnabled, toggleIsDarkModeEnabled]
    );
}

export default CurrentUserMenu;