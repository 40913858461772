import React from "react";
import {BooleanRadioButton, BooleanRadioButtonGroup, Checkbox} from "@octopusdeploy/design-system-components";
import repository from "client/repository";
import {
    DataTable,
    DataTableBody,
    DataTableRow,
    DataTableRowColumn,
    DataTableRowHeaderColumn,
    DataTableRowWithActionColumn,
    DataTableRowWithOverflowMenuColumn
} from "components/DataTable";
import {Text} from "components/form";
import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent";
import {CloneReefRequest} from "client/resources/cloneReefRequest";
import Callout, {CalloutType} from "components/Callout";
import PaperLayout from "components/PaperLayout";
import {default as OverflowMenu} from "components/Menu/OverflowMenu";
import SaveDialogLayout from "components/DialogLayout/SaveDialogLayout";
import FormSectionHeading from "components/form/Sections/FormSectionHeading";
import ActionList from "components/ActionList/ActionList";
import {InternalRedirect} from "components/Navigation/InternalRedirect";
import {ReefResource} from "client/resources/reefResource";
import {DynamicWorkerServiceResource} from "client/resources/dynamicWorkerServiceResource";
import {auditLinks} from "areas/auditing/auditRouteLinks";
import {reefsRouteLinks} from "areas/reefs/reefsRouteLinks";
import OpenDialogButton from "components/Dialog/OpenDialogButton";
import ChangeAvailabilityForCustomersDialog from "areas/reefs/ChangeAvailabilityForCustomersDialog";
import ChangeInstanceUsageReportingDialog from "areas/reefs/ChangeInstanceUsageReportingDialog";
import ChangePoolConfigurationDialog from "areas/reefs/ChangePoolConfigurationDialog";
import ChangeDynamicWorkerServiceDialog from "areas/reefs/ChangeDynamicWorkerServiceDialog";
import ChangeHubDialog from "areas/reefs/ChangeHubDialog";
import {SqlServerResource} from "client/resources/SqlServerResource";
import {KubernetesClusterResource} from "client/resources/KubernetesClusterResource";
import {StorageAccountResource} from "client/resources/StorageAccountResource";
import {CreateKubernetesClusterRequest} from "client/resources/createKubernetesClusterRequest";
import {CreateSqlServerRequestResource} from "client/resources/createSqlServerRequestResource";
import convertBooleanToText from "utils/convertBooleanToText";
import CreateKubernetesClusterDialog from "./CreateKubernetesClusterDialog";
import ConnectionDetailsDialog from "./ConnectionsDetailsDialog";
import UpgradeKubernetesClustersDialog from "areas/reefs/UpgradeKubernetesClustersDialog";
import MoveFileStoragesDialog from "areas/reefs/MoveFileStoragesDialog";
import externalSystemLinks from "../../externalSystemLinks";
import ExternalLink from "../../components/Navigation/ExternalLink";
import ChangeSqlServerAdminPasswordDialog from "./ChangeSqlServerAdminPasswordDialog";
import ChangeStorageAccountAvailabilityDialog from "./ChangeStorageAccountAvailabilityDialog";
import {DeleteConfirmation} from "../instances/Instances/DeleteConfirmation";
import {AzureRegionResource} from "client/resources/azureRegionResource";
import { KubernetesClusterStateResource } from "client/resources/KubernetesClusterStateResource";
import ChangeReefDisplayNameDialog from "./ChangeReefDisplayNameDialog";
import DecommissionNodeDialog from "./DecommissionNodeDialog";
import TaskSnackbar from "../../shared/TaskSnackbar";
import routeLinks from "../../routeLinks";
import InternalLink from "../../components/Navigation/InternalLink/InternalLink";
import {HubResource} from "../../client/resources/hubResource";
import {CreateStorageAccountRequest} from "../../client/resources/createStorageAccountRequest";
import { linkToInstance } from "../instances/linkToInstance";

interface State extends DataBaseComponentState {
    redirectTo?: string;
    canDelete: boolean;
    cloneReefRequest: Partial<CloneReefRequest>;
    deleteOrphanResources: boolean;
    environment: string;
    dynamicWorkerServices?: DynamicWorkerServiceResource[];
    azureRegions?: AzureRegionResource[];
    createReefKubernetesCluster?: CreateKubernetesClusterRequest;
    createReefSqlServer?: CreateSqlServerRequestResource;
    openSnackBar: boolean;
    snackBarTaskId?: string;
    azureServicePrincipalSubscriptionId: string;
    hub?: HubResource;
    kubernetesClusterStates?: Map<string, KubernetesClusterStateResource>;
    reefs?: Map<string, ReefResource>;
    CreateStorageAccount?: CreateStorageAccountRequest;
}

interface Props {
    reef: ReefResource;
    breadcrumbTitle: string;
    updateReef: (reef: Partial<ReefResource>) => void;
}

export class ReefDetail extends DataBaseComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            cloneReefRequest: {ProvisionResources: false},
            canDelete: false,
            deleteOrphanResources: false,
            environment: null,
            openSnackBar: false,
            azureServicePrincipalSubscriptionId: null,
            CreateStorageAccount: {CreatePremiumStorageAccount: false},
        };
    }

    async componentDidMount() {
        return await this.doBusyTask(async () => {
            this.setState({
                dynamicWorkerServices: await repository.DynamicWorkerServices.list(),
                azureRegions: await repository.AzureRegions.list(),
                environment: (await repository.Configuration.getAppInfo()).Environment,
                azureServicePrincipalSubscriptionId: (await repository.Configuration.getExternalUrls()).AzureSubscriptionId,
                hub: await repository.Hubs.get(this.props.reef.HubId),
                kubernetesClusterStates: await repository.Reefs.mapKubernetesClusterStates(this.props.reef.Id),
                reefs: await repository.Reefs.map(),
            });
        });
    }

    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo}/>;
        }

        const reef = this.props.reef;

        return <PaperLayout busy={this.state.busy}
                            errors={this.state.errors}
                            title="Detail"
                            breadcrumbTitle={this.props.breadcrumbTitle}
                            sectionControl={<ActionList actions={reef ? this.getActions() : []}/>}>
            <TaskSnackbar
                getLink={() => this.state.snackBarTaskId ? routeLinks.backgroundTasks.task(this.state.snackBarTaskId).root : routeLinks.backgroundTasks.root}
                onRequestClose={() => this.setState({openSnackBar: false, snackBarTaskId: null})}
                open={this.state.openSnackBar} />
            {reef && this.getBody()}
            
        </PaperLayout>;
    }

    private getActions() {
        const reef = this.props.reef;

        const menuItems = [
            OverflowMenu.dialogItem("Reprovision", this.renderReprovisionDialog()),
            OverflowMenu.dialogItem("Add new Kubernetes Cluster", this.renderAddKubernetesClusterDialog()),
            OverflowMenu.dialogItem("Upgrade Kubernetes Clusters", this.renderUpgradeKubernetesClustersDialog()),
            OverflowMenu.dialogItem("Add new Storage Account", this.renderAddStorageAccountDialog()),
            OverflowMenu.dialogItem("Move instances between Storage Accounts", <MoveFileStoragesDialog reef={this.props.reef} />),
            this.state.environment !== "Production" && OverflowMenu.dialogItem("Grant access to Engineering Team", this.grantAccessToEngineeringTeamDialog()),
            OverflowMenu.dialogItem("Clone", this.renderCloneReefDialog()),
            OverflowMenu.navItem("Audit Trail", auditLinks.auditTrailFor(reef)),
            OverflowMenu.deleteItem("Delete", "Are you sure you want to delete this reef?", () => this.deleteReef(), this.renderDeleteDialog(), !this.state.canDelete)
        ];

        return [
            <ExternalLink
                href={externalSystemLinks.sumoLogic.clusterAutoScaler(reef)}>
                Cluster Autoscaler
            </ExternalLink>,
            <ExternalLink
                href={externalSystemLinks.sumoLogic.storageAccount(reef)}>
                Storage Account
            </ExternalLink>,
            <OverflowMenu menuItems={menuItems}/>
        ];
    }

    private getBody() {
        const reef = this.props.reef;
        const hub = this.state.hub;
        const azureServicePrincipalSubscriptionId = this.state.azureServicePrincipalSubscriptionId;
        return reef && this.state.dynamicWorkerServices ? <>
            <FormSectionHeading title="General"/>
            <DataTable>
                <DataTableBody>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Name</DataTableRowHeaderColumn>
                        <DataTableRowColumn>{reef.Name}</DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Display Name</DataTableRowHeaderColumn>
                        <DataTableRowWithActionColumn action={
                            <OpenDialogButton label={"Change Reef Display Name"}>
                                <ChangeReefDisplayNameDialog
                                    reefId={reef.Id}
                                    displayName={reef.DisplayName}
                                    onChange={this.props.updateReef}/>
                            </OpenDialogButton>}>
                            {reef.DisplayName}
                        </DataTableRowWithActionColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Status</DataTableRowHeaderColumn>
                        <DataTableRowColumn>{reef.Status}</DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Available To Customers</DataTableRowHeaderColumn>
                        <DataTableRowWithActionColumn action={
                            <OpenDialogButton label={"Change Reef Availability"}>
                                <ChangeAvailabilityForCustomersDialog
                                    reefId={reef.Id}
                                    isAvailableToCustomers={reef.AvailableToCustomers}
                                    onChange={this.props.updateReef}/>
                            </OpenDialogButton>}>
                            {convertBooleanToText(reef.AvailableToCustomers)}
                        </DataTableRowWithActionColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Enable Instance Usage Reporting</DataTableRowHeaderColumn>
                        <DataTableRowWithActionColumn action={
                            <OpenDialogButton label={"Change Instance Usage Reporting"}>
                                <ChangeInstanceUsageReportingDialog
                                    reefId={reef.Id}
                                    isInstanceUsageReportingEnabled={reef.EnableReporting}
                                    onChange={this.props.updateReef}/>
                            </OpenDialogButton>}>
                            {convertBooleanToText(reef.EnableReporting)}
                        </DataTableRowWithActionColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Top Level Domain</DataTableRowHeaderColumn>
                        <DataTableRowColumn>{reef.TopLevelDomain}</DataTableRowColumn>
                    </DataTableRow>
                </DataTableBody>
            </DataTable>
            <FormSectionHeading title="Azure - General"/>
            <DataTable>
                <DataTableBody>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>AzureRegion</DataTableRowHeaderColumn>
                        <DataTableRowColumn>{this.state.azureRegions.find(r => r.Id === reef.AzureRegionId).Region}</DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>ResourceGroupName</DataTableRowHeaderColumn>
                        <DataTableRowColumn>{reef.ResourceGroupName}</DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>VNet Address Space</DataTableRowHeaderColumn>
                        <DataTableRowColumn><code>{reef.AddressSpace}</code></DataTableRowColumn>
                    </DataTableRow>
                </DataTableBody>
            </DataTable>
            <FormSectionHeading title="Azure - Storage"/>
            <DataTable>
            {reef.StorageAccounts.map((storageAccount: StorageAccountResource) => {
                return <DataTableBody>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>StorageAccountName</DataTableRowHeaderColumn>
                        <DataTableRowWithOverflowMenuColumn action={
                            <OverflowMenu menuItems={[
                                OverflowMenu.dialogItem(
                                    "Change availability",
                                    <ChangeStorageAccountAvailabilityDialog
                                        reefId={reef.Id}
                                        storageAccount={storageAccount}
                                        onChange={this.updateStorageAccount}/>
                                ),
                                OverflowMenu.deleteItem(
                                    "Delete",
                                    "Are you sure you want to delete this Storage Account?",
                                    () => this.deleteStorageAccount(storageAccount.Name),
                                    this.renderDeleteStorageAccountDialog(storageAccount.Name),
                                    !this.state.canDelete)
                            ]}/>}>
                            {storageAccount.Name === null ? "<Creating>" : storageAccount.Name}
                        </DataTableRowWithOverflowMenuColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Available</DataTableRowHeaderColumn>
                        <DataTableRowColumn>{convertBooleanToText(storageAccount.IsAvailable)}</DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Premium Storage Account</DataTableRowHeaderColumn>
                        <DataTableRowColumn>{convertBooleanToText(storageAccount.IsPremium)}</DataTableRowColumn>
                    </DataTableRow>
                </DataTableBody>;
            })}
            </DataTable>
            <FormSectionHeading title="Azure - SQL"/>
            <DataTable>
            {reef.SqlServers.map((sqlServer: SqlServerResource) => {
                return <DataTableBody>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>SqlServerName</DataTableRowHeaderColumn>
                        <DataTableRowColumn>{sqlServer.Name === null ? "<Creating>" : sqlServer.Name}</DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>SqlServerAdminUser</DataTableRowHeaderColumn>
                        <DataTableRowWithActionColumn action={
                            <OpenDialogButton label={"Change SQL Admin Password"}>
                                <ChangeSqlServerAdminPasswordDialog reef={reef} sqlServer={sqlServer}
                                    onSave={() => this.setState({redirectTo: reefsRouteLinks.reef(this.props.reef.Id).tasks})}/>
                            </OpenDialogButton>}>
                            {sqlServer.AdminUser}
                        </DataTableRowWithActionColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>SqlServerElasticPoolTier</DataTableRowHeaderColumn>
                        <DataTableRowColumn>{sqlServer.ElasticPoolTier}</DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>SqlServerElasticPoolDtuSize</DataTableRowHeaderColumn>
                        <DataTableRowColumn>{sqlServer.ElasticPoolDtuSize}</DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>SqlServerSingleDatabaseDtuLimit</DataTableRowHeaderColumn>
                        <DataTableRowColumn>{sqlServer.SingleDatabaseDtuLimit}</DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>SqlServerElasticPoolStorageSizeInMB</DataTableRowHeaderColumn>
                        <DataTableRowColumn>{sqlServer.ElasticPoolStorageSizeInMB}</DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>SqlServerElasticPoolSize</DataTableRowHeaderColumn>
                        <DataTableRowColumn>{sqlServer.ElasticPoolSize}</DataTableRowColumn>
                    </DataTableRow>
                </DataTableBody>;
            })}
            </DataTable>
            <FormSectionHeading title="Azure - Kubernetes"/>
            <DataTable>
            {reef.KubernetesClusters.map((kubernetesCluster: KubernetesClusterResource) => {
                const kubernetesClusterState = this.state.kubernetesClusterStates.get(kubernetesCluster.Name);
                return <DataTableBody>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Kubernetes Cluster Name</DataTableRowHeaderColumn>
                        <DataTableRowWithOverflowMenuColumn action={
                            <OverflowMenu menuItems={[
                                OverflowMenu.dialogItem("Connection Details", <ConnectionDetailsDialog reef={reef} cluster={kubernetesCluster} azureServicePrincipalSubscriptionId={azureServicePrincipalSubscriptionId}/>),
                                OverflowMenu.dialogItem("Decommission Node", <DecommissionNodeDialog 
                                    errors={this.state.errors}
                                    busy={this.state.busy}
                                    reefId={reef.Id}
                                    clusterName={kubernetesCluster.Name}
                                    onSave={(taskId: string | null) => this.setState({openSnackBar: true, snackBarTaskId: taskId})}/>),
                                OverflowMenu.deleteItem(
                                    "Delete",
                                    "Are you sure you want to delete this Kubernetes Cluster?",
                                    () => this.deleteKubernetesCluster(kubernetesCluster.Name),
                                    this.renderDeleteKubernetesClusterDialog(kubernetesCluster.Name),
                                    !this.state.canDelete)
                            ]}/>}>
                            {kubernetesCluster.Name === null ? "<Creating>" : kubernetesCluster.Name}
                        </DataTableRowWithOverflowMenuColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Available</DataTableRowHeaderColumn>
                        <DataTableRowColumn>{convertBooleanToText(kubernetesCluster.IsAvailable)}</DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Kubernetes Cluster Version</DataTableRowHeaderColumn>
                        <DataTableRowColumn><code>{kubernetesCluster.ClusterVersion}</code></DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Node Image Version</DataTableRowHeaderColumn>
                        <DataTableRowColumn>{kubernetesClusterState?.NodeImageVersion
                            ? <code>{kubernetesClusterState.NodeImageVersion}</code>
                            : <i>Unknown</i>}
                        </DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Kubernetes Node VM Type</DataTableRowHeaderColumn>
                        <DataTableRowColumn>{kubernetesCluster.NodeVmType}</DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Kubernetes Node Disk Size in GB</DataTableRowHeaderColumn>
                        <DataTableRowColumn>{kubernetesCluster.NodeDiskSizeInGB}</DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Availability Zones</DataTableRowHeaderColumn>
                        <DataTableRowColumn>{kubernetesCluster.AvailabilityZones}</DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Hosted Scripts Version</DataTableRowHeaderColumn>
                        <DataTableRowColumn><code>{kubernetesCluster.HostedScriptsVersion}</code></DataTableRowColumn>
                    </DataTableRow>
                </DataTableBody>;
            })}
            </DataTable>
            <FormSectionHeading title="Pool"/>
            <DataTable>
                <DataTableBody>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Pool Size</DataTableRowHeaderColumn>
                        <DataTableRowWithActionColumn action={
                            <OpenDialogButton label={"Change Pool Configuration"}>
                                <ChangePoolConfigurationDialog reefId={reef.Id}
                                    poolConfiguration={{poolSize: reef.PoolSize, poolInstanceTemplate: reef.PoolInstanceTemplate}}
                                    onChange={this.props.updateReef}/>
                            </OpenDialogButton>}>
                            {reef.PoolSize}
                        </DataTableRowWithActionColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Pool Instance Template</DataTableRowHeaderColumn>
                        <DataTableRowColumn>{reef.PoolInstanceTemplate}</DataTableRowColumn>
                    </DataTableRow>
                </DataTableBody>
            </DataTable>
            <FormSectionHeading title="Overprovisioning"/>
            <DataTable>
                <DataTableBody>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Replicas</DataTableRowHeaderColumn>
                        <DataTableRowColumn>{reef.OverprovisioningReplicas}</DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Request Memory</DataTableRowHeaderColumn>
                        <DataTableRowColumn>{reef.OverprovisioningMemoryRequest}</DataTableRowColumn>
                    </DataTableRow>
                </DataTableBody>
            </DataTable>
            <FormSectionHeading title="Dynamic Workers"/>
            <DataTable>
                <DataTableBody>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Dynamic Worker Service</DataTableRowHeaderColumn>
                        <DataTableRowWithActionColumn action={
                            <OpenDialogButton label={"Change Dynamic Worker Service"}>
                                <ChangeDynamicWorkerServiceDialog reefId={reef.Id}
                                    dynamicWorkerServices={this.state.dynamicWorkerServices}
                                    dynamicWorkerServiceId={reef.DynamicWorkerServiceId}
                                    onChange={this.props.updateReef}/>
                            </OpenDialogButton>}>
                            {this.state.dynamicWorkerServices.find(service => service.Id === reef.DynamicWorkerServiceId).Name}
                        </DataTableRowWithActionColumn>
                    </DataTableRow>
                </DataTableBody>
            </DataTable>
            <FormSectionHeading title="Hub"/>
            <DataTable>
                <DataTableBody>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Hub</DataTableRowHeaderColumn>
                        <DataTableRowWithActionColumn action={
                            <OpenDialogButton label={"Change Hub"}>
                                <ChangeHubDialog reefId={reef.Id}
                                                 onChange={this.changeHub} 
                                                 hubId={reef.HubId} />
                            </OpenDialogButton>}>
                            <InternalLink to={routeLinks.hubs.hub(reef.HubId).root}>{hub?.DisplayName ?? reef.HubId}</InternalLink>
                            {hub && <>&nbsp;({this.getHubLink(hub)})</>}
                        </DataTableRowWithActionColumn>
                    </DataTableRow>
                </DataTableBody>
            </DataTable>
        </> : <></>;
    }

    private getHubLink = (hub: HubResource) => {
        if (hub.HubInstance) {
            const instance = hub.HubInstance;
            const reef = this.state.reefs.get(instance.ReefId);
            return linkToInstance(instance, reef);
        }

        return <ExternalLink href={hub.HubUrl}>{hub.HubUrl}</ExternalLink>;
    }

    private renderDeleteDialog = () => {
        return <>
            <DeleteConfirmation itemName={this.props.reef ? this.props.reef.DisplayName : ""} deleteWhat="reef"
                                onChange={canDelete => this.setState({canDelete})}/>

            {this.state.environment !== "Production" && <Checkbox label="Delete orphan resources"
                      value={this.state.deleteOrphanResources}
                      onChange={deleteOrphanResource => this.setState({deleteOrphanResources: deleteOrphanResource})}
                      note={"Select this option if you want to delete orphan resources. A resource becomes an orphan when an associated with it instance gets deleted from the database. This should/can only happen" +
                      " during local dev or during an E2E test run. This should NEVER happen in Production and this option should NEVER be used there."}
                      error={this.getFieldError("DeleteOrphanResources")}
            />}
        </>;
    };
    
    private updateStorageAccount = (storageAccount: StorageAccountResource): void => {
        const updatedStorageAccounts = [...this.props.reef.StorageAccounts];

        const accountIndex = this.props.reef.StorageAccounts.findIndex(a => a.Name == storageAccount.Name);
        updatedStorageAccounts.splice(accountIndex, 1, storageAccount);

        this.props.updateReef({StorageAccounts: updatedStorageAccounts})
    }

    private deleteReef = async (): Promise<boolean> => {
        await repository.Reefs.remove(this.props.reef, this.state.deleteOrphanResources);
        this.setState({redirectTo: reefsRouteLinks.root});

        return true;
    };

    private reprovision = (): Promise<boolean> => {
        return this.doBusyTask(async () => {
            await repository.Reefs.reprovision(this.props.reef);
            this.setState({redirectTo: reefsRouteLinks.reef(this.props.reef.Id).tasks});

            return true;
        });
    }

    private addReefStorageAccount = (): Promise<boolean> => {
        return this.doBusyTask(async () => {
            await repository.Reefs.createStorageAccount(this.props.reef.Id, this.state.CreateStorageAccount);
            this.setState({redirectTo: reefsRouteLinks.reef(this.props.reef.Id).tasks});

            return true;
        });
    }

    private deleteStorageAccount = async (storageAccountName: string): Promise<boolean> => {
        await repository.Reefs.deleteStorageAccount(this.props.reef.Id, storageAccountName, this.state.deleteOrphanResources);
        this.setState({redirectTo: reefsRouteLinks.reef(this.props.reef.Id).tasks});

        return true;
    };

    private deleteKubernetesCluster = async (kubernetesClusterName: string): Promise<boolean> => {
        await repository.Reefs.deleteKubernetesCluster(this.props.reef.Id, kubernetesClusterName, this.state.deleteOrphanResources);
        this.setState({redirectTo: reefsRouteLinks.reef(this.props.reef.Id).tasks});

        return true;
    };

    private cloneReef = (): Promise<boolean> => {
        return this.doBusyTask(async () => {
            const clone = await repository.Reefs.clone(this.props.reef, this.state.cloneReefRequest as CloneReefRequest);
            this.setState({redirectTo: reefsRouteLinks.reef(clone.Id).root});

            return true;
        });
    };

    private updateCloneReefRequest(update: Partial<CloneReefRequest>) {
        return this.setState(prevState => ({cloneReefRequest: {...prevState.cloneReefRequest, ...update}}));
    }

    private grantAccessToEngineeringTeam = async (): Promise<boolean> => {
        return this.doBusyTask(async () => {
            await repository.Reefs.grantAccessToAllEngineers(this.props.reef);
            this.setState({redirectTo: reefsRouteLinks.reef(this.props.reef.Id).tasks});
        });
    }

    private changeHub = async (): Promise<boolean> => {
        return this.doBusyTask(async () => {          
            this.setState({redirectTo: reefsRouteLinks.reef(this.props.reef.Id).tasks});
        });
    }

    renderReprovisionDialog() {
        return <SaveDialogLayout title="Reprovision Reef"
                                 saveButtonLabel="Reprovision"
                                 busy={this.state.busy}
                                 errors={this.state.errors}
                                 onSaveClick={this.reprovision}>
                  <p> Reprovision the reef without any changes? </p>
                </SaveDialogLayout>;
    }

    renderAddKubernetesClusterDialog() {
        return <CreateKubernetesClusterDialog
            existingReef={this.props.reef}
            afterCreate={() => this.setState({redirectTo: reefsRouteLinks.reef(this.props.reef.Id).tasks})} />;
    }

    renderUpgradeKubernetesClustersDialog() {
        return <UpgradeKubernetesClustersDialog
            reef={this.props.reef}
            afterUpgrade={() => this.setState({redirectTo: reefsRouteLinks.reef(this.props.reef.Id).tasks})} />;
    }

    renderDeleteStorageAccountDialog = (storageAccountName: string) => {
        return <>
            <DeleteConfirmation itemName={storageAccountName}
                                deleteWhat="Storage Account"
                                onChange={canDelete => this.setState({canDelete})} />

            {this.state.environment !== "Production" && <Checkbox label="Delete orphan resources"
                      value={this.state.deleteOrphanResources}
                      onChange={deleteOrphanResource => this.setState({deleteOrphanResources: deleteOrphanResource})}
                      note={"Select this option if you want to skip the step to ensure that there are no file shares on this Storage Account. This should only be used during local dev or during an" +
                      " E2E test run. This should NEVER be used in production."}
                      error={this.getFieldError("DeleteOrphanResources")}
            />}
        </>;
    };

    renderDeleteKubernetesClusterDialog = (kubernetesClusterName: string) => {
        return <>
            <DeleteConfirmation itemName={kubernetesClusterName}
                                deleteWhat="Kubernetes Cluster"
                                onChange={canDelete => this.setState({canDelete})} />

            {this.state.environment !== "Production" && <Checkbox label="Delete orphan resources"
                      value={this.state.deleteOrphanResources}
                      onChange={deleteOrphanResource => this.setState({deleteOrphanResources: deleteOrphanResource})}
                      note={"Select this option if you want to skip the step to ensure that there are no Hosted Instances running on this Kubernetes Cluster. This should only be used during local dev or during an" +
                      " E2E test run. This should NEVER be used in production."}
                      error={this.getFieldError("DeleteOrphanResources")}
            />}
        </>;
    };

    renderAddStorageAccountDialog() {
        return <SaveDialogLayout title="Add new Storage Account"
                                 saveButtonLabel="Add"
                                 busy={this.state.busy}
                                 errors={this.state.errors}
                                 onSaveClick={this.addReefStorageAccount}>
                    <p> Create a new Azure Storage Account in this reef? </p>

            {this.state.environment !== "Production" && <Checkbox label="Create Premium Storage Account"
                      value={this.state.CreateStorageAccount.CreatePremiumStorageAccount}
                      onChange={CreatePremiumStorageAccount => this.setState(prevState => ({CreateStorageAccount: {...prevState.CreateStorageAccount, CreatePremiumStorageAccount: CreatePremiumStorageAccount}}))}
                      error={this.getFieldError("Create Premium Storage Account")}                      
            />}
            {this.state.environment !== "Production" && <Callout type={CalloutType.Warning} title="Do not create Premium Storage Accounts in Production!" />}                
            
        </SaveDialogLayout>;
    }

    private renderCloneReefDialog() {
        return <SaveDialogLayout title={"Clone"}
                                 busy={this.state.busy}
                                 errors={this.state.errors}
                                 onSaveClick={this.cloneReef}>

            <Callout type={CalloutType.Warning} title="!!! Do not use in Production !!!">
                Use cloning only during <strong>testing</strong> where reefs don't have to be fully isolated
                and it is ok for them to share secrets.
            </Callout>

            <Text label="Name"
                  hintText="This name will be used as the name of all major resources"
                  value={this.state.cloneReefRequest.Name}
                  onChange={Name => this.updateCloneReefRequest({Name})}
                  autoFocus={true}
            />

            <Text label="VNet Address Space"
                  hintText="CIDR notation, with a /17 prefix length"
                  value={this.state.cloneReefRequest.AddressSpace}
                  onChange={AddressSpace => this.updateCloneReefRequest({AddressSpace})}
                  autoFocus={true}
            />

            <BooleanRadioButtonGroup value={this.state.cloneReefRequest.ProvisionResources} onChange={ProvisionResources => this.updateCloneReefRequest({ProvisionResources})}>
                <BooleanRadioButton value={true} label="Provision Resources" />
                <BooleanRadioButton value={false} label="Adopt Existing Resources" />
            </BooleanRadioButtonGroup>

        </SaveDialogLayout>;
    }

    private grantAccessToEngineeringTeamDialog() {
        return <SaveDialogLayout title={"Grant Access To Engineering Team"}
                                 busy={this.state.busy}
                                 errors={this.state.errors}
                                 saveButtonLabel={"Yes"}
                                 cancelButtonLabel={"No"}
                                 onSaveClick={() => this.grantAccessToEngineeringTeam()}>
            <Callout type={CalloutType.Warning} title="Warning">
            <p>Are you sure you want to grant members of the <ExternalLink href={"https://portal.azure.com/#view/Microsoft_AAD_IAM/GroupDetailsMenuBlade/~/Overview/groupId/e0a4a590-800a-4b36-b0ae-ccc263575df2"}>Department: Engineering</ExternalLink> group <strong>Contributor</strong> access to this Reef in Azure?</p>
            <p>This will also allow all engineers to access the Reef's AKS clusters via <ExternalLink href={"https://octopushq.atlassian.net/wiki/spaces/SO/pages/2437218335/Connect+to+a+Resource+in+strongDM#strongDM-Slackbot-(Beta)"}>strongDM Slackbot</ExternalLink></p>
            </Callout>
        </SaveDialogLayout>;
    }
}

export default ReefDetail;
