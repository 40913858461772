// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_formExpander__PaYoP,
.style_formNonExpander__YSKPu {
  width: 100%;
  box-shadow: none!important;
  border-radius: 0!important;
  border-width: 0!important;
  margin: 0 0 -0.0625rem 0;
  background-color: inherit !important;
}
.style_formExpander__PaYoP > div:first-child,
.style_formNonExpander__YSKPu > div:first-child {
  padding: 0!important;
}
.style_formExpander__PaYoP > div:first-child > div:first-child,
.style_formNonExpander__YSKPu > div:first-child > div:first-child {
  padding: 0!important;
}
.style_formExpander__PaYoP > div:first-child > div:first-child {
  cursor: pointer;
}
.style_cardMedia__ynicU {
  margin: 0;
  padding: 0;
}
`, "",{"version":3,"sources":["webpack://./app/components/SimpleExpander/style.less"],"names":[],"mappings":"AAAA;;EACI,WAAA;EACA,0BAAA;EACA,0BAAA;EACA,yBAAA;EACA,wBAAA;EACA,oCAAA;AAEJ;AARA;;EAUQ,oBAAA;AAER;AAZA;;EAYY,oBAAA;AAIZ;AACA;EAGY,eAAA;AADZ;AAMA;EACI,SAAA;EACA,UAAA;AAJJ","sourcesContent":[".formExpander, .formNonExpander {\n    width: 100%;\n    box-shadow: none!important; //swap the box-shadow of the material-ui control for a border\n    border-radius: 0!important;\n    border-width: 0!important;\n    margin: 0 0 -0.0625rem 0; //so we don't get double borders with consecutive expanders\n    background-color: inherit !important; // The card provides its own background color, which could be different from whatever it is embedded in\n\n    // mark.siedle - Oh God, these are hidden away inside the Card from material-ui #plsKillMe\n    > div:first-child {\n        padding: 0!important;\n        > div:first-child {\n            padding: 0!important;\n        }\n    }\n}\n\n.formExpander {\n    > div:first-child {\n        > div:first-child {\n            cursor: pointer;\n        }\n    }\n}\n\n.cardMedia {\n    margin: 0;\n    padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formExpander": `style_formExpander__PaYoP`,
	"formNonExpander": `style_formNonExpander__YSKPu`,
	"cardMedia": `style_cardMedia__ynicU`
};
module.exports = ___CSS_LOADER_EXPORT___;
