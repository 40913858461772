import React from "react";
import {BooleanRadioButton, BooleanRadioButtonGroup, RadioButton, RadioButtonGroup} from "@octopusdeploy/design-system-components";
import {DataBaseComponent, DataBaseComponentState, Errors} from "components/DataBaseComponent";
import repository from "client/repository";
import {BusyState} from "components/BusyFromPromise/BusyFromPromise";
import {HostedInstanceResource} from "client/resources/hostedInstanceResource";
import SaveDialogLayout from "components/DialogLayout/SaveDialogLayout";
import {HostedInstanceConfigureReplicasRequest} from "../../../client/resources/HostedInstanceConfigureReplicasRequest";
import {Number} from "../../../shared/Number";
import {ZoneDiversityType} from "../../../client/resources/ZoneDiversityType";
import {Callout, CalloutType} from "../../../components/Callout";

interface ConfigureInstanceReplicasDialogProps {
    errors: Errors;
    busy: BusyState;
    instance: HostedInstanceResource;
    onSaveClick(): Promise<boolean>;
}

interface ConfigureInstanceReplicasDialogState extends DataBaseComponentState {
    replicaRequest: Partial<HostedInstanceConfigureReplicasRequest>;
}

export class ConfigureInstanceReplicasDialog extends DataBaseComponent<ConfigureInstanceReplicasDialogProps, ConfigureInstanceReplicasDialogState> {
    constructor(props: ConfigureInstanceReplicasDialogProps) {
        super(props);
        this.state = {
            replicaRequest: {
                ReplicaCount: this.props.instance.Replicas,
                ZoneDiversityType: this.props.instance.ZoneDiversityType,
                ReprovisionDuringMaintenanceWindow: true
            }
        };
    }

    render() {
        const request = this.state.replicaRequest;
        return <SaveDialogLayout title={"Change instance replicas"}
                                 busy={this.state.busy}
                                 errors={this.state.errors}
                                 saveButtonDisabled={this.replicasOrZoneAvailabilityAreUnchanged()}
                                 onSaveClick={() => this.setReplicas(request)}>
            
            <Number label="Replicas"
                  value={request.ReplicaCount}
                  onChange={ReplicaCount => this.updateReplicaRequest({ReplicaCount})}
            />

            <Callout type={CalloutType.Information} title={"Zone Diversity"}>
                <p>
                    If you set zone diversity to relaxed, there's no guarantee that the replicas for this instance will run in different availability zones (AZs).
                    <br/>
                    If you set the zone diversity to forced, replicas are forced to run in different AZs. This means that you can only run as many replicas as AZs available on a cluster.
                    <br/>
                    The request is validated to ensure the replicas are not accidentally increased above the number of AZs available on a cluster, however If there is an AZ unavailable due to an Azure issue, this may cause the instance to fail to provision.
                </p>
            </Callout>
            
            <RadioButtonGroup value={this.state.replicaRequest.ZoneDiversityType}
                              onChange={ZoneDiversityType => this.updateReplicaRequest({ZoneDiversityType})}>
                <RadioButton value={ZoneDiversityType.Relaxed} label="Relaxed Zone Diversity"/>
                <RadioButton value={ZoneDiversityType.Forced} label="Forced Zone Diversity"/>
            </RadioButtonGroup>

            <BooleanRadioButtonGroup value={this.state.replicaRequest.ReprovisionDuringMaintenanceWindow}
                              onChange={ReprovisionDuringMaintenanceWindow => this.updateReplicaRequest({ReprovisionDuringMaintenanceWindow})}>
                <BooleanRadioButton value={true} label="Apply During Outage Window"/>
                <BooleanRadioButton value={false} label="Apply Immediately"/>
            </BooleanRadioButtonGroup>
            
        </SaveDialogLayout>;
    }

    private replicasOrZoneAvailabilityAreUnchanged() {
        return this.state.replicaRequest.ReplicaCount == this.props.instance.Replicas 
            && this.state.replicaRequest.ZoneDiversityType == this.props.instance.ZoneDiversityType;
    }

    private updateReplicaRequest(update: Partial<HostedInstanceConfigureReplicasRequest>) {
        return this.setState(prevState => ({replicaRequest: {...prevState.replicaRequest, ...update}}));
    }

    private setReplicas(request: Partial<HostedInstanceConfigureReplicasRequest>) {
        return this.doBusyTask(async () => {
            await repository.HostedInstances.configureReplicas(this.props.instance, request as HostedInstanceConfigureReplicasRequest);
            await this.props.onSaveClick();
            return true;
        });
    }
}
