import {Checkbox} from "@octopusdeploy/design-system-components";
import {DataBaseComponent, DataBaseComponentState, Errors} from "../../components/DataBaseComponent";
import repository from "../../client/repository";
import SaveDialogLayout from "../../components/DialogLayout/SaveDialogLayout";
import Callout, {CalloutType} from "../../components/Callout";
import Select from "../../components/form/Select/Select";
import * as React from "react";
import {BusyState} from "../../components/BusyFromPromise/BusyFromPromise";
import {KubernetesClusterNodeResource} from "../../client/resources/KubernetesClusterNodeResource";

interface DecommissionNodeDialogProps {
    errors: Errors;
    busy: BusyState;
    reefId: string;
    clusterName: string;
    onSave: (taskId: string | null) => void;
}

interface DecommissionNodeDialogState extends DataBaseComponentState {
    selectedNode?: string | null;
    nodes: KubernetesClusterNodeResource[] | null;
    runNow: boolean;
    runOnWeekend: boolean;
    taskId?: string;
}

class DecommissionNodeDialog extends DataBaseComponent<DecommissionNodeDialogProps, DecommissionNodeDialogState> {
    constructor(props: DecommissionNodeDialogProps) {
        super(props);
        this.state = {
            selectedNode: null,
            runNow: false,
            runOnWeekend: false,
            nodes: null
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            this.setState({nodes: await repository.Reefs.getNodes(this.props.reefId, this.props.clusterName)});
        });
    }

    render() {
        return <SaveDialogLayout title={"Decommission AKS Node"}
                                 busy={this.state.nodes == null || this.props.busy}
                                 errors={this.props.errors}
                                 saveButtonDisabled={this.state.selectedNode == null}
                                 saveButtonLabel="Decommission"
                                 busyButtonLabel="Decommissioning..."
                                 onSaveClick={this.save}>
            <Callout type={CalloutType.Warning} title="Warning">
                Checking <b>Run immediately</b> will immediately kill the machine and cause an outage to the instances
                currently running on the node.
                <br/>
                Leave this unchecked to attempt to move instances to running nodes before removing it.
                <br/>
                <br/>
                Checking <b>Run on weekend</b> will allow instance moves to attempt to run over the weekend.
            </Callout>
            {this.state.nodes && <Select value={this.state.selectedNode}
                                         onChange={(selection) => this.setState({selectedNode: selection})}
                                         items={this.state.nodes.map((node) => ({
                                             text: `${node.Name}`,
                                             value: node.Name
                                         }))}
                                         fieldName="the AKS node to decommission"
            />}
            <Checkbox value={this.state.runOnWeekend} onChange={v => this.setState({runOnWeekend: v})} label={"Run on weekend"}/>
            <Checkbox value={this.state.runNow} onChange={v => this.setState({runNow: v})} label={"Run immediately"}/>
            
        </SaveDialogLayout>;
    }

    private save = async () => {
        await this.doBusyTask(async () => {
            const node = this.state.nodes.find(n => n.Name === this.state.selectedNode);
            const taskId = await repository.Reefs.deleteNode(this.props.reefId, node.ClusterName, node.Name, {RunNow: this.state.runNow, RunOnWeekend: this.state.runOnWeekend});
            this.setState({taskId})
        });

        this.props.onSave(this.state.taskId);

        return true;
    }
}

export default DecommissionNodeDialog;