import * as React from "react";
import SaveDialogLayout from "components/DialogLayout/SaveDialogLayout";
import repository from "client/repository";
import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent";
import {ReefResource} from "client/resources/reefResource";
import Select from "components/form/Select/Select";
import {DynamicWorkerServiceResource} from "client/resources/dynamicWorkerServiceResource";

interface Props {
    reefId: string;
    dynamicWorkerServiceId: string;
    dynamicWorkerServices: DynamicWorkerServiceResource[];
    onChange: (reef: Partial<ReefResource>) => void;
}

interface State extends DataBaseComponentState {
    dynamicWorkerServiceIdToChange: string;
}

class ChangeDynamicWorkerServiceDialog extends DataBaseComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            dynamicWorkerServiceIdToChange: props.dynamicWorkerServiceId
        };
    }

    render() {
        return <SaveDialogLayout title={"Dynamic Worker Service"}
            onSaveClick={() => this.changeDynamicWorkerServiceId()}
            errors={this.state.errors}
            saveButtonDisabled={!this.state.dynamicWorkerServiceIdToChange || this.state.dynamicWorkerServiceIdToChange === this.props.dynamicWorkerServiceId}
            busy={this.state.busy}>

            <Select value={this.state.dynamicWorkerServiceIdToChange}
                    onChange={dynamicWorkerServiceId => this.setState({dynamicWorkerServiceIdToChange: dynamicWorkerServiceId})}
                    items={this.props.dynamicWorkerServices.map(s => ({text: s.Name, value: s.Id}))}
                    fieldName="DynamicWorkerServiceId"
                    hintText="The Azure reef that the reef is to be created in"
            />
        </SaveDialogLayout>;
    }

    private async changeDynamicWorkerServiceId(): Promise<boolean> {
        return await this.doBusyTask(async () => {
            const dynamicWorkServiceIdToChange: string = this.state.dynamicWorkerServiceIdToChange;
            await repository.Reefs.changeDynamicWorkerServiceId(this.props.reefId, {DynamicWorkerServiceId: dynamicWorkServiceIdToChange});
            this.props.onChange({DynamicWorkerServiceId: dynamicWorkServiceIdToChange});
        });
    }
}

export default ChangeDynamicWorkerServiceDialog;