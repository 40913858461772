import * as React from "react";
import {sortBy} from "lodash";
import PaperLayout from "components/PaperLayout/PaperLayout";
import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent";
import repository from "client/repository";
import {FeatureToggle, FeatureToggleScope} from "client/resources/featureFlagsResource";
import FeatureFlagsDataTable from "./FeatureFlagsDataTable";
import {HostedInstanceLookupResource} from "../../client/resources/hostedInstanceLookupResource";
import {ReefResource} from "../../client/resources/reefResource";

interface State extends DataBaseComponentState {
    featureFlags?: FeatureToggle[];
    instances?: HostedInstanceLookupResource[];
    reefs?: ReefResource[];
    newFeatureFlagName?: string;
}

class FeatureFlags extends DataBaseComponent<{}, State> {
    constructor(props: {}) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.loadData();
    }

    private async loadData() {
        return this.doBusyTask(async () => {
            const featureFlags = await repository.Configuration.getFeatureFlags();
            const reefs = await repository.Reefs.list();
            const instances = await repository.HostedInstances.lookup();
            
            this.setState({
                featureFlags: sortBy(featureFlags.Features, f => f.Name.toLowerCase()),
                reefs: reefs,
                instances: instances
            });
        });
    }

    private editFeatureFlag = (featureFlag: FeatureToggle): Promise<any> => {
        return this.doBusyTask(async () => {
            await repository.Configuration.editFeatureFlag(featureFlag.Id, {Enabled: featureFlag.IsEnabled});
            await this.loadData();
        });
    }
    
    private editFeatureFlagScope = (id: string, scope: FeatureToggleScope): Promise<any> => {
        return this.doBusyTask(async () => {
            await repository.Configuration.editFeatureFlagScope(id, scope);
            await this.loadData();
        });
    }
    
    render() {
        return <PaperLayout title="Portal Feature Flags"
                            busy={this.state.busy}
                            errors={this.state.errors}
                            fullWidth={false}>
            {this.state.featureFlags &&
            <FeatureFlagsDataTable
                featureFlags={this.state.featureFlags}
                editFeatureFlag={this.editFeatureFlag}
                editFeatureFlagScope={this.editFeatureFlagScope}
                instances={this.state.instances}
                reefs={this.state.reefs}
            />}
        </PaperLayout>;
    }
}

export default FeatureFlags;