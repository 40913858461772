export interface HostedInstanceSecretRotationHistoryResource {
	BackgroundTaskId: string;
	RotationType: string;
	Status: HostedInstanceSecretRotationStatus;
	Created: string;
	Finished?: string;
}

export enum HostedInstanceSecretRotationStatus {
	Pending = "Pending",
	Failed = "Failed",
	Cancelled = "Cancelled",
	Success = "Success"
}