import React from "react";
import {DataBaseComponent, DataBaseComponentState, Errors} from "../../../components/DataBaseComponent";
import SaveDialogLayout from "../../../components/DialogLayout/SaveDialogLayout";
import {Text} from "components/form";
import repository from "../../../client/repository";
import {DiagnosticFileResource} from "../../../client/resources/diagnosticFileResource";

interface DownloadDiagnosticFileDialogProps {
    instanceId: string;
    file: DiagnosticFileResource;
    onSaveClick(url: string): Promise<boolean>;
}

interface DownloadDiagnosticFileDialogState extends DataBaseComponentState {
    Justification: string;
}

export class DownloadDiagnosticFile extends DataBaseComponent<DownloadDiagnosticFileDialogProps, DownloadDiagnosticFileDialogState> {
    constructor(props: DownloadDiagnosticFileDialogProps) {
        super(props);
        this.state = {
            Justification: ""
        }
    }

    render() {
        return <SaveDialogLayout title={"Download Diagnostic File"}
                                 busy={this.state.busy}
                                 errors={this.state.errors}
                                 saveButtonLabel={"Download"}
                                 cancelButtonLabel={"Cancel"}
                                 onSaveClick={() => this.props.onSaveClick(repository.HostedInstances.getUrlToDiagnosticFileContent(this.props.instanceId, this.props.file, this.state.Justification))}>
            
            <Text label="Justification"
                  hintText="Link to email, support ticket, explanation of issue, etc."
                  value={this.state.Justification}
                  onChange={Justification => this.updateJustification(Justification)}
            />
            
        </SaveDialogLayout>;
    }

    private updateJustification(update: string) {
        return this.setState(_ => ({Justification: update}));
    }
}
