import type { NavigationBarActionData, NavigationBarItemData } from "@octopusdeploy/design-system-components";
import { NavigationBar } from "@octopusdeploy/design-system-components";
import * as React from "react";
import routeLinks from "routeLinks";
import styles = require("./style.less");
import {RouteComponentProps, withRouter} from "react-router-dom";
import CurrentUserMenu from "areas/root/Navbar/CurrentUserMenu";
import AppVersion from "areas/root/Navbar/AppVersion";
import {InstanceStatus} from "client/resources/instanceStatus";
import Environment from "areas/root/Navbar/Environment";
import repository from "client/repository";
import {AppInfoResource} from "client/resources/appInfoResource";
import Logs from "./Logs";
import Help from "./Help";
import { css } from "@emotion/css";
import { colorScales } from "@octopusdeploy/design-system-tokens";

type NavbarComponentProps = RouteComponentProps<void>;

type Props = NavbarComponentProps;

interface State {
    appInfo?: AppInfoResource;
}

class Navbar extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    async componentDidMount() {
        const appInfo = await repository.Configuration.getAppInfo();

        this.setState({appInfo});
    }

    render() {
        const navigationItems = this.getNavigationItems();
        const navigationActions = this.getNavigationActions();
    
        // The topnavbar id is used by E2E UI tests
        const environment = this.state.appInfo?.Environment;
        return <div>
            {environment && <Environment name={environment}/>}
            <div id="topnavbar" className={styles.topnavbar}>
                <div className={navbarStyles}>
                    <NavigationBar items={navigationItems} actions={navigationActions} />
                </div>
            </div>
        </div>;
    }

    private getNavigationItems() {
        const navigationItems: NavigationBarItemData[] = [
            {type: "link", href: routeLinks.root, label: "Dashboard", showLinkAsActive: "if path matches exactly"},
            {type: "link", href: routeLinks.instances.filtered(this.getActiveStatuses()), label: "Instances", showLinkAsActive: "if path partially matches"},
            {type: "link", href: routeLinks.backgroundTasks.root, label: "Tasks", showLinkAsActive: "if path partially matches"},
            {type: "link", href: routeLinks.reefs.root, label: "Reefs", showLinkAsActive: "if path partially matches"},
            {type: "link", href: routeLinks.dynamicWorkerServices.root, label: "Workers", showLinkAsActive: "if path partially matches"},
            {type: "link", href: routeLinks.settings.root, label: "Settings", showLinkAsActive: "if path partially matches"},
            {type: "link", href: routeLinks.azureRegions.root, label: "Azure Regions", showLinkAsActive: "if path partially matches"},
            {type: "link", href: routeLinks.hubs.root, label: "Hubs", showLinkAsActive: "if path partially matches"},
            {type: "link", href: routeLinks.instanceTaskLocks.root, label: "Locks", showLinkAsActive: "if path partially matches"},
            {type: "link", href: routeLinks.messageBus.root, label: "Message Bus", showLinkAsActive: "if path partially matches"},
            {type: "link", href: routeLinks.audit.root, label: "Audit", showLinkAsActive: "if path partially matches"},
        ];
        return navigationItems;
    }

    private getNavigationActions() {
        const navigationActions: NavigationBarActionData[] = [
            {key: "help", content: <Help/>}
        ]
        if (this.state.appInfo?.Environment) {
            navigationActions.push({key: "logs", content: <Logs environment={this.state.appInfo?.Environment}/>});
        }
        if (this.state.appInfo) {
            navigationActions.push({key: "app-version", content: <AppVersion version={this.state.appInfo.InformationalVersion}/>});
        }
        navigationActions.push({key: "user-menu", content: <CurrentUserMenu/>});
        return navigationActions
    }

    private getActiveStatuses() {
        return {
            isInPool: false,
            status: Object.entries(InstanceStatus)
                .map(values => values[0] as InstanceStatus)
                .filter(instanceStatus => instanceStatus !== InstanceStatus.Deleted && instanceStatus !== InstanceStatus.Deactivated)
        };
    }
}

const navbarStyles = css({
    display: "flex",
    alignItems: "center",
    borderBottom: `1px solid ${colorScales.grey[600]}`,
    whiteSpace: "nowrap",
    "& a:hover": {
        color: "inherit",
    },
})

// Unfortunately this component needs to be connected to the router purely so that it updates when the route changes.
// Otherwise, the connect() breaks all route-related updates.  This might be fixed in React 16.3 which should introduce the new context API
export default withRouter<NavbarComponentProps>(Navbar);