import * as React from "react";
import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent/DataBaseComponent";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import {createStaticRanges, DateRangePicker as DateRangePickerInner, RangeKeyDict} from "react-date-range";
import moment from "moment";
import SaveDialogLayout from "components/DialogLayout/SaveDialogLayout";

export interface DateRange {
    startDate: moment.Moment;
    endDate: moment.Moment;
}

interface DateRangePickerProps {
    startDate: moment.Moment;
    endDate: moment.Moment;

    onChange?(range: DateRange): void;

    // Save handler (eg. for when used in dialogs).
    onSaveDone?(range: DateRange): void;
}

interface DateRangePickerState extends DataBaseComponentState {
    range: DateRange;
}

export default class DateRangePicker extends DataBaseComponent<DateRangePickerProps, DateRangePickerState> {

    constructor(props: DateRangePickerProps) {
        super(props);
        this.state = {
            range: {
                startDate: this.props.startDate.clone(),
                endDate: this.props.endDate.clone(),
            }
        };
    }

    render() {
        const startOfToday = moment().startOf("day");
        const endOfToday = moment().endOf("day");
        const staticRanges = createStaticRanges([
            {label: "Today", range: () => ({startDate: startOfToday.toDate(), endDate: endOfToday.toDate()})},
            {label: "Yesterday", range: () => ({startDate: startOfToday.clone().subtract(1, "days").toDate(), endDate: endOfToday.clone().subtract(1, "days").toDate()})},
            {label: "Last 7 days", range: () => ({startDate: startOfToday.clone().subtract(7, "days").toDate(), endDate: endOfToday.toDate()})},
            {label: "Last 30 days", range: () => ({startDate: startOfToday.clone().subtract(30, "days").toDate(), endDate: endOfToday.toDate()})},
            {label: "Last 90 days", range: () => ({startDate: startOfToday.clone().subtract(90, "days").toDate(), endDate: endOfToday.toDate()})}
        ]);
        return <SaveDialogLayout title="Date Range"
                                 busy={this.state.busy}
                                 errors={this.state.errors}
                                 onSaveClick={() => this.save()}>
            <DateRangePickerInner
                onChange={this.handleSelect}
                ranges={[{ startDate: this.state.range.startDate.toDate(), endDate: this.state.range.endDate.toDate(), key: "selection" }]}
                staticRanges={staticRanges}
                inputRanges={[]}
                months={2}
                direction="horizontal"
            />
        </SaveDialogLayout>;
    }

    private async save() {
        if (this.props.onSaveDone) {
            this.props.onSaveDone(this.state.range);
        }
        return true;
    }

    private handleSelect = (rangesByKey: RangeKeyDict) => {
        const selectedRange = rangesByKey.selection;
        const range = {
            startDate: moment(selectedRange.startDate).startOf("day"),
            endDate: moment(selectedRange.endDate).endOf("day")
        };

        this.setState({range});
        if (this.props.onChange) {
            this.props.onChange(range);
        }
    }
}