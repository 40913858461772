// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* This file is DEPRECATED
   We are moving to the Octopus Design System
   https://www.octopus.design
  
   When changing component styles:
   1. Do not use variables from this file
   2. Aim to use Emotion \`css\` + themeTokens.colors
       - see Dashboard.tsx for example
   3. If you need to use Less, reference CSS variables
       - see index.ejs for example
       - for full list see https://github.com/OctopusDeploy/OctopusDeploy/blob/main/frontend/packages/design-system-tokens/src/generated/themeTokens.ts
   4. Remove variables from this file when they are no longer used
 */
.style_materialUIIcon__KswOW {
  width: 1.5rem !important;
  height: 1.5rem !important;
  min-width: 1.5rem !important;
  line-height: 1.5rem !important;
  padding: 0 !important;
  opacity: 0.7;
}
.style_materialUIIcon__KswOW span {
  padding: 0rem !important;
}
.style_materialUIIcon__KswOW:hover {
  background-color: var(--colorBackgroundPrimaryDefault) !important;
}
.style_materialUIIcon__KswOW:hover {
  opacity: 1;
}
.style_imageIcon__N34Tf {
  width: 1.5rem;
  height: 1.5rem;
  opacity: 0.7;
}
.style_imageIcon__N34Tf:hover {
  opacity: 1;
}
.style_fontAwesomeIcon__ubqd6 {
  opacity: 0.7;
}
.style_fontAwesomeIcon__ubqd6:hover {
  opacity: 1;
}
`, "",{"version":3,"sources":["webpack://./app/components/IconButton/style.less"],"names":[],"mappings":"AAAA;;;;;;;;;;;;EAYE;AAHF;EACI,wBAAA;EACA,yBAAA;EACA,4BAAA;EACA,8BAAA;EACA,qBAAA;EAXA,YAAA;AAiBJ;AAXA;EAOQ,wBAAA;AAOR;AAJI;EACI,iEAAA;AAMR;AAtBI;EACI,UAAA;AAwBR;AAHA;EACI,aAAA;EACA,cAAA;EAzBA,YAAA;AA+BJ;AA9BI;EACI,UAAA;AAgCR;AAJA;EA9BI,YAAA;AAqCJ;AApCI;EACI,UAAA;AAsCR","sourcesContent":["@import \"../../resources/styles/colors\";\n\n.reduceVisibility() {\n    opacity: 0.7;\n    &:hover {\n        opacity: 1;\n    }\n}\n\n.materialUIIcon {\n    width: 24/16rem !important;\n    height: 24/16rem !important;\n    min-width: 24/16rem !important;\n    line-height: 24/16rem !important;\n    padding: 0 !important;\n    span {\n        padding: 0rem !important;\n    }\n\n    &:hover {\n        background-color: @white !important;\n    }\n\n    .reduceVisibility();\n}\n\n.imageIcon {\n    width: 24/16rem;\n    height: 24/16rem;\n\n    .reduceVisibility();\n}\n\n.fontAwesomeIcon {\n    .reduceVisibility();\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"materialUIIcon": `style_materialUIIcon__KswOW`,
	"materialUiIcon": `style_materialUIIcon__KswOW`,
	"imageIcon": `style_imageIcon__N34Tf`,
	"fontAwesomeIcon": `style_fontAwesomeIcon__ubqd6`
};
module.exports = ___CSS_LOADER_EXPORT___;
