import * as React from "react";
import {FormFieldProps} from "components/form";
import MultiSelect from "components/MultiSelect/MultiSelect";
import {SelectItem} from "components/VirtualListWithKeyboard/SelectItem";
import DefaultChip from "shared/DefaultChip";
import {DynamicWorkerTaskCapValues} from "./dynamicWorkerTaskCapValues";

interface DynamicWorkerTaskCapMultiSelectProps extends FormFieldProps<string[]> {
    autoFocus?: boolean;
    label?: string | JSX.Element;
    error?: string;
}

const DynamicWorkerTaskCapTypedMultiSelect = MultiSelect<SelectItem>();

const DynamicWorkerTaskCapMultiSelect: React.StatelessComponent<DynamicWorkerTaskCapMultiSelectProps> = props => {
    const items: SelectItem[] = DynamicWorkerTaskCapValues.filter((value, index, self) => !!value && self.indexOf(value) === index)
        .map(value => ({Id: value, Name: value}));
    const chipRenderer = (i: SelectItem, onRequestDelete: (event: object) => void) => {
        return <DefaultChip item={i} onRequestDelete={onRequestDelete}/>;
    };

    return <DynamicWorkerTaskCapTypedMultiSelect
        {...props}
        fieldName="task cap"
        renderChip={chipRenderer}
        items={items}/>;
};

export default DynamicWorkerTaskCapMultiSelect;