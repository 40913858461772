// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* This file is DEPRECATED
   We are moving to the Octopus Design System
   https://www.octopus.design
  
   When changing component styles:
   1. Do not use variables from this file
   2. Aim to use Emotion \`css\` + themeTokens.colors
       - see Dashboard.tsx for example
   3. If you need to use Less, reference CSS variables
       - see index.ejs for example
       - for full list see https://github.com/OctopusDeploy/OctopusDeploy/blob/main/frontend/packages/design-system-tokens/src/generated/themeTokens.ts
   4. Remove variables from this file when they are no longer used
 */
.style_markdown___qhb6 {
  line-height: normal;
}
.style_markdown___qhb6 ul {
  list-style-type: disc;
  padding-left: 2.5rem;
}
.style_markdown___qhb6 ol {
  list-style-type: decimal;
  padding-left: 2.5rem;
}
.style_markdown___qhb6 blockquote {
  border-left: 0.25rem solid var(--colorBorderSubtle);
  padding-left: 1rem;
}
`, "",{"version":3,"sources":["webpack://./app/components/Markdown/style.less"],"names":[],"mappings":"AAAA;;;;;;;;;;;;EAYE;AAVF;EACI,mBAAA;AAYJ;AAbA;EAOQ,qBAAA;EAJA,oBAAA;AAcR;AAjBA;EAYQ,wBAAA;EATA,oBAAA;AAkBR;AArBA;EAiBQ,mDAAA;EACA,kBAAA;AAOR","sourcesContent":["@import \"../../resources/styles/colors\";\n\n.markdown {\n    line-height: normal;\n    .list() {\n        padding-left: 2.5rem;\n    }\n\n    ul {\n        list-style-type: disc;\n        .list();\n    }\n\n    ol {\n        list-style-type: decimal;\n        .list();\n    }\n\n    blockquote {\n        border-left: 0.25rem solid @divider;\n        padding-left: 1rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"markdown": `style_markdown___qhb6`
};
module.exports = ___CSS_LOADER_EXPORT___;
