import * as React from "react";
import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent";
import {AreYouReallySureConfirmation} from "../instances/Instances/AreYouReallySureConfirmation";

interface Props {
    useHubInstanceId: boolean;
    selectedHubDnsPrefix?: string;
    existingHubDnsPrefix?: string;
    onChange: (isValid: boolean) => void;
}

class HubConfigurationConfirmation extends DataBaseComponent<Props, DataBaseComponentState> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        if (!HubConfigurationConfirmation.isExpectedHubDnsPrefix(this.props.selectedHubDnsPrefix, this.props.existingHubDnsPrefix))
            return <AreYouReallySureConfirmation title={"Are you sure this is the correct hub instance?"}
                                                 warning={<>The selected instance doesn't follow the usual hub naming convention. Please double-check the correct instance has been selected.</>}
                                                 requiredConfirmation={this.props.selectedHubDnsPrefix}
                                                 onChange={canProcess => this.props.onChange(canProcess)}/>;

        return null;
    }

    public static isExpectedHubDnsPrefix(dnsPrefix?: string, existingDnsPrefix?: string)    {

        return dnsPrefix == null ||
            dnsPrefix === existingDnsPrefix ||
            dnsPrefix.startsWith("v2-hub-") ||
            dnsPrefix.startsWith("staging");
    }
}

export default HubConfigurationConfirmation;