import * as React from "react";
import { BaseComponent } from "components/BaseComponent/BaseComponent";

interface BusyPromiseState {
    busy: boolean;
}

type BusyState = Promise<any> | boolean;

interface BusyPromiseProps {
    promise: BusyState;
    children(busy: boolean): JSX.Element;
}

export { BusyState };

export default class BusyFromPromise extends BaseComponent<BusyPromiseProps, BusyPromiseState> {
    private current: Promise<any> = null;
    constructor(props: BusyPromiseProps) {
        super(props);
        this.state = this.determineState(props);
    }

    UNSAFE_componentWillReceiveProps(nextProps: BusyPromiseProps) {
        if (nextProps.promise !== this.props.promise) {
            this.setState(this.determineState(nextProps));
        }
    }

    render() {
        return this.props.children(this.state.busy);
    }

    private resetState = (promise: any) => {
        // don't turn off the busy indicator if a new promise is running
        // we don't worry about order of promises finishing here, callers
        // should maintain an array of "requests" and pass in Promise.all
        // on them when it updates so we only need to worry about latest.
        if (promise === this.current) {
            this.current = null;
            this.setState({ busy: false });
        }
    };

    private determineState = (props: BusyPromiseProps): BusyPromiseState => {
        if (props.promise) {
            if (typeof props.promise === "boolean") {
                this.current = null;
                return { busy: props.promise };
            } else {
                this.current = props.promise;
                props.promise.then(() => this.resetState(props.promise), () => this.resetState(props.promise));
                return { busy: true };
            }
        } else {
            return { busy: false };
        }
    };
}
