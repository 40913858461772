import * as React from "react";
import {FunctionComponent} from "react";
import ReloadableRoute from "components/ReloadableRoute/ReloadableRoute";
import {Switch} from "react-router-dom";
import routeLinks from "routeLinks";
import MessageBus from "./MessageBus";

const MessageBusLayout: FunctionComponent<{}> = props =>
    <Switch>
        <ReloadableRoute path={routeLinks.messageBus.root} exact={true} component={MessageBus} />
    </Switch>;

MessageBusLayout.displayName = "MessageBusLayout";

export default MessageBusLayout;