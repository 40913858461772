import * as React from "react";
import SaveDialogLayout from "components/DialogLayout/SaveDialogLayout";
import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent";
import repository from "client/repository";
import {ChangeReefDisplayNameRequest} from "../../client/resources/changeReefDisplayNameRequest";
import {Text} from "../../components/form";
import {ReefResource} from "../../client/resources/reefResource";

interface Props {
    reefId: string;
    displayName: string;
    onChange: (reef: Partial<ReefResource>) => void;
}

interface State extends DataBaseComponentState {
    model: ChangeReefDisplayNameRequest;
}

class ChangeReefDisplayNameDialog extends DataBaseComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            model: {
                DisplayName: props.displayName
            }
        };
    }

    render() {
        const model = this.state.model;
        return <SaveDialogLayout title={"Change Reef Display Name"}
                                 onSaveClick={() => this.changeReefDisplayName()}
                                 errors={this.state.errors}
                                 saveButtonDisabled={!this.state.model.DisplayName || this.state.model.DisplayName == this.props.displayName}
                                 busy={this.state.busy}>
            <Text label="ReefDisplayName"
                  value={model.DisplayName}
                  onChange={displayName => this.modifyDisplayNameState({DisplayName: displayName})}
            />
        </SaveDialogLayout>;
    }

    private modifyDisplayNameState(displayName: Partial<ChangeReefDisplayNameRequest>): void {
        this.setState((prevState: State) => ({model: {...prevState.model, ...displayName}}));
    }

    private async changeReefDisplayName(): Promise<boolean> {
        return this.doBusyTask(async () => {
            await repository.Reefs.changeDisplayName(this.props.reefId, this.state.model);
            this.props.onChange({DisplayName: this.state.model.DisplayName});
        });
    }
}

export default ChangeReefDisplayNameDialog;
