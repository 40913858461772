import {AzureRegionResource} from "client/resources/azureRegionResource";
import client from "client/client";
import {CreateAzureRegionResource} from "client/resources/createAzureRegionResource";

export default class AzureRegionRepository {

    private rootUri = "/api/azure-regions";

    list(localPortalInstancesOnly?: boolean): Promise<AzureRegionResource[]> {
        return client.get(`${this.rootUri}{?localPortalInstancesOnly}`, {localPortalInstancesOnly});
    }

    async map(): Promise<Map<string, AzureRegionResource>> {
        const all = await this.list();
        return new Map<string, AzureRegionResource>(all.map<[string, AzureRegionResource]>(r => [r.Id, r]));
    }

    get(id: string): Promise<AzureRegionResource> {
        return client.get(`${this.rootUri}/{id}`, {id});
    }

    create(resource: CreateAzureRegionResource): Promise<AzureRegionResource> {
        return client.post(`${this.rootUri}`, resource);
    }

    reprovision(azureRegion: AzureRegionResource) {
        return client.put(`${this.rootUri}/{id}/reprovisioning`, {id: azureRegion.Id}, {id: azureRegion.Id});
    }
}
