import * as React from "react";
import Clipboard from "clipboard";
import Tooltip from "components/ToolTip";
import IconButton from "material-ui/IconButton";
import CopyIcon from "material-ui/svg-icons/content/content-copy";

const styles = require("./style.less");

interface CopyToClipboardProps {
    value: string;
}

interface CopyToClipboardState {
    showConfirmation: boolean;
}

export default class CopyToClipboard extends React.Component<CopyToClipboardProps, CopyToClipboardState> {
    private clipboard: Clipboard;
    private hideTimeoutId: number;
    private element: HTMLElement;

    constructor(props: CopyToClipboardProps) {
        super(props);
        this.state = {showConfirmation: false};
    }

    componentDidMount() {
        this.clipboard = new Clipboard(this.element, {
            text: () => this.props.value
        });
        this.clipboard.on("success", () => this.onCopy());
    }

    componentWillUnmount() {
        this.clipboard.destroy();
        if (this.hideTimeoutId) {
            window.clearTimeout(this.hideTimeoutId);
        }
    }

    render() {
        const button = <CopyIcon style={{height: "1em"}}/>;

        return <span ref={el => this.setRef(el)}  className={styles.icon}>
            {this.state.showConfirmation && <Tooltip
                content="Copied!"
                trigger="manual"
                open={true}
            >
                {button}
            </Tooltip>}
            {!this.state.showConfirmation && <Tooltip content="Copy to clipboard">
                {button}
            </Tooltip>}
        </span>;
    }

    private setRef(el: HTMLElement) {
        this.element = el;
    }

    private onCopy() {
        this.setState({showConfirmation: true});
        if (this.hideTimeoutId) {
            window.clearTimeout(this.hideTimeoutId);
        }
        this.hideTimeoutId = window.setTimeout(() => this.onHide(), 3500);
    }

    private onHide() {
        this.setState({showConfirmation: false});
        this.hideTimeoutId = null;
    }
}