import React from "react";
import { css } from "@emotion/css";
import { space } from "@octopusdeploy/design-system-tokens";
import routeLinks from "routeLinks";
import PaperLayout from "components/PaperLayout/PaperLayout";
import SimpleDataTable from "components/SimpleDataTable/SimpleDataTable";
import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent";
import NavigationButton, {NavigationButtonType} from "components/Button/NavigationButton";
import repository from "client/repository";
import { AzureRegion } from "client/resources/azureRegion";
import {ReefResource} from "client/resources/reefResource";
import externalSystemLinks from "externalSystemLinks";
import OverflowMenu from "components/Menu/OverflowMenu";
import TaskSnackbar from "shared/TaskSnackbar";
import {Refresh} from "components/DataBaseComponent/DataBaseComponent";
import ActionList from "components/ActionList";
import ActionButton from "components/Button";
import BulkChangeHostedScriptVersionDialog from "./BulkChangeHostedScriptVersionDialog";
import {AzureRegionResource} from "client/resources/azureRegionResource";
import { KubernetesClusterStateResource } from "client/resources/KubernetesClusterStateResource";
import InternalLink from "../../components/Navigation/InternalLink/InternalLink";
import {reefsRouteLinks} from "./reefsRouteLinks";
import { KubernetesClusterResource } from "../../client/resources/KubernetesClusterResource";

const clusterListStyle = css`
    margin-block-start: 0;
    margin-block-end: 0;
    
    li:not(:last-child) {
        margin-bottom: ${space["16"]};
    }
`;

interface State extends DataBaseComponentState {
    reefs?: ReefResource[];
    azureRegions?: AzureRegionResource[];
    kubernetesClusterStates?: Map<string, KubernetesClusterStateResource>;
    openSnackBar: boolean;
    environment?: string;
}

class ReefsDataTable extends SimpleDataTable<ReefResource> {
}

export class Reefs extends DataBaseComponent<{}, State> {
    constructor(props: {}) {
        super(props);
        this.state = {
            openSnackBar: false
        };
    }

    componentDidMount() {
        return this.doBusyTask(async () => {
            this.doRefresh = await this.startRefreshLoop(() => this.loadData(), 5000);
            this.setState({ environment: (await repository.Configuration.getAppInfo()).Environment });
        });
    }

    render() {
        return <PaperLayout title="Reefs"
                            busy={this.state.busy}
                            errors={this.state.errors}
                            sectionControl={<ActionList actions={[
                                <ActionButton label="Refresh" onClick={this.doRefresh}/>,
                                <NavigationButton label="New Reef" href={routeLinks.reefs.create} type={NavigationButtonType.Primary}/>,
                                <OverflowMenu menuItems={[
                                    OverflowMenu.dialogItem("Bulk update hosted script versions", this.bulkUpdateHostedScriptVersion())
                                ]}/>
                                ]
                            }/>}
                            fullWidth={true}>
            <TaskSnackbar
                getLink={() => routeLinks.backgroundTasks.root}
                onRequestClose={() => this.setState({openSnackBar: false})}
                open={this.state.openSnackBar} />
            {this.state.reefs && this.state.azureRegions && <ReefsDataTable
                data={this.state.reefs}
                onRow={this.buildRow}
                onRowOverflowMenu={test => this.getOverflowMenu(test)}
                headerColumns={["ID", "Display Name", "Name", "Status", "Region", "Available To Customers", "Kubernetes Clusters"]}
                onRowRedirectUrl={r => routeLinks.reefs.reef(r.Id).root}
                onEmpty={<div>No reefs found</div>}/>}
        </PaperLayout>;
    }

    private bulkUpdateHostedScriptVersion() {
        return <BulkChangeHostedScriptVersionDialog onChange={() => this.setState({openSnackBar: true})} />;
    }

    private async loadData() {
        const [reefs, azureRegions, kubernetesClusterStates] = await Promise.all([
            repository.Reefs.list(),
            repository.AzureRegions.list(),
            repository.Reefs.mapKubernetesClusterStates(),
        ]);
        sortReefs(reefs, azureRegions);
        return {
            reefs,
            azureRegions,
            kubernetesClusterStates,
        };
    }

    private doRefresh: Refresh = () => Promise.resolve();

    private getOverflowMenu(reef: ReefResource) {
        return [
            OverflowMenu.externalNavItem("Hub", externalSystemLinks.hub.root(reef))
        ];
    }

    private buildRow = (reef: ReefResource) => {
        return [
            <InternalLink  to={reefsRouteLinks.reef(reef.Id).root}>{reef.Id}</InternalLink>,
            <InternalLink  to={reefsRouteLinks.reef(reef.Id).root}>{reef.DisplayName}</InternalLink>,
            reef.Name,
            reef.Status,
            this.state.azureRegions.find(r => r.Id === reef.AzureRegionId).Region,
            reef.AvailableToCustomers ? "Yes" : "No",
            <ul className={clusterListStyle}>
                {reef.KubernetesClusters.map((k, index) =>
                    <li key={k.Name}>{this.kubernetesClusterInfo(k)}</li>
                )}
            </ul>,
        ];
    };

    private kubernetesClusterInfo = (k: KubernetesClusterResource) => {
        const clusterState = this.state.kubernetesClusterStates.get(k.Name);
        return <>
            {(k.Name === null ? "<Creating>" : k.Name)}<br />
            Cluster Version: <code>{(k.ClusterVersion)}</code><br />
            Hosted Scripts Version: <code>{(k.HostedScriptsVersion)}</code><br />
            {clusterState?.NodeImageVersion && <>
                Node Image: <code>{(clusterState.NodeImageVersion)}</code><br />
            </>}
        </>;
    };
}

function sortReefs(reefs: ReefResource[], azureRegions: AzureRegionResource[]) {
    const regionNames = new Map<string, AzureRegion>(
        azureRegions.map(r => [r.Id, r.Region])
    );
    reefs.sort(reefSortCriteria);

    function reefSortCriteria(a: ReefResource, b: ReefResource) {
        const aRegion = regionNames.get(a.AzureRegionId);
        const bRegion = regionNames.get(b.AzureRegionId);
        return aRegion.localeCompare(bRegion)
            || a.Name.localeCompare(b.Name);
    }
}
