import * as React from "react";
import {RouteComponentProps, Switch} from "react-router-dom";
import {ReefResource} from "client/resources/reefResource";
import repository from "client/repository";
import NavigationSidebarLayout, {Navigation} from "components/NavigationSidebarLayout";
import {reefsRouteLinks} from "areas/reefs/reefsRouteLinks";
import ReloadableRoute from "components/ReloadableRoute/ReloadableRoute";
import RelatedBackgroundTasks from "shared/RelatedBackgroundTasks";
import ReefDetail from "areas/reefs/ReefDetail";
import {reefDisplayLabel} from "./ReefDisplayLabel";

type Props = RouteComponentProps<{ id: string }>;

interface State {
    reef?: ReefResource;
}

class ReefLayout extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    async componentDidMount() {
        this.setState({
            reef: await repository.Reefs.get(this.props.match.params.id)
        });
    }

    render() {
        const reefId = this.props.match.params.id;
        const reefRoute = reefsRouteLinks.reef(reefId);
        const reefBreadcrumbTitle = this.state.reef && `${this.state.reef.DisplayName} (${reefId})`
        const navLinks = [
            Navigation.navItem("Detail", reefRoute.root, true),
            Navigation.navItem("Tasks", reefRoute.tasks, true)
        ];
        
        const content = <Switch>
            <ReloadableRoute path={reefRoute.root} exact={true} render={_ => <ReefDetail reef={this.state.reef} breadcrumbTitle={reefBreadcrumbTitle} updateReef={this.updateReef}/>} />
            <ReloadableRoute path={reefRoute.tasks} exact={true} render={_ => <RelatedBackgroundTasks documentId={reefId} breadcrumbTitle={reefBreadcrumbTitle} />}/>
        </Switch>;

        return this.state.reef ? <NavigationSidebarLayout navLinks={navLinks}
                                        preNavbarComponent={this.state.reef && reefId}
                                        content={content}
                                        name={this.state.reef && reefDisplayLabel(this.state.reef)}/> : <></>;
    }
    private updateReef = (reef: Partial<ReefResource>): void => {
        this.setState((prevState: State) => ({reef: {...prevState.reef, ...reef}}));
    }

}

export default ReefLayout;