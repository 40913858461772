import * as React from "react";
import {CommonChipProps} from "components/Chips";
import {chipWithAvatar} from "components/Chips/Chip";
import SvgIconProps = __MaterialUI.SvgIconProps;
import {SelectItem} from "components/VirtualListWithKeyboard/SelectItem";

const icon = (props: SvgIconProps) =>
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width || 24} height={props.height || 24} viewBox="0 0 180 180">
        <path style={{fill: "#2f93e0"}}
              d="M36.48,123c9.55-6,20.8-16.38,16.69-28.76-2.24-6.74-5.37-12.54-5.73-19.81a44.69,44.69,0,0,1,2.89-18.06c8.73-22.74,34.12-33.29,56.9-26.2,21.1,6.57,35.41,31.29,26.42,52.61-5.18,12.3-7.55,21.82,3.64,31.55,3,2.63,10.43,6.62,10.35,11.24-.1,6-11.9-1.41-13.21-2.48,1.48,2.66,16.29,18.41,6.75,19.43-8.78.93-16.4-11.4-21.61-16.71-8.77-8.92-7.42,10.64-7.5,14.67-.13,6.37-4.76,19.24-12.76,10.75-6.6-7-4-18.06-8.53-25.82-5-8.5-13.62,8.31-15.76,11.38-2.4,3.43-14.38,20-19,11.08-3.73-7.27,2.47-18.55,5.54-25.06-1.12,2.37-8.9,5.8-11.17,6.92-5.14,2.53-10.35,4-16.09,3.54-11.89-1-2.7-7.2,2.17-10.27Z"/>
    </svg>;

const DefaultChip: React.StatelessComponent<{ item: SelectItem; } & CommonChipProps> = props => {
    const {item, ...rest} = props;
    const name = item.Name;
    return chipWithAvatar(name, icon, {description: name, ...rest});
};

export default DefaultChip;