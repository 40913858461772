import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent";
import * as React from "react";
import Callout, {CalloutType} from "components/Callout";
import {Text} from "components/form";
import {ReactElement} from "react";

interface AreYouReallySureConfirmationProps {
    title: string;
    warning: ReactElement;
    requiredConfirmation: string;
    onChange(canDelete: boolean): void;
}

interface AreYouReallySureConfirmationState extends DataBaseComponentState {
    confirmationText: string;
}

export class AreYouReallySureConfirmation extends DataBaseComponent<AreYouReallySureConfirmationProps, AreYouReallySureConfirmationState> {
    constructor(props: AreYouReallySureConfirmationProps) {
        super(props);
        this.state = {
            confirmationText: ""
        };
    }

    render() {
        return <div>
            <Callout title={this.props.title} type={CalloutType.Danger}>
                {this.props.warning}
            </Callout>
            <div>
                <p>Please type '{this.props.requiredConfirmation}' below.</p>
                <Text value={this.state.confirmationText} onChange={this.onChange} />
            </div>
        </div>;
    }

    private onChange = (confirmationText: string) => {
        this.setState({ confirmationText });
        this.props.onChange(confirmationText === this.props.requiredConfirmation);
    };
}
