// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* This file is DEPRECATED
   We are moving to the Octopus Design System
   https://www.octopus.design
  
   When changing component styles:
   1. Do not use variables from this file
   2. Aim to use Emotion \`css\` + themeTokens.colors
       - see Dashboard.tsx for example
   3. If you need to use Less, reference CSS variables
       - see index.ejs for example
       - for full list see https://github.com/OctopusDeploy/OctopusDeploy/blob/main/frontend/packages/design-system-tokens/src/generated/themeTokens.ts
   4. Remove variables from this file when they are no longer used
 */
.style_error__YE2bO {
  padding: 1.5rem 1rem 1.5rem 1rem;
  color: var(--colorTextDanger);
  background-color: var(--colorBackgroundDanger);
  text-align: left;
}
.style_error__YE2bO ul {
  padding-left: 1rem;
  margin-bottom: 0;
  list-style-type: disc;
  font-weight: 400;
}
.style_titleContainer__UzEGI {
  display: flex;
  justify-content: space-between;
}
.style_title__bH6xV {
  font-weight: 500;
}
.style_detailLink__A0vZ7 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
`, "",{"version":3,"sources":["webpack://./app/components/ErrorPanel/style.less"],"names":[],"mappings":"AAAA;;;;;;;;;;;;EAYE;AATF;EACI,gCAAA;EACA,6BAAA;EACA,8CAAA;EAOA,gBAAA;AAKJ;AAfA;EAKQ,kBAAA;EACA,gBAAA;EACA,qBAAA;EACA,gBAAA;AAaR;AARA;EACI,aAAA;EACA,8BAAA;AAUJ;AAPA;EACI,gBAAA;AASJ;AANA;EACI,oBAAA;EACA,uBAAA;AAQJ","sourcesContent":["@import \"../../resources/styles/variables\";\n@import \"../../resources/styles/colors\";\n\n.error {\n    padding: 1.5rem 1rem 1.5rem 1rem;\n    color: @danger;\n    background-color: @dangerHighlight;\n    ul {\n        padding-left: 1rem;\n        margin-bottom: 0;\n        list-style-type: disc;\n        font-weight: @normal;\n    }\n    text-align: left;\n}\n\n.titleContainer {\n    display: flex;\n    justify-content: space-between;\n}\n\n.title {\n    font-weight: @semibold;\n}\n\n.detailLink {\n    padding-top: 0.25rem;\n    padding-bottom: 0.25rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error": `style_error__YE2bO`,
	"titleContainer": `style_titleContainer__UzEGI`,
	"title": `style_title__bH6xV`,
	"detailLink": `style_detailLink__A0vZ7`
};
module.exports = ___CSS_LOADER_EXPORT___;
