import {themeTokens} from "@octopusdeploy/design-system-tokens";
import {SecondaryUptimeStatus} from "../client/resources/secondaryUptimeStatus";

const textColor = themeTokens.color.text;

export function getSecondaryUptimeColorFor(status: SecondaryUptimeStatus, active = true): string {
    if (!active)
        return textColor.subtle;

    switch (status) {
        case SecondaryUptimeStatus.Up:
            return textColor.success;
        case SecondaryUptimeStatus.Down:
        case SecondaryUptimeStatus.Unknown:
            return textColor.danger;
    }
}
