import * as React from "react";
import {handleActions} from "redux-actions";
import {UnhandledError} from "globalState";

const ActionTypes = {
    UNHANDLED_ERROR_OCCURRED: "UNHANDLED_ERROR_OCCURRED",
};

export function raiseUnhandledErrorAction(error: Error) {
    return {type: ActionTypes.UNHANDLED_ERROR_OCCURRED, error};
}

const raiseUnhandledError = handleActions({
    [ActionTypes.UNHANDLED_ERROR_OCCURRED]: (state: UnhandledError, action) => {
        const error: any = action.error;

        if (error instanceof Error) {
            return {
                message: error.message,
                details: error.stack.split(" at ")
            };
        }

        return {
            message: JSON.stringify(error)
        };
    }
}, null);

export default raiseUnhandledError;