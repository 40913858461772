import * as React from "react";
import {RouteComponentProps, Switch} from "react-router-dom";
import repository from "client/repository";
import NavigationSidebarLayout, {Navigation} from "components/NavigationSidebarLayout";
import {dynamicWorkerServicesRouteLinks} from "areas/dynamicWorkerServices/dynamicWorkerServicesRouteLinks";
import ReloadableRoute from "components/ReloadableRoute/ReloadableRoute";
import {DynamicWorkerServiceResource} from "../../client/resources/dynamicWorkerServiceResource";
import {EditDynamicWorkerService} from "./EditDynamicWorkerService";
import DynamicWorkers from "./Workers/DynamicWorkers";
import DynamicWorkerTypes from "./WorkerTypes/DynamicWorkerTypes";
import {EditDynamicWorkerType} from "./WorkerTypes/DynamicWorkerType";
import DynamicWorkerSources from "./WorkerSources/DynamicWorkerSources";
import {DynamicWorkerServiceDetail} from "./DynamicWorkerServiceDetail";
import DynamicWorkerServiceLiveInformation from "./DynamicWorkerServiceLiveInformation";

type Props = RouteComponentProps<{ id: string }>;

interface State {
    dynamicWorkerService?: DynamicWorkerServiceResource;
}

class DynamicWorkerServiceLayout extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    async componentDidMount() {
        this.setState({
            dynamicWorkerService: await repository.DynamicWorkerServices.get(this.props.match.params.id)
        });
    }

    render() {
        const dynamicWorkerServiceId = this.props.match.params.id;
        
        const dynamicWorkerServiceRoute = dynamicWorkerServicesRouteLinks.service(dynamicWorkerServiceId);
        const navLinks = [
            Navigation.navItem("Detail", dynamicWorkerServiceRoute.root, true),
            Navigation.navItem("Workers", dynamicWorkerServiceRoute.workers, true),
            Navigation.navItem("Live", dynamicWorkerServiceRoute.live, true),
            Navigation.navItem("Worker Types", dynamicWorkerServiceRoute.workerTypes, true),
            Navigation.navItem("Edit", dynamicWorkerServiceRoute.edit, true),
        ];

        const dynamicWorkerServiceRouteTemplate = dynamicWorkerServicesRouteLinks.service(":id");
        const content = <Switch>
            <ReloadableRoute path={dynamicWorkerServiceRouteTemplate.root} exact={true} component={DynamicWorkerServiceDetail} />
            <ReloadableRoute path={dynamicWorkerServiceRouteTemplate.edit} exact={true} component={EditDynamicWorkerService} />
            <ReloadableRoute path={dynamicWorkerServiceRouteTemplate.workers} exact={true} component={DynamicWorkers} />
            <ReloadableRoute path={dynamicWorkerServiceRouteTemplate.live} exact={true} component={DynamicWorkerServiceLiveInformation} />
            <ReloadableRoute path={dynamicWorkerServiceRouteTemplate.workerTypes} exact={true} component={DynamicWorkerTypes} />
            <ReloadableRoute path={dynamicWorkerServiceRouteTemplate.workerType(":type").root} exact={true} component={EditDynamicWorkerType} />
            <ReloadableRoute path={dynamicWorkerServiceRouteTemplate.workerSources(":typeId").root} exact={true} component={DynamicWorkerSources} />
        </Switch>;

        return this.state.dynamicWorkerService ? <NavigationSidebarLayout navLinks={navLinks}
                                        preNavbarComponent={this.state.dynamicWorkerService && dynamicWorkerServiceId}
                                        content={content}
                                        name={this.state.dynamicWorkerService && this.state.dynamicWorkerService.Name}/> : <></>;
    }
}

export default DynamicWorkerServiceLayout;