// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_filterField__Mrd2v {
  position: relative;
  display: inline-block;
  /* clears the 'X' from Internet Explorer */
  /* clears the 'X' from Chrome */
  /* clears the padding and overlay issues on Safari */
}
.style_filterField__Mrd2v .style_searchIcon__uXmUw {
  position: absolute;
  right: 0;
  top: 10px;
  width: 1.5rem;
  height: 1.5rem;
}
.style_filterField__Mrd2v input[type=search]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
.style_filterField__Mrd2v input[type=search]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
.style_filterField__Mrd2v input[type="search"]::-webkit-search-decoration,
.style_filterField__Mrd2v input[type="search"]::-webkit-search-cancel-button,
.style_filterField__Mrd2v input[type="search"]::-webkit-search-results-button,
.style_filterField__Mrd2v input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
.style_filterField__Mrd2v input[type="search"] {
  -webkit-appearance: none;
}
.style_filterTextBoxStandardWidth__tlQTa {
  width: 15.6rem !important;
  /* mark.siedle: This matches the sidebarContainer width-padding. Pls be careful about changing this. */
}
.style_filterTextBoxFullWidth__oP0aT {
  width: 100% !important;
}
`, "",{"version":3,"sources":["webpack://./app/components/FilterSearchBox/style.less"],"names":[],"mappings":"AACA;EACI,kBAAA;EACA,qBAAA;EAAF,0CAA0C;EAC1C,+BAA+B;EAC/B,oDAAoD;AACtD;AALA;EAIQ,kBAAA;EACA,QAAA;EACA,SAAA;EACA,aAAA;EACA,cAAA;AAIR;AAZA;EAYqC,aAAA;EAAe,QAAA;EAAW,SAAA;AAK/D;AAjBA;EAasC,aAAA;EAAe,QAAA;EAAW,SAAA;AAShE;AAtBA;;;;EAmB8D,aAAA;AAS9D;AA5BA;EAuBQ,wBAAA;AAQR;AAJA;EACI,yBAAA;EAMF,sGAAsG;AACxG;AAJA;EACI,sBAAA;AAMJ","sourcesContent":["\n.filterField {\n    position: relative;\n    display: inline-block;\n    .searchIcon {\n        position: absolute;\n        right: 0;\n        top: 10px;\n        width: 1.5rem;\n        height: 1.5rem;\n    }\n\n    /* clears the 'X' from Internet Explorer */\n    input[type=search]::-ms-clear {  display: none; width : 0; height: 0; }\n    input[type=search]::-ms-reveal {  display: none; width : 0; height: 0; }\n\n    /* clears the 'X' from Chrome */\n    input[type=\"search\"]::-webkit-search-decoration,\n    input[type=\"search\"]::-webkit-search-cancel-button,\n    input[type=\"search\"]::-webkit-search-results-button,\n    input[type=\"search\"]::-webkit-search-results-decoration { display: none; }\n\n    /* clears the padding and overlay issues on Safari */\n    input[type=\"search\"] {\n        -webkit-appearance: none;\n    }\n}\n\n.filterTextBoxStandardWidth {\n    width: 15.6rem !important; /* mark.siedle: This matches the sidebarContainer width-padding. Pls be careful about changing this. */\n}\n\n.filterTextBoxFullWidth {\n    width: 100% !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filterField": `style_filterField__Mrd2v`,
	"searchIcon": `style_searchIcon__uXmUw`,
	"filterTextBoxStandardWidth": `style_filterTextBoxStandardWidth__tlQTa`,
	"filterTextBoxFullWidth": `style_filterTextBoxFullWidth__oP0aT`
};
module.exports = ___CSS_LOADER_EXPORT___;
