import * as React from "react";
import PaperLayout from "components/PaperLayout/PaperLayout";
import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent";
import repository from "client/repository";
import {RouteComponentProps} from "react-router-dom";
import {MessageBusMessageResource} from "../../client/resources/messageBusMessageResource";
import {TopicSubscriptionResource} from "../../client/resources/topicSubscriptionResource";
import SimpleDataTable from "../../components/SimpleDataTable";
import ActionButton, {ActionButtonType} from "../../components/Button";
import OverflowMenu from "../../components/Menu/OverflowMenu";
import {Number} from "../../shared/Number";
import InfoDialogLayout from "components/DialogLayout/InfoDialogLayout";
import Callout, {CalloutType} from "../../components/Callout/Callout";
import {FriendlyLocalTime} from "shared/FriendlyLocalTime";
import externalSystemLinks from "../../externalSystemLinks";
import ActionList from "../../components/ActionList";
import SaveDialogLayout from "../../components/DialogLayout/SaveDialogLayout";
import {required, Text} from "../../components/form";

interface State extends DataBaseComponentState {
    messagesToPreview?: MessageBusMessageResource[];
    subscriptions?: TopicSubscriptionResource[];
    numberOfMessagesToPreview?: number;
    testEventSeconds: number;
    testEventMessage: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props extends RouteComponentProps<{}> {
}

class SubscriptionsDataTable extends SimpleDataTable<TopicSubscriptionResource> {}

class MessagesDataTable extends SimpleDataTable<MessageBusMessageResource> {}

class MessageBus extends DataBaseComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            numberOfMessagesToPreview: 10,
            testEventSeconds: 60,
            testEventMessage: "Test Message"
        };
    }
    
    render() {
        const refreshButton = <ActionButton label="Refresh" disabled={this.state.busy} onClick={() => this.refreshData()}/>;

        return <PaperLayout title="Subscriptions"
                            busy={this.state.busy}
                            errors={this.state.errors}
                            sectionControl={<ActionList actions={[refreshButton]} />}
                            fullWidth={true}>
            {
                <SubscriptionsDataTable
                    data={this.state.subscriptions}
                    onRow={subscription => this.buildSubscriptionRow(subscription)}
                    onRowOverflowMenu={subscription => this.getOverflowMenu(subscription)}
                    headerColumns={["Topic Name", "Name", "Total Message Count", "Active Message Count", "Dead Letter Message Count", "Transfer Dead Letter Message Count"]}
                    onEmpty={<div>No subscriptions found</div>}/>
            }
        </PaperLayout>;
    }

    componentDidMount() {
        this.refreshData();
    }

    private refreshData = async () => {
        await this.doBusyTask(async () => {
            const subscriptions = await repository.MessageBus.listSubscriptions();

            this.setState({
                subscriptions,
            });
        });
    };

    private buildSubscriptionRow = (subscription: TopicSubscriptionResource) => {
        return [
            subscription.TopicName,
            subscription.Name,
            subscription.TotalMessageCount,
            subscription.ActiveMessageCount,
            subscription.DeadLetterMessageCount,
            subscription.TransferDeadLetterMessageCount
        ];
    }
    
    private async onSendClick() {
        await this.doBusyTask(async () => {
            const request = { Message: this.state.testEventMessage, Seconds: this.state.testEventSeconds };
            await repository.MessageBus.testEventMessageWithDelay(request);
            return true;
        });
        return false;
    }
    
    private testEventMessageDialog() {
        return <SaveDialogLayout onSaveClick={() => this.onSendClick()} title={"Send Test Message"} errors={this.state.errors} busy={this.state.busy} saveButtonLabel={"Send"}>
            <Number label="Seconds to delay message success"
                    value={this.state.testEventSeconds}
                    min={0}
                    onChange={seconds => this.setState({testEventSeconds: seconds})}
            />
            <Text label="Message"
                  value={this.state.testEventMessage}
                  onChange={message => this.setState({testEventMessage: message})}
                  validate={required("Please enter a message")}
                  error={this.getFieldError("Message")}
                  autoFocus={true}
            />
        </SaveDialogLayout>
    }

    private getOverflowMenu(subscription: TopicSubscriptionResource) {
        const previewItem = OverflowMenu.dialogItem("Preview messages from DLQ", this.previewDeadLetterQueueMessagesDialog(subscription), true);
        const sendTestEventMenuItem = OverflowMenu.dialogItem("Send Test Message", this.testEventMessageDialog());
        
        return (subscription.TopicName === "cloudplatform") 
            ? [previewItem, sendTestEventMenuItem]
            : [previewItem];
    }

    private previewDeadLetterQueueMessagesDialog(subscription: TopicSubscriptionResource) {
        return <InfoDialogLayout title={"Preview"}
                          errors={this.state.errors}
                          busy={this.state.busy}>
            <Callout type={CalloutType.Information} title="Limit">
                <p>
                    Messages are read from the beginning of the dead letter queue.
                </p>
                <p>
                    Avoid previewing more than 100 to keep the UI responsive.
                </p>
            </Callout>
            <div>
                <Number label="Number Of Messages To Preview"
                    value={this.state.numberOfMessagesToPreview}
                    min={0}
                    onChange={numberOfMessagesToPreview => this.setState({numberOfMessagesToPreview})}
                />

                <ActionButton type={ActionButtonType.Primary} label="Preview" onClick={() => this.previewMessages(subscription, this.state.numberOfMessagesToPreview)} />
            </div>

            {this.state.messagesToPreview && this.showMessages(subscription, this.state.messagesToPreview)}
        </InfoDialogLayout>;
    }

    private async previewMessages(subscription: TopicSubscriptionResource, numberOfMessagesToPreview: number) {
        return await this.doBusyTask(async () => {
            const messagesToPreview = await repository.MessageBus.previewDeadLetterQueueMessages(subscription, numberOfMessagesToPreview);

            this.setState({
                messagesToPreview
            });
        });
    }

    private async retryDeadLetterQueueMessage(subscription: TopicSubscriptionResource, message: MessageBusMessageResource) {
        return await this.doBusyTask(async () => {
            await repository.MessageBus.retryDeadLetterQueueMessage(subscription, message.SequenceNumber);
            await this.previewMessages(subscription, this.state.numberOfMessagesToPreview);
        });
    }

    private async deleteDeadLetterQueueMessage(subscription: TopicSubscriptionResource, message: MessageBusMessageResource): Promise<boolean> {
        return await this.doBusyTask(async () => {
            await repository.MessageBus.deleteDeadLetterQueueMessage(subscription, message.SequenceNumber);
            await this.previewMessages(subscription, this.state.numberOfMessagesToPreview);
        });
    }

    private showMessages(subscription: TopicSubscriptionResource, messagesToPreview: MessageBusMessageResource[]) {
        return <MessagesDataTable
            data={messagesToPreview}
            onRow={message => this.buildMessageRow(message)}
            onRowOverflowMenu={message =>
                [
                    OverflowMenu.externalNavItem("Logs", externalSystemLinks.seq.messageBusMessage(message)),
                    OverflowMenu.item("Retry", async () => { await this.retryDeadLetterQueueMessage(subscription, message); }),
                    OverflowMenu.deleteItemDefault(`${message.Type} event with ${message.MessageId} id`, () => this.deleteDeadLetterQueueMessage(subscription, message))
                ]}
            headerColumns={["Message Id", "Sequence Number", "Type", "State", "Enqueued", "Body"]}
            onEmpty={<div>No messages found</div>}
        />;
    }

    private buildMessageRow = (message: MessageBusMessageResource) => {
        return [
            message.MessageId,
            message.SequenceNumber,
            message.Type,
            message.State,
            <FriendlyLocalTime time={message.Enqueued}/>,
            <pre>{message.Body}</pre>
        ];
    }
}

export default MessageBus;