// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.instanceEnvironmentVariableValue_value__kRpPv {
  max-width: 30rem;
  overflow-wrap: break-word;
  display: inline-block;
}
`, "",{"version":3,"sources":["webpack://./app/areas/settings/instanceEnvironmentVariables/instanceEnvironmentVariableValue.less"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,yBAAA;EACA,qBAAA;AACJ","sourcesContent":[".value {\n    max-width: 30rem;\n    overflow-wrap: break-word;\n    display: inline-block;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"value": `instanceEnvironmentVariableValue_value__kRpPv`
};
module.exports = ___CSS_LOADER_EXPORT___;
