// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_select__aKUA8 {
  width: 100% !important;
  min-width: 0 !important;
  overflow: visible;
  display: block!important;
}
.style_filter__V3JMb {
  margin: 0 1rem;
}
.style_container__yRxX3 {
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./app/components/form/Select/style.less"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,uBAAA;EACA,iBAAA;EACA,wBAAA;AACF;AAEA;EACE,cAAA;AAAF;AAGA;EACE,WAAA;AADF","sourcesContent":[".select {\n  width: 100% !important;\n  min-width: 0 !important;\n  overflow: visible; // Needed to display the floating labels/error messages associated with controls.\n  display: block!important; // Fix for IE11 - otherwise inline-block causes alignment issues on the dashboard with project group filter next to a text-input field.\n}\n\n.filter {\n  margin: 0 1rem;\n}\n\n.container {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select": `style_select__aKUA8`,
	"filter": `style_filter__V3JMb`,
	"container": `style_container__yRxX3`
};
module.exports = ___CSS_LOADER_EXPORT___;
