// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_container__u_H3n {
  display: flex;
  width: 7rem;
  position: relative;
  flex: 0 0 7rem;
}
.style_colorSwatch__VYmKC {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.25em;
  position: absolute;
  bottom: 1rem;
  right: 0.5rem;
  cursor: pointer;
}
.style_colorPickItem__PW96y {
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
  transition: background-color 0.1s linear;
  border-radius: 0;
}
.style_colorAllOptions__WINEq {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./app/components/form/ColorPicker/style.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,WAAA;EACA,kBAAA;EACA,cAAA;AACF;AAEA;EACE,aAAA;EACA,cAAA;EACA,qBAAA;EAEA,kBAAA;EACA,YAAA;EACA,aAAA;EACA,eAAA;AADF;AAIA;EACE,aAAA;EACA,cAAA;EACA,eAAA;EACA,wCAAA;EACA,gBAAA;AAFF;AAWA;EACE,aAAA;EACA,mBAAA;EACA,eAAA;EACA,uBAAA;AATF","sourcesContent":[".container{\n  display:flex;\n  width: 7rem;\n  position: relative;\n  flex: 0 0 7rem; //ie11\n}\n\n.colorSwatch{\n  width: 1.5rem;\n  height: 1.5rem;\n  border-radius: 0.25em;\n\n  position: absolute;\n  bottom: 1rem;\n  right: 0.5rem;\n  cursor: pointer;\n}\n\n.colorPickItem {\n  width: 2.5rem;\n  height: 2.5rem;\n  cursor: pointer;\n  transition: background-color 0.1s linear;\n  border-radius: 0;\n  &:hover{\n    //border: 0.15rem solid lightgrey;\n    //height: 2.25rem;\n    //width: 2.25rem;\n    //border-radius: 0.125rem;\n  }\n}\n\n.colorAllOptions{\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `style_container__u_H3n`,
	"colorSwatch": `style_colorSwatch__VYmKC`,
	"colorPickItem": `style_colorPickItem__PW96y`,
	"colorAllOptions": `style_colorAllOptions__WINEq`
};
module.exports = ___CSS_LOADER_EXPORT___;
