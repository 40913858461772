import * as React from "react";
import {SFC} from "react";
import ReloadableRoute from "components/ReloadableRoute/ReloadableRoute";
import {Switch} from "react-router-dom";
import routeLinks from "routeLinks";
import BackgroundTasks from "areas/backgroundTasks/BackgroundTasks";
import {BackgroundTaskDetails} from "areas/backgroundTasks/BackgroundTaskDetails";

const BackgroundTasksLayout: SFC<{}> = props =>
    <Switch>
        <ReloadableRoute path={routeLinks.backgroundTasks.root} exact={true} component={BackgroundTasks} />
        <ReloadableRoute path={routeLinks.backgroundTasks.task(":backgroundTaskId").root} component={BackgroundTaskDetails} />
    </Switch>;

BackgroundTasksLayout.displayName = "BackgroundTasksLayout";

export default BackgroundTasksLayout;