import React from "react";
import {BooleanRadioButton, BooleanRadioButtonGroup} from "@octopusdeploy/design-system-components";
import {DataBaseComponent, DataBaseComponentState, Errors} from "../../../components/DataBaseComponent";
import {BusyState} from "../../../components/BusyFromPromise/BusyFromPromise";
import {HostedInstanceResource} from "../../../client/resources/hostedInstanceResource";
import SaveDialogLayout from "../../../components/DialogLayout/SaveDialogLayout";
import repository from "../../../client/repository";
import {HostedInstanceRotateMasterKeyRequest} from "../../../client/resources/HostedInstanceRotateMasterKeyRequest";
import {AreYouReallySureConfirmation} from "./AreYouReallySureConfirmation";

interface Props {
    errors: Errors;
    busy: BusyState;
    instance: HostedInstanceResource;
    onSaveClick(): Promise<boolean>;
}

interface State extends DataBaseComponentState {
    rotateMasterKeyRequest: Partial<HostedInstanceRotateMasterKeyRequest>;
    canRotateMasterKey: boolean;
}


export class RotateMasterKeyDialog extends DataBaseComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            rotateMasterKeyRequest: {
                ApplyDuringMaintenanceWindow: true,
                WaitForShortTermBackupsToExpire: true
            },
            canRotateMasterKey: false,
        };
    }

    render() {
        const request = this.state.rotateMasterKeyRequest;
        return <SaveDialogLayout title={"Rotate Master Key"}
                                 errors={this.state.errors}
                                 busy={this.state.busy}
                                 saveButtonDisabled={!this.state.canRotateMasterKey}
                                 onSaveClick={() => this.rotateMasterKey(request)}>

            <AreYouReallySureConfirmation title={"Backups will become inaccessible"}
                                          warning={<>Database backups <strong>cannot be restored</strong> after rotating the master key. Do not proceed if you require access to these backups. The <strong>Administrator</strong> role is required to rotate a master key.</>}
                                          requiredConfirmation={"affected backups"}
                                          onChange={acknowledged => this.setState({canRotateMasterKey: acknowledged})} />

            <BooleanRadioButtonGroup value={this.state.rotateMasterKeyRequest.ApplyDuringMaintenanceWindow}
                              onChange={ApplyDuringOutage => this.updateRotateMasterKeyRequest({ApplyDuringMaintenanceWindow: ApplyDuringOutage})}>
                <BooleanRadioButton value={true} label="Apply During Outage Window"/>
                <BooleanRadioButton value={false} label="Apply Immediately"/>
            </BooleanRadioButtonGroup>
        </SaveDialogLayout>;
    }

    private updateRotateMasterKeyRequest(update: Partial<HostedInstanceRotateMasterKeyRequest>) {
        return this.setState(prevState => ({rotateMasterKeyRequest: {...prevState.rotateMasterKeyRequest, ...update}}));
    }

    private async rotateMasterKey(request: Partial<HostedInstanceRotateMasterKeyRequest>) {
        return await this.doBusyTask(async () => {
            await repository.HostedInstances.rotateMasterKey(this.props.instance, request as HostedInstanceRotateMasterKeyRequest);
            await this.props.onSaveClick();
            return true;
        });
    }
}
