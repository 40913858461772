import * as React from "react";
import SaveDialogLayout from "components/DialogLayout/SaveDialogLayout";
import repository from "client/repository";
import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent";
import {Callout, CalloutType} from "../../components/Callout";
import {HubResource} from "../../client/resources/hubResource";
import Select from "components/form/Select/Select";

interface Props {
    reefId: string;
    hubId: string;
    onChange: () => void;
}

interface State extends DataBaseComponentState {
    hubId: string;
    hubs?: HubResource[];
}

class ChangeHubDialog extends DataBaseComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            hubId: props.hubId,
        };
    }

    render() {
        return <SaveDialogLayout title={"Hub Configuration"}
            onSaveClick={() => this.changeHub()}
            errors={this.state.errors}
            saveButtonDisabled={this.state.hubId === this.props.hubId}
            busy={this.state.busy}>
            <Callout type={CalloutType.Warning} title="Warning">
                <p>Ensure the hub is not hosted on this reef</p>
            </Callout>

            {this.state.hubs && <Select value={this.state.hubId}
                    items={this.state.hubs.map(h => ({text: h.DisplayName, value: h.Id}))}
                    onChange={hubId => this.setState({hubId})}/>}
        </SaveDialogLayout>;
    }

    componentDidMount() {
        return this.doBusyTask(async () => {
            this.setState(await this.loadData());
        });
    }
    
    async loadData() {
        const hubs= await repository.Hubs.list();
        return {hubs};
    }


    private async changeHub(): Promise<boolean> {
        return this.doBusyTask(async () => {
            const request = { HubId: this.state.hubId };
            
            await repository.Reefs.changeHub(this.props.reefId, request);
            this.props.onChange();
        });
    }

    
}

export default ChangeHubDialog;