import * as React from "react";
import {BaseComponent} from "../BaseComponent/BaseComponent";
import styles = require("./style.less");
import DeleteDialog from "components/Dialog/DeleteDialog";
import ActionButton, {ActionButtonType} from "components/Button/ActionButton";

interface OpenDeleteDialogButtonProps {
    dialogTitle: string;
    type?: ActionButtonType;
    label?: string;
    disabled: boolean;
    dialogButtonLabel?: string;
    dialogButtonBusyLabel?: string;

    onDeleteClick(): Promise<boolean>;
}

interface OpenDeleteDialogButtonState {
    open: boolean;
}

export default class OpenDeleteDialogButton extends BaseComponent <OpenDeleteDialogButtonProps, OpenDeleteDialogButtonState> {
    constructor(props: OpenDeleteDialogButtonProps) {
        super(props);
        this.state = {open: false};
    }

    render() {
        return <div className={styles.buttonWrapper}>
            <ActionButton
                type={this.props.type || ActionButtonType.Delete}
                onClick={() => this.setState({open: true})}
                label={this.props.label}
                disabled={this.props.disabled}
            />
            <DeleteDialog
                title={this.props.dialogTitle}
                deleteButtonLabel={this.props.dialogButtonLabel}
                deleteButtonBusyLabel={this.props.dialogButtonBusyLabel}
                open={this.state.open}
                onClose={() => this.setState({open: false})}
                onDeleteClick={() => this.props.onDeleteClick()}
                renderContent={() => this.props.children}
            />
        </div>;
    }
}