// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* This file is DEPRECATED
   We are moving to the Octopus Design System
   https://www.octopus.design
  
   When changing component styles:
   1. Do not use variables from this file
   2. Aim to use Emotion \`css\` + themeTokens.colors
       - see Dashboard.tsx for example
   3. If you need to use Less, reference CSS variables
       - see index.ejs for example
       - for full list see https://github.com/OctopusDeploy/OctopusDeploy/blob/main/frontend/packages/design-system-tokens/src/generated/themeTokens.ts
   4. Remove variables from this file when they are no longer used
 */
.style_markdownEditorContainer__icMGZ {
  position: relative;
  width: 100%;
}
.style_restrictedHeight__zVcyZ {
  max-height: 15rem;
}
.style_iconButton__ZgM4J {
  width: 2rem !important;
  min-width: 2rem !important;
}
.style_iconButton__ZgM4J:hover span {
  opacity: 1;
}
.style_iconButton__ZgM4J span {
  padding-left: 0rem !important;
  padding-right: 0rem !important;
  opacity: 0.6;
}
.style_markdownSupported__pHPZh {
  padding-right: 0.25rem;
}
.style_iconButtonContainer__l7DNE {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  background: var(--colorBackgroundPrimaryHovered);
  border: 0.0625rem solid var(--colorBorderSubtle);
  border-bottom: 0;
}
.style_textarea__EFcLb {
  max-width: inherit !important;
}
`, "",{"version":3,"sources":["webpack://./app/components/form/MarkdownEditor/style.less"],"names":[],"mappings":"AAAA;;;;;;;;;;;;EAYE;AAVF;EACI,kBAAA;EACA,WAAA;AAYJ;AATA;EACI,iBAAA;AAWJ;AARA;EACI,sBAAA;EACA,0BAAA;AAUJ;AATI;EACI,UAAA;AAWR;AAfA;EAOQ,6BAAA;EACA,8BAAA;EACA,YAAA;AAWR;AAPA;EACI,sBAAA;AASJ;AANA;EACI,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,gDAAA;EACA,gDAAA;EACA,gBAAA;AAQJ;AALA;EACI,6BAAA;AAOJ","sourcesContent":["@import \"../../../resources/styles/colors.less\";\n\n.markdownEditorContainer {\n    position: relative;\n    width:100%;\n}\n\n.restrictedHeight {\n    max-height: 15rem;\n}\n\n.iconButton {\n    width: 2rem !important;\n    min-width: 2rem !important;\n    &:hover span {\n        opacity: 1;\n    }\n    span {\n        padding-left: 0rem !important;\n        padding-right: 0rem !important;\n        opacity: 0.6;\n    }\n}\n\n.markdownSupported{\n    padding-right: 0.25rem;\n}\n\n.iconButtonContainer {\n    display: flex;\n    flex-direction: row;\n    justify-content: flex-end;\n    background: @hover;\n    border: 0.0625rem solid @divider;\n    border-bottom: 0;\n}\n\n.textarea {\n    max-width: inherit !important\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"markdownEditorContainer": `style_markdownEditorContainer__icMGZ`,
	"restrictedHeight": `style_restrictedHeight__zVcyZ`,
	"iconButton": `style_iconButton__ZgM4J`,
	"markdownSupported": `style_markdownSupported__pHPZh`,
	"iconButtonContainer": `style_iconButtonContainer__l7DNE`,
	"textarea": `style_textarea__EFcLb`
};
module.exports = ___CSS_LOADER_EXPORT___;
