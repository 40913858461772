export interface DynamicWorkerInstanceResource {
	State: DynamicWorkerState;
	WorkerTypeId: string;
	Size: string;
	ApiKeyId: string;
	OctopusServerId: string;
	HostName: string;
	InstanceName: string;
	Port: number;
	Thumbprint: string;
	Created: string;
	LastStateChange: string;
	Id: string;
	HostedInstanceShortId: string;
	Source: string;
	CrowdStrikeAgentId: string;
}

export enum DynamicWorkerState {
	New = "New",
	Provisioning = "Provisioning",
	Provisioned = "Provisioned",
	PendingConfigureTrust = "PendingConfigureTrust",
	ConfiguringTrust = "ConfiguringTrust",
	TrustConfigured = "TrustConfigured",
	QueuedToDelete = "QueuedToDelete",
	Deleting = "Deleting",
	DoNotLease = "DoNotLease",
	KeepForDebugging = "KeepForDebugging"
}