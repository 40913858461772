import React, {FC, useState} from "react";
import {Switch} from "@octopusdeploy/design-system-components";
import ConfirmationDialog from "../../components/Dialog/ConfirmationDialog";

interface SwitchWithConfirmationProps {
    value: boolean;
    onChange: (newValue: boolean) => Promise<void>;
    settingName: string;
    noun: string;
}

const SwitchWithConfirmation: FC<SwitchWithConfirmationProps> = ({
    value,
    onChange,
    settingName,
    noun,
}) => {
    const verb = value ? "Disable" : "Enable";
    const [isOpen, setOpen] = useState(false);
    
    const onConfirm = (): Promise<void> => {
        return onChange(!value);
    };
    
    return <>
        <Switch value={value} onChange={() => setOpen(true)} />
        <ConfirmationDialog
            open={isOpen}
            onClose={() => setOpen(false)}
            onContinueClick={onConfirm}
            title={`${verb} ${noun}`}
            continueButtonLabel={verb}
        >
            Are you sure you want to {verb.toLowerCase()} <strong>{settingName}</strong>?
        </ConfirmationDialog>
    </>;
};

export default SwitchWithConfirmation;
