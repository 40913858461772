import {ThemeName} from "@octopusdeploy/design-system-components";
import {themeTokens} from "@octopusdeploy/design-system-tokens";
import {MuiTheme, ThemePalette, getMuiTheme} from "material-ui/styles";
import darkBaseTheme from "material-ui/styles/baseThemes/darkBaseTheme";
import lightBaseTheme from "material-ui/styles/baseThemes/lightBaseTheme";

// Overrides of default theme
// https://github.com/mui/material-ui/blob/e5014bcd2f697bb3654d1c34d66355c8f85e7cf2/src/styles/getMuiTheme.js#L31
const baseTheme: MuiTheme = {
    slider: {
        selectionColor: themeTokens.color.text.link.default,
    },
    menuItem: {
        hoverColor: themeTokens.color.menuList.background.hover,
    },
};

const basePalette: ThemePalette = {
    textColor: themeTokens.color.text.primary,
    secondaryTextColor: themeTokens.color.text.subtle,
    canvasColor: themeTokens.color.background.primary.default,
    borderColor: themeTokens.color.border.primary,
}

const darkTheme: MuiTheme = {
    ...baseTheme,
    baseTheme: darkBaseTheme,
    palette: {
        ...darkBaseTheme.palette,
        ...basePalette,
    },
}

const lightTheme: MuiTheme = {
    ...baseTheme,
    baseTheme: lightBaseTheme,
    palette: {
        ...lightBaseTheme.palette,
        ...basePalette,
    }
}

export function createMuiTheme(themeName: ThemeName): MuiTheme {
    const theme = themeName === "dark" ? darkTheme : lightTheme;
    return getMuiTheme(theme);
}
