import * as React from "react";
import {
    DataTable,
    DataTableHeader,
    DataTableHeaderColumn,
    DataTableBody,
    DataTableToolsRow,
    DataTableRow,
    DataTableRowColumn
} from "../DataTable";
import BaseComponent from "../BaseComponent";
import styles = require("./style.less");
import cn from "classnames";
import InternalRedirect from "../Navigation/InternalRedirect/InternalRedirect";
import TransitionAnimation from "components/TransitionAnimation/TransitionAnimation";
import OverflowMenu, {MenuItem} from "components/Menu/OverflowMenu";

interface SimpleDataTableProps<TData> {
    onToolsSection?: any;
    headerColumns: any[];
    headerColumnClassName?: string;
    rowColumnClassName?: string;
    tableClassName?: string;
    data: TData[];
    onEmpty?: React.ReactNode;
    match?: any;
    onRow(item: TData): React.ReactNode[];
    onRowOverflowMenu?(item: TData): MenuItem[];
    onRowRedirectUrl?(item: TData): string;
    onRowClick?(item: TData): void;
}

interface SimpleDataTableState {
    redirectTo?: string;
}

export default abstract class SimpleDataTable<TData> extends BaseComponent<SimpleDataTableProps<TData>, SimpleDataTableState> {
    constructor(props: SimpleDataTableProps<TData>) {
        super(props);
        this.state = {};
    }

    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo} push={true} />;
        }

        const {
            onToolsSection,
            data,
            headerColumns,
            headerColumnClassName,
            rowColumnClassName,
            onRow,
            onEmpty
        } = this.props;

        if (!data) {
            return null;
        }

        return <TransitionAnimation>
            <DataTable>
                <DataTableHeader>
                    {onToolsSection ?
                        <DataTableToolsRow>
                            <DataTableHeaderColumn
                            colSpan={headerColumns.length}>
                            {onToolsSection()}
                            </DataTableHeaderColumn>
                        </DataTableToolsRow>
                        : null
                    }
                    {data.length > 0
                    ? this.buildTableHeader(headerColumns, headerColumnClassName)
                    : null}
                </DataTableHeader>
                <DataTableBody>
                {data.length > 0
                ? data.map((item, index) => {
                    return this.buildTableRow(item, index, onRow(item), rowColumnClassName);
                })
                : <DataTableRow displayNoBorder={true}>
                    <DataTableRowColumn>
                        {onEmpty}
                    </DataTableRowColumn>
                </DataTableRow>
                }
                </DataTableBody>
            </DataTable>
        </TransitionAnimation>;
    }

    private buildTableHeader(headerColumns: any[], className: string) {
        return (
            <DataTableRow>
                {headerColumns.map((col, index) => {
                    const props: any = {
                        key: index
                    };

                    if (className) {
                        props["className"] = className;
                    }

                    return (
                        <DataTableHeaderColumn {...props}>
                            {col}
                        </DataTableHeaderColumn>
                    );
                })}
                {this.props.onRowOverflowMenu && <DataTableHeaderColumn className={styles.overflowMenuColumn} />}
            </DataTableRow>
        );
    }

    private buildTableRow(item: TData, index: number, rowColumns: any, className: string) {
        // Only some tables include a redirect, so only show cursor if necessary.
        const redirectUrl = this.getNavigationUrl(this.props, item);
        const linkClassName = redirectUrl ? styles.linkableItem : null;
        return (
            <DataTableRow key={index} onClick={(e: any) => this.props.onRowClick ? this.props.onRowClick(item) : this.navigate(item)}>
                {rowColumns.map((col: any, idx: number) => {
                    const props: any = {
                        key: idx
                    };
                    if (className) {
                        props["className"] = cn(className, props["className"]);
                    }
                    if (linkClassName) {
                        props["className"] = cn(linkClassName, props["className"]);
                    }
                    return (
                        <DataTableRowColumn {...props}>
                            {col}
                        </DataTableRowColumn>
                    );
                })}
                {this.props.onRowOverflowMenu && <DataTableRowColumn className={styles.overflowMenuColumn}>
                    <OverflowMenu menuItems={this.props.onRowOverflowMenu(item)} />
                </DataTableRowColumn>}
            </DataTableRow>
        );
    }

    private navigate(item: TData) {
        const redirectTo = this.getNavigationUrl(this.props, item);
        if (!redirectTo) {
            return;
        }
        this.setState({ redirectTo });
    }

    private getNavigationUrl<R>({ match, onRowRedirectUrl }: { match?: any, onRowRedirectUrl?(item: R): string }, item: R): string {
        if (onRowRedirectUrl) {
            return onRowRedirectUrl(item);
        } else if (match && (item as any).Id) {
            return `${match.url}/${(item as any).Id}`;
        }
        return null;
    }
}