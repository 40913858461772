import * as React from "react";
import {Route, RouteProps} from "react-router-dom";
import ErrorBoundary from "components/ErrorBoundary";

type ReloadableRouteProps = RouteProps & {computedMatch?: any};

export default class ReloadableRoute extends React.Component<ReloadableRouteProps, any> {
    render() {
        // We are tagging the parent route of each component with a key made out of params it depends on so redirects to the same component with different
        // params unmount it and mount it again.
        // This can also be done by responding to changes in props but this is a lot of work to do it correctly and it is very easy to forget about it.
        // Details: https://github.com/ReactTraining/react-router/issues/1982
        const key = this.getComponentKey(this.props.computedMatch);
        return <ErrorBoundary><Route {...this.props} key={key} /></ErrorBoundary>;
    }

    private accumulate = (acc: string, paramName: string, params: any)  => {
        return acc ? `${acc}/${params[paramName]}` : params[paramName];
    }

    private getComponentKey(match: any) {
        const params = match && match.params;
        return params ?  Object.keys(params).reduce((acc, paramName) => this.accumulate(acc, paramName, params), null) : null;
    }
}
