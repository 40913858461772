import client from "client/client";
import {AuditEntryResource} from "client/resources/auditEntryResource";
import {ListArgs} from "./listArgs";

export interface AuditListArgs {
    documentId: string;
}

export default class AuditRepository {

    private rootUri = "/api/audit";
    private defaultListArgs: ListArgs = {take: 100, skip: 0};

    list(args?: Partial<AuditListArgs> | ListArgs): Promise<AuditEntryResource[]> {
        return client.get(`${this.rootUri}{?documentId,skip,take}`, {...this.defaultListArgs, ...args});
    }
}
