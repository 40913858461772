// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* This file is DEPRECATED
   We are moving to the Octopus Design System
   https://www.octopus.design
  
   When changing component styles:
   1. Do not use variables from this file
   2. Aim to use Emotion \`css\` + themeTokens.colors
       - see Dashboard.tsx for example
   3. If you need to use Less, reference CSS variables
       - see index.ejs for example
       - for full list see https://github.com/OctopusDeploy/OctopusDeploy/blob/main/frontend/packages/design-system-tokens/src/generated/themeTokens.ts
   4. Remove variables from this file when they are no longer used
 */
.style_internalLink__gXSEz {
  color: var(--colorTextLinkDefault);
  text-decoration: none;
}
.style_internalLink__gXSEz:hover {
  color: var(--colorTextLinkPressed);
}
.style_listInternalLink__q52bZ {
  color: inherit;
}
.style_listInternalLink__q52bZ:hover,
.style_listInternalLink__q52bZ:focus {
  color: inherit;
}
`, "",{"version":3,"sources":["webpack://./app/components/Navigation/InternalLink/style.less"],"names":[],"mappings":"AAAA;;;;;;;;;;;;EAYE;AAVF;EACE,kCAAA;EACA,qBAAA;AAYF;AAVE;EACE,kCAAA;AAYJ;AARA;EACE,cAAA;AAUF;AATE;;EACE,cAAA;AAYJ","sourcesContent":["@import \"../../../resources/styles/colors\";\n\n.internalLink {\n  color: @primary;\n  text-decoration: none;\n\n  &:hover {\n    color: @primaryDark\n  }\n}\n\n.listInternalLink {\n  color: inherit; // inherit from parent so these don't \"appear\" like links.\n  &:hover, &:focus {\n    color: inherit;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"internalLink": `style_internalLink__gXSEz`,
	"listInternalLink": `style_listInternalLink__q52bZ`
};
module.exports = ___CSS_LOADER_EXPORT___;
