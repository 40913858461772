import * as React from "react";
import {CommonChipProps} from "components/Chips";
import {InstanceStatus} from "client/resources/instanceStatus";
import {chipWithAvatar} from "components/Chips/Chip";
import SvgIconProps = __MaterialUI.SvgIconProps;

const icon = (props: SvgIconProps) =>
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width || 24} height={props.height || 24} viewBox="0 0 24 24" fillRule="evenodd" clipRule="evenodd">
        <path d="M21.826 9h-2.086c.171-.487.262-.957.262-1.41 0-2.326-1.818-3.776-4.024-3.573-2.681.247-4.518 3.71-4.978 4.484-.527-.863-2.261-4.238-4.981-4.494-2.11-.199-4.019 1.181-4.019 3.582 0 3.109 4.347 7.084 9.001 11.615 1.16-1.127 2.285-2.208 3.324-3.243l.97 1.857c-1.318 1.302-2.769 2.686-4.294 4.181-6.164-6.037-11.001-10.202-11.001-14.403 0-3.294 2.462-5.526 5.674-5.596 2.163-.009 4.125.957 5.327 2.952 1.177-1.956 3.146-2.942 5.253-2.942 3.064 0 5.746 2.115 5.746 5.595 0 .464-.06.928-.174 1.395zm-11.094 4c-.346.598-.992 1-1.732 1-1.104 0-2-.896-2-2s.896-2 2-2c.74 0 1.386.402 1.732 1h1.222l1.88-2.71c.14-.202.376-.315.622-.299.245.016.464.161.576.38l2.27 4.437.813-1.45c.124-.221.357-.358.611-.358h5.274v2h-4.513l-1.759 2.908c-.132.219-.373.348-.629.337-.255-.01-.484-.16-.598-.389l-2.256-4.559-.989 1.406c-.131.186-.345.297-.573.297h-1.951z"/>
    </svg>;

const InstanceStatusChip: React.StatelessComponent<{ ring: InstanceStatus; } & CommonChipProps> = props => {
    const {ring, ...rest} = props;
    return chipWithAvatar(ring, icon, {description: "Status: " + ring, ...rest});
};

export default InstanceStatusChip;