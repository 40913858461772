import { HostedInstanceTaskLockListArgs } from "client/repositories/hostedInstanceRepository";

export const instanceTaskLocksRouteLinks = {
    root: "/instanceTasklocks",
    filtered,
};

function filtered(filter: HostedInstanceTaskLockListArgs) {
    const filters = [];

    if (filter.relatedToHostedInstanceIds) {
        filter.relatedToHostedInstanceIds.forEach(id => filters.push(`relatedToHostedInstanceIds=${id}`));
    }

    if (filter.relatedToTaskId) {
        filters.push(`relatedToTaskId=${filter.relatedToTaskId}`);
    }

    return `/instanceTasklocks?${filters.join("&")}`;
}