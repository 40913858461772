import React from "react";
import {BooleanRadioButton, BooleanRadioButtonGroup} from "@octopusdeploy/design-system-components";
import {HostedInstanceResource} from "../../../client/resources/hostedInstanceResource";
import {BusyState} from "../../../components/BusyFromPromise/BusyFromPromise";
import {DataBaseComponent, DataBaseComponentState, Errors} from "../../../components/DataBaseComponent";
import {HostedInstanceRunSqlRequest} from "../../../client/resources/hostedInstanceRunSqlRequest";
import SaveDialogLayout from "../../../components/DialogLayout/SaveDialogLayout";
import {Callout, CalloutType} from "../../../components/Callout";
import {Select} from "../../../components/form";
import humanizeDuration from "humanize-duration";
import {Text} from "components/form";
import repository from "../../../client/repository";

interface RunSqlDialogProps {
    errors: Errors;
    busy: BusyState;
    instance: HostedInstanceResource;
    onSaveClick(): Promise<boolean>;
}

interface RunSqlDialogState extends DataBaseComponentState {
    runSqlRequest: Partial<HostedInstanceRunSqlRequest>;
}

export class RunSqlDialog extends DataBaseComponent<RunSqlDialogProps, RunSqlDialogState> {
    constructor(props: RunSqlDialogProps) {
        super(props);
        this.state = {
            runSqlRequest: {
                CommandTimeout: "0:05:00",
                RunSqlDuringOutageWindow: false,
                IsQuery: true
            }
        }
    }

    render() {
        const commandTimeouts = [30, 300, 900, 1800, 3600, 7200, 43200, 86400].map(t => ({
            value: `${Math.floor(t / 3600)}:${Math.floor(t / 60 % 60).toString().padStart(2, "0")}:${(t % 60).toString().padStart(2, "0")}`, // h:mm:ss
            text: humanizeDuration(t * 1000, {units: ["h", "m", "s"]})
        }));
        const request = this.state.runSqlRequest;

        return <SaveDialogLayout title={"Run SQL"}
                                 busy={this.state.busy}
                                 errors={this.state.errors}
                                 saveButtonLabel={"Run"}
                                 cancelButtonLabel={"Cancel"}
                                 onSaveClick={() => this.runSql(request)}>

            <Callout type={CalloutType.Warning} title="Warning">
                <li>Obtain <strong>permission</strong> from the instance owner before running a query.</li>
                <li>Ensure the query will not cause outage or <strong>performance</strong> issues, especially if run during the outage window when nobody is watching.</li>
                <li>Ensure your SQL is <strong>idempotent</strong> as it <strong>may be run multiple times</strong> due to Cloud Portal's built-in retry logic.</li>
            </Callout>


            <BooleanRadioButtonGroup value={this.state.runSqlRequest.RunSqlDuringOutageWindow} onChange={RunSqlDuringOutageWindow => this.updateRunSqlRequest({RunSqlDuringOutageWindow})}>
                <BooleanRadioButton value={true} label="Run During Outage Window" />
                <BooleanRadioButton value={false} label="Run Immediately" />
            </BooleanRadioButtonGroup>

            <Callout type={CalloutType.Information} title="Results">
                If your SQL statement is a query then the results will be embedded in the task's state.
            </Callout>

            <BooleanRadioButtonGroup value={this.state.runSqlRequest.IsQuery} onChange={IsQuery => this.updateRunSqlRequest({IsQuery})}>
                <BooleanRadioButton value={true} label="Run Query (e.g. Select)" />
                <BooleanRadioButton value={false} label="Run Command (e.g. Update/Insert/Delete)" />
            </BooleanRadioButtonGroup>

            <Select value={this.state.runSqlRequest.CommandTimeout.toString()}
                    onChange={CommandTimeout => this.updateRunSqlRequest({CommandTimeout})}
                    items={commandTimeouts}
                    fieldName="execution timeout"
            />

            <Text label="Permission"
                  hintText="Link to email, support ticket, etc."
                  value={this.state.runSqlRequest.Permission}
                  onChange={Permission => this.updateRunSqlRequest({Permission})}
                  autoFocus={true}
                  multiLine={true}
                  rows={2}
            />

            <Text label="SQL"
                  hintText="SQL"
                  value={this.state.runSqlRequest.Sql}
                  onChange={Sql => this.updateRunSqlRequest({Sql})}
                  multiLine={true}
                  rows={10}
            />

        </SaveDialogLayout>;
    }

    private updateRunSqlRequest(update: Partial<HostedInstanceRunSqlRequest>) {
        return this.setState(prevState => ({runSqlRequest: {...prevState.runSqlRequest, ...update}}));
    }

    private runSql(request: Partial<HostedInstanceRunSqlRequest>) {
        return this.doBusyTask(async () => {
            await repository.HostedInstances.runSql(this.props.instance, request as HostedInstanceRunSqlRequest);
            await this.props.onSaveClick();
            return true;
        })
    }
}
