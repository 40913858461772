import * as React from "react";
import ErrorPanel from "components/ErrorPanel";
import {UnhandledError} from "globalState";
import Sticky from "components/Sticky/Sticky";
import {Switch} from "react-router-dom";
import ReloadableRoute from "components/ReloadableRoute/ReloadableRoute";
import InstancesLayout from "areas/instances/InstancesLayout";
import routeLinks from "routeLinks";
import Dashboard from "areas/dashboard/Dashboard";
import {InternalRedirect} from "components/Navigation/InternalRedirect";
import Navbar from "areas/root/Navbar/Navbar";
import styles = require("./style.less");
import BackgroundTasksLayout from "areas/backgroundTasks/BackgroundTasksLayout";
import ReefsLayout from "areas/reefs/ReefsLayout";
import AuditLayout from "areas/auditing/AuditLayout";
import InstanceTaskLocksLayout from "areas/instanceTaskLocks/InstanceTaskLocksLayout";
import SettingsLayout from "areas/settings/SettingsLayout";
import MessageBusLayout from "../messageBus/MessageBusLayout";
import CloudSubscriptionLayout from "../instances/Instances/CloudSubscriptionLayout";
import AzureRegionsLayout from "areas/azureRegions/AzureRegionsLayout";
import DynamicWorkerServicesLayout from "../dynamicWorkerServices/DynamicWorkerServicesLayout";
import HubsLayout from "../hubs/HubsLayout";

interface RootLayoutProps {
    versionText: string;
    unhandledError: UnhandledError;
    onErrorClose(): void;
}

export default class RootLayoutInternal extends React.Component<RootLayoutProps> {
    render() {
        return <div>
            <Sticky innerZ={10} enableTransforms={false}>
                <div id="toppanel">
                    <Navbar/>
                    {this.props.unhandledError &&
                    <ErrorPanel message={`An unexpected error occurred: ${this.props.unhandledError.message}`}
                                details={this.props.unhandledError.details}
                                fullWidth={true}
                                canClose={true}
                                onErrorClose={this.onErrorClose}/>}
                </div>
            </Sticky>

            <div className={styles.main}>
                <Switch>
                    <ReloadableRoute path={routeLinks.instances.root} exact={false} component={InstancesLayout}/>
                    <ReloadableRoute path={routeLinks.cloudSubscriptions.subscription(":cloudSubscriptionId").root} exact={false} component={CloudSubscriptionLayout}/>
                    <ReloadableRoute path={routeLinks.backgroundTasks.root} exact={false} component={BackgroundTasksLayout}/>
                    <ReloadableRoute path={routeLinks.dynamicWorkerServices.root} exact={false} component={DynamicWorkerServicesLayout}/>
                    <ReloadableRoute path={routeLinks.reefs.root} exact={false} component={ReefsLayout}/>
                    <ReloadableRoute path={routeLinks.messageBus.root} exact={false} component={MessageBusLayout}/>
                    <ReloadableRoute path={routeLinks.audit.root} exact={false} component={AuditLayout}/>
                    <ReloadableRoute path={routeLinks.instanceTaskLocks.root} exact={false} component={InstanceTaskLocksLayout}/>
                    <ReloadableRoute path={routeLinks.settings.root} exact={false} component={SettingsLayout}/>
                    <ReloadableRoute path={routeLinks.azureRegions.root} exact={false} component={AzureRegionsLayout}/>
                    <ReloadableRoute path={routeLinks.hubs.root} exact={false} component={HubsLayout}/>
                    <ReloadableRoute path={routeLinks.root} exact={true} component={Dashboard}/>
                    <ReloadableRoute>
                        <InternalRedirect to={routeLinks.root} />
                    </ReloadableRoute>
                </Switch>
             </div>
        </div>;
    }

    private onErrorClose = () => {
        this.props.onErrorClose();
    }
}