import * as React from "react";
import {FunctionComponent} from "react";
import {Switch} from "react-router-dom";
import routeLinks from "routeLinks";
import InternalRedirect from "components/Navigation/InternalRedirect";
import NavigationSidebarLayout, {Navigation} from "components/NavigationSidebarLayout";
import ReloadableRoute from "components/ReloadableRoute/ReloadableRoute";
import FeatureFlags from "./FeatureFlags";
import {settingsRouteLinks} from "./settingsRouteLinks";
import InstanceEnvironmentVariables from "./instanceEnvironmentVariables/InstanceEnvironmentVariables";

const SettingsLayout: FunctionComponent<{}> = props => {
    const navLinks = [
        Navigation.navItem("Portal Feature Flags", settingsRouteLinks.featureFlags, true),
        Navigation.navItem("Instance Environment Variables", settingsRouteLinks.instanceEnvironmentVariables, true),
    ];

    const content = <Switch>
        <ReloadableRoute path={routeLinks.settings.featureFlags} exact={true} component={FeatureFlags} />
        <ReloadableRoute path={routeLinks.settings.instanceEnvironmentVariables} exact={true} component={InstanceEnvironmentVariables} />
        <ReloadableRoute>
            <InternalRedirect to={routeLinks.settings.featureFlags} />
        </ReloadableRoute>
    </Switch>;

    return <NavigationSidebarLayout navLinks={navLinks} content={content} name="Settings" />;
};

SettingsLayout.displayName = "SettingsLayout";

export default SettingsLayout;