import * as React from "react";
import {Checkbox} from "@octopusdeploy/design-system-components";
import {FormBaseComponent, OptionalFormBaseComponentState} from "components/form/FormBaseComponent";
import FormPaperLayout from "components/FormPaperLayout/FormPaperLayout";
import {InternalRedirect} from "components/Navigation/InternalRedirect";
import routeLinks from "routeLinks";
import {MoveHostedInstancesRequest} from "client/resources/moveHostedInstancesRequest";
import repository from "client/repository";
import {ReefResource} from "../../../client/resources/reefResource";
import Summary from "../../../components/form/Sections/Summary";
import Select from "../../../components/form/Select/Select";
import ExpandableFormSection from "../../../components/form/Sections/ExpandableFormSection";
import {Text} from "../../../components/form";
import {cloneDeep} from "lodash";
import {reefDisplayLabel} from "../../reefs/ReefDisplayLabel";

interface State extends OptionalFormBaseComponentState<Partial<MoveHostedInstancesRequest>> {
    model: Partial<MoveHostedInstancesRequest>;
    cleanModel: Partial<MoveHostedInstancesRequest>;
    reefs: Map<string, ReefResource>;
    redirectTo?: string;
}


class MoveHostedInstances extends FormBaseComponent<{}, State, Partial<MoveHostedInstancesRequest>> {
    constructor(props: {}) {
        super(props);

        const model = { MaxConcurrentMoveTasks: 5, IncludeLiveInstances: false, IncludeDeactivatedInstances: false };

        this.state = {
            model,
            cleanModel: cloneDeep(model),
            reefs: new Map<string, ReefResource>()
        };
    }

    async componentDidMount() {
        return await this.doBusyTask(async () => {
            const [reefs] = await Promise.all([repository.Reefs.map()]);
            this.setState({reefs});
        });
    }

    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo}/>;
        }

        return <FormPaperLayout
            title="Move Hosted Instances"
            busy={this.state.busy}
            errors={this.state.errors}
            model={this.state.model}
            cleanModel={this.state.cleanModel}
            onSaveClick={() => this.handleSaveClick()}
            saveButtonBusyLabel={"Moving"}
            saveButtonLabel={"Move"}
            overFlowActions={null}
            expandAllOnMount={true}>
            {this.getBody()}
        </FormPaperLayout>;
    }

    getBody() {
        const model = this.state.model;

        return <div>
            <ExpandableFormSection
                errorKey="SourceReef"
                title="Source Reef"
                summary={Summary.summary("Source Reef")}
                help={<span>Select the reef in which to move all of the instances from.</span>}>
                <Select
                    fieldName="Source Reef"
                    value={this.state.model.SourceReefId}
                    items={Array
                        .from(this.state.reefs.values())
                        .map(r => ({text: reefDisplayLabel(r), value: r.Id}))
                    }
                    onChange={(sourceReefId: any) => this.setModelState({SourceReefId: sourceReefId})}
                    hintText="The reef in which to move all of the instances from."
                />
            </ExpandableFormSection>
            <ExpandableFormSection
                errorKey="DestinationReef"
                title="Destination Reef"
                summary={Summary.summary("Destination Reef")}
                help={<span>Select the reef in which to move all of the instances to.</span>}>
                <Select
                    fieldName="Destination Reef"
                    value={this.state.model.DestinationReefId}
                    items={Array
                        .from(this.state.reefs.values())
                        .filter(r => r.Id !== this.state.model.SourceReefId)
                        .map(r => ({text: reefDisplayLabel(r), value: r.Id}))
                    }
                    onChange={(destinationReefId: any) => this.setModelState({DestinationReefId: destinationReefId})}
                    hintText="The reef in which to move all of the instances to."
                />
            </ExpandableFormSection>
            <ExpandableFormSection
                summary={Summary.summary("Instance Statuses")}
                title="Instance Statuses"
                errorKey="Instance Statuses">

                <Checkbox label="Include Live Instances"
                          value={model.IncludeLiveInstances}
                          onChange={IncludeLiveInstances => this.setModelState({IncludeLiveInstances})}
                          error={this.getFieldError("IncludeLiveInstances")}
                />

                <Checkbox label="Include Deactivated Instances"
                          value={model.IncludeDeactivatedInstances}
                          onChange={IncludeDeactivatedInstances => this.setModelState({IncludeDeactivatedInstances})}
                          error={this.getFieldError("IncludeDeactivatedInstances")}
                />


            </ExpandableFormSection>
            <ExpandableFormSection
                errorKey="MaxConcurrentMoveTasks"
                title="Max Concurrent Move Tasks"
                summary={Summary.summary("Max Concurrent Move Tasks")}
                help={<span>Enter the maximum number of concurrent move tasks.</span>}>
                <Text label="Max Concurrent Move Tasks"
                      value={model.MaxConcurrentMoveTasks.toString()}
                      onChange={(maxConcurrentMoveTasks: any) => this.setModelState({MaxConcurrentMoveTasks: maxConcurrentMoveTasks})}
                      hintText={"The maximum number of concurrent move tasks."}
                />
            </ExpandableFormSection>
        </div>;
    }

    handleSaveClick() {
        return this.doBusyTask(async () => {
            const model = this.state.model;
            await repository.HostedInstances.moveHostedInstances(model as MoveHostedInstancesRequest);
            this.setState({redirectTo: routeLinks.backgroundTasks.root});
        });
    }
}

export default MoveHostedInstances;
