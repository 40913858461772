export enum DynamicWorkerState {
    New = "New",
    Provisioning = "Provisioning",
    Provisioned = "Provisioned",
    PendingConfigureTrust = "PendingConfigureTrust",
    ConfiguringTrust = "ConfiguringTrust",
    TrustConfigured = "TrustConfigured",
    QueuedToDelete = "QueuedToDelete",
    Deleting = "Deleting",
    DoNotLease = "DoNotLease",
    KeepForDebugging = "KeepForDebugging"
}