import * as React from "react";
import cn from "classnames";

import styles = require("./style.less");

interface DataTableRowColumnProps {
    fullWidth?: boolean;
    fullHeight?: boolean;
    className?: string;
    colSpan?: number;
    title?: string;
}

const DataTableRowColumn: React.StatelessComponent<DataTableRowColumnProps> = (props) => {

    const {
        className,
        fullWidth,
        fullHeight,
        ...otherProps
    } = props;

    const style: React.CSSProperties = {};

    if (fullHeight) {
        style.paddingBottom = 0;
        style.paddingTop = 0;
    }

    if (fullWidth) {
        style.paddingRight = 0;
        style.paddingLeft = 0;
    }

    return (
        <td {...otherProps} style={style} className={cn(className, styles.dataTableRowColumn)}>
            {props.children}
        </td>
    );
};

interface DataTableRowWithActionColumnProps {
    action: JSX.Element;
    title?: string;
}

const DataTableRowWithActionColumn: React.StatelessComponent<DataTableRowWithActionColumnProps> = (props) => {

    const style: React.CSSProperties = {};
    style.paddingBottom = style.paddingTop = `${3 / 16}rem`;

    return (
        <td style={style} className={styles.dataTableRowColumn} title={props.title}>
            <div className={styles.dataTableRowWithActionColumn}>
                <div className={styles.dataTableRowWithActionColumnText}>{props.children}</div>
                <div className={styles.dataTableRowWithActionColumnAction}>{props.action}</div>
            </div>
        </td>
    );
};

const DataTableRowWithOverflowMenuColumn: React.StatelessComponent<DataTableRowWithActionColumnProps> = (props) => {

    const style: React.CSSProperties = {};
    style.paddingBottom = style.paddingTop = `${3 / 16}rem`;

    return (
        <td style={style} className={styles.dataTableRowColumn}>
            <div className={styles.dataTableRowWithActionColumn}>
                <div className={styles.dataTableRowWithActionColumnText}>{props.children}</div>
                <div>{props.action}</div>
            </div>
        </td>
    );
};

export { DataTableRowColumn, DataTableRowWithActionColumn, DataTableRowWithOverflowMenuColumn };
