import {
  DataTableRow,
  DataTableRowColumn,
  DataTableRowHeaderColumn,
  DataTableRowWithActionColumn
} from "../../../components/DataTable";
import * as React from "react";
import {useState} from "react";
import {HostedInstanceResource} from "../../../client/resources/hostedInstanceResource";
import {InstanceLimitResource} from "../../../client/resources/instanceLimitResource";
import OpenDialogButton from "../../../components/Dialog/OpenDialogButton";
import repository from "../../../client/repository";
import SaveDialogLayout from "../../../components/DialogLayout/SaveDialogLayout";
import {Errors} from "../../../components/DataBaseComponent";
import {BusyState} from "../../../components/BusyFromPromise/BusyFromPromise";
import {DoBusyTask} from "../../../components/DataBaseComponent/DataBaseComponent";
import {Text} from "../../../components/form";
import {HostedBranchInstanceLicenseResource} from "../../../client/resources/hostedBranchInstanceLicenseResource";
import {CleanUpStrategyType} from "../../../client/resources/cleanUpStrategy";
import Callout, {CalloutType} from "../../../components/Callout";
import ExternalLink from "../../../components/Navigation/ExternalLink";

interface InstanceDetailLicenseXmlProps {
  instance: HostedInstanceResource
  instanceLimit: InstanceLimitResource
  setInstanceLimit: (instanceLimit: InstanceLimitResource) => void;
  errors: Errors;
  busy: BusyState;
  doBusyTask: DoBusyTask;
}

export function InstanceDetailLicenseXml(props: InstanceDetailLicenseXmlProps) {
  if (props.instance.CleanUpStrategy.CleanUpStrategyType === CleanUpStrategyType.ExternallyManagedNoCleanupStrategy) {
    return (
      <DataTableRow>
        <DataTableRowHeaderColumn>License XML</DataTableRowHeaderColumn>
        <DataTableRowColumn>{props.instanceLimit.LicenseXml}</DataTableRowColumn>
      </DataTableRow>);
  }

  return (
    <DataTableRow>
      <DataTableRowHeaderColumn>License XML</DataTableRowHeaderColumn>
      <DataTableRowWithActionColumn action={
        <OpenDialogButton label="Change License">
          <ChangeLicenseDialog {...props} />
        </OpenDialogButton>}>
        {props.instanceLimit.LicenseXml}
      </DataTableRowWithActionColumn>
    </DataTableRow>);
}

function ChangeLicenseDialog({ instance, errors, busy, doBusyTask, instanceLimit, setInstanceLimit }: InstanceDetailLicenseXmlProps) {
  const [licenseXml, setLicenseXml] = useState("")
  const changeLicense = () => {
    return doBusyTask(async () => {
      const request: HostedBranchInstanceLicenseResource = {
        LicenseXml: licenseXml
      }
      const instanceLimit = await repository.HostedInstances.changeBranchInstanceLicense(instance, request);
      setInstanceLimit(instanceLimit);
      return true;
    });
  }
  
  return (<SaveDialogLayout title={"Change License"}
                            busy={busy}
                            errors={errors}
                            onSaveClick={changeLicense}>

    <Callout type={CalloutType.Warning} title="Warning">
      <p>If this branch instance license has expired, please head to <ExternalLink
        href={`https://billing.octopushq.com/server-licenses/14d1fb6e-5688-4a79-c228-08daccd1363d?showCloudXmlDialog=true`}>
          the licensing system</ExternalLink>, update this license's expiry and then paste below.</p>
    </Callout>

    <Text label="License XML"
          value={licenseXml}
          onChange={LicenseXml => setLicenseXml(LicenseXml)}
          hintText={"Please enter the license XML to associate with this instance"}
          multiLine={true}
          rows={10}
          rowsMax={10}
    />
  </SaveDialogLayout>);
}