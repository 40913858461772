import * as React from "react";
const formStyles = require("./style.less");

interface NoteProps {
    style?: any;
    checkboxNote?: boolean;
}

const Note: React.StatelessComponent<NoteProps> = props => {
    const customStyle =  props.checkboxNote ? {...props.style, marginLeft: "40px"} : props.style;
    return <div className={formStyles.note} style={customStyle}>{props.children}</div>;
};

export default Note;