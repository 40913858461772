// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* This file is DEPRECATED
   We are moving to the Octopus Design System
   https://www.octopus.design
  
   When changing component styles:
   1. Do not use variables from this file
   2. Aim to use Emotion \`css\` + themeTokens.colors
       - see Dashboard.tsx for example
   3. If you need to use Less, reference CSS variables
       - see index.ejs for example
       - for full list see https://github.com/OctopusDeploy/OctopusDeploy/blob/main/frontend/packages/design-system-tokens/src/generated/themeTokens.ts
   4. Remove variables from this file when they are no longer used
 */
@media (max-width: 37.4rem) {
  .style_hide-sm__BBDAu {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .style_visible-sm__Mp0eL {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .style_hide-md__CvL96 {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .style_visible-md__KNYIg {
    display: none !important;
  }
}
.style_logoContainer__H8Va_ {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./app/components/form/LogoEditor/style.less","webpack://./app/resources/styles/media.less"],"names":[],"mappings":"AAAA;;;;;;;;;;;;EAYE;ACNF;EACE;IACE,wBAAA;EDQF;AACF;ACLA;EACE;IACE,wBAAA;EDOF;AACF;ACJA;EACE;IACE,wBAAA;EDMF;AACF;ACJA;EACE;IACE,wBAAA;EDMF;AACF;AA5BA;EACE,aAAA;EACA,mBAAA;EACA,eAAA;EACA,WAAA;EACA,mBAAA;AA8BF","sourcesContent":["@import \"../../../resources/styles/variables\";\n@import \"../../../resources/styles/colors\";\n@import \"../../../resources/styles/media\";\n\n.logoContainer {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  width:100%;\n  align-items: center;\n}\n","//@screen-xs: 480px;\n@screen-sm: 37.4rem; //600px\n@screen-md: 79.9rem; //1279px\n//@screen-lg-min: 1280px;\n//@scree-xl-min: 1920px;\n\n@media (max-width: @screen-sm) {\n  .hide-sm {\n    display: none !important;\n  }\n}\n\n@media (min-width: @screen-sm) {\n  .visible-sm {\n    display: none !important;\n  }\n}\n\n@media (max-width: @screen-md) {\n  .hide-md {\n    display: none !important;\n  }\n}\n@media (min-width: @screen-md) {\n  .visible-md {\n    display: none !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hide-sm": `style_hide-sm__BBDAu`,
	"hideSm": `style_hide-sm__BBDAu`,
	"visible-sm": `style_visible-sm__Mp0eL`,
	"visibleSm": `style_visible-sm__Mp0eL`,
	"hide-md": `style_hide-md__CvL96`,
	"hideMd": `style_hide-md__CvL96`,
	"visible-md": `style_visible-md__KNYIg`,
	"visibleMd": `style_visible-md__KNYIg`,
	"logoContainer": `style_logoContainer__H8Va_`
};
module.exports = ___CSS_LOADER_EXPORT___;
