import {DataBaseComponent, DataBaseComponentState} from "../../components/DataBaseComponent";
import SaveDialogLayout from "../../components/DialogLayout/SaveDialogLayout";
import {Text} from "../../components/form";
import repository from "../../client/repository";
import * as React from "react";

interface Props {
    onChange: () => void;
}

interface State extends DataBaseComponentState {
    versionToChange: string;
}

class BulkChangeHostedScriptVersionDialog extends DataBaseComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            versionToChange: ""
        };
    }

    render() {
        return <SaveDialogLayout
            title="Hosted Scripts Version" saveButtonLabel="Update"
            onSaveClick={() => this.bulkChangeHostedScriptVersion()}
            errors={this.state.errors}
            saveButtonDisabled={!this.state.versionToChange}
            busy={this.state.busy}>
            <Text label="Version"
                value={this.state.versionToChange}
                onChange={value => this.setState({versionToChange: value})}
            />
        </SaveDialogLayout>;
    }

    private async bulkChangeHostedScriptVersion(): Promise<boolean> {
        return await this.doBusyTask(async () => {
            const versionToChange: string = this.state.versionToChange;
            await repository.Reefs.bulkUpdateHostedScriptVersions({Version: versionToChange});
            this.props.onChange();
        });
    }
}

export default BulkChangeHostedScriptVersionDialog;
