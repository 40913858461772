import * as React from "react";
import {FormFieldProps} from "components/form";
import MultiSelect from "components/MultiSelect/MultiSelect";
import {SelectItem} from "components/VirtualListWithKeyboard/SelectItem";
import DefaultChip from "shared/DefaultChip";
import { Step } from "client/resources/step";
import splitStringIntoWords from "utils/StringHelper/splitStringIntoWords";

interface BackgroundTaskStepMultiSelectProps extends FormFieldProps<string[]> {
    autoFocus?: boolean;
    label?: string | JSX.Element;
    error?: string;
    items: Step[];
}

const BackgroundTaskStepTypedMultiSelect = MultiSelect<SelectItem>();

const BackgroundTaskStepMultiSelect: React.StatelessComponent<BackgroundTaskStepMultiSelectProps> = props => {
    const items = props.items.map(s => ({ ...s, Name: splitStringIntoWords(s.Id) }));
    const chipRenderer = (i: SelectItem, onRequestDelete: (event: object) => void) => {
        return <DefaultChip item={i} onRequestDelete={onRequestDelete}/>;
    };
    return <BackgroundTaskStepTypedMultiSelect
        {...props}
        fieldName="current or next step"
        renderChip={chipRenderer}
        items={items}/>;
};

export default BackgroundTaskStepMultiSelect;