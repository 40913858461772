import * as React from "react";
import DebounceValue from "components/DebounceValue/DebounceValue";
import Text, {TextProps} from "components/form/Text/Text";

interface AdvancedFilterTextInputProps {
    fieldName: string;
    value: string;
    hintText?: string | JSX.Element;
    floatingLabelFixed?: boolean;
    onChange(value: string): void;
}

const DebouncedText = DebounceValue<TextProps & {fieldName?: string, floatingLabelFixed?: boolean}, string>(Text);

export const AdvancedFilterTextInput: React.FunctionComponent<AdvancedFilterTextInputProps> = (props: AdvancedFilterTextInputProps) => {
    return <DebouncedText fieldName={props.fieldName} onChange={props.onChange} value={props.value} hintText={props.hintText} floatingLabelFixed={props.floatingLabelFixed} />;
};