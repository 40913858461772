import * as React from "react";
import {DataBaseComponent, DataBaseComponentState, DoBusyTask, Refresh} from "components/DataBaseComponent/DataBaseComponent";
import {DynamicWorkerInstanceResource, DynamicWorkerState} from "client/resources/dynamicWorkerInstanceResource";
import SimpleDataTable from "components/SimpleDataTable";
import repository from "client/repository";
import OverflowMenu from "components/Menu/OverflowMenu";
import {instancesRouteLinks} from "areas/instances/instancesRouteLinks";
import InternalLink from "components/Navigation/InternalLink/InternalLink";
import {FriendlyLocalTime} from "shared/FriendlyLocalTime";
import externalSystemLinks from "externalSystemLinks";
import moment from "moment";
import { isEqual } from "lodash";
import {DynamicWorkerTypeResource} from "../../../client/resources/dynamicWorkerTypeResource";
import {dynamicWorkerServicesRouteLinks} from "../dynamicWorkerServicesRouteLinks";
import { HostedInstanceLookupResource } from "client/resources/hostedInstanceLookupResource";
import { DynamicWorkerServiceResource } from "client/resources/dynamicWorkerServiceResource";
import { DynamicWorkersFilterArgs } from "client/repositories/dynamicWorkerServicesRepository";
import Section from "components/Section";
import { OpenDeleteDialogButton } from "components/Button";
import Callout, { CalloutType } from "components/Callout";
import SectionTitle from "components/SectionTitle";
import ActionList from "../../../components/ActionList/ActionList";

interface State extends DataBaseComponentState {
    workers?: DynamicWorkerInstanceResource[];
    instances: Map<string, HostedInstanceLookupResource>;
    workerTypes: Map<string, DynamicWorkerTypeResource>;
    service: DynamicWorkerServiceResource;
}

interface Props {
    id: string;
    doBusyTask: DoBusyTask;
    filter: DynamicWorkersFilterArgs;
}

class DataTable extends SimpleDataTable<DynamicWorkerInstanceResource> {
}

class DynamicWorkersDataTable extends DataBaseComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            instances: new Map<string, HostedInstanceLookupResource>(),
            workerTypes: new Map<string, DynamicWorkerTypeResource>(),
            service: null,
        };
    }

    componentDidMount() {
        return this.props.doBusyTask(async () => {
            this.doRefresh = await this.startRefreshLoop(() => this.loadData(), 5000);
        });
    }

    componentDidUpdate(prevProps: Props) {
        if (!isEqual(prevProps.filter, this.props.filter)) {
             this.refreshData();
        }
    }

    private doRefresh: Refresh = () => Promise.resolve();
    render() {
        return <React.Fragment>
            <div>
                { this.state.service &&
                    <div>
                        <Section>
                            <SectionTitle title="" sectionControl={<ActionList actions={[
                                <InternalLink to={dynamicWorkerServicesRouteLinks.service(this.state.service.Id).root}>Dynamic Worker Service({this.state.service.Name})</InternalLink>,
                                this.getOpenDeleteDialogButton(this.state.workers)
                            ]} />} />
                        </Section>
                    </div>
                }
            <DataTable
                data={this.state.workers}
                onRow={(item: DynamicWorkerInstanceResource) => this.buildRow(item)}
                onRowOverflowMenu={worker => this.getOverflowMenu(worker)}
                headerColumns={["Id", "State", "Size", "Created", "Modified", "Image", "Snapshot", "Assigned"]}
                onEmpty={<div>No workers found</div>}/>
            </div>
        </React.Fragment>;
    }

    private getOpenDeleteDialogButton(workers: DynamicWorkerInstanceResource[]) {
        return <OpenDeleteDialogButton
            key={"delete filtered dialog"}
            label="Delete Filtered"
            disabled={!workers || workers.length === 0}
            dialogTitle={`Are you sure you want to delete all the filtered worker(s)?`}
            onDeleteClick={() => this.deleteWorkers()}>
            <Callout title="This is a destructive action" type={CalloutType.Danger}>
                This action <strong>cannot</strong> be undone. This will permanently delete the selected worker(s).
            </Callout>
        </OpenDeleteDialogButton>;
    }

    private refreshData = async () => {
        await this.props.doBusyTask(async () => {
            this.setState(await this.loadData());
        });
    };

    async loadData() {
        const [workers, service, workerTypes, instancesLookup] = await Promise.all([
            repository.DynamicWorkerServices.workers(this.props.id, this.props.filter),
            repository.DynamicWorkerServices.get(this.props.id),
            repository.DynamicWorkerServices.workerTypes(this.props.id),
            repository.HostedInstances.lookup()
        ]);

        return {
            workers,
            service,
            workerTypes: new Map<string, DynamicWorkerTypeResource>(workerTypes.map<[string, DynamicWorkerTypeResource]>(t =>  [t.Id, t])),
            instances: new Map<string, HostedInstanceLookupResource>(instancesLookup.map<[string, HostedInstanceLookupResource]>(r => [r.ShortId, r])) };
    }

    private buildRow(worker: DynamicWorkerInstanceResource) {
        const workerType = this.state.workerTypes.get(worker.WorkerTypeId);
        const instance = this.state.instances.get(worker.HostedInstanceShortId);
        const assigned = instance === undefined
            ? worker.HostedInstanceShortId
            : <InternalLink to={instancesRouteLinks.instance(instance.Id).root}>{instance.DnsPrefix}</InternalLink>;
        return [
            worker.Id,
            this.GetWorkerStateValue(worker.State),
            worker.Size,
            <FriendlyLocalTime time={moment.utc(worker.Created).local().toString()}/>,
            <FriendlyLocalTime time={moment.utc(worker.LastStateChange).local().toString()}/>,
            <InternalLink to={dynamicWorkerServicesRouteLinks.service(this.props.id).workerType(workerType.Id).root}>{workerType.Type}</InternalLink>,
            worker.Source,
            <div>{assigned}</div>
        ];
    }

    private getOverflowMenu(worker: DynamicWorkerInstanceResource) {
        const validStatesForDebug = [DynamicWorkerState.Provisioned, DynamicWorkerState.TrustConfigured, DynamicWorkerState.DoNotLease];
        return [
            worker.State === DynamicWorkerState.TrustConfigured ? OverflowMenu.item("Do not lease", async () => { await this.doNotLeaseWorker(worker); }) : null,
            validStatesForDebug.some(s => s === worker.State) ? OverflowMenu.item("Keep for debugging", async () => { await this.debugWorker(worker); }) : null,
            worker.State === DynamicWorkerState.KeepForDebugging ? OverflowMenu.item("Extend debugging", async () => { await this.debugWorker(worker); }) : null,
            OverflowMenu.externalNavItem("Open Seq", externalSystemLinks.seq.dynamicWorker(worker)),
            worker.CrowdStrikeAgentId && OverflowMenu.externalNavItem("Open CrowdStrike", externalSystemLinks.crowdstrike.agent(worker.CrowdStrikeAgentId.toLowerCase())),
            OverflowMenu.deleteItem(
                "Delete", 
                "Are you sure you want to delete this dynamic worker?",
                () => this.deleteWorker(worker),
                this.getDeleteWorkerConfirmation(worker.State))
        ];
    }

    private deleteWorker(worker: DynamicWorkerInstanceResource) {
        return this.props.doBusyTask(async () => {
            await repository.DynamicWorkerServices.deleteWorker(this.props.id, worker.Id);
            this.loadData();
        });
    }

    private doNotLeaseWorker(worker: DynamicWorkerInstanceResource) {
        return this.props.doBusyTask(async () => {
            await repository.DynamicWorkerServices.doNotLeaseWorker(this.props.id, worker.Id);
            this.loadData();
        });
    }

    private debugWorker(worker: DynamicWorkerInstanceResource) {
        return this.props.doBusyTask(async () => {
            await repository.DynamicWorkerServices.debugWorker(this.props.id, worker.Id);
            this.loadData();
        });
    }

    private deleteWorkers(): Promise<boolean> {
        return this.doBusyTask(async () => {
            const deletions = this.state.workers.map(worker =>
                repository.DynamicWorkerServices.deleteWorker(this.props.id, worker.Id)
            );
            await Promise.all(deletions);
        });
    }

    private GetWorkerStateValue(state: DynamicWorkerState) {
        let description = state.toString();
        switch (state) {
            case DynamicWorkerState.QueuedToDelete:
                description = "Queued To Delete";
                break;
            case DynamicWorkerState.PendingConfigureTrust:
                description = "Pending Configure Trust";
                break;
            case DynamicWorkerState.ConfiguringTrust:
                description = "Configuring Trust";
                break;
            case DynamicWorkerState.TrustConfigured:
                description = "Trust Configured";
                break;
            case DynamicWorkerState.DoNotLease:
                description = "Do Not Lease";
                break;
            case DynamicWorkerState.KeepForDebugging:
                description = "Keep For Debugging";
                break;
        }

        return description;
    }
    
    private getDeleteWorkerConfirmation(state: DynamicWorkerState) {
        return <div>
            <p>Deleting this worker is permanent, there is no going back.</p>
            {state == DynamicWorkerState.TrustConfigured && <Callout title="This worker is in use" type={CalloutType.Danger}>
                <p>This worker is currently leased to a cloud instance. Deleting it may affect customer deployments.</p>
                <p>Consider using <strong>Do Not Lease</strong> instead.</p>
            </Callout>}
            <p>Do you wish to continue?</p>
        </div>
    }
}

export default DynamicWorkersDataTable;