import * as React from "react";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent";
import SaveDialogLayout from "components/DialogLayout/SaveDialogLayout";
import repository from "client/repository";
import Select from "components/form/Select/Select";
import Callout, {CalloutType} from "components/Callout";
import { MoveFileStoragesRequest } from "client/resources/moveFileStoragesRequest";
import { ReefResource } from "client/resources/reefResource";
import { Number } from "shared/Number";
import { reefsRouteLinks } from "areas/reefs/reefsRouteLinks";
import { InternalRedirect } from "components/Navigation/InternalRedirect";

interface Props {
    reef: ReefResource;
}

interface State extends DataBaseComponentState {
    request: Partial<MoveFileStoragesRequest>;
    redirectTo?: string;
}

class MoveFileStoragesDialog extends DataBaseComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            request: {
                MaxConcurrentMoveTasks: 5,
            }
        };
    }

    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo}/>;
        }

        const request = this.state.request;
        return <SaveDialogLayout title="Move instances between Storage Accounts"
            saveButtonLabel="Move"
            busy={this.state.busy}
            errors={this.state.errors}
            saveButtonDisabled={!request.SourceStorageAccountName || !request.DestinationStorageAccountName || request.SourceStorageAccountName === request.DestinationStorageAccountName}
            onSaveClick={this.moveToNewStorageAccount}>
                <Callout title="Please be aware that..." type={CalloutType.Warning}>
                    <p>This will change the availability of the source storage account to <strong>Unavailable</strong></p>
                </Callout>
                <Select value={request.SourceStorageAccountName}
                        onChange={accountName => this.updateRequest({SourceStorageAccountName: accountName})}
                        items={this.props.reef.StorageAccounts.map(a => ({text: a.Name, value: a.Name}))}
                        fieldName="Source Storage Account Name"
                        hintText="The source storage account to move from"
                />
                <Select value={request.DestinationStorageAccountName}
                        onChange={accountName => this.updateRequest({DestinationStorageAccountName: accountName})}
                        items={this.props.reef.StorageAccounts.filter(a => a.IsAvailable).map(a => ({text: a.Name, value: a.Name}))}
                        fieldName="Destination Storage Account Name"
                        hintText="The destiantion storage account to move to"
                />
                <Number label="Max Concurrent Move Tasks"
                        value={request.MaxConcurrentMoveTasks}
                        min={1}
                        onChange={maxConcurrentMoveTasks => this.updateRequest({MaxConcurrentMoveTasks: maxConcurrentMoveTasks})}
                />
        </SaveDialogLayout>;
    }

    private updateRequest(update: Partial<MoveFileStoragesRequest>) {
        return this.setState(prevState => ({request: {...prevState.request, ...update}}));
    }

    private moveToNewStorageAccount = async (): Promise<boolean> => {
        return this.doBusyTask(async () => {
            const reefId = this.props.reef.Id;
            await repository.Reefs.moveFileStorages(reefId, this.state.request as MoveFileStoragesRequest);
            this.setState({redirectTo: reefsRouteLinks.reef(reefId).tasks});
        });
    }
}

export default MoveFileStoragesDialog;