import React from "react";
import moment from "moment";
import {themeTokens} from "@octopusdeploy/design-system-tokens";
import BaseComponent from "components/BaseComponent";
import {HostedInstanceResource} from "client/resources/hostedInstanceResource";
import {OutageWindowFrequency} from "client/resources/outageWindowFrequency";

const textColor = themeTokens.color.text;

const humanizer = require("humanize-duration").humanizer({
    language: "short",
    spacer: "",
    delimiter: " ",
    units: ["d", "h", "m"],
    languages: { short: { d: () => "d", h: () => "h", m: () => "m" }},
    round: true
});

const styles = require("components/TimeLabels/style.less");

interface Props {
    instance: HostedInstanceResource;
    compact?: boolean;
}

class NextOutageWindowDisplay extends BaseComponent<Props, {}> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const now = new Date();
        const instance = this.props.instance;
        const inWindow = instance.IsCurrentlyInOutageWindow;

        const nextOutageWindowDisplayStrings = getNextOutageWindowDisplayStrings(now, instance);

        if (this.props.compact) {
            return (
                <span style={{color: inWindow ? textColor.success : textColor.primary}} className={styles.when}>
                    <abbr title={nextOutageWindowDisplayStrings.hint}>{nextOutageWindowDisplayStrings.start} - {nextOutageWindowDisplayStrings.end}</abbr>
                </span>
            );
        }

        return (
            <span style={{color: inWindow ? textColor.success : textColor.primary}}>
                {nextOutageWindowDisplayStrings.start} - {nextOutageWindowDisplayStrings.end}&nbsp;({nextOutageWindowDisplayStrings.timeZone})&nbsp;&mdash;&nbsp;{nextOutageWindowDisplayStrings.hint}
            </span>
        );
    }
}

type HostedInstanceOutageWindow = Pick<HostedInstanceResource, "OutageWindowFrequency" | "NextOutageWindowStart" | "NextOutageWindowEnd" | "OutageWindowDurationInMilliseconds" | "IsCurrentlyInOutageWindow">

export function getNextOutageWindowDisplayStrings(now: Date, outageWindow: HostedInstanceOutageWindow): { hint: string; start: string; end: string; timeZone: string; } {
    const start = moment(outageWindow.NextOutageWindowStart).local();
    const end = moment(outageWindow.NextOutageWindowEnd).local();

    const hint = outageWindow.IsCurrentlyInOutageWindow ?
        `ends in ${humanizer(end.diff(now))}` :
        `starts in ${humanizer(start.diff(now))}, lasts for ${humanizer(outageWindow.OutageWindowDurationInMilliseconds)}`;

    const timeZone = start.format("Z");

    if (outageWindow.OutageWindowFrequency === OutageWindowFrequency.Daily) {
        return {
            hint,
            start: start.format("HH:mm"),
            end: end.format("HH:mm"),
            timeZone
        }
    }

    return {
        hint,
        start: start.format("ddd DD/MM/yyyy HH:mm"),
        end: end.format("ddd DD/MM/yyyy HH:mm"),
        timeZone
    }
}

export default NextOutageWindowDisplay;
