import * as React from "react";
import repository from "client/repository";
import {
    DataTable,
    DataTableBody,
    DataTableRow,
    DataTableRowColumn,
    DataTableRowHeaderColumn
} from "components/DataTable";
import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent";
import PaperLayout from "components/PaperLayout";
import {default as OverflowMenu} from "components/Menu/OverflowMenu";
import SaveDialogLayout from "components/DialogLayout/SaveDialogLayout";
import FormSectionHeading from "components/form/Sections/FormSectionHeading";
import ActionList from "components/ActionList/ActionList";
import {InternalRedirect} from "components/Navigation/InternalRedirect";
import {AzureRegionResource} from "client/resources/azureRegionResource";
import {azureRegionsRouteLinks} from "./azureRegionsRouteLinks";

interface State extends DataBaseComponentState {
    redirectTo?: string;
    azureRegion: AzureRegionResource;
}

interface Props {
    azureRegion: AzureRegionResource;
    breadcrumbTitle: string;
}

export class AzureRegionDetail extends DataBaseComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            azureRegion: props.azureRegion,
        };
    }

    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo}/>;
        }

        const azureRegion = this.state.azureRegion;

        return <PaperLayout busy={this.state.busy}
                            errors={this.state.errors}
                            title="Detail"
                            breadcrumbTitle={this.props.breadcrumbTitle}
                            sectionControl={<ActionList actions={azureRegion ? this.getActions() : []}/>}>
            {azureRegion && this.getBody()}
        </PaperLayout>;
    }

    private getActions() {
        const menuItems = [
            OverflowMenu.dialogItem("Reprovision", this.renderReprovisionDialog()),
        ];

        return [
            <OverflowMenu menuItems={menuItems}/>
        ];
    }

    private getBody() {
        const azureRegion = this.state.azureRegion;
        return azureRegion && <>
            <FormSectionHeading title="General"/>
            <DataTable>
                <DataTableBody>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Azure Region</DataTableRowHeaderColumn>
                        <DataTableRowColumn>{azureRegion.Region}</DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Portal Instance</DataTableRowHeaderColumn>
                        <DataTableRowColumn>{azureRegion.PortalInstanceName}</DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Status</DataTableRowHeaderColumn>
                        <DataTableRowColumn>{azureRegion.Status}</DataTableRowColumn>
                    </DataTableRow>
                </DataTableBody>
            </DataTable>
        </>;
    }

    private reprovision = (): Promise<boolean> => {
        return this.doBusyTask(async () => {
            await repository.AzureRegions.reprovision(this.state.azureRegion);
            this.setState({redirectTo: azureRegionsRouteLinks.azureRegion(this.state.azureRegion.Id).tasks});

            return true;
        });
    }

    renderReprovisionDialog() {
        return <SaveDialogLayout title="Reprovision Azure Region"
                                 saveButtonLabel="Reprovision"
                                 busy={this.state.busy}
                                 errors={this.state.errors}
                                 onSaveClick={this.reprovision}>
                  <p>Reprovision the Azure Region?</p>
                </SaveDialogLayout>;
    }
}

export default AzureRegionDetail;
