
function findKey<T>(val: string, template: T) {
    if (!template) {
        return val;
    }
    const found = Object.keys(template).find(k => k.toLowerCase() === val.toLowerCase());
    return found ? found : val;
}

export default function matchErrorsToFieldNames<T>(err: any, template: T) {
    const details = err.Details
        ? Object.keys(err.Details).reduce((acc, val) => {
            (acc as any)[findKey(val, template)] = err.Details[val];
            return acc;
        }, {})
        : {};

    return  {
        ...details
    };
}