import * as React from "react";
import LinearProgress from "material-ui/LinearProgress";
import CircularProgress from "material-ui/CircularProgress";
import styles = require("./style.less");
import {themeTokens} from "@octopusdeploy/design-system-tokens";

interface BusyIndicatorProps {
    show: boolean;
    inline?: boolean;
}

const BusyIndicator: React.StatelessComponent<BusyIndicatorProps> = props => {
    if (props.inline) {
        return props.show &&
            <span className={styles.circularBusyIndicator}>
                <CircularProgress mode="indeterminate" color={themeTokens.color.progressBar.foreground.circular} size={22} thickness={2} />
            </span>;
    } else {
        return props.show
            ? <div className={styles.busyIndicator}>
                <LinearProgress mode="indeterminate" color={themeTokens.color.progressBar.foreground.linear} />
            </div>
            : <div className={styles.busyIndicatorHiddenSpacer} />;
    }
};

BusyIndicator.displayName = "BusyIndicator";

export default BusyIndicator;