export class LocalTime {

    public static toDate(value: string) {
        const localTime = localTimeParser(value);
        return new Date(0, 0, 0,  localTime.hours, localTime.minutes, localTime.seconds);
    }

    public static fromDate(date: Date): string {
        return (new LocalTime(date.getHours(), date.getMinutes(), date.getSeconds())).toString();
    }

    hours: number;
    minutes: number;
    seconds: number;

    constructor(hours: number, minutes: number, seconds: number) {
        this.hours = hours;
        this.minutes = minutes;
        this.seconds = seconds;
    }

    public toString() {
        return `${this.pad(this.hours)}:${this.pad(this.minutes)}:${this.pad(this.seconds)}`;
    }

    private pad(value: number) {
        return value >= 10 ? value.toString(10) : `0${value.toString(10)}`;
    }
}

export function localTimeParser(value: string): LocalTime {

    function throwError() {
        throw new Error(`Not a valid local time: ${value}`);
    }

    if (!value || value.length !== 8) {
        throwError();
    }

    const parts = value.split(":");
    if (parts.length !== 3) {
        throwError();
    }

    const hours = parseInt(parts[0], 10);
    if (isNaN(hours)) {
        throwError();
    }

    const minutes = parseInt(parts[1], 10);
    if (isNaN(minutes)) {
        throwError();
    }

    const seconds = parseInt(parts[2], 10);
    if (isNaN(seconds)) {
        throwError();
    }

    return new LocalTime(hours, minutes, seconds);
}