import client from "client/client";
import {BackgroundTaskResource} from "client/resources/backgroundTaskResource";
import {BackgroundTaskSummaryResource} from "client/resources/backgroundTaskSummaryResource";
import {BackgroundProcessResource} from "client/resources/backgroundProcessResource";
import {Step} from "client/resources/step";
import {BulkCancelBackgroundTaskRequest} from "../resources/bulkCancelBackgroundTaskRequest";
import {PagingResource} from "../resources/pagingResource";

export interface ListArgs {
    relatedTo?: string | string[];
    skip?: number;
    take?: number;
    from?: string;
    to?: string;
    processIds?: string[];
    statuses?: string[];
    excludeRecurring?: boolean;
    intents?: string[];
    currentOrNextStepIds?: string[];
}

export interface TaskFilterArgs {
    relatedTaskIds?: string[];
}

export default class BackgroundTasksRepository {

    private rootUri = "/api/background-tasks";
    static defaultListArgs = {take: 50, skip: 0};
    
    listSummaries(args?: ListArgs): Promise<PagingResource<BackgroundTaskSummaryResource>> {
        return client.get(`${this.rootUri}{?relatedTo,skip,take,from,to,processIds,statuses,excludeRecurring,intents,currentOrNextStepIds}`, {...BackgroundTasksRepository.defaultListArgs, ...args});
    }

    get(id: string): Promise<BackgroundTaskResource> {
        return client.get(`${this.rootUri}/{id}`, {id});
    }

    listProcesses(): Promise<BackgroundProcessResource[]> {
        return client.get(`${this.rootUri}/processes`);
    }

    nextStep(task: BackgroundTaskResource, step: Step) {
        return client.post(`${this.rootUri}/{id}/next-step/{stepId}`, null, {id: task.Id, stepId: step.Id});
    }

    resume(task: BackgroundTaskResource) {
        return client.post(`${this.rootUri}/{id}/resume`, {}, {id: task.Id});
    }

    cancel(task: BackgroundTaskResource) {
        return client.post(`${this.rootUri}/{id}/cancel`, {}, {id: task.Id});
    }
    
    bulkCancel(request: BulkCancelBackgroundTaskRequest) {
        return client.post(`${this.rootUri}/bulk-cancel`, request);
    }

    autoUpgradeGet(): Promise<BackgroundTaskResource> {
        return client.get(`${this.rootUri}/autoupgrade`);
    }

    autoUpgradeStop() {
        return client.post(`${this.rootUri}/autoupgrade/stop`, {}, {});
    }

    autoUpgradeStart() {
        return client.post(`${this.rootUri}/autoupgrade/start`, {}, {});
    }

    recurringCancel() {
        return client.post(`${this.rootUri}/recurring/cancel`, {}, {});
    }

    recurringResume() {
        return client.post(`${this.rootUri}/recurring/resume`, {}, {});
    }
}