// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* This file is DEPRECATED
   We are moving to the Octopus Design System
   https://www.octopus.design
  
   When changing component styles:
   1. Do not use variables from this file
   2. Aim to use Emotion \`css\` + themeTokens.colors
       - see Dashboard.tsx for example
   3. If you need to use Less, reference CSS variables
       - see index.ejs for example
       - for full list see https://github.com/OctopusDeploy/OctopusDeploy/blob/main/frontend/packages/design-system-tokens/src/generated/themeTokens.ts
   4. Remove variables from this file when they are no longer used
 */
.confirmationDialog_dialogHeader__GoZFx {
  background: var(--colorBackgroundWarning);
}
.confirmationDialog_warningIcon__xLaC8 {
  color: var(--colorTextWarning);
}
`, "",{"version":3,"sources":["webpack://./app/components/Dialog/confirmationDialog.less"],"names":[],"mappings":"AAAA;;;;;;;;;;;;EAYE;AAVF;EACE,yCAAA;AAYF;AATA;EACE,8BAAA;AAWF","sourcesContent":["@import \"../../resources/styles/colors\";\n\n.dialogHeader {\n  background: @alertHighlight;\n}\n\n.warningIcon {\n  color: @alert;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialogHeader": `confirmationDialog_dialogHeader__GoZFx`,
	"warningIcon": `confirmationDialog_warningIcon__xLaC8`
};
module.exports = ___CSS_LOADER_EXPORT___;
