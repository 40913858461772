import * as React from "react";
import { FormFieldProps } from "components/form";
import { SelectItem } from "components/VirtualListWithKeyboard/SelectItem";
import CleanUpStrategyChip from "shared/CleanUpStrategyChip";
import MultiSelect from "components/MultiSelect/MultiSelect";
import { CleanUpStrategyType, cleanUpStrategyTypeDescriptions } from "client/resources/cleanUpStrategy";

interface CleanUpStrategyMultiSelectProps extends FormFieldProps<CleanUpStrategyType[]> {
    canAdd?: boolean;
    openOnFocus?: boolean;
    autoFocus?: boolean;
    label?: string | JSX.Element;
    error?: string;
    hideFloatingLabel?: boolean;
    validate?(value: CleanUpStrategyType[]): string;
}

const CleanUpStrategyTypedMultiSelect = MultiSelect<SelectItem>();

const CleanUpStrategyMultiSelect: React.StatelessComponent<CleanUpStrategyMultiSelectProps> = props => {
    const onAdd = (value: CleanUpStrategyType) => {
        props.onChange(props.value.concat(value));
    };

    const chipRenderer = (r: SelectItem, onRequestDelete: (event: object) => void) => {
        return <CleanUpStrategyChip
            onRequestDelete={onRequestDelete}
            cleanUpStrategy={r.Id as CleanUpStrategyType}
        />;
    };

    return <CleanUpStrategyTypedMultiSelect
        items={Object.keys(CleanUpStrategyType).map(t => ({Id: t, Name: (cleanUpStrategyTypeDescriptions[t as CleanUpStrategyType] as string)}))}
        fieldName="cleanup strategy"
        label={props.label}
        renderChip={chipRenderer}
        onNew={props.canAdd ? onAdd : null}
        value={props.value}
        onChange={props.onChange}
        error={props.error}
        openOnFocus={props.openOnFocus}
        autoFocus={props.autoFocus}
        hideFloatingLabel={props.hideFloatingLabel}
    />;
};

export default CleanUpStrategyMultiSelect;
