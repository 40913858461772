// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* This file is DEPRECATED
   We are moving to the Octopus Design System
   https://www.octopus.design
  
   When changing component styles:
   1. Do not use variables from this file
   2. Aim to use Emotion \`css\` + themeTokens.colors
       - see Dashboard.tsx for example
   3. If you need to use Less, reference CSS variables
       - see index.ejs for example
       - for full list see https://github.com/OctopusDeploy/OctopusDeploy/blob/main/frontend/packages/design-system-tokens/src/generated/themeTokens.ts
   4. Remove variables from this file when they are no longer used
 */
.style_box__sL0e8 {
  padding: 0.625rem 1rem;
  align-self: stretch;
  font-size: 0.875rem;
  color: var(--colorTextPrimary);
}
.style_information__oGLJI {
  background: var(--colorBackgroundInfo);
  padding: 0.625rem 1rem;
  align-self: stretch;
  font-size: 0.875rem;
  color: var(--colorTextPrimary);
}
.style_information__oGLJI .style_title__jX9n1 {
  color: var(--colorTextInfo);
}
.style_success__XTt5a {
  background: var(--colorBackgroundSuccess);
  padding: 0.625rem 1rem;
  align-self: stretch;
  font-size: 0.875rem;
  color: var(--colorTextPrimary);
}
.style_success__XTt5a .style_title__jX9n1 {
  color: var(--colorTextSuccess);
}
.style_warning__pUdha {
  background: var(--colorBackgroundWarning);
  padding: 0.625rem 1rem;
  align-self: stretch;
  font-size: 0.875rem;
  color: var(--colorTextPrimary);
}
.style_warning__pUdha .style_title__jX9n1 {
  color: var(--colorTextWarning);
}
.style_danger__DmMdu {
  background: var(--colorBackgroundDanger);
  padding: 0.625rem 1rem;
  align-self: stretch;
  font-size: 0.875rem;
  color: var(--colorTextPrimary);
}
.style_danger__DmMdu .style_title__jX9n1 {
  color: var(--colorTextDanger);
}
.style_none__ofxNq {
  padding: 0.625rem 1rem;
  align-self: stretch;
  font-size: 0.875rem;
  color: var(--colorTextPrimary);
}
.style_title__jX9n1 {
  margin-bottom: 0.625rem;
}
`, "",{"version":3,"sources":["webpack://./app/components/Callout/style.less"],"names":[],"mappings":"AAAA;;;;;;;;;;;;EAYE;AAVF;EACI,sBAAA;EACA,mBAAA;EACA,mBAAA;EACA,8BAAA;AAYJ;AATA;EACI,sCAAA;EAPA,sBAAA;EACA,mBAAA;EACA,mBAAA;EACA,8BAAA;AAmBJ;AAhBA;EAIQ,2BAAA;AAeR;AAXA;EACI,yCAAA;EAfA,sBAAA;EACA,mBAAA;EACA,mBAAA;EACA,8BAAA;AA6BJ;AAlBA;EAIQ,8BAAA;AAiBR;AAbA;EACI,yCAAA;EAvBA,sBAAA;EACA,mBAAA;EACA,mBAAA;EACA,8BAAA;AAuCJ;AApBA;EAIQ,8BAAA;AAmBR;AAfA;EACI,wCAAA;EA/BA,sBAAA;EACA,mBAAA;EACA,mBAAA;EACA,8BAAA;AAiDJ;AAtBA;EAIQ,6BAAA;AAqBR;AAjBA;EAtCI,sBAAA;EACA,mBAAA;EACA,mBAAA;EACA,8BAAA;AA0DJ;AAnBA;EACI,uBAAA;AAqBJ","sourcesContent":["@import \"../../resources/styles/colors\";\n\n.box {\n    padding: 0.625rem 1rem; // the 1rem left and right is needed when Callouts are used in WarningFormSections (to match the vertical alignemnt of the form).\n    align-self: stretch;\n    font-size:14/16rem;\n    color: var(--colorTextPrimary);\n}\n\n.information {\n    background: var(--colorBackgroundInfo);\n    .box;\n    .title {\n        color: var(--colorTextInfo);\n    }\n}\n\n.success {\n    background: var(--colorBackgroundSuccess);\n    .box;\n    .title {\n        color: var(--colorTextSuccess);\n    }\n}\n\n.warning {\n    background: var(--colorBackgroundWarning);\n    .box;\n    .title {\n        color: var(--colorTextWarning);\n    }\n}\n\n.danger {\n    background: var(--colorBackgroundDanger);\n    .box;\n    .title {\n        color: var(--colorTextDanger);\n    }\n}\n\n.none {\n    .box;\n}\n\n.title {\n    margin-bottom: 0.625rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `style_box__sL0e8`,
	"information": `style_information__oGLJI`,
	"title": `style_title__jX9n1`,
	"success": `style_success__XTt5a`,
	"warning": `style_warning__pUdha`,
	"danger": `style_danger__DmMdu`,
	"none": `style_none__ofxNq`
};
module.exports = ___CSS_LOADER_EXPORT___;
