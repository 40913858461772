// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VirtualListWithKeyboard_menuContainer__Go3Yz {
  max-height: 25rem;
}
.VirtualListWithKeyboard_menuItem__NXz5i {
  overflow-wrap: break-word;
}
.VirtualListWithKeyboard_empty__XBdMB {
  padding: 0.625rem;
}
`, "",{"version":3,"sources":["webpack://./app/components/VirtualListWithKeyboard/VirtualListWithKeyboard.less"],"names":[],"mappings":"AAAA;EACI,iBAAA;AACJ;AAEA;EACI,yBAAA;AAAJ;AAGA;EACI,iBAAA;AADJ","sourcesContent":[".menuContainer {\n    max-height: 400/16rem;\n}\n\n.menuItem {\n    overflow-wrap: break-word;\n}\n\n.empty {\n    padding: 10/16rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menuContainer": `VirtualListWithKeyboard_menuContainer__Go3Yz`,
	"menuItem": `VirtualListWithKeyboard_menuItem__NXz5i`,
	"empty": `VirtualListWithKeyboard_empty__XBdMB`
};
module.exports = ___CSS_LOADER_EXPORT___;
