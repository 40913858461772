import {
  CleanUpStrategyType,
  LimitedLifetimeCleanUpStrategy,
  ExternallyManagedNoCleanupStrategy
} from "client/resources/cleanUpStrategy";
import React from "react";
import {
  DataTableRow,
  DataTableRowColumn,
  DataTableRowHeaderColumn,
  DataTableRowWithActionColumn
} from "../../../components/DataTable";
import {FriendlyLocalTime} from "../../../shared/FriendlyLocalTime";
import moment from "moment/moment";
import OpenDialogButton from "../../../components/Dialog/OpenDialogButton";
import SaveDialogLayout from "../../../components/DialogLayout/SaveDialogLayout";
import Callout, {CalloutType} from "../../../components/Callout";
import {Errors} from "../../../components/DataBaseComponent";
import {BusyState} from "../../../components/BusyFromPromise/BusyFromPromise";
import repository from "../../../client/repository";
import {DoBusyTask} from "../../../components/DataBaseComponent/DataBaseComponent";
import {HostedInstanceResource} from "../../../client/resources/hostedInstanceResource";
import {ChangeCleanUpStrategyRequest} from "../../../client/resources/changeCleanUpStrategyRequest";

interface CleanUpStrategyRowsProps { 
  instance: HostedInstanceResource; 
  setInstance: (instance: HostedInstanceResource) => void; 
  errors: Errors; 
  busy: BusyState; 
  doBusyTask: DoBusyTask; 
}


function TrialCleanUpStrategy(props: CleanUpStrategyRowsProps & {cleanUpStrategy: LimitedLifetimeCleanUpStrategy}) {
  return (<>
    <DataTableRow>
      <DataTableRowHeaderColumn>Created</DataTableRowHeaderColumn>
      <DataTableRowColumn><FriendlyLocalTime time={moment.utc(props.cleanUpStrategy.CreatedOn).local().toString()}/></DataTableRowColumn>
    </DataTableRow>
    <DataTableRow>
      <DataTableRowHeaderColumn>Deactivation</DataTableRowHeaderColumn>
      <DataTableRowWithActionColumn action={
        <OpenDialogButton label="Set To Never Deactivate">
          {setToNeverDeactivateDialog(props)}
        </OpenDialogButton>}>
        <FriendlyLocalTime time={moment.utc(props.cleanUpStrategy.DeactivationDate).local().toString()}/>
      </DataTableRowWithActionColumn>
    </DataTableRow>
    <DataTableRow>
      <DataTableRowHeaderColumn>Deletion</DataTableRowHeaderColumn>
      <DataTableRowColumn><FriendlyLocalTime time={moment.utc(props.cleanUpStrategy.DeletionDate).local().toString()}/></DataTableRowColumn>
    </DataTableRow>
  </>);
}

function setToNeverDeactivateDialog({ instance, setInstance, errors, busy, doBusyTask , cleanUpStrategy}: CleanUpStrategyRowsProps & {cleanUpStrategy: LimitedLifetimeCleanUpStrategy}) {
  const setToNeverDeactivate = () => {
    return doBusyTask(async () => {
      const request: ChangeCleanUpStrategyRequest = {
        CleanUpStrategy: {
          CleanUpStrategyType:CleanUpStrategyType.NeverCleanUpStrategy,
          CreatedOn: cleanUpStrategy.CreatedOn
        }
      }
      const savedInstance = await repository.HostedInstances.changeCleanUpStrategy({ instance, request });
      setInstance(savedInstance);
      return true;
    });
  }

  return <SaveDialogLayout title={"Set To Never Deactivate?"}
                           busy={busy}
                           errors={errors}
                           onSaveClick={setToNeverDeactivate}>

    <Callout type={CalloutType.Warning} title="Warning">
      Setting a branch instance to never deactivate will require you to manually delete this instance when you are finished.
      Consider cost implications when using this feature.
    </Callout>
  </SaveDialogLayout>;
}

function ExternallyManagedCleanUpStrategy(noCleanupStrategy: ExternallyManagedNoCleanupStrategy) {
  return null as JSX.Element;
}

function NeverCleanUpStrategy(props: { CleanUpStrategyType: CleanUpStrategyType.NeverCleanUpStrategy, CreatedOn: string }) {
  return (<>
    <DataTableRow>
      <DataTableRowHeaderColumn>Created</DataTableRowHeaderColumn>
      <DataTableRowColumn><FriendlyLocalTime time={moment.utc(props.CreatedOn).local().toString()}/></DataTableRowColumn>
    </DataTableRow>
    <DataTableRow>
      <DataTableRowHeaderColumn>Deactivation</DataTableRowHeaderColumn>
      <DataTableRowColumn>Never</DataTableRowColumn>

    </DataTableRow>
    <DataTableRow>
      <DataTableRowHeaderColumn>Deletion</DataTableRowHeaderColumn>
      <DataTableRowColumn>Never</DataTableRowColumn>
    </DataTableRow>
  </>);
}

export function CleanUpStrategyRows(props: CleanUpStrategyRowsProps) {
  switch (props.instance.CleanUpStrategy.CleanUpStrategyType) {
    case CleanUpStrategyType.LimitedLifetimeCleanUpStrategy:
      return <TrialCleanUpStrategy {...props} cleanUpStrategy={props.instance.CleanUpStrategy} />
    case CleanUpStrategyType.ExternallyManagedNoCleanupStrategy:
      return <ExternallyManagedCleanUpStrategy {...props.instance.CleanUpStrategy} />
    case CleanUpStrategyType.NeverCleanUpStrategy:
      return <NeverCleanUpStrategy {...props.instance.CleanUpStrategy} />
    default:
      const _exhaustiveCheck: never = props.instance.CleanUpStrategy;
  }
}