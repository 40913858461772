function validateMemory(val: string) {
    if (val !== "" && !/^\d+((E|P|T|G|M|K)i?)?$/.test(val)) {
        return "Memory must be an integer with an appropriate suffix (e.g 100Mi, 100M, 500)";
    }
}

function validateCPU(val: string) {
    if (val !== "" && !/^((\d(\.\d))|(\d+m?))$/.test(val)) {
        return "CPU must be in format of CPU units (e.g. 1, 0.5, 100m)";
    }
}

export {validateMemory, validateCPU};