import * as React from "react";
import {FormFieldProps} from "components/form";
import MultiSelect from "components/MultiSelect/MultiSelect";
import {SelectItem} from "components/VirtualListWithKeyboard/SelectItem";
import DefaultChip from "shared/DefaultChip";
import {BackgroundTaskStatus} from "client/resources/backgroundTaskStatus";

interface BackgroundTaskStatusMultiSelectProps extends FormFieldProps<string[]> {
    autoFocus?: boolean;
    label?: string | JSX.Element;
    error?: string;
}

const BackgroundTaskStatusTypedMultiSelect = MultiSelect<SelectItem>();

const BackgroundTaskStatusMultiSelect: React.StatelessComponent<BackgroundTaskStatusMultiSelectProps> = props => {
    const items = Object.keys(BackgroundTaskStatus).map(i => ({Id: i, Name: i}));
    const chipRenderer = (i: SelectItem, onRequestDelete: (event: object) => void) => {
        return <DefaultChip item={i} onRequestDelete={onRequestDelete}/>;
    };
    return <BackgroundTaskStatusTypedMultiSelect
        {...props}
        fieldName="status"
        renderChip={chipRenderer}
        items={items}/>;
};

export default BackgroundTaskStatusMultiSelect;