import React from "react";
import routeLinks from "routeLinks";
import PaperLayout from "components/PaperLayout/PaperLayout";
import SimpleDataTable from "components/SimpleDataTable/SimpleDataTable";
import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent";
import repository from "client/repository";
import { HostedInstanceResource } from "client/resources/hostedInstanceResource";
import {HubResource} from "client/resources/hubResource";
import { ReefResource } from "client/resources/reefResource";
import TaskSnackbar from "shared/TaskSnackbar";
import {Refresh} from "components/DataBaseComponent/DataBaseComponent";
import ActionList from "components/ActionList";
import ActionButton, {NavigationButton, NavigationButtonType} from "components/Button";
import ExternalLink from "components/Navigation/ExternalLink";
import InternalLink from "components/Navigation/InternalLink/InternalLink";
import { displayInstanceVersion } from "areas/instances/displayInstanceVersion";
import { linkToInstance } from "areas/instances/linkToInstance";

interface State extends DataBaseComponentState {
    hubs?: HubResource[];
    reefs: Map<string, ReefResource>;
    openSnackBar: boolean;
}

class HubsDataTable extends SimpleDataTable<HubResource> {
}

export class Hubs extends DataBaseComponent<{}, State> {
    constructor(props: {}) {
        super(props);
        this.state = {
            openSnackBar: false,
            reefs: new Map<string, ReefResource>(),
        };
    }

    componentDidMount() {
        return this.doBusyTask(async () => {
            this.doRefresh = await this.startRefreshLoop(() => this.loadData(), 5000);
        });
    }

    render() {
        return <PaperLayout title="Hubs"
                            busy={this.state.busy}
                            errors={this.state.errors}
                            sectionControl={<ActionList actions={[
                                <ActionButton label="Refresh" onClick={this.doRefresh}/>,
                                <NavigationButton label="New Hub" href={routeLinks.hubs.create} type={NavigationButtonType.Primary}/>,
                                ]
                            }/>}
                            fullWidth={true}>
            <TaskSnackbar
                getLink={() => routeLinks.backgroundTasks.root}
                onRequestClose={() => this.setState({openSnackBar: false})}
                open={this.state.openSnackBar} />
            {this.state.hubs && <HubsDataTable
                data={this.state.hubs}
                onRow={this.buildRow}
                headerColumns={["ID", "Name", "Instance", "URL", "Version"]}
                onRowRedirectUrl={r => routeLinks.hubs.hub(r.Id).root}
                onEmpty={<div>No hubs found</div>}/>}
        </PaperLayout>;
    }

    private async loadData() {
        return {
            hubs: await repository.Hubs.list(),
            reefs: await repository.Reefs.map(),
        };
    }

    private doRefresh: Refresh = () => Promise.resolve();

    private buildRow = (hub: HubResource) => {
        return [
            hub.Id,
            hub.DisplayName,
            hub.HubInstance ? this.getInstanceLink(hub.HubInstance) : null,
            this.getHubUrlLink(hub),
            hub.HubInstance ? displayInstanceVersion(hub.HubInstance) : null,
        ];
    };

    private getInstanceLink(instance: HostedInstanceResource) {
        return <InternalLink to={routeLinks.instances.instance(instance.Id).root}>{instance.Id}</InternalLink>;
    }

    private getHubUrlLink = (hub: HubResource) => {
        if (hub.HubUrl) {
            return <ExternalLink href={hub.HubUrl}>{hub.HubUrl}</ExternalLink>;
        }

        const instance = hub.HubInstance;
        const reef = this.state.reefs.get(instance.ReefId);
        return reef ? linkToInstance(instance, reef) : instance.DnsPrefix;
    };
}
