import Ajax from "./ajax";
import URITemplate from "urijs/src/URITemplate";

class Client {
    get<TResource>(path: string, args?: any): Promise<TResource> {
        const url = this.resolve(path, args);
        return this.dispatchRequest("GET", url) as Promise<TResource>;
    }

    post<TReturn>(path: string, resource?: any, args?: any): Promise<TReturn> {
        const url = this.resolve(path, args);
        return this.dispatchRequest("POST", url, resource) as Promise<TReturn>;
    }

    del<TReturn>(path: string, options?: DeleteOptions): Promise<TReturn> {
        const url = this.resolve(path, options && options.args);
        return this.dispatchRequest("DELETE", url, options && options.requestBody) as Promise<TReturn>;
    }

    put<TResource>(path: string, resource?: TResource, args?: any): Promise<TResource> {
        const url = this.resolve(path, args);
        return this.dispatchRequest("PUT", url, resource) as Promise<TResource>;
    }

    private dispatchRequest(method: any, url: string, requestBody?: any) {
        return new Promise((resolve, reject) => {
            new Ajax({
                onUnauthenticated: () => window.location.reload(),
                error: e => reject(e),
                method,
                url,
                requestBody,
                success: data => resolve(data),
                withCredentials: true
            }).execute();
        });
    }

    resolve(path: string, uriTemplateParameters?: any): string {
        const template = new URITemplate(path);
        return (template as any).expand(uriTemplateParameters || {});
    }
}
const client = new Client();
export default client;

interface DeleteOptions {
    args?: any;
    requestBody?: any;
}