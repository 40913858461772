import React = require("react");
import {MenuItem} from "components/Menu/OverflowMenu";
import SimpleDataTable from "./SimpleDataTable";
import NumberedPagingBar from "components/Paging/NumberedPagingBar";

interface SimpleDataTablePagingProps<TData> {
    onToolsSection?: any;
    headerColumns: any[];
    headerColumnClassName?: string;
    rowColumnClassName?: string;
    tableClassName?: string;
    data: TData[];
    onEmpty?: React.ReactNode;
    match?: any;
    onRow(item: TData): React.ReactNode[];
    onRowOverflowMenu?(item: TData): MenuItem[];
    onRowRedirectUrl?(item: TData): string;
    onRowClick?(item: TData): void;
    paging?: PagingInfo;
}

class PagingDataTable<TData> extends SimpleDataTable<TData> {
}

export class SimpleDataTablePaging<TData> extends React.Component<SimpleDataTablePagingProps<TData>> {

    constructor(props: SimpleDataTablePagingProps<TData>) {
        super(props);
        this.state = {};
    }

    render() { return (
        <div>
            {this.props.paging && this.props.paging.total > this.props.paging.take &&
                <NumberedPagingBar
                    currentPageIndex={Math.floor(this.props.paging.skip / this.props.paging.take)}
                    pageSize={this.props.paging.take}
                    totalItems={this.props.paging.total}
                    onPageSelected={this.props.paging.pageSelected}
                />
            }
            <PagingDataTable<TData> {...this.props} />
            {this.props.paging && this.props.paging.total > this.props.paging.take &&
                <NumberedPagingBar
                    currentPageIndex={Math.floor(this.props.paging.skip / this.props.paging.take)}
                    pageSize={this.props.paging.take}
                    totalItems={this.props.paging.total}
                    onPageSelected={this.props.paging.pageSelected}
                />
            }
        </div>
        );
    }
}

export interface PagingInfo {
    total: number;
    skip: number;
    take: number;
    pageSelected?: (skip: number, page: number) => void;
}