import MultiSelect from "components/MultiSelect/MultiSelect";
import FormFieldProps from "components/form/FormFieldProps";
import * as React from "react";
import {HostedInstanceChip, DeletedHostedInstanceChip} from "shared/HostedInstanceChip";
import {HostedInstanceLookupResource} from "client/resources/hostedInstanceLookupResource";
import {SelectItem} from "../components/VirtualListWithKeyboard/SelectItem";

interface HostedInstanceMultiSelectProps extends FormFieldProps<string[]> {
    items: HostedInstanceLookupResource[];
    autoFocus?: boolean;
    label?: string | JSX.Element;
    error?: string;
}

interface InstanceSelectItem extends HostedInstanceLookupResource {
    Name: string;
}

const HostedInstanceTypedMultiSelect = MultiSelect<InstanceSelectItem>();

const HostedInstanceMultiSelect: React.FC<HostedInstanceMultiSelectProps> = props => {
    const chipRenderer = (i: InstanceSelectItem | SelectItem, onRequestDelete: (event: object) => void) => {
        if ("ShortId" in i) {
            return <HostedInstanceChip instance={i} onRequestDelete={onRequestDelete}/>;
        }
        return <DeletedHostedInstanceChip instanceId={i.Id} onRequestDelete={onRequestDelete}/>;
    };
    const items = props.items.map(i => ({...i, Name: `${i.DnsPrefix} (${i.ShortId })`}));
    return <HostedInstanceTypedMultiSelect
        {...props}
        renderItem={i => ({primaryText: i.DnsPrefix, secondaryText: i.ShortId})}
        fieldName="hosted instance"
        renderChip={chipRenderer}
        items={items}/>;
};

export default HostedInstanceMultiSelect;