import * as React from "react";
import SaveDialogLayout from "components/DialogLayout/SaveDialogLayout";
import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent";
import repository from "client/repository";
import {StorageAccountResource} from "client/resources/StorageAccountResource";

interface Props {
    reefId: string;
    storageAccount: StorageAccountResource;

    onChange: (storageAccount: StorageAccountResource) => void;
}

type State = DataBaseComponentState;

class ChangeStorageAccountAvailabilityDialog extends DataBaseComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    render() {
        return <SaveDialogLayout title={"Storage Account Availability"}
            busy={this.state.busy}
            errors={this.state.errors}
            saveButtonLabel={"Yes"}
            cancelButtonLabel={"No"}
            onSaveClick={() => this.changeAvailability()}>
            <p>Are you sure you want to make this storage account <b>{`${!this.props.storageAccount.IsAvailable ? "Available" : "Unavailable"}`}</b>?</p>
        </SaveDialogLayout>;
    }

    private changeAvailability = async (): Promise<boolean> => {
        return this.doBusyTask(async () => {
            const availabilityToChange: boolean = !this.props.storageAccount.IsAvailable;
            await repository.Reefs.changeStorageAccountAvailability(this.props.reefId, this.props.storageAccount.Name, {IsAvailable: availabilityToChange});
            this.props.onChange({ ...this.props.storageAccount, IsAvailable: availabilityToChange});
        });
    }
}

export default ChangeStorageAccountAvailabilityDialog;
