// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_icon__SH6zS {
  vertical-align: middle;
}
`, "",{"version":3,"sources":["webpack://./app/components/CopyToClipboardButton/style.less"],"names":[],"mappings":"AAAA;EACI,sBAAA;AACJ","sourcesContent":[".icon {\n    vertical-align: middle;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": `style_icon__SH6zS`
};
module.exports = ___CSS_LOADER_EXPORT___;
