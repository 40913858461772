import * as React from "react";
import styles = require("./style.less");
import {sum} from "lodash";
import {
        DynamicWorkerServiceRuntimeStatusResource
} from "../../client/resources/dynamicWorkerServiceRuntimeStatusResource";



const WorkersByState: React.FunctionComponent<{status: DynamicWorkerServiceRuntimeStatusResource}> = props =>
    <ul className={styles.workerList}>
            <li><strong>Provisioning:</strong> {getCount("Provisioning", props.status)}</li>
            <li><strong>Provisioned:</strong> {getCount("Provisioned", props.status)}</li>
            <li><strong>Trust Configured:</strong> {getCount("TrustConfigured", props.status)}</li>
            <li><strong>Total:</strong> {props.status.WorkerTypesStateDetails ? sum(props.status.WorkerTypesStateDetails.map(w => w.Count)) : "?"}</li>
    </ul>;

const getCount = (state: string, status: DynamicWorkerServiceRuntimeStatusResource ) =>
    status.WorkerTypesStateDetails
        ? sum(status.WorkerTypesStateDetails.filter(w => w.State === state).map(w => w.Count))
        : "?";

WorkersByState.displayName = "WorkersByState";

export default WorkersByState;