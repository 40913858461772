import * as React from "react";
import {sortBy} from "lodash";
import PaperLayout from "components/PaperLayout/PaperLayout";
import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent";
import repository from "client/repository";
import {HostedInstanceLookupResource} from "client/resources/hostedInstanceLookupResource";
import {ReefResource} from "client/resources/reefResource";
import ActionList from "components/ActionList";
import {ActionButtonType} from "components/Button";
import OpenDialogButton from "components/Dialog/OpenDialogButton";
import SaveDialogLayout from "components/DialogLayout/SaveDialogLayout";
import {Text} from "components/form";
import InstanceEnvironmentVariableDataTable from "./InstanceEnvironmentVariableDataTable";
import {    
    EnvironmentVariableScope,
    InstanceEnvironmentVariable,
    InstanceEnvironmentVariableUsage
} from "client/resources/instanceEnvironmentVariablesResource";

interface State extends DataBaseComponentState {
    environmentVariables?: InstanceEnvironmentVariable[];
    instances?: HostedInstanceLookupResource[];
    reefs?: ReefResource[];
    newEnvironmentVariableName?: string;
    newEnvironmentVariableValue?: string;
    newEnvironmentVariableDesc?: string;
    usageStats?: InstanceEnvironmentVariableUsage[];
}

class InstanceEnvironmentVariables extends DataBaseComponent<{}, State> {
    constructor(props: {}) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.loadData();
    }

    private async loadData() {
        return this.doBusyTask(async () => {
            const environmentVariables = await repository.InstanceEnvironmentVariables.getInstanceEnvironmentVariables();
            const reefs = await repository.Reefs.list();
            const instances = await repository.HostedInstances.lookup();
            repository.InstanceEnvironmentVariables.getUsageStats().then((usageStats) => {
                this.setState({usageStats});
                console.log(usageStats);
            })

            this.setState({
                environmentVariables: sortBy(environmentVariables, f => f.Name.toLowerCase()),
                reefs: reefs,
                instances: instances
            });
        });
    }

    private editEnvironmentVariable = (environmentVariable: InstanceEnvironmentVariable) => {
        return this.doBusyTask(async () => {
            await repository.InstanceEnvironmentVariables.editInstanceEnvironmentVariable(environmentVariable.Id,  environmentVariable.IsEnabled);
            const newState = [...this.state.environmentVariables];
            const index = newState.findIndex(f => f.Id === environmentVariable.Id);
            newState[index] = environmentVariable;
            this.setState({environmentVariables: newState});
        });
    }

    private deleteEnvironmentVariable = (environmentVariable: InstanceEnvironmentVariable) => {
        return this.doBusyTask(async () => {
            await repository.InstanceEnvironmentVariables.deleteInstanceEnvironmentVariable(environmentVariable.Id)
            const newState = [...this.state.environmentVariables];
            const index = newState.findIndex(f => f.Id === environmentVariable.Id);
            newState.splice(index, 1);
            this.setState({environmentVariables: newState});
        });
    }

    private editEnvironmentVariableScope = async (id: string, scope: EnvironmentVariableScope) => {
        await repository.InstanceEnvironmentVariables.editInstanceEnvironmentVariableScope(id, scope);
        await this.loadData();

        return true;
    }

    private addEnvironmentVariable = () => {
        return this.doBusyTask(async () => {
            await repository.InstanceEnvironmentVariables.addInstanceEnvironmentVariable(
                this.state.newEnvironmentVariableName, this.state.newEnvironmentVariableValue, this.state.newEnvironmentVariableDesc);
            this.setState({
                newEnvironmentVariableName: null, 
                newEnvironmentVariableValue: null, 
                newEnvironmentVariableDesc: null
            });
            await this.loadData();
        });
    }
    
    private addEnvironmentVariableDialog = () => {
        return <SaveDialogLayout title={"Add new environment variable"}
                                        busy={this.state.busy}
                                        errors={this.state.errors}
                                        onSaveClick={this.addEnvironmentVariable}>
            <Text label="Variable Name"
                  value={this.state.newEnvironmentVariableName}
                  onChange={value => this.setState({newEnvironmentVariableName: value})}
            />
            <Text label="Variable Value"
                  value={this.state.newEnvironmentVariableValue}
                  onChange={value => this.setState({newEnvironmentVariableValue: value})} />
            <Text label="Description"
                  value={this.state.newEnvironmentVariableDesc}
                  onChange={value => this.setState({newEnvironmentVariableDesc: value})} />
        </SaveDialogLayout>;
    }

    render() {
        return <PaperLayout title="Instance Environment Variables"
                            busy={this.state.busy}
                            errors={this.state.errors}
                            sectionControl={<ActionList actions={[
                                <OpenDialogButton label={"New environment variable"} type={ActionButtonType.Primary}>
                                    {this.addEnvironmentVariableDialog()}
                                </OpenDialogButton>
                            ]
                            }/>}
                            fullWidth={true}>
            {this.state.environmentVariables &&
            <InstanceEnvironmentVariableDataTable
                environmentVariables={this.state.environmentVariables}
                editEnvironmentVariables={this.editEnvironmentVariable}
                editEnvironmentVariableScope={this.editEnvironmentVariableScope}
                deleteEnvironmentVariable={this.deleteEnvironmentVariable}
                usageStats={this.state.usageStats}
                instances={this.state.instances}
                reefs={this.state.reefs}
            />}
        </PaperLayout>;
    }
}

export default InstanceEnvironmentVariables;