export function bytesToGigaBytes(valueInBytes: number) {
    return (valueInBytes / 1024 / 1024 / 1024).toFixed(2);
}

export function bytesToMegaBytes(valueInBytes: number) {
    return (valueInBytes / 1024 / 1024).toFixed(2);
}

export function megaBytesToGigaBytes(valueInMegaBytes: number) {
    return (valueInMegaBytes / 1024).toFixed(2);
}

export function gigaBytesToGigaBytes(valueInGigaBytes: number) {
    return valueInGigaBytes.toFixed(2);
}