// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_when__rozQ3 abbr[title] {
  text-decoration: none;
  cursor: help;
  border-bottom: 0.05rem dotted #777777;
}
`, "",{"version":3,"sources":["webpack://./app/shared/FriendlyLocalTime/style.less"],"names":[],"mappings":"AAAA;EAEQ,qBAAA;EACA,YAAA;EACA,qCAAA;AAAR","sourcesContent":[".when {\n    abbr[title] {\n        text-decoration: none;\n        cursor: help;\n        border-bottom: 0.05rem dotted #777777;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"when": `style_when__rozQ3`
};
module.exports = ___CSS_LOADER_EXPORT___;
