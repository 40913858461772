import {DataBaseComponent, DataBaseComponentState, Errors} from "components/DataBaseComponent";
import repository from "client/repository";
import * as React from "react";
import {BusyState} from "components/BusyFromPromise/BusyFromPromise";
import {HostedInstanceResource} from "client/resources/hostedInstanceResource";
import SaveDialogLayout from "components/DialogLayout/SaveDialogLayout";
import Select from "components/form/Select/Select";
import {FileShareSnapshotResource} from "client/resources/fileShareSnapshotResource";
import {FileShareRestoreRequest} from "client/resources/fileShareRestoreRequest";

interface RestoreFileShareDialogProps {
    errors: Errors;
    busy: BusyState;
    instance: HostedInstanceResource;
}

interface RestoreFileShareDialogState extends DataBaseComponentState {
    fileShareSnapshots?: FileShareSnapshotResource[];
    request: Partial<FileShareRestoreRequest>;
}

export class RestoreFileShareDialog extends DataBaseComponent<RestoreFileShareDialogProps, RestoreFileShareDialogState> {
    constructor(props: RestoreFileShareDialogProps) {
        super(props);
        this.state = {
            fileShareSnapshots: [],
            request: {SnapshotQualifiedUri: ""},
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {

            const fileShareSnapshots = await repository.HostedInstances.fileShareSnapshots(this.props.instance.Id);
            this.setState({fileShareSnapshots});
        });
    }

    render() {

        const request = this.state.request;
        return <SaveDialogLayout title={"Restore File Share"}
                                 busy={this.state.busy}
                                 errors={this.state.errors}
                                 onSaveClick={() => this.restoreFileShare(request)}>
            <label>Restore file share for hosted instance {this.props.instance.DnsPrefix}?</label>
            <Select value={this.state.request.SnapshotQualifiedUri}
                    onChange={(SnapshotQualifiedUri) => this.setState({request: {SnapshotQualifiedUri}})}
                    items={this.state.fileShareSnapshots.map((fileShareSnapshot) => ({text: fileShareSnapshot.SnapshotTime, value: fileShareSnapshot.SnapshotQualifiedUri}))}
                    fieldName="Snapshot Time"
            />
        </SaveDialogLayout>;
    }

    private restoreFileShare(request: Partial<FileShareRestoreRequest>) {
        return this.doBusyTask(async () => {
            await repository.HostedInstances.restoreFileShare(this.props.instance, request as FileShareRestoreRequest);
        });
    }
}