import * as React from "react";
import FormSectionHeading from "../../components/form/Sections/FormSectionHeading";
import styles = require("./style.less");

interface FormSectionHeadingWithActionProps {
    title: string;
    action: JSX.Element;
}

const FormSectionHeadingWithAction: React.StatelessComponent<FormSectionHeadingWithActionProps> = (props) => {
    return <FormSectionHeading title={
        <div className={styles.heading}>
            <span className={styles.title}>{props.title}</span>
            <span className={styles.action}>{props.action}</span>
        </div>}
    />;
};

export default FormSectionHeadingWithAction;