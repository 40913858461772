import React from "react";
import {BooleanRadioButton, BooleanRadioButtonGroup} from "@octopusdeploy/design-system-components";
import {DataBaseComponent, DataBaseComponentState, Errors} from "components/DataBaseComponent";
import repository from "client/repository";
import InternalLink from "components/Navigation/InternalLink/InternalLink";
import {instancesRouteLinks} from "areas/instances/instancesRouteLinks";
import ExternalLink from "components/Navigation/ExternalLink/ExternalLink";
import {BusyState} from "components/BusyFromPromise/BusyFromPromise";
import {ProcessDumpResource} from "client/resources/processDumpResource";
import {TakeProcessDumpRequest} from "../../../client/resources/takeProcessDumpRequest";
import {ProcessDumpType} from "../../../client/resources/processDumpType";
import SaveDialogLayout from "../../../components/DialogLayout/SaveDialogLayout";
import {AreYouReallySureConfirmation} from "./AreYouReallySureConfirmation";
import Select from "components/form/Select/Select";

interface TakeProcessDumpDialogProps {
    errors: Errors;
    busy: BusyState;
    instanceId: string;
}

interface TakeProcessDumpDialogState extends DataBaseComponentState {
    processDump: ProcessDumpResource;
    processDumpRequest: Partial<TakeProcessDumpRequest>;
    canProcess: boolean;
}

export class TakeProcessDumpDialog extends DataBaseComponent<TakeProcessDumpDialogProps, TakeProcessDumpDialogState> {
    constructor(props: TakeProcessDumpDialogProps) {
        super(props);
        this.state = {
            processDump: null,
            processDumpRequest: {
                ...{TakeProcessDumpDuringOutageWindow: false, ProcessDumpType: ProcessDumpType.Full}
            },
            canProcess: false
        };
    }

    render() {
        if (this.state.processDump == null) {
            const request = this.state.processDumpRequest;
            return <SaveDialogLayout title={"Take process dump"}
                                     saveButtonLabel={"Process"}
                                     busy={this.state.busy}
                                     errors={this.state.errors}
                                     onSaveClick={() => this.takeProcessDump()}
                                     saveButtonDisabled={!this.state.canProcess}>
                
                <AreYouReallySureConfirmation title={"This may cause a customer outage"}
                                              warning={<>Taking a process dump may cause the instance to <strong>restart</strong>, resulting in an outage for the customer.</>} 
                                              requiredConfirmation={"instance may restart"} 
                                              onChange={canProcess => this.setState({canProcess})}/>

                <Select value={request.ProcessDumpType && request.ProcessDumpType.toString()}
                        onChange={(value: string) => this.updateProcessDumpRequest({ProcessDumpType: (value as ProcessDumpType)})}
                        items={Object.entries(ProcessDumpType).map(e => ({text: e[0], value: e[0]}))}
                        fieldName="Process Dump Type"
                        hintText="The process dump type"
                />

                <BooleanRadioButtonGroup value={request.TakeProcessDumpDuringOutageWindow} onChange={UpgradeDuringOutageWindow => this.updateProcessDumpRequest({TakeProcessDumpDuringOutageWindow: UpgradeDuringOutageWindow})}>
                    <BooleanRadioButton value={true} label="Process During Outage Window" />
                    <BooleanRadioButton value={false} label="Process Immediately" />
                </BooleanRadioButtonGroup>
                <br/>
                <div>
                    A task will be created in the background to start the dump.<br/>
                    Once the <InternalLink
                    to={instancesRouteLinks.instance(this.props.instanceId).tasks}>task</InternalLink> completes,
                    a dump file can be found <InternalLink to={instancesRouteLinks.instance(this.props.instanceId).diagnosticFiles}>here</InternalLink>.
                    <br/>
                </div>
                <div>
                    Details on the different types of process dump (Full, Heap and Mini) can be found <ExternalLink
                    href="https://learn.microsoft.com/en-us/dotnet/core/diagnostics/dotnet-dump#dotnet-dump-collect">here</ExternalLink>
                </div>
                <div>
                    Instructions on how to analyse this dump file can be found <ExternalLink
                    href="https://github.com/OctopusDeploy/hosted-docs/blob/master/how-to/collect-and-analyse-a-process-dump.md#how-to-analyse-process-dumps">here</ExternalLink>
                </div>
            </SaveDialogLayout>;
        }
    }
    private updateProcessDumpRequest(update: Partial<TakeProcessDumpRequest>) {
        return this.setState(prevState => ({processDumpRequest: {...prevState.processDumpRequest, ...update}}));
    }
    
    private takeProcessDump() {
        return this.doBusyTask(async () => {
            this.setState({processDump: await repository.HostedInstances.takeProcessDump(this.props.instanceId, this.state.processDumpRequest)});
            return <TakeProcessDumpDialog instanceId={this.props.instanceId}
                                          busy={this.state.busy}
                                          errors={this.state.errors}/>
        });
    }
}