import * as React from "react";
import {themeTokens} from "@octopusdeploy/design-system-tokens";
import { LockStatus } from "client/resources/instanceTaskLockResource";
import Error from "material-ui/svg-icons/alert/error-outline";
import Warning from "material-ui/svg-icons/alert/warning";

export function getColorAndIconByLockStatus(status: LockStatus): {Color: string, Icon: JSX.Element} {
    if (status === LockStatus.Blocked) {
        return { Color: themeTokens.color.text.danger, Icon: <Error color={themeTokens.color.icon.danger}/>};
    }
    else if (status === LockStatus.OverDue){
        return { Color: themeTokens.color.text.warning, Icon: <Warning color={themeTokens.color.icon.warning}/>};
    }
    else{
        return { Color: themeTokens.color.text.primary, Icon: <></>};
    }
}