import * as React from "react";
import {Switch} from "react-router-dom";
import ReloadableRoute from "components/ReloadableRoute/ReloadableRoute";
import {azureRegionsRouteLinks} from "areas/azureRegions/azureRegionsRouteLinks";
import {InternalRedirect} from "components/Navigation/InternalRedirect";
import {AzureRegions} from "areas/azureRegions/AzureRegions";
import AzureRegionLayout from "areas/azureRegions/AzureRegionLayout";
import CreateAzureRegion from "./CreateAzureRegion";

const ConfigurationLayout: React.FunctionComponent<{}> = _ =>
    <Switch>
        <ReloadableRoute path={azureRegionsRouteLinks.create} exact={true} component={CreateAzureRegion}/>
        <ReloadableRoute path={azureRegionsRouteLinks.root} exact={true} component={AzureRegions}/>
        <ReloadableRoute path={azureRegionsRouteLinks.azureRegion(":id").root} component={AzureRegionLayout}/>
        <ReloadableRoute render={() => <InternalRedirect to={azureRegionsRouteLinks.root}/>}/>
    </Switch>;

export default ConfigurationLayout;
