// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* This file is DEPRECATED
   We are moving to the Octopus Design System
   https://www.octopus.design
  
   When changing component styles:
   1. Do not use variables from this file
   2. Aim to use Emotion \`css\` + themeTokens.colors
       - see Dashboard.tsx for example
   3. If you need to use Less, reference CSS variables
       - see index.ejs for example
       - for full list see https://github.com/OctopusDeploy/OctopusDeploy/blob/main/frontend/packages/design-system-tokens/src/generated/themeTokens.ts
   4. Remove variables from this file when they are no longer used
 */
.MultiSelect_textContainer__Nh7r9 {
  position: relative;
}
.MultiSelect_iconContainer__yaXXB {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  margin: 0.5625rem;
}
.MultiSelect_error__tbooK {
  font-Size: 0.75rem;
  line-Height: 0.75rem;
  color: var(--colorTextDanger);
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}
.MultiSelect_label__Lt05U {
  line-height: 22px;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  user-select: none;
  color: var(--colorTextLinkDefault);
  font-size: 0.75rem;
  font-weight: 400;
}
`, "",{"version":3,"sources":["webpack://./app/components/MultiSelect/MultiSelect.less"],"names":[],"mappings":"AAAA;;;;;;;;;;;;EAYE;AATF;EACI,kBAAA;AAWJ;AARA;EACI,kBAAA;EACA,QAAA;EACA,MAAA;EACA,eAAA;EACA,iBAAA;AAUJ;AAPA;EACI,kBAAA;EACA,oBAAA;EACA,6BAAA;EACA,wDAAA;AASJ;AANA;EACI,iBAAA;EACA,wDAAA;EACA,iBAAA;EACA,kCAAA;EACA,kBAAA;EACA,gBAAA;AAQJ","sourcesContent":["@import \"../../resources/styles/colors\";\n@import \"../../resources/styles/variables\";\n\n.textContainer {\n    position: relative\n}\n\n.iconContainer {\n    position: absolute;\n    right: 0;\n    top:0;\n    cursor: pointer;\n    margin: 9/16rem;\n}\n\n.error {\n    font-Size: 12/16rem;\n    line-Height: 12/16rem;\n    color: @danger;\n    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;\n}\n\n.label{\n    line-height: 22px;\n    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;\n    user-select: none;\n    color: @primary;\n    font-size: 12/16rem;\n    font-weight: @normal;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textContainer": `MultiSelect_textContainer__Nh7r9`,
	"iconContainer": `MultiSelect_iconContainer__yaXXB`,
	"error": `MultiSelect_error__tbooK`,
	"label": `MultiSelect_label__Lt05U`
};
module.exports = ___CSS_LOADER_EXPORT___;
