import * as React from "react";
import {Switch} from "@octopusdeploy/design-system-components";
import SimpleDataTable from "components/SimpleDataTable/SimpleDataTable";
import OverflowMenu from "components/Menu/OverflowMenu";
import {HostedInstanceLookupResource} from "client/resources/hostedInstanceLookupResource";
import {ReefResource} from "client/resources/reefResource";
import Warning from "material-ui/svg-icons/alert/warning";
import ToolTip from "components/ToolTip";
import {
    EnvironmentVariableScope,
    InstanceEnvironmentVariable,
    InstanceEnvironmentVariableUsage
} from "client/resources/instanceEnvironmentVariablesResource";
import routeLinks from "routeLinks";
import {InstanceStatus} from "client/resources/instanceStatus";
import ForceApplyInstanceEnvironmentVariableDialog from "./ForceApplyInstanceEnvironmentVariableDialog";
import repository from "client/repository";
import {DeleteInstanceEnvironmentVariableDialog} from "./DeleteInstanceEnvironmentVariableDialog";
import styles = require("../style.less");
import {ToolTipIcon} from "./ToolTipIcon";
import EditInstanceEnvironmentVariableScopeDialog from "./EditInstanceEnvironmentVariableScopeDialog";
import InternalLink from "../../../components/Navigation/InternalLink";
import InstanceEnvironmentVariableValue from "./InstanceEnvironmentVariableValue";
import {auditLinks} from "../../auditing/auditRouteLinks";
import SwitchWithConfirmation from "../SwitchWithConfirmation";

class DataTable extends SimpleDataTable<InstanceEnvironmentVariable> {
}

interface Props {
    environmentVariables: InstanceEnvironmentVariable[];
    editEnvironmentVariables: (environmentVariable: InstanceEnvironmentVariable) => Promise<boolean>;
    deleteEnvironmentVariable: (environmentVariable: InstanceEnvironmentVariable) => Promise<boolean>;
    editEnvironmentVariableScope: (id: string, scope: EnvironmentVariableScope) => Promise<boolean>;
    instances: HostedInstanceLookupResource[];
    reefs: ReefResource[];
    usageStats?: InstanceEnvironmentVariableUsage[];
}

class InstanceEnvironmentVariableDataTable extends React.Component<Props, {deletionAction: string}> {
    constructor(props: Props) {
        super(props);
        this.state = {
            deletionAction: "Nothing"
        }
    }
    
    render() {
        return (
            <DataTable
                data={this.props.environmentVariables}
                onRow={this.buildRow}
                onRowOverflowMenu={this.getOverflowMenu}
                headerColumns={["Variable Name", "Value", 
                    <>Rollout<br />Enabled<ToolTipIcon description={"Disabling a variable won't remove it from instances that already use it, " +
                        "but further rollout will be prevented."} /></>, 
                    "Scope",
                    <>Progress<ToolTipIcon description={"Expected counts do not take into consideration explicit instance inclusion or exclusion."} /></>]}
                rowColumnClassName={styles.dataTableRowColumn}
            />
        );
    }

    private buildRow = (environmentVariable: InstanceEnvironmentVariable) => {
        const onToggle = (isInputChecked: boolean): Promise<any> => {
            return this.props.editEnvironmentVariables({
                ...environmentVariable,
                IsEnabled: isInputChecked
            });
        };

        return [
            this.renderName(environmentVariable),
            <InstanceEnvironmentVariableValue value={environmentVariable.Value} />,
            <SwitchWithConfirmation value={environmentVariable.IsEnabled} onChange={onToggle} settingName={environmentVariable.Name} noun="Environment Variable Rollout" />,
            this.renderScopeDetails(environmentVariable),
            this.renderUsageDetails(environmentVariable)
        ];
    }
    
    private renderScopeDetails (environmentVariable: InstanceEnvironmentVariable) {
     return <>{this.renderScope(environmentVariable)}
         <br />
         {this.renderVersionRange(environmentVariable)}
     </>;   
    }
    
    private renderName (environmentVariable: InstanceEnvironmentVariable) {
        if(environmentVariable.Description){
            return <ToolTip content={environmentVariable.Id + " - " + environmentVariable.Description}>{environmentVariable.Name}</ToolTip>;
        }
        return <ToolTip content={environmentVariable.Id}>{environmentVariable.Name}</ToolTip>;
    }

    private renderUsageDetails = (environmentVariable: InstanceEnvironmentVariable) => {
        if (!this.props.usageStats) {
            return "loading...";
        }
        const variableUsage = this.props.usageStats.find(f => f.InstanceEnvironmentVariableId == environmentVariable.Id);
        if (!variableUsage) {
            return "No Usage Info";
        }

        return <div style={{display: "flex", alignItems: "center"}}><ToolTip content={this.renderUsageDetailsPopupContent(environmentVariable, variableUsage)}>
            <InternalLink to={routeLinks.instances.filtered({
                envVariable: [environmentVariable.Id],
                status: [InstanceStatus.Live, InstanceStatus.Provisioning]
            })}>
                {variableUsage.InstanceCountUsingVariable} ({variableUsage.PercentageUsingVariable}%)
            </InternalLink>
        </ToolTip>
            {variableUsage.InstanceCountUnderVersionRange != null && variableUsage.InstanceCountUnderVersionRange == 0 ? <>
                <ToolTip content="This variable can be removed. All active instances globally are on later versions than the defined maximum version"><Warning color={"#fb8c00"} /></ToolTip>
            </> : null}
        </div>;
    }
    
    private renderUsageDetailsPopupContent = (environmentVariable: InstanceEnvironmentVariable, variableUsage: InstanceEnvironmentVariableUsage) => {
        return <table>
                <tr>
                    <td align="right">Total Active {environmentVariable.AppliesTo.ScopeLevel == "Global" ? "Globally" : <>In {environmentVariable.AppliesTo.ReefIds.length} Reefs</>}</td>
                    <td>{variableUsage.InstanceCountWithinReef}</td>
                </tr>
                {(environmentVariable.AppliesTo.MinimumVersion || environmentVariable.AppliesTo.MaximumVersion) && <tr>
                    <td align="right">Within Version Range</td>
                    <td>{variableUsage.InstanceCountWithinReefVersion}</td>
                </tr>}
                <tr>
                    <td align="right" style={{fontWeight: "bold"}}>Target Rollout</td>
                    <td>{variableUsage.InstanceCountExpectedFromRolloutPercent} ({environmentVariable.AppliesTo.RolloutPercentage}%)</td>
                </tr>
                <tr>
                    <td align="right" style={{fontWeight: "bold"}}>Current Rollout</td>
                    <td>{variableUsage.InstanceCountUsingVariable} ({variableUsage.PercentageUsingVariable}%)</td>
                </tr>
                {variableUsage.InstanceCountUnderVersionRange != null && <tr>
                    <td align="right">Lower than Max Version</td>
                    <td>{variableUsage.InstanceCountUnderVersionRange}</td>
                </tr>}
            </table>;
    }
    
    private renderVersionRange = (environmentVariable: InstanceEnvironmentVariable) => {
        const {MinimumVersion, MaximumVersion} = environmentVariable.AppliesTo;

        if (!MinimumVersion && !MaximumVersion) {
            return null;
        }

        if (!!MinimumVersion && !!MaximumVersion) {
            return <><code>{MinimumVersion}</code> to <code>{MaximumVersion}</code></>;
        }

        if(MinimumVersion) {
            return <>&gt;= <code>{MinimumVersion}</code></>;
        }

        if(MaximumVersion) {
            return <>&lt;= <code>{MaximumVersion}</code></>;
        }
    }
    
    private renderScope = (environmentVariable: InstanceEnvironmentVariable) => {
        const { RolloutPercentage, ScopeLevel, IncludedInstanceIds, ExcludedInstanceIds, ReefIds } = environmentVariable.AppliesTo;
        
        let range = ScopeLevel == "Reef" ? 
            `${ReefIds.length} Reefs` :
            "Global";
        range = `${RolloutPercentage}% of ${range}`;
        
        if(IncludedInstanceIds && IncludedInstanceIds.length > 0){
            if(RolloutPercentage == 0){
                range = `${IncludedInstanceIds.length} instance${IncludedInstanceIds.length > 1 ? "s" : ""}`;
            } else {
                range = `${range} +${IncludedInstanceIds.length} instance${IncludedInstanceIds.length > 1 ? "s" : ""}`;    
            }
        }

        if(ExcludedInstanceIds && ExcludedInstanceIds.length > 0){
            if(RolloutPercentage != 0){
                range = `${range} -${ExcludedInstanceIds.length} instance${ExcludedInstanceIds.length > 1 ? "s" : ""}`;
            }
        }
        
        return range;
    }

    private getOverflowMenu = (environmentVariable: InstanceEnvironmentVariable) => {
        const otherEnvironmentVariablesWithSameNameExist = !!this.props.environmentVariables
            .find(e => e.Id != environmentVariable.Id && e.Name === environmentVariable.Name); 

        const items = [
            OverflowMenu.dialogItem("Edit scope",  
                <EditInstanceEnvironmentVariableScopeDialog
                    environmentVariable={environmentVariable}
                    otherEnvironmentVariablesWithSameNameExist={otherEnvironmentVariablesWithSameNameExist}
                    editScope={this.props.editEnvironmentVariableScope}
                    instances={this.props.instances}
                    reefs={this.props.reefs}/>,
                true),
            OverflowMenu.dialogItem("Force Apply", 
                <ForceApplyInstanceEnvironmentVariableDialog
                    forceAllocation={(reprovisionDuringWeekends) => 
                        repository.InstanceEnvironmentVariables.forceAllocation(environmentVariable.Id, reprovisionDuringWeekends)}
                    environmentVariable={environmentVariable}/>, true),
            OverflowMenu.dialogItem("Delete", 
                <DeleteInstanceEnvironmentVariableDialog
                    environmentVariable={environmentVariable}
                    delete={async () => {
                        await this.props.deleteEnvironmentVariable(environmentVariable);
                    }} />, true),
            OverflowMenu.navItem("Audit Trail", auditLinks.auditTrailFor(environmentVariable)),
        ];
        return items;
    }
}

export default InstanceEnvironmentVariableDataTable;