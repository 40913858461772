import * as React from "react";
import ToolTip from "components/ToolTip";
import HelpOutline from "material-ui/svg-icons/action/help-outline";

export class ToolTipIcon extends React.Component<{ description: string }> {
    render() {
        const {description} = this.props;
        return <ToolTip content={description}>
            <HelpOutline style={{"height": "1rem", "width": "1rem", "color": ""}}/>
        </ToolTip>;
    }
}