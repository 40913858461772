// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* This file is DEPRECATED
   We are moving to the Octopus Design System
   https://www.octopus.design
  
   When changing component styles:
   1. Do not use variables from this file
   2. Aim to use Emotion \`css\` + themeTokens.colors
       - see Dashboard.tsx for example
   3. If you need to use Less, reference CSS variables
       - see index.ejs for example
       - for full list see https://github.com/OctopusDeploy/OctopusDeploy/blob/main/frontend/packages/design-system-tokens/src/generated/themeTokens.ts
   4. Remove variables from this file when they are no longer used
 */
@media (max-width: 37.4rem) {
  .style_hide-sm__ZZwwl {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .style_visible-sm__xkY6w {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .style_hide-md__ZgW6N {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .style_visible-md__b1Kow {
    display: none !important;
  }
}
.style_container__dV6pQ {
  display: flex;
  align-items: baseline;
  flex-wrap: nowrap;
  width: 100%;
}
@media (max-width: 37.4rem) {
  .style_container__dV6pQ {
    flex-wrap: wrap;
  }
}
.style_input__XBV12 {
  flex: 1 1 auto;
}
.style_actions___WvtZ {
  margin-left: 0.5rem;
}
`, "",{"version":3,"sources":["webpack://./app/components/InputWithActions/style.less","webpack://./app/resources/styles/media.less"],"names":[],"mappings":"AAAA;;;;;;;;;;;;EAYE;ACNF;EACE;IACE,wBAAA;EDQF;AACF;ACLA;EACE;IACE,wBAAA;EDOF;AACF;ACJA;EACE;IACE,wBAAA;EDMF;AACF;ACJA;EACE;IACE,wBAAA;EDMF;AACF;AA7BA;EACI,aAAA;EACA,qBAAA;EACA,iBAAA;EAIA,WAAA;AA4BJ;AA/BI;EAAA;IACI,eAAA;EAkCN;AACF;AA9BA;EACI,cAAA;AAgCJ;AA7BA;EACI,mBAAA;AA+BJ","sourcesContent":["@import \"../../resources/styles/colors\";\n@import \"../../resources/styles/media\";\n\n.container {\n    display: flex;\n    align-items: baseline;\n    flex-wrap: nowrap;\n    @media (max-width: @screen-sm) {\n        flex-wrap: wrap;\n    }\n    width: 100%;\n}\n\n.input {\n    flex: 1 1 auto;\n}\n\n.actions {\n    margin-left: 0.5rem;\n}","//@screen-xs: 480px;\n@screen-sm: 37.4rem; //600px\n@screen-md: 79.9rem; //1279px\n//@screen-lg-min: 1280px;\n//@scree-xl-min: 1920px;\n\n@media (max-width: @screen-sm) {\n  .hide-sm {\n    display: none !important;\n  }\n}\n\n@media (min-width: @screen-sm) {\n  .visible-sm {\n    display: none !important;\n  }\n}\n\n@media (max-width: @screen-md) {\n  .hide-md {\n    display: none !important;\n  }\n}\n@media (min-width: @screen-md) {\n  .visible-md {\n    display: none !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hide-sm": `style_hide-sm__ZZwwl`,
	"hideSm": `style_hide-sm__ZZwwl`,
	"visible-sm": `style_visible-sm__xkY6w`,
	"visibleSm": `style_visible-sm__xkY6w`,
	"hide-md": `style_hide-md__ZgW6N`,
	"hideMd": `style_hide-md__ZgW6N`,
	"visible-md": `style_visible-md__b1Kow`,
	"visibleMd": `style_visible-md__b1Kow`,
	"container": `style_container__dV6pQ`,
	"input": `style_input__XBV12`,
	"actions": `style_actions___WvtZ`
};
module.exports = ___CSS_LOADER_EXPORT___;
