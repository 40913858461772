import client from "client/client";
import {SupportUserResource} from "client/resources/supportUserResource";

export interface ListArgs {
    instanceId?: string;
}

export default class SupportUserRepository {

    private rootUri = "/api/support-users";

    list(args: ListArgs) {
        return client.get<SupportUserResource[]>(`${this.rootUri}{?instanceId}`, args);
    }
}
