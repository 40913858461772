import * as React from "react";
import SaveDialogLayout from "components/DialogLayout/SaveDialogLayout";
import repository from "client/repository";
import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent";
import {ReefResource} from "client/resources/reefResource";
import Sensitive from "components/Sensitive/Sensitive";
import {createEmptySensitiveValue} from "../../shared/sensitiveValueHelper";
import { SqlServerResource } from "client/resources/SqlServerResource";
import { ChangeSqlServerAdminPasswordRequest } from "client/resources/changeSqlServerAdminLoginRequest";
import Callout, { CalloutType } from "components/Callout";

interface Props {
    reef: ReefResource;
    sqlServer: SqlServerResource;
    onSave: () => void;
}

interface State extends DataBaseComponentState {
    model: ChangeSqlServerAdminPasswordRequest;
}

class ChangeSqlServerAdminPasswordDialog extends DataBaseComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            model: {
                AdminPassword: createEmptySensitiveValue()
            }
        };
    }

    render() {
        const model = this.state.model;
        return <SaveDialogLayout title={"Change SQL Server Admin Login"}
            onSaveClick={() => this.changeSqlServerAdminPassword()}
            errors={this.state.errors}
            saveButtonDisabled={!this.state.model.AdminPassword.HasValue}
            busy={this.state.busy}>
            <Callout type={CalloutType.Information} title="Password Requirements">
                See <a href="https://docs.microsoft.com/en-us/sql/relational-databases/security/password-policy?view=sql-server-ver15">Password Policy</a>
                <br />
                <br />
                <strong>Remember to update the associated entry in 1Password!</strong>
            </Callout>
            <Sensitive label="New SQL Server Admin Password"
                value={model.AdminPassword}
                onChange={sqlAdminPassword => this.modifySqlServerAdminPasswordState({AdminPassword: sqlAdminPassword})}
            />
        </SaveDialogLayout>;
    }

    private modifySqlServerAdminPasswordState(sqlServerAdminLoginConfiguration: Partial<ChangeSqlServerAdminPasswordRequest>): void {
        this.setState((prevState: State) => ({model: {...prevState.model, ...sqlServerAdminLoginConfiguration}}));
    }

    private async changeSqlServerAdminPassword(): Promise<boolean> {
        const task = await this.doBusyTask(async () => {
            await repository.Reefs.changeSqlServerAdminPassword(this.props.reef.Id, this.props.sqlServer.Name, this.state.model);
        });

        if (task) this.props.onSave();

        return task;
    }
}

export default ChangeSqlServerAdminPasswordDialog;
