import * as React from "react";
import {required, Text} from "components/form";
import Summary from "components/form/Sections/Summary";
import ExpandableFormSection from "components/form/Sections/ExpandableFormSection";
import FormPaperLayout from "components/FormPaperLayout/FormPaperLayout";
import OptionalFormBaseComponent, {OptionalFormBaseComponentState} from "components/form/FormBaseComponent";
import repository from "client/repository";
import {InternalRedirect} from "components/Navigation/InternalRedirect";
import {DynamicWorkerServiceResource} from "client/resources/dynamicWorkerServiceResource";
import {cloneDeep} from "lodash";
import {RouteComponentProps} from "react-router-dom";
import {default as OverflowMenu} from "components/Menu/OverflowMenu";
import Sensitive, {ObfuscatedPlaceholder} from "components/Sensitive/Sensitive";
import {dynamicWorkerServicesRouteLinks} from "areas/dynamicWorkerServices/dynamicWorkerServicesRouteLinks";
import { CreateUpdateDynamicWorkerServiceResource } from "client/resources/createUpdateDynamicWorkerServiceResource";
import { createEmptySensitiveValue } from "shared/sensitiveValueHelper";
import buildDynamicWorkerServiceBreadcrumbTitle from "./buildDynamicWorkerServiceBreadcrumbTitle";
type Props = RouteComponentProps<{ id: string }>;

interface State extends OptionalFormBaseComponentState<CreateUpdateDynamicWorkerServiceResource> {
    redirectTo?: string;
}

export class EditDynamicWorkerService extends OptionalFormBaseComponent<Props, State, CreateUpdateDynamicWorkerServiceResource> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {
        const id = this.props.match.params.id;

        const model = id
            ? await repository.DynamicWorkerServices.get(id) as CreateUpdateDynamicWorkerServiceResource
            : {
                Id: "",
                Name: "",
                InstanceId: "",
                LeasingApiWebAppResourceId: "",
                Url: "",
                Username: "",
                ApiKey: createEmptySensitiveValue(),
                SeqDashboardId: "",
                SeqDashboardName: ""
            };
        
        this.setState({
            model,
            cleanModel: cloneDeep(model),
        });
    }

    render() {

        const state = this.state;

        if (state.redirectTo) {
            return <InternalRedirect to={state.redirectTo}/>;
        }

        const menuItems = [
            OverflowMenu.deleteItemDefault("dynamic worker service", () => this.delete())
        ];

        const dynamicWorkerServiceId = this.props.match.params.id; 
        const isNew = !dynamicWorkerServiceId;
        const actionName = `${isNew ? "Connect to Existing" : "Update"} Dynamic Worker Service`;
        
        
        return <FormPaperLayout
            title={actionName}
            breadcrumbTitle={isNew ? dynamicWorkerServiceId : buildDynamicWorkerServiceBreadcrumbTitle(this.state.model)}
            busy={state.busy}
            errors={state.errors}
            model={state.model}
            cleanModel={state.cleanModel}
            saveButtonLabel={actionName}
            saveText="Dynamic Worker Service updated"
            saveButtonBusyLabel={"Saving"}
            expandAllOnMount={true}
            overFlowActions={menuItems}
            onSaveClick={this.handleSaveClick}
        >
            {state.model && this.getBody()}
        </FormPaperLayout>;
    }

    getBody() {
        const model = this.state.model;

        return <div>
            <ExpandableFormSection
                errorKey="Name"
                title="Name"
                summary={model.Name ? Summary.summary(model.Name) : Summary.placeholder("No name")}
                help={<span>Enter the display name for this service.</span>}>
                <Text label="Name"
                      value={model.Name}
                      onChange={Name => this.setModelState({Name})}
                      validate={required("Please enter the name to use for this dynamic worker service")}
                      error={this.getFieldError("Name")}
                      autoFocus={true}
                />
            </ExpandableFormSection>

            <ExpandableFormSection
                errorKey="InstanceId"
                title="Instance Id"
                summary={model.InstanceId ? Summary.summary(model.InstanceId) : Summary.placeholder("No instance id")}
                help={<span>Enter the Instance Id for this DW service (see 'leasingapi-{`{`}Instance Id{`}`}' in the service's Url) </span>}>
                <Text label="InstanceId"
                      value={model.InstanceId}
                      onChange={InstanceId => this.setModelState({InstanceId})}
                      validate={required("Please enter the instance id to use for this dynamic worker service")}
                      error={this.getFieldError("InstanceId")}
                      autoFocus={true}
                />
            </ExpandableFormSection>

            <ExpandableFormSection
                errorKey="LeasingApiWebAppResourceId"
                title="Leasing Api Web App Resource Id"
                summary={model.LeasingApiWebAppResourceId ? Summary.summary(model.LeasingApiWebAppResourceId) : Summary.placeholder("No Leasing Api Web App Resource Id")}
                help={<span>Enter the Resource ID for this service's LeasingApi Web App - see in 'JSON View' in Azure Portal for this web app</span>}>
                <Text label="LeasingApiWebAppResourceId"
                      value={model.LeasingApiWebAppResourceId}
                      onChange={LeasingApiWebAppResourceId => this.setModelState({LeasingApiWebAppResourceId: LeasingApiWebAppResourceId})}
                      validate={required("Please enter the resource id to use for this dynamic worker service's leasingApi web app")}
                      error={this.getFieldError("LeasingApiWebAppResourceId")}
                      autoFocus={true}
                />
            </ExpandableFormSection>

            <ExpandableFormSection
                errorKey="Url"
                title="Url"
                summary={model.Url ? Summary.summary(model.Url) : Summary.placeholder("No Url")}
                help={<span>Enter the url for this service.</span>}>
                <Text label="Url"
                      value={model.Url}
                      onChange={Url => this.setModelState({Url})}
                      validate={required("Please enter the url this service")}
                      error={this.getFieldError("Url")}
                />
            </ExpandableFormSection>

            <ExpandableFormSection
                errorKey="Username"
                title="Username"
                summary={model.Username ? Summary.summary(model.Username) : Summary.placeholder("No Username")}
                help={<span>Enter the username for this service.</span>}>
                <Text label="Username"
                      value={model.Username}
                      onChange={Username => this.setModelState({Username})}
                      validate={required("Please enter the username this service")}
                      error={this.getFieldError("Username")}
                />
            </ExpandableFormSection>

            <ExpandableFormSection
                errorKey="ApiKey"
                title="ApiKey"
                summary={model.ApiKey && model.ApiKey.HasValue ? Summary.summary(ObfuscatedPlaceholder) : Summary.placeholder("No ApiKey")}
                help={<span>Enter the api key for this service.</span>}>

                <Sensitive label="ApiKey"
                           value={model.ApiKey}
                           onChange={ApiKey => this.setModelState({ApiKey})}
                           error={this.getFieldError("ApiKey")}
                />
            </ExpandableFormSection>

            <ExpandableFormSection
                errorKey="SeqDashboardName"
                title="Seq Dashboard Name"
                summary={model.Username ? Summary.summary(model.SeqDashboardName) : Summary.placeholder("No dashboard name")}
                help={<span>Enter the Seq Dashboard Name for this service.</span>}>
                <Text label="SeqDashboardName"
                      value={model.SeqDashboardName}
                      onChange={SeqDashboardName => this.setModelState({SeqDashboardName})}
                      validate={required("Please enter the Seq Dashboard Name this service")}
                      error={this.getFieldError("SeqDashboardName")}
                />
            </ExpandableFormSection>

        </div>;
    }

    private delete = async () => {
        await repository.DynamicWorkerServices.remove(this.state.model as DynamicWorkerServiceResource);
        this.setState({redirectTo: dynamicWorkerServicesRouteLinks.root});
        return true;
    };

    private handleSaveClick = () => {
        return this.doBusyTask(async () => {
            this.state.model.Id
                ? await repository.DynamicWorkerServices.update(this.state.model as CreateUpdateDynamicWorkerServiceResource)
                : await repository.DynamicWorkerServices.create(this.state.model as CreateUpdateDynamicWorkerServiceResource);
            this.setState({redirectTo: dynamicWorkerServicesRouteLinks.root});
        });
    };
}
