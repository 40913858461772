import React from "react";
import Callout, { CalloutType } from "components/Callout";
import { upgradeStrategyTypeDescriptions, UpgradeStrategyType } from "client/resources/upgradeStrategy";
import styles = require("./style.less");

export function ChangeUpgradeStrategyCallout() {
    return <Callout type={CalloutType.Information} title="Further Action">
            If you're changing the Upgrade Strategy to <strong>{upgradeStrategyTypeDescriptions[UpgradeStrategyType.NoAutomaticUpgradeStrategy]}</strong> in order to minimize automated instance reprovisions, 
            you should also add an Explicit Instance Exclusion for this instance to the following Cloud Portal Feature Flags:
            <ul className={styles.calloutList}>
                <li><strong>Participate in Bulk Reprovisioning</strong></li>
                <li><strong>Participate in Force Apply Instance Environment Variables</strong></li>
            </ul>
        </Callout>
}