import {BackgroundTaskStatus} from "client/resources/backgroundTaskStatus";

export interface InstanceTaskLockResource {
    HostedInstanceId: string;
    LockOwnerId: string;
    LockOwnerType: string;
    LockOwnerStatus: BackgroundTaskStatus;
    Created: string;
    LockStatus: LockStatus;
}

export enum LockStatus {
    Normal = "Normal",
    OverDue = "OverDue",
    Blocked = "Blocked",
}