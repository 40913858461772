import * as React from "react";
import { Switch, BrowserRouter } from "react-router-dom";
import {LinearProgress} from "@octopusdeploy/design-system-components";
import routeLinks from "./routeLinks";
import RootLayout from "areas/root/RootLayout";
import { SessionRoute } from "./SessionRoute";
import {InteractionType, RedirectRequest} from "@azure/msal-browser";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { authTokenProvider } from "./auth-token-provider";
import {IPublicClientApplication} from "@azure/msal-browser/dist/app/IPublicClientApplication";
import PortalOctopusRoutingProvider from "components/Routes/PortalOctopusRoutingProvider";

interface AppState {
    msalInstance?: IPublicClientApplication;
    loginRequest?: RedirectRequest;
}

export default class App extends React.Component<{}, AppState> {
    constructor(props: {}) {
        super(props);
        this.state = {}
    }

    async componentDidMount() {
        const msalInstance = await authTokenProvider.getMsalInstance();
        const loginRequest = await authTokenProvider.createLoginRequest();
        this.setState({ msalInstance: msalInstance, loginRequest: loginRequest });
    }

    render() {
        if (!this.state || !this.state.msalInstance)
            return <div>
                <LinearProgress variant="indeterminate" show={true} />
                <p style={{ padding: "0 1rem" }}>Loading authentication configuration...</p>
            </div>;

        return <MsalProvider instance={this.state.msalInstance}>
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={this.state.loginRequest}>
                <BrowserRouter>
                    <PortalOctopusRoutingProvider>
                        <Switch>
                            <SessionRoute path={routeLinks.root} component={RootLayout} />
                        </Switch>
                    </PortalOctopusRoutingProvider>
                </BrowserRouter>
            </MsalAuthenticationTemplate>
        </MsalProvider>;
    }
}