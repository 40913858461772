export default class StringHelper {
    public static ellipsis = "...";

    public static capitalizeFirstLetter(value: string) {
        return value === null || typeof value === "undefined" || value.length === 0
            ? value
            : value.charAt(0).toUpperCase() + value.slice(1);
    }

    public static isNullOrWhiteSpace(value: string | null): boolean {
        return value === null || typeof value === "undefined" || value.trim().length === 0;
    }

    public static join(values: string[], separator: string) {
        return values.reduce((prev, cur) => prev + separator + cur);
    }

    public static formatDiff(diff: string) {
        if (!diff) {
            return diff;
        }
        const replaced = diff.replace(new RegExp("\r&para;", "g"), "");
        return replaced;
    }
}