// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_linkableItem__wlPge {
  cursor: pointer;
}
.style_overflowMenuColumn__fzr3d {
  padding: 0;
  vertical-align: middle;
}
`, "",{"version":3,"sources":["webpack://./app/components/SimpleDataTable/style.less"],"names":[],"mappings":"AAAA;EACI,eAAA;AACJ;AAEA;EACI,UAAA;EACA,sBAAA;AAAJ","sourcesContent":[".linkableItem {\n    cursor: pointer;\n}\n\n.overflowMenuColumn {\n    padding: 0;\n    vertical-align: middle;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"linkableItem": `style_linkableItem__wlPge`,
	"overflowMenuColumn": `style_overflowMenuColumn__fzr3d`
};
module.exports = ___CSS_LOADER_EXPORT___;
