import React from "react";
import {css, cx} from "@emotion/css";
import {FixedSizeList as List, ListChildComponentProps} from "react-window";
import {FriendlyLocalTime} from "shared/FriendlyLocalTime";
import {freshnessUnit, getHeaderColor} from "areas/dashboard/getHeaderColor";
import InternalLink from "components/Navigation/InternalLink/InternalLink";
import {instancesRouteLinks} from "areas/instances/instancesRouteLinks";
import {getAttentionIndicator} from "./getAttentionIndicator";
import {AttentionLevel} from "client/resources/attentionLevel";
import * as styles from "./styles";

export interface InstanceAlertDetails  {
    DnsPrefix: string;
    HostedInstanceId: string;
    MetricValueInPercents: number;
    AttentionLevel: AttentionLevel;
    SnoozeAlert: boolean;
    Title?: string;
}

const dnsPrefixStyle = css`
    text-overflow: ellipsis;
    overflow : hidden;
    white-space: nowrap;
    display: inline-block;
    width: 7rem;
`;

const instanceAlertContentStyle = css`
    margin-right: -1px;`
;

const windowedAlertItemStyle = css`
    position: absolute;
    left: 1em;
    right: 1em;
    height: 30px;
    width: auto;
`;

class RowRenderer extends React.PureComponent<ListChildComponentProps<InstanceAlertDetails[]>, {}> {
    render() {
        const item = this.props.data[this.props.index];
        const attentionIndicator = getAttentionIndicator(item.AttentionLevel, item.SnoozeAlert);

        const topPadding = 16;
        return (<InstanceAlertItem
            key={item.HostedInstanceId}
            top={parseFloat(this.props.style.top.toString()) + topPadding}
            icon={attentionIndicator.icon}
            color={attentionIndicator.color}
            text={this.linkToDetails(item, attentionIndicator)}
            title={item.Title}
            value={`${item.MetricValueInPercents}%`} />);
    }

    private linkToDetails(usage: { HostedInstanceId: string, DnsPrefix: string }, attentionIndicator: { color: string; icon: JSX.Element }) {
        return <InternalLink to={instancesRouteLinks.instance(usage.HostedInstanceId).root}>
            <span className={dnsPrefixStyle}>{usage.DnsPrefix}</span>
        </InternalLink>;
    }
}

export interface InstanceAlertsProps {
    header: React.ReactNode;
    lastUpdated: string;
    freshness: freshnessUnit;
    data: InstanceAlertDetails[];
}

export const InstanceAlerts = (props: InstanceAlertsProps) => {
    return <div className={styles.cardContainer}>
        <div className={styles.cardTitle} style={{backgroundColor: getHeaderColor(props.lastUpdated, props.freshness)}}>
            <div>{props.header}</div>
            <div className={styles.lastUpdated}>
                Updated: <FriendlyLocalTime time={props.lastUpdated} />
            </div>
        </div>
        <List
            className={cx(styles.cardContent, instanceAlertContentStyle)}
            height={266}
            width="auto"
            itemCount={props.data.length}
            itemSize={30}
            itemData={props.data}>
                {RowRenderer}
        </List>
    </div>;
};

interface InstanceAlertProps {
    text: React.ReactNode;
    value: number | React.ReactNode;
    icon: JSX.Element;
    color: string;
    top: number;
    title?: string;
}

const InstanceAlertItem = (props: InstanceAlertProps) => {
    return <div className={`${styles.metricItem} ${windowedAlertItemStyle}`} title={props.title} style={{top: `${props.top}px`}}>
        <div style={{color: props.color}}>{props.icon}</div>
        <div className={styles.metricTitle}>{props.text}</div>
        <div style={{color: props.color}}>{props.value}</div>
    </div>;
};
