// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_pagingControlContainer__I6eRl {
  margin: 1.5rem 1rem;
}
.style_disablePagingEvents__YJfNX {
  pointer-events: none;
}
`, "",{"version":3,"sources":["webpack://./app/components/Paging/style.less"],"names":[],"mappings":"AAAA;EACI,mBAAA;AACJ;AAEA;EACI,oBAAA;AAAJ","sourcesContent":[".pagingControlContainer {\n    margin: 1.5rem 1rem;\n}\n\n.disablePagingEvents {\n    pointer-events: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pagingControlContainer": `style_pagingControlContainer__I6eRl`,
	"disablePagingEvents": `style_disablePagingEvents__YJfNX`
};
module.exports = ___CSS_LOADER_EXPORT___;
