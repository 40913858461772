import * as React from "react";
import DataBaseComponent, {DataBaseComponentState} from "components/DataBaseComponent";
import PaperLayout from "components/PaperLayout";
import repository from "client/repository";
import SimpleDataTable from "components/SimpleDataTable";
import {FriendlyLocalTime} from "shared/FriendlyLocalTime";
import {sortBy, reduce} from "lodash";
import InternalLink from "components/Navigation/InternalLink";
import {backgroundTasksRouteLinks} from "areas/backgroundTasks/backgroundTasksRouteLinks";
import ExternalLink from "components/Navigation/ExternalLink";

interface Props {
    instanceId: string;
    breadcrumbTitle: string;
}

interface State extends DataBaseComponentState {
    records?: HostedInstanceUpgradeRecord[];
}

interface HostedInstanceUpgradeRecord {
    TaskId: string;
    Version: string;
    Upgraded: string;
    ChangesetLink?: string;
}

class DataTable extends SimpleDataTable<HostedInstanceUpgradeRecord> {
}

class InstanceUpgradeHistory extends DataBaseComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {
        await this.loadData();
    }

    render() {
        const records = this.state.records;
        return (
            <PaperLayout busy={this.state.busy} errors={this.state.errors} title={records && "Upgrade History"} breadcrumbTitle={this.props.breadcrumbTitle}>
                <DataTable
                    data={this.state.records}
                    onRow={(item: HostedInstanceUpgradeRecord) => this.buildRow(item)}
                    headerColumns={["Task ID", "Version", "Upgraded"]}
                    onEmpty={<div>No records found</div>}/>
            </PaperLayout>
        );
    }

    buildRow(item: HostedInstanceUpgradeRecord) {
        return [
            <InternalLink to={backgroundTasksRouteLinks.task(item.TaskId).root}>{item.TaskId}</InternalLink>,
            <><span>{item.Version}</span>{item.ChangesetLink && <> (<ExternalLink href={item.ChangesetLink}>Changeset</ExternalLink>)</>}</>,
            <FriendlyLocalTime time={item.Upgraded}/>
        ];
    }

    loadData = async () => {
        await this.doBusyTask(async () => {
            const records = await repository.HostedInstances.getUpgradeHistory(this.props.instanceId).then((tasks) =>
                reduce(sortBy(tasks, "Finished"), (acc, t, i, all) => {
                    if (i === 0 || all[i - 1].Version !== t.Version) {
                        acc.unshift({
                            TaskId: t.BackgroundTaskId,
                            Version: t.Version,
                            Upgraded: t.Finished,
                            ChangesetLink: t.ChangesetLink
                        });
                    }
                    return acc;
                }, [])
            );
            this.setState({ records });
        });
    }
}

export default InstanceUpgradeHistory;