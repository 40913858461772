import * as React from "react";
import BaseComponent from "components/BaseComponent";
import FormSectionHeadingWithAction from "shared/FormSectionHeadingWithAction/FormSectionHeadingWithAction";
import {
    DataTable,
    DataTableBody,
    DataTableRow,
    DataTableRowColumn,
    DataTableRowHeaderColumn
} from "components/DataTable";
import EmptyDataTableRow from "shared/EmptyDataTableRow";
import {DataBaseComponentState} from "components/DataBaseComponent";
import {HostedInstanceResource} from "client/resources/hostedInstanceResource";
import styles = require("./style.less");
import {
    ApplicableInstanceEnvironmentVariable, ApplicableInstanceEnvironmentVariableAppliedState
} from "client/repositories/instanceEnvironmentVariableRepository";
import NavigationButton, {NavigationButtonType} from "../../../components/Button/NavigationButton";
import {settingsRouteLinks} from "../../settings/settingsRouteLinks";
import cn from "classnames";
import InstanceEnvironmentVariableValue
    from "../../settings/instanceEnvironmentVariables/InstanceEnvironmentVariableValue";


interface Props extends DataBaseComponentState {
    redirectToInstanceTasks: () => Promise<boolean>;
    instanceEnvironmentVariables: ApplicableInstanceEnvironmentVariable[];
    instance: HostedInstanceResource; 
}

export class InstanceEnvironmentVariableDetails extends BaseComponent<Props, never> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return <><FormSectionHeadingWithAction
            title="Environment Variables"
            action={<NavigationButton label="Manage Environment Variables" href={settingsRouteLinks.instanceEnvironmentVariables} type={NavigationButtonType.Secondary}/>}/>
            <DataTable>
                <DataTableBody>
                    {this.variableRows()}
                </DataTableBody>
            </DataTable>
        </>;
    }

    private variableRows() {
        const instanceVars = this.props.instanceEnvironmentVariables;

        if (instanceVars.length == 0) {
            return <EmptyDataTableRow message="No variables defined"/>;
        }

        return instanceVars.map((variable, i) => {
            const {AppliedState, Name, Value} = variable;
            const overridenStyle = cn({[styles.cardSubTitleWithOverflowMenu]: AppliedState != ApplicableInstanceEnvironmentVariableAppliedState.Applied});
            const message = AppliedState == ApplicableInstanceEnvironmentVariableAppliedState.AppliedOnNextProvision ?
                "(Applied on next provision)" :
                (AppliedState == ApplicableInstanceEnvironmentVariableAppliedState.RemovedOnNextProvision ?
                    "(Removed on next provision)" :
                    "");
            
            return <DataTableRow key={i}>
                <DataTableRowHeaderColumn className={overridenStyle}>{Name}</DataTableRowHeaderColumn>
                <DataTableRowColumn className={overridenStyle}>
                    <InstanceEnvironmentVariableValue value={Value} />
                </DataTableRowColumn>
                <DataTableRowColumn><i>{message}</i></DataTableRowColumn>
            </DataTableRow>
        });
    }
}