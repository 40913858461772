import * as React from "react";
import {Switch} from "react-router-dom";
import ReloadableRoute from "components/ReloadableRoute/ReloadableRoute";
import {reefsRouteLinks} from "areas/reefs/reefsRouteLinks";
import {InternalRedirect} from "components/Navigation/InternalRedirect";
import CreateReef from "areas/reefs/CreateReef";
import {Reefs} from "areas/reefs/Reefs";
import ReefLayout from "areas/reefs/ReefLayout";

const ConfigurationLayout: React.FunctionComponent<{}> = _ =>
    <Switch>
        <ReloadableRoute path={reefsRouteLinks.create} exact={true} component={CreateReef}/>
        <ReloadableRoute path={reefsRouteLinks.root} exact={true} component={Reefs}/>
        <ReloadableRoute path={reefsRouteLinks.reef(":id").root} component={ReefLayout}/>
        <ReloadableRoute render={() => <InternalRedirect to={reefsRouteLinks.root}/>}/>
    </Switch>;

export default ConfigurationLayout;
