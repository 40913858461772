import {Checkbox} from "@octopusdeploy/design-system-components";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent";
import SaveDialogLayout from "components/DialogLayout/SaveDialogLayout";
import repository from "client/repository";
import * as React from "react";
import { HostedInstanceBulkReprovisionRequest } from "client/resources/hostedInstanceBulkReprovisionRequest";
import ReefMultiSelect from "shared/ReefMultiSelect";
import { ReefResource } from "client/resources/reefResource";
import { InstanceEnvironmentVariable } from "client/resources/instanceEnvironmentVariablesResource";
import SelectField from "components/form/Select/SelectField";
import MenuItem from "material-ui/MenuItem";
import InternalRedirect from "components/Navigation/InternalRedirect";
import {backgroundTasksRouteLinks} from "areas/backgroundTasks/backgroundTasksRouteLinks";
import Callout, {CalloutType} from "components/Callout";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {
}

interface State extends DataBaseComponentState {
    reefs: Map<string, ReefResource>;
    instanceEnvironmentVariables: InstanceEnvironmentVariable[];
    reprovisionRequest: HostedInstanceBulkReprovisionRequest;
    redirectTo?: string;
}

class BulkReprovisionHostedInstanceDialog extends DataBaseComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            reefs: new Map<string, ReefResource>(),
            instanceEnvironmentVariables: [],
            reprovisionRequest: {
                ReefIds: [],
                WithoutInstanceEnvVariableIds: [],
                ReprovisionDuringWeekends: false
            },
        };
    }

    componentDidMount() {
        return this.doBusyTask(async () => {
            const reefs = repository.Reefs.map();
            const instanceEnvironmentVariables = repository.InstanceEnvironmentVariables.getInstanceEnvironmentVariables();

            this.setState({
                reefs: await reefs,
                instanceEnvironmentVariables: await instanceEnvironmentVariables,
            });
        });
    }

    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo}/>;
        }

        return <SaveDialogLayout title={`Bulk Reprovision Hosted Instances`}
            onSaveClick={() => this.bulkReprovisionHostedInstance()}
            errors={this.state.errors}
            busy={this.state.busy}>
            <Callout type={CalloutType.Information} title="Further Action">
                Instances excluded from the <strong>Participate in Bulk Reprovisioning</strong> Portal Feature Flag will not be reprovisioned. These instances will need to be reprovisioned manually.
            </Callout>

            <Checkbox value={this.state.reprovisionRequest.ReprovisionDuringWeekends} onChange={v => this.updateReprovisionRequest({ReprovisionDuringWeekends: v})} label="Reprovision instances during weekends" />
            <p>
                If unticked, instances will be reprovisioned only on weekdays. If ticked, instances will also be reprovisioned during the weekend.
            </p>

            <ReefMultiSelect
                items={this.state.reefs ? Array.from(this.state.reefs.values()) : []}
                value={this.state.reprovisionRequest.ReefIds}
                onChange={ReefIds => this.updateReprovisionRequest({ReefIds})}
            />
            <p>
                Instances will be reprovisioned only if they are on the selected reefs. If no reef has been selected, all instances will be reprovisioned.
            </p>

            <SelectField floatingLabelText="Without Applied Environment Variable" 
                         value={this.state.reprovisionRequest.WithoutInstanceEnvVariableIds[0]}
                         onChange={(s: any, d: any, v: string) => this.updateReprovisionRequest({WithoutInstanceEnvVariableIds: [v]})}
                         fullWidth
            >
                <MenuItem value="" primaryText="" />
                {this.state.instanceEnvironmentVariables
                    .map(c => <MenuItem key={c.Name} value={c.Id} primaryText={c.Name} />)}
            </SelectField>
            <p>
                Instances will be reprovisioned only if they do not have the selected environment variable applied. If no environment variable has been selected, all instances will be reprovisioned.
            </p>
        </SaveDialogLayout>;
    }

    private updateReprovisionRequest(update: Partial<HostedInstanceBulkReprovisionRequest>) {
        return this.setState(prevState => ({reprovisionRequest: {...prevState.reprovisionRequest, ...update}}));
    }

    private async bulkReprovisionHostedInstance(): Promise<boolean> {
        return await this.doBusyTask(async () => {
            const backgroundTask = await repository.HostedInstances.bulkReprovision(this.state.reprovisionRequest);
            this.setState({redirectTo: backgroundTasksRouteLinks.task(backgroundTask.Id).root})
        });
    }
}

export default BulkReprovisionHostedInstanceDialog;
