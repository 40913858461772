// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_next__Jvta0 {
  text-align: end;
}
.style_custom-env-variable__PxRCY {
  display: flex;
}
.style_prometheus-metrics__CIfmB {
  padding-left: 1em;
}
.style_prometheus-metric__U6B64 {
  padding-bottom: 0.2em;
}
.style_support-user-description__sHvT1 {
  padding-left: 1em;
}
.style_overriden-variable__t2ZMm {
  color: darkgray;
}
.style_calloutList__Ory8L {
  padding-inline-start: 40px;
  list-style-type: disc;
}
`, "",{"version":3,"sources":["webpack://./app/areas/instances/Instances/style.less"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;AAEA;EACE,aAAA;AAAF;AAGA;EACE,iBAAA;AADF;AAIA;EACE,qBAAA;AAFF;AAKA;EACE,iBAAA;AAHF;AAMA;EACE,eAAA;AAJF;AAOA;EACE,0BAAA;EACA,qBAAA;AALF","sourcesContent":[".next {\n  text-align: end;\n}\n\n.custom-env-variable {\n  display: flex\n}\n\n.prometheus-metrics {\n  padding-left: 1em;\n}\n\n.prometheus-metric {\n  padding-bottom: 0.2em;\n}\n\n.support-user-description {\n  padding-left: 1em;\n}\n\n.overriden-variable {\n  color: darkgray;\n}\n\n.calloutList {\n  padding-inline-start: 40px;\n  list-style-type: disc;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"next": `style_next__Jvta0`,
	"custom-env-variable": `style_custom-env-variable__PxRCY`,
	"customEnvVariable": `style_custom-env-variable__PxRCY`,
	"prometheus-metrics": `style_prometheus-metrics__CIfmB`,
	"prometheusMetrics": `style_prometheus-metrics__CIfmB`,
	"prometheus-metric": `style_prometheus-metric__U6B64`,
	"prometheusMetric": `style_prometheus-metric__U6B64`,
	"support-user-description": `style_support-user-description__sHvT1`,
	"supportUserDescription": `style_support-user-description__sHvT1`,
	"overriden-variable": `style_overriden-variable__t2ZMm`,
	"overridenVariable": `style_overriden-variable__t2ZMm`,
	"calloutList": `style_calloutList__Ory8L`
};
module.exports = ___CSS_LOADER_EXPORT___;
