import React from "react";
import {BooleanRadioButton, BooleanRadioButtonGroup, Checkbox} from "@octopusdeploy/design-system-components";
import {DataBaseComponent, DataBaseComponentState, Errors} from "components/DataBaseComponent";
import repository from "client/repository";
import {BusyState} from "components/BusyFromPromise/BusyFromPromise";
import SaveDialogLayout from "components/DialogLayout/SaveDialogLayout";
import Callout, {CalloutType} from "../../../components/Callout";
import {
    HostedInstanceDatabaseMaintenanceRequest
} from "../../../client/resources/hostedInstanceDatabaseMaintenanceRequest";
import session from "../../../session";
import semver = require("semver");
import {Number} from "../../../shared/Number";

interface RunDatabaseMaintenanceDialogProps {
    errors: Errors;
    busy: BusyState;
    instanceId: string;
    version: string;
    onSaveClick(): Promise<boolean>;
}

interface RunDatabaseMaintenanceDialogState extends DataBaseComponentState {
    request: Partial<HostedInstanceDatabaseMaintenanceRequest>;
}

export class RunDatabaseMaintenanceDialog extends DataBaseComponent<RunDatabaseMaintenanceDialogProps, RunDatabaseMaintenanceDialogState> {
    constructor(props: RunDatabaseMaintenanceDialogProps) {
        super(props);
        this.state = {
            request: {
                RunDuringOutageWindow: true
            }
        };
    }

    render() {
        const request = this.state.request;

        const featureToggle = session.featureFlags.Features.find(f => f.Name == "Run regular maintenance on hosted instance database");
        const minimumServerVersion = featureToggle.AppliesTo.MinimumVersion;

        return <SaveDialogLayout title={"Run Database Maintenance"}
                                 busy={this.state.busy}
                                 errors={this.state.errors}
                                 saveButtonDisabled={this.versionDoesNotSupportsDatabaseMaintenance(minimumServerVersion)}
                                 onSaveClick={() => this.runDatabaseMaintenance(request)}>

            {this.versionDoesNotSupportsDatabaseMaintenance(minimumServerVersion) &&
                <Callout type={CalloutType.Danger} title="Version does not support database maintenance">
                    Please upgrade the hosted instance to <strong>{minimumServerVersion}</strong> or newer before trying to perform this action.
                </Callout>}
            
            <Callout type={CalloutType.Warning} title="Warning">
                Database maintenance requires extra resources. Even though we scale up the database for larger databases, the instance may be less responsive than normal. Avoid running outside of the outage window. 
            </Callout>

            <Checkbox label="Shrink Database"
                      value={request.ShrinkDatabase}
                      onChange={ShrinkDatabase => this.updateRequest({ShrinkDatabase})}                      
            />

            {request.ShrinkDatabase && <Number label="Target Size (MB)"
                    value={request.TargetSizeInMb}
                    onChange={TargetSizeInMb => this.updateRequest({TargetSizeInMb: TargetSizeInMb})}                    
            />}
            
            <BooleanRadioButtonGroup value={request.RunDuringOutageWindow} onChange={RunDuringOutageWindow => this.updateRequest({RunDuringOutageWindow})}>
                <BooleanRadioButton value={true} label="Run during Outage Window" />
                <BooleanRadioButton value={false} label="Run Immediately" />
            </BooleanRadioButtonGroup>

        </SaveDialogLayout>;
    }

    private versionDoesNotSupportsDatabaseMaintenance(minimumServerVersion: string) {
        return semver.valid(minimumServerVersion) &&
            semver.valid(this.props.version) &&           
            semver.lt(this.props.version, minimumServerVersion)
    }
    
    private updateRequest(update: Partial<HostedInstanceDatabaseMaintenanceRequest>) {
        return this.setState(prevState => ({request: {...prevState.request, ...update}}));
    }

    private runDatabaseMaintenance = async (request: Partial<HostedInstanceDatabaseMaintenanceRequest>): Promise<boolean> => {
        return this.doBusyTask(async () => {
            await repository.HostedInstances.runDatabaseMaintenance(this.props.instanceId, request as HostedInstanceDatabaseMaintenanceRequest);
            await this.props.onSaveClick();
            return true;
        });
    }
}
