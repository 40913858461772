import client from "client/client";
import {DynamicWorkerServiceResource} from "client/resources/dynamicWorkerServiceResource";
import {DynamicWorkerInstanceResource} from "client/resources/dynamicWorkerInstanceResource";
import {DynamicWorkerSourceResource} from "client/resources/dynamicWorkerSourceResource";
import {DynamicWorkerTypeResource} from "client/resources/dynamicWorkerTypeResource";
import {DynamicWorkerState} from "shared/dynamicWorkerState";
import { CreateUpdateDynamicWorkerServiceResource } from "client/resources/createUpdateDynamicWorkerServiceResource";
import {DynamicWorkerServiceRuntimeStatusResource} from "../resources/dynamicWorkerServiceRuntimeStatusResource";

export interface DynamicWorkersFilterArgs {
    states?: DynamicWorkerState[];
    snapshots?: string[];
    workerTypes?: string[];
    taskCaps?: string[];
    crowdStrikeAgentId?: string;
    shortId?: string;
}

export default class DynamicWorkerServicesRepository {
    private rootUri = "/api/dynamic-worker-services";

    list(): Promise<DynamicWorkerServiceResource[]> {
        return client.get(this.rootUri);
    }

    get(id: string): Promise<DynamicWorkerServiceResource> {
        return client.get(`${this.rootUri}/{id}`, {id});
    }

    create(resource: CreateUpdateDynamicWorkerServiceResource): Promise<DynamicWorkerServiceResource> {
        return client.post(this.rootUri, resource);
    }

    update(resource: CreateUpdateDynamicWorkerServiceResource): Promise<DynamicWorkerServiceResource> {
        return client.put(`${this.rootUri}/{id}`, resource, {id: resource.Id});
    }

    remove(resource: DynamicWorkerServiceResource) {
        return client.del(`${this.rootUri}/{id}`, {args: {id: resource.Id}});
    }

    sizes(id: string): Promise<string[]> {
        return client.get(`${this.rootUri}/{id}/sizes`, {id});
    }

    workers(id: string, args?: DynamicWorkersFilterArgs): Promise<DynamicWorkerInstanceResource[]> {
        return client.get(`${this.rootUri}/{id}/workers{?states,snapshots,workerTypes,taskCaps,crowdStrikeAgentId,shortId}`, {id, ...args});
    }

    deleteWorker(id: string, workerId: string) {
        return client.del(`${this.rootUri}/{id}/workers/{workerId}`, {args: {id, workerId}});
    }

    doNotLeaseWorker(id: string, workerId: string) {
        return client.put(`${this.rootUri}/{id}/workers/{workerId}/donotlease`, null, {id, workerId});
    }

    debugWorker(id: string, workerId: string) {
        return client.put(`${this.rootUri}/{id}/workers/{workerId}/keepfordebugging`, null, {id, workerId});
    }

    workerTypes(id: string): Promise<DynamicWorkerTypeResource[]> {
        return client.get(`${this.rootUri}/{id}/worker-types`, {id});
    }

    workerType(id: string, workerImage: string): Promise<DynamicWorkerTypeResource> {
        return client.get(`${this.rootUri}/{id}/worker-types/{workerImage}`, {id, workerImage});
    }

    createWorkerType(id: string, type: DynamicWorkerTypeResource): Promise<DynamicWorkerTypeResource> {
        return client.post(`${this.rootUri}/{id}/worker-type`, type, {id});
    }

    updateWorkerType(id: string, type: DynamicWorkerTypeResource): Promise<DynamicWorkerTypeResource> {
        return client.put(`${this.rootUri}/{id}/worker-type`, type, {id});
    }

    workerSources(id: string, typeId: string): Promise<DynamicWorkerSourceResource[]> {
        return client.get(`${this.rootUri}/{id}/sources/{typeId}`, {id, typeId});
    }

    setWorkerSourceActive(id: string, source: DynamicWorkerSourceResource): Promise<DynamicWorkerSourceResource> {
        return client.put(`${this.rootUri}/{id}/sources/active`, source, {id});
    }

    deleteWorkerSource(id: string, sourceId: string) {
        return client.del(`${this.rootUri}/{id}/sources/{sourceId}`, {args: {id, sourceId}});
    }
    
    getRuntimeStatus(id: string): Promise<DynamicWorkerServiceRuntimeStatusResource> {
        return client.get(`${this.rootUri}/{id}/runtime-status`, {id});
    }
}
