import React from "react";
import {themeTokens} from "@octopusdeploy/design-system-tokens";
import {BackgroundTaskStatus} from "client/resources/backgroundTaskStatus";
import splitStringIntoWords from "utils/StringHelper/splitStringIntoWords";

const textColor = themeTokens.color.text;

export function displayTaskStatus(status: BackgroundTaskStatus) {
    const name = splitStringIntoWords(status);
    const color = getColorFor(status);

    return <span style={{color}}>{name}</span>;
}

function getColorFor(status: BackgroundTaskStatus): string {
    switch (status) {
        case BackgroundTaskStatus.Sleeping: return textColor.warning;
        case BackgroundTaskStatus.Running: return textColor.info;
        case BackgroundTaskStatus.Failed: return textColor.danger;
        case BackgroundTaskStatus.Cancelled: return textColor.subtle;
        case BackgroundTaskStatus.Success: return textColor.success;
        default: return textColor.primary;
    }
}