import * as React from "react";
import {themeTokens} from "@octopusdeploy/design-system-tokens";
import RaisedButton from "material-ui/RaisedButton";
import FlatButton from "material-ui/FlatButton";
import BusyFromPromise from "components/BusyFromPromise";
import cn from "classnames";
import styles = require("./style.less");
import {baseSizeInPx, normal} from "fontWeights";

export interface ActionButtonProps {
    innerRef?: any;
    label: string;
    busyLabel?: string;
    disabled?: Promise<any> | boolean; // Currently unsupported for Ternary buttons
    type?: ActionButtonType;
    icon?: JSX.Element;
    iconPosition?: IconPosition;
    tabIndex?: number;
    className?: string;
    keyboardFocused?: boolean;
    style?: object;
    labelProps?: any;

    onFocus?(event: React.FocusEvent<HTMLElement>): void;
    onClick?(event: any): Promise<any> | void;
}

interface ActionButtonState {
    invocation?: Promise<any>;
}

export enum ActionButtonType {
    Primary,
    Secondary,
    Ternary,
    Save,
    Delete,
    CreateRelease, // Edge case unique to our design.
    Category,
}

export enum IconPosition {
    Left,
    Right
}

export default class ActionButton extends React.Component<ActionButtonProps, ActionButtonState> {
    constructor(props: ActionButtonProps) {
        super(props);
        this.state = {};
    }

    onClick(event: any) {
        if (!this.props.onClick) {
            return;
        }

        if (event.preventDefault) {
            event.preventDefault();
        }
        const promise = (async () => await this.props.onClick(event))();
        this.setState({invocation: promise});
        return promise;
    }

    getButton(busy: boolean, disabled: boolean) {
        const {iconPosition = IconPosition.Left, ...props } = this.props;
        const labelPosition = iconPosition === IconPosition.Left ? "after" : "before";

        const otherPropsForButtons = {
            tabIndex: props.tabIndex,
            className: cn(styles.globalActionButtonAndLinks, styles.globalActionButton, props.className),
        };
        const otherPropsForLinks = {
            tabIndex: props.tabIndex,
            className: cn(styles.globalActionButtonAndLinks, props.className),
        };
        const commonLabelStyle = {
            fontSize: "0.8125rem",
        };
        const labelStyle = {
            color: themeTokens.color.text.link.default,
            opacity: props.disabled ? 0.5 : 1,
            whiteSpace: "nowrap",
            ...commonLabelStyle,
            ...props.labelProps
        };

        const label = busy && props.busyLabel ? props.busyLabel : props.label;

        const onClick = disabled ? null : (e: any) => this.onClick(e);

        switch (props.type) {

            case ActionButtonType.Primary:
                return <RaisedButton
                    ref={this.props.innerRef}
                    type="submit"
                    label={label}
                    title={label}
                    disabled={disabled}
                    icon={props.icon}
                    labelPosition={labelPosition}
                    backgroundColor={themeTokens.color.actionButton.background.primary.default} // Primary's are the one thing that should stand out on the page.
                    labelColor={themeTokens.color.actionButton.text.primary}
                    labelStyle={commonLabelStyle}
                    disabledBackgroundColor={themeTokens.color.actionButton.background.disabled}
                    disabledLabelColor={themeTokens.color.actionButton.text.disabled}
                    onClick={onClick}
                    onFocus={props.onFocus}
                    keyboardFocused={this.props.keyboardFocused}
                    {...otherPropsForButtons}
                    children={this.props.children}
                />;

            case ActionButtonType.Category:
                return <FlatButton
                    ref={this.props.innerRef}
                    type="submit"
                    label={label}
                    title={label}
                    disabled={disabled}
                    icon={props.icon}
                    backgroundColor={"#e9edf2"}
                    labelStyle={labelStyle}
                    labelPosition={labelPosition}
                    onClick={onClick}
                    onFocus={props.onFocus}
                    keyboardFocused={this.props.keyboardFocused}
                    {...otherPropsForButtons}
                    children={this.props.children}
                />;
            case ActionButtonType.CreateRelease:
                return <RaisedButton
                    ref={this.props.innerRef}
                    type="submit"
                    keyboardFocused={this.props.keyboardFocused}
                    label={label}
                    title={label}
                    disabled={disabled}
                    disabledBackgroundColor={themeTokens.color.actionButton.background.disabled}
                    disabledLabelColor={themeTokens.color.actionButton.text.disabled}
                    backgroundColor={themeTokens.color.actionButton.background.createRelease.default}
                    labelColor={themeTokens.color.actionButton.text.primary}
                    labelStyle={commonLabelStyle}
                    labelPosition={labelPosition}
                    onClick={onClick}
                    children={this.props.children}
                />;

            case ActionButtonType.Save:
                return <RaisedButton
                    ref={this.props.innerRef}
                    type="submit"
                    keyboardFocused={this.props.keyboardFocused}
                    label={label}
                    title={label}
                    disabled={disabled}
                    disabledBackgroundColor={themeTokens.color.actionButton.background.disabled}
                    disabledLabelColor={themeTokens.color.actionButton.text.disabled}
                    backgroundColor={themeTokens.color.actionButton.background.primary.default}
                    labelColor={themeTokens.color.actionButton.text.primary}
                    labelStyle={commonLabelStyle}
                    labelPosition={labelPosition}
                    onClick={onClick}
                    children={this.props.children}
                />;

            case ActionButtonType.Delete:
                return <RaisedButton
                    ref={this.props.innerRef}
                    type="submit"
                    keyboardFocused={this.props.keyboardFocused}
                    label={label}
                    title={label}
                    disabled={disabled}
                    backgroundColor={themeTokens.color.button.background.destructive.default}
                    labelColor={themeTokens.color.button.text.destructive}
                    labelStyle={commonLabelStyle}
                    labelPosition={labelPosition}
                    onClick={onClick}
                    disabledBackgroundColor={themeTokens.color.button.background.disabled}
                    disabledLabelColor={themeTokens.color.button.text.disabled}
                    children={this.props.children}
                />;

            case ActionButtonType.Ternary:
                const contents = props.icon
                    ? <>{props.icon}{label}</>
                    : label;
                const height = 36 / baseSizeInPx + "rem";
                return <a href="#"
                          title={label}
                          onClick={onClick}
                          onFocus={props.onFocus}
                          style={{
                              height,
                              lineHeight: height,
                              marginLeft: "0.5rem",
                              marginRight: "0.5rem",
                              fontSize: "0.8125rem",
                              fontWeight: normal,
                              textTransform: "uppercase"
                            }}
                          {...otherPropsForLinks}>
                    {contents}
                </a>;

            // Fallthrough to Secondary
            default:
                const style = this.props.style ? this.props.style : {margin: "inherit"};
                // Div allows margin to be added
                return <FlatButton
                    ref={this.props.innerRef}
                    label={label}
                    title={label}
                    labelStyle={labelStyle}
                    labelPosition={labelPosition}
                    style={style}
                    icon={props.icon}
                    disabled={disabled}
                    onClick={onClick}
                    onFocus={props.onFocus}
                    keyboardFocused={this.props.keyboardFocused}
                    {...otherPropsForButtons}
                    children={this.props.children}
                />;
        }
    }

    render() {
        return <BusyFromPromise promise={this.state.invocation}>
            {(busy: boolean) => <BusyFromPromise promise={this.props.disabled}>
                {(disabled: boolean) => this.getButton(busy, disabled || busy)}
            </BusyFromPromise>}
        </BusyFromPromise>;
    }
}

export {ActionButton};