import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent";
import SaveDialogLayout from "components/DialogLayout/SaveDialogLayout";
import {Text} from "components/form";
import repository from "client/repository";
import * as React from "react";
import Callout, {CalloutType} from "components/Callout";
import {ActionButton, ActionButtonType} from "components/Button/ActionButton";
import {BulkRevokeUsersSessionsRequest} from "client/resources/bulkRevokeUsersSessionsRequest";
import {backgroundTasksRouteLinks} from "areas/backgroundTasks/backgroundTasksRouteLinks";
import InternalRedirect from "../../../components/Navigation/InternalRedirect";


interface Props {
    importedHostedInstanceIds: string[];
}

interface State extends DataBaseComponentState {
    bulkRevokeUsersSessionsRequest: BulkRevokeUsersSessionsRequest;
    hostedInstanceIdsDelimitedByLine: string;
    redirectTo?: string;
}

class BulkRevokeUsersSessionsDialog extends DataBaseComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            bulkRevokeUsersSessionsRequest: {
                hostedInstanceIds: [],
            },
            hostedInstanceIdsDelimitedByLine: "",
        };
    }

    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo}/>;
        }
        
        const bulkRevokeUsersSessionsRequest = this.state.bulkRevokeUsersSessionsRequest;
        return <SaveDialogLayout title={`Bulk Revoke Users Sessions`}
            onSaveClick={() => this.bulkRevokeUsersSessions()}
            errors={this.state.errors}
            saveButtonDisabled={!bulkRevokeUsersSessionsRequest.hostedInstanceIds.length}
            busy={this.state.busy}
            extraHeaderContent={className => (<div className={className}><strong>Specified Instances: {bulkRevokeUsersSessionsRequest.hostedInstanceIds.length}</strong></div>)}>
            <br/>
            <Callout type={CalloutType.Information} title="Import your data">
                <p>All the hosted instances displayed on the current page will be imported.</p>
                <p><strong>Note</strong>: This will override the existing ids.</p>
                <ActionButton key="Import"
                                   type={ActionButtonType.CreateRelease}
                                   label={"Import"}
                                   onClick={() => this.updateHostedInstanceIds(this.props.importedHostedInstanceIds.join("\n"))}/>
            </Callout>
            <Text label="Hosted Instance Ids, one per line"
                      value={this.state.hostedInstanceIdsDelimitedByLine}
                      onChange={ids => this.updateHostedInstanceIds(ids)}
                      multiLine={true}
                      hintText={"Ids, e.g. HostedInstances-1"}
            />
        </SaveDialogLayout>;
    }

    private updateHostedInstanceIds(idsDelimitedByLine: string) {
        return this.setState(prevState => ({
            hostedInstanceIdsDelimitedByLine: idsDelimitedByLine,
            bulkRevokeUsersSessionsRequest: { ...prevState.bulkRevokeUsersSessionsRequest, hostedInstanceIds: idsDelimitedByLine.split("\n").filter(id => id.trim() !== "") },
          }));
    }

    private async bulkRevokeUsersSessions(): Promise<boolean> {
        return await this.doBusyTask(async () => {
            const backgroundTask = await repository.HostedInstances.bulkRevokeUsersSessions({...this.state.bulkRevokeUsersSessionsRequest});
            this.setState({redirectTo: backgroundTasksRouteLinks.task(backgroundTask.Id).root})
        });
    }
}

export default BulkRevokeUsersSessionsDialog;
