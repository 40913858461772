import React from "react";
import {BooleanRadioButton, BooleanRadioButtonGroup} from "@octopusdeploy/design-system-components";
import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent";
import repository from "client/repository";
import {HostedInstanceResource} from "client/resources/hostedInstanceResource";
import SaveDialogLayout from "components/DialogLayout/SaveDialogLayout";
import Select from "components/form/Select/Select";
import { StorageAccountResource } from "client/resources/StorageAccountResource";
import { MoveFileStorageRequest } from "client/resources/moveFileStorageRequest";
import { instancesRouteLinks } from "areas/instances/instancesRouteLinks";
import { InternalRedirect } from "components/Navigation/InternalRedirect";

interface Props {
    instance: HostedInstanceResource;
}

interface State extends DataBaseComponentState {
    storageAccounts: StorageAccountResource[];
    request: Partial<MoveFileStorageRequest>;
    redirectTo?: string;
}

export class MoveFileStorageDialog extends DataBaseComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            storageAccounts: [],
            request: {
                DestinationStorageAccountName: this.props.instance.StorageAccountName,
                MoveDuringOutageWindow: true,
            }
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const reef = await repository.Reefs.get(this.props.instance.ReefId);
            this.setState({ storageAccounts: reef.StorageAccounts.filter(a => a.IsAvailable && a.Name !== this.props.instance.StorageAccountName) });
        });
    }

    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo}/>;
        }

        const instance = this.props.instance;
        const request = this.state.request;
        return <SaveDialogLayout title={"Move File Storage"}
                                 busy={this.state.busy}
                                 errors={this.state.errors}
                                 saveButtonDisabled={instance.StorageAccountName === request.DestinationStorageAccountName}
                                 onSaveClick={() => this.moveFileStorage()}>
            <Select value={request.DestinationStorageAccountName}
                    onChange={accountName => this.updateRequest({DestinationStorageAccountName: accountName})}
                    items={this.state.storageAccounts.map(a => ({text: a.Name, value: a.Name}))}
                    fieldName="Storage Account Name"
                    hintText="The destiantion storage account to move to"
            />
            <BooleanRadioButtonGroup value={request.MoveDuringOutageWindow} onChange={moveDuringOutageWindow => this.updateRequest({MoveDuringOutageWindow: moveDuringOutageWindow})}>
                <BooleanRadioButton value={true} label="Move During Outage Window" />
                <BooleanRadioButton value={false} label="Move Immediately" />
            </BooleanRadioButtonGroup>
        </SaveDialogLayout>;
    }

    private updateRequest(update: Partial<MoveFileStorageRequest>) {
        return this.setState(prevState => ({request: {...prevState.request, ...update}}));
    }

    private moveFileStorage() {
        return this.doBusyTask(async () => {

            const instanceId = this.props.instance.Id;
            await repository.HostedInstances.moveFileStorage(instanceId, this.state.request as MoveFileStorageRequest);
            this.setState({redirectTo: instancesRouteLinks.instance(instanceId).tasks});
        });
    }
}