import Chip from "./Chip";
import * as React from "react";
import Avatar from "material-ui/Avatar";
import {primaryText, secondaryBackground} from "colors";
import InternalLink from "../Navigation/InternalLink/InternalLink";
import { LocationDescriptor } from "history";
import styles = require("./styles.less");

interface ExtraCommonProps {
    description?: string;
    backgroundColor?: string;
    markAsRemoved?: boolean;
    labelColor?: string;
    children?: any;
}

export interface CommonChipProps {
    tabIndex?: number;
    onRequestDelete?: (event: object) => void;
    fullWidth?: boolean;
    noMargin?: boolean;
    to?: LocationDescriptor;
}

interface IconStyles {
    margin: number;
    fill: string;
    width: number;
    height: number;
    backgroundColor?: string;
}

const iconStyles: IconStyles = {
    margin: 5,
    fill: primaryText,
    width: 14,
    height: 14
};

export const avatarStyles = {
    width: 24,
    height: 24,
};

const chipWithAvatar = (
        text: string,
        SvgIcon: any,
        props: CommonChipProps & ExtraCommonProps,
        customAvatar?: any,
        description?: string,
        labelColor?: string,
        customIconStyles?: IconStyles,
        fullWidth = false) => {
    const theChip = <Chip backgroundColor={secondaryBackground}
                 fullWidth={fullWidth}
                 labelColor={labelColor || primaryText}
                 description={description || text}
                 avatar={<Avatar style={customAvatar ? customAvatar : avatarStyles}
                                 size={avatarStyles.width}
            icon={<SvgIcon style={customIconStyles || iconStyles} />} />}
          {...props}>
          {text}
    </Chip>;
    if (props.to) {
        return <InternalLink to={props.to} className={styles.clickableChip}>{theChip}</InternalLink>;
    }
    return theChip;
};