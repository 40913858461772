export const preprodMonitoredShortIds = [
    "i001621", // "staging"
    "i044782", // "v2-hub-hstaging3"
    "i027202", // "main"
];
export const preprodSnoozeUptimeShortIds = [
    "i027202", //"main"
];

export const preprodSnoozeThrottledResourceTypes = [
    "DNSZONES/A",
];
