import * as React from "react";
import MaterialDialog from "material-ui/Dialog";
import {SFC} from "react";

import styles = require("./style.less");

interface DialogProps {
    open?: boolean;
    wide?: boolean;
    repositionOnUpdate?: boolean;
    onRequestClose?: () => void;
}

const Dialog: SFC<DialogProps> = props => {

    const bodyStyle = {
        padding: "0",
        color: "inherit",
        fontSize: "inherit",
        display: "flex",
        overflow: "hidden"
    };

    const contentStyle = !props.wide ? {
        width: "75%",
        maxWidth: "550px"
    } : {
        width: "75%",
        maxWidth: "75%"
    };

    return <MaterialDialog open={props.open || false}
                           bodyStyle={bodyStyle as any}
                           contentStyle={contentStyle}
                           contentClassName={styles.materialDialogContent}
                           autoDetectWindowHeight={true}
                           repositionOnUpdate={props.repositionOnUpdate}>
        {props.open && props.children}
    </MaterialDialog>;
};

Dialog.displayName = "Dialog";

export default Dialog;