import { css } from "@emotion/css";
import {colorScales, fontSize, themeTokens} from "@octopusdeploy/design-system-tokens";

export const cardContainer = css`
    margin: 1rem;
    min-width: 17.13rem;
    height: 21.875rem;
    min-height: 21.875rem;
    box-shadow: 0 0.0625rem 0.25rem 0 rgba(0, 0, 0, 0.12);
    border-radius: 0.25rem;
    overflow: hidden;
    color: ${themeTokens.color.text.primary};
    text-decoration: none;
    background: ${themeTokens.color.background.primary.default};
    transition: background-color 0.1s ease-in;
    display: flex;
    flex-direction: column;
    position: relative;
`;

export const cardTitle = css`
    color: ${colorScales.white};
    background-color: ${themeTokens.color.icon.info};
    font-size: 1.063rem;
    height: 3rem;
    min-height: 3rem;
    display: flex;
    align-items: center;
    padding:  0.25rem 1rem 0 1rem;
    flex-direction: column;
`;

export const cardContent = css`
    color: ${themeTokens.color.text.subtle};
    font-size: ${fontSize.medium};
    padding: 1rem;
    overflow-y: auto;
`;

export const lastUpdated = css`
    font-size: 0.6rem;
`;

export const metricItem = css`
    display: flex;
    align-items: center;
`;

export const metricIcon = css`
    margin-top: 2px;
    margin-bottom: -2px;
`;

export const metricTitle = css`
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    flex-grow: 1;
    text-overflow: ellipsis;
    max-width: 350px;
    white-space: nowrap;
    overflow: hidden;
`;
