import * as React from "react";
import {ActionButton} from "components/Button/ActionButton";
import {
    DialogLayoutDispatchProps,
    DialogLayout, DialogLayoutCommonProps,
} from "components/DialogLayout/DialogLayout";
import {DialogLayoutConnect} from "components/Dialog/DialogLayoutConnect";
import FormComponent from "../FormComponent/FormComponent";
import styles = require("./style.less");

interface InfoDialogLayoutProps extends DialogLayoutCommonProps {
    closeButtonLabel?: string;
}

class InfoDialogLayoutInternal extends React.Component<InfoDialogLayoutProps & DialogLayoutDispatchProps> {

    render() {
        const {
            children,
            ...other
        } = this.props;
        const close = <ActionButton label={this.props.closeButtonLabel || "Close"} disabled={this.props.busy} onClick={() => this.props.close()}/>;
        return <DialogLayout actions={close} closeDialog={this.props.close} {...other}>
            <FormComponent onFormSubmit={this.props.close} className={styles.dialogWrapper}>
                {children}
            </FormComponent>
        </DialogLayout>;
    }
}

const InfoDialogLayout = DialogLayoutConnect.to<DialogLayoutCommonProps>(InfoDialogLayoutInternal);
InfoDialogLayout.displayName = "InfoDialogLayout";
export default InfoDialogLayout;
