// Octopus prefix is used here as there is already a built-in type called Error
export class DetailedError extends Error {
    static create(statusCode: number, response: any): DetailedError {
        const e = new DetailedError(statusCode);
        const n = { ...e, ...response };

        Object.setPrototypeOf(n, DetailedError.prototype);
        return n;
    }

    FullException?: string;
    Errors?: string[];
    ParsedHelpLinks?: string[];
    HelpText?: string;
    HelpLink?: string;
    Details?: any;
    ErrorMessage: string;

    constructor(readonly StatusCode: number, message?: string) {
        super(message);
        this.ErrorMessage = message;
        this.Errors = [];

        Object.setPrototypeOf(this, DetailedError.prototype);
    }
}

export default DetailedError;
