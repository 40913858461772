import React, {useMemo} from "react";
import {
    Theme as DesignSystemTheme,
    ThemeName,
    useApplyGlobalThemeEffect
} from "@octopusdeploy/design-system-components";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import {createMuiTheme} from "./muiTheme";

interface ThemeProps {
    themeName: ThemeName;
}

const Theme = ({themeName, children}: React.PropsWithChildren<ThemeProps>) => {
    const muiTheme = useMemo(() => createMuiTheme(themeName), [themeName]);
    useApplyGlobalThemeEffect(themeName);

    return (
        <MuiThemeProvider muiTheme={muiTheme}>
            <DesignSystemTheme themeName={themeName}>
                {children}
            </DesignSystemTheme>
        </MuiThemeProvider>
    );
};

export default Theme;