import { BackgroundProcessResource } from "client/resources/backgroundProcessResource";

export function getProcessesByIds(processIds: string[], processes: BackgroundProcessResource[]): BackgroundProcessResource[] {

    let filteredProcesses: Array<BackgroundProcessResource> = [];

    if (processIds.length === 0) {
        filteredProcesses = processes;
    } else {
        filteredProcesses = processes.filter((obj) => {
            return processIds.includes(obj.Id);
        });
    }

    return filteredProcesses;
}