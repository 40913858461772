import * as React from "react";
import { Card } from "material-ui/Card";
import styles = require("./style.less");
import { FormSectionBase, FormSectionBaseProps } from "components/form/Sections/FormSectionBase";

class UnstructuredFormSection extends FormSectionBase<FormSectionBaseProps> {
    render() {
        return <Card
            className={styles.formNonExpander}
            expanded={true}>
            {this.body(styles.cardMediaNonExpander, false)}
        </Card>;
    }
}

export default UnstructuredFormSection;