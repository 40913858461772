import * as React from "react";
import {RouteComponentProps, Switch} from "react-router-dom";
import {AzureRegionResource} from "client/resources/azureRegionResource";
import repository from "client/repository";
import NavigationSidebarLayout, {Navigation} from "components/NavigationSidebarLayout";
import {azureRegionsRouteLinks} from "areas/azureRegions/azureRegionsRouteLinks";
import ReloadableRoute from "components/ReloadableRoute/ReloadableRoute";
import RelatedBackgroundTasks from "shared/RelatedBackgroundTasks";
import AzureRegionDetail from "areas/azureRegions/AzureRegionDetail";

type Props = RouteComponentProps<{ id: string }>;

interface State {
    azureRegion?: AzureRegionResource;
}

class AzureRegionLayout extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    async componentDidMount() {
        this.setState({
            azureRegion: await repository.AzureRegions.get(this.props.match.params.id)
        });
    }

    render() {
        const azureRegionId = this.props.match.params.id;
        const azureRegionRoute = azureRegionsRouteLinks.azureRegion(azureRegionId);
        const azureRegionBreadcrumbTitle = this.state.azureRegion && `${this.state.azureRegion.Region} (${azureRegionId})`
        const navLinks = [
            Navigation.navItem("Detail", azureRegionRoute.root, true),
            Navigation.navItem("Tasks", azureRegionRoute.tasks, true)
        ];

        const content = <Switch>
            <ReloadableRoute path={azureRegionRoute.root} exact={true} render={_ => <AzureRegionDetail azureRegion={this.state.azureRegion} breadcrumbTitle={azureRegionBreadcrumbTitle} />} />
            <ReloadableRoute path={azureRegionRoute.tasks} exact={true} render={_ => <RelatedBackgroundTasks documentId={azureRegionId} breadcrumbTitle={azureRegionBreadcrumbTitle} />}/>
        </Switch>;

        return this.state.azureRegion ? <NavigationSidebarLayout navLinks={navLinks}
                                        preNavbarComponent={this.state.azureRegion && azureRegionId}
                                        content={content}
                                        name={this.state.azureRegion && this.state.azureRegion.Region}/> : <></>;
    }
}

export default AzureRegionLayout;