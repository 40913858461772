import { HostedInstanceResource } from "client/resources/hostedInstanceResource";
import { AssignedVersionUpgradeStrategy, UpgradeStrategyType } from "client/resources/upgradeStrategy";

export function displayInstanceVersion(instance: HostedInstanceResource) {
    let versionSuffix = "";
    if (instance.UpgradeStrategy.UpgradeStrategyType === UpgradeStrategyType.NoAutomaticUpgradeStrategy)
        versionSuffix = "No auto upgrade";
    else if (instance.UpgradeStrategy.UpgradeStrategyType === UpgradeStrategyType.TrackBranchUpgradeStrategy)
        versionSuffix = "Tracking branch";
    else if (instance.UpgradeStrategy.UpgradeStrategyType == UpgradeStrategyType.AssignedVersionUpgradeStrategy)
        versionSuffix = (instance.UpgradeStrategy as AssignedVersionUpgradeStrategy).Version;
    else if (instance.UpgradeStrategy.UpgradeStrategyType == UpgradeStrategyType.TrackDefaultVersionUpgradeStrategy)
        versionSuffix = "Tracking default version";

    return instance.Version ? `${instance.Version} (${versionSuffix})` : "[Not Set]";
}
