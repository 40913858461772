import {BackgroundProcessResource} from "client/resources/backgroundProcessResource";
import splitStringIntoWords from "utils/StringHelper/splitStringIntoWords";

export function shortProcessName(process: BackgroundProcessResource | string) {
    const processId = typeof process === "string" ? process : process.Id;
    return splitStringIntoWords(processId.replace("BackgroundProcess", ""))
        // Ensure correct capitalisation
        .replace("aks", "AKS")
        .replace("azure", "Azure")
        .replace("Azure ad auth", "Azure AD authentication")
        .replace("better uptime", "Better Uptime")
        .replace("cloud platform", "Cloud Platform")
        .replace("cloud subscription", "Cloud subscription")
        .replace("dynamic worker", "Dynamic Worker")
        .replace("e2e", "E2E")
        .replace("engineering team", "Engineering Team")
        .replace("kubernetes", "Kubernetes")
        .replace("sql", "SQL")
        // Add hyphen to auto-scale, auto-upgrade, etc
        .replace("Auto ", "Auto-")
        .trim();
}