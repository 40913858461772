import React from "react";
import moment from "moment/moment";
import {css} from "@emotion/css";
import {fontSize, themeTokens} from "@octopusdeploy/design-system-tokens";
import { RestartResource } from "../../client/resources/restartResource";
import { HostedInstanceLookupResource } from "../../client/resources/hostedInstanceLookupResource";
import InternalLink from "../../components/Navigation/InternalLink";
import routeLinks from "../../routeLinks";
import ToolTip from "../../components/ToolTip";
import { FriendlyLocalTime } from "../../shared/FriendlyLocalTime";
import ExternalLink from "../../components/Navigation/ExternalLink";
import ExternalSystemLinks from "../../externalSystemLinks";
import * as styles from "./styles";

export interface PodRestartAlertProps {
    restartResource: RestartResource;
    hostedInstance?: HostedInstanceLookupResource;
    icon: JSX.Element;
    color: string;
}

function PodLink(props: PodRestartAlertProps) {
    const tooltipContent = <div>Namespace: {props.restartResource.Namespace}<br />Pod: {props.restartResource.PodName}</div>;

    // If this isn't Octopus Server, we don't link to anything
    if (!props.hostedInstance)
        return <ToolTip content={tooltipContent}>{props.restartResource.PodName}</ToolTip>;

    // Link to the Hosted Instance that this pod is responsible for
    return <ToolTip content={tooltipContent}>
        <InternalLink to={routeLinks.instances.instance(props.restartResource.HostedInstanceId).root}>{props.hostedInstance.DnsPrefix}</InternalLink>
    </ToolTip>
}

function PodLogsLink(props: PodRestartAlertProps) {
    // If this isn't Octopus Server, we don't link to anything
    if (!props.hostedInstance)
        return null;

    // Link to the Octopus Server logs for this pod's Hosted Instance
    return <ExternalLink href={ExternalSystemLinks.seq.instanceLogsWithTimeRange(props.hostedInstance, { to: moment(props.restartResource.Last).add(10, "seconds") })}>logs</ExternalLink>;
}

function ShortNodeName(props: PodRestartAlertProps) {
    // Node name is something like "aks-primary-123456-vmss000001"
    let nodeName = props.restartResource.NodeName;

    // If we can, just display the "vmss000001" bit, so we can easily identify if the restarts are on a single node
    const vmssMatch = nodeName.match(/-(vmss\d+)/);
    if (vmssMatch) nodeName = vmssMatch[1];

    return <ToolTip content={`Node: ${props.restartResource.NodeName}`}>{nodeName}</ToolTip>;
}

const widthLimitedMetricContentStyle = css`
    color: ${themeTokens.color.text.subtle};
    font-size: ${fontSize.medium};
    padding: .25rem;
    text-overflow: ellipsis;
    width: 140px;
    overflow: hidden;
    white-space: nowrap;
`;

export const PodRestartAlertItem = (props: PodRestartAlertProps) => {
    return <div className={styles.metricItem}>
        <div style={{ color: props.color }}>{props.icon}</div>
        <div className={widthLimitedMetricContentStyle}>
            <PodLink {...props} />
        </div>
        <div className={widthLimitedMetricContentStyle}>
            <ToolTip content={`Cluster: ${props.restartResource.ClusterName}`}>{props.restartResource.ClusterName}</ToolTip>
        </div>
        <div className={widthLimitedMetricContentStyle}>
            <ShortNodeName {...props} />
        </div>
        <div className={widthLimitedMetricContentStyle}>
            <FriendlyLocalTime time={props.restartResource.Last} />
        </div>
        <div className={styles.cardContent}>
            <ToolTip content={`Exit Code: ${props.restartResource.RestartCode}`}>{props.restartResource.RestartReason}</ToolTip>
        </div>
        <div className={styles.cardContent}>
            <PodLogsLink {...props} />
        </div>
        <div style={{ color: props.color }}>
            <ToolTip content="Restarts since last upgrade">{props.restartResource.Count}</ToolTip>
        </div>
    </div>;
};
