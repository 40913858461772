// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 37.4rem) {
  .style_hide-sm__y5yDZ {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .style_visible-sm__Rz8s6 {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .style_hide-md__OhlhV {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .style_visible-md__Gi7ef {
    display: none !important;
  }
}
.style_buttonDialogContainer__Le6Ui {
  display: inline-block;
}
.style_materialDialogContent__r9knk {
  padding: 0;
}
@media (max-width: 37.4rem) {
  .style_materialDialogContent__r9knk {
    width: 90% !important;
  }
}
`, "",{"version":3,"sources":["webpack://./app/resources/styles/media.less","webpack://./app/components/Dialog/style.less"],"names":[],"mappings":"AAMA;EACE;IACE,wBAAA;ECLF;AACF;ADQA;EACE;IACE,wBAAA;ECNF;AACF;ADSA;EACE;IACE,wBAAA;ECPF;AACF;ADSA;EACE;IACE,wBAAA;ECPF;AACF;AAjBA;EACE,qBAAA;AAmBF;AAfA;EACE,UAAA;AAiBF;AAhBE;EAAA;IAEE,qBAAA;EAkBF;AACF","sourcesContent":["//@screen-xs: 480px;\n@screen-sm: 37.4rem; //600px\n@screen-md: 79.9rem; //1279px\n//@screen-lg-min: 1280px;\n//@scree-xl-min: 1920px;\n\n@media (max-width: @screen-sm) {\n  .hide-sm {\n    display: none !important;\n  }\n}\n\n@media (min-width: @screen-sm) {\n  .visible-sm {\n    display: none !important;\n  }\n}\n\n@media (max-width: @screen-md) {\n  .hide-md {\n    display: none !important;\n  }\n}\n@media (min-width: @screen-md) {\n  .visible-md {\n    display: none !important;\n  }\n}","@import \"../../resources/styles/media\";\n\n.buttonDialogContainer {\n  display: inline-block;\n}\n\n// Hide the MaterialUI dialog items\n.materialDialogContent {\n  padding: 0;\n  @media (max-width: @screen-sm) {\n    // Need more width @ low res.\n    width:90% !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hide-sm": `style_hide-sm__y5yDZ`,
	"hideSm": `style_hide-sm__y5yDZ`,
	"visible-sm": `style_visible-sm__Rz8s6`,
	"visibleSm": `style_visible-sm__Rz8s6`,
	"hide-md": `style_hide-md__OhlhV`,
	"hideMd": `style_hide-md__OhlhV`,
	"visible-md": `style_visible-md__Gi7ef`,
	"visibleMd": `style_visible-md__Gi7ef`,
	"buttonDialogContainer": `style_buttonDialogContainer__Le6Ui`,
	"materialDialogContent": `style_materialDialogContent__r9knk`
};
module.exports = ___CSS_LOADER_EXPORT___;
