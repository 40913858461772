import * as React from "react";
import routeLinks from "routeLinks";
import PaperLayout from "components/PaperLayout/PaperLayout";
import SimpleDataTable from "components/SimpleDataTable/SimpleDataTable";
import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent";
import repository from "client/repository";
import TaskSnackbar from "shared/TaskSnackbar";
import {Refresh} from "components/DataBaseComponent/DataBaseComponent";
import ActionList from "components/ActionList";
import ActionButton, {NavigationButton, NavigationButtonType} from "components/Button";
import {AzureRegionResource} from "client/resources/azureRegionResource";
import OverflowMenu from "../../components/Menu/OverflowMenu";
import SaveDialogLayout from "../../components/DialogLayout/SaveDialogLayout";
import Callout, {CalloutType} from "../../components/Callout";

interface State extends DataBaseComponentState {
    azureRegions?: AzureRegionResource[];
    openSnackBar: boolean;
    environment?: string;
}

class AzureRegionsDataTable extends SimpleDataTable<AzureRegionResource> {
}

export class AzureRegions extends DataBaseComponent<{}, State> {
    constructor(props: {}) {
        super(props);
        this.state = {
            openSnackBar: false
        };
    }

    componentDidMount() {
        return this.doBusyTask(async () => {
            this.doRefresh = await this.startRefreshLoop(() => this.loadData(), 5000);
            this.setState({ environment: (await repository.Configuration.getAppInfo()).Environment });
        });
    }

    render() {
        return <PaperLayout title="Azure Regions"
                            busy={this.state.busy}
                            errors={this.state.errors}
                            sectionControl={<ActionList actions={[
                                <ActionButton label="Refresh" onClick={this.doRefresh}/>,
                                <NavigationButton label="New Azure Region" href={routeLinks.azureRegions.create} type={NavigationButtonType.Primary}/>
                                ]
                            }/>}
                            fullWidth={true}>
            <TaskSnackbar
                getLink={() => routeLinks.backgroundTasks.root}
                onRequestClose={() => this.setState({openSnackBar: false})}
                open={this.state.openSnackBar} />
            {this.state.azureRegions && <AzureRegionsDataTable
                data={this.state.azureRegions}
                onRow={this.buildRow}
                headerColumns={["ID", "Azure Region", "Portal Instance", "Status"]}
                onRowRedirectUrl={r => routeLinks.azureRegions.azureRegion(r.Id).root}
                onEmpty={<div>No Azure Regions found</div>}/>}
        </PaperLayout>;
    }

    private async loadData() {
        return {
            azureRegions: await repository.AzureRegions.list()
        };
    }

    private doRefresh: Refresh = () => Promise.resolve();

    private buildRow = (azureRegion: AzureRegionResource) => {
        return [
            azureRegion.Id,
            azureRegion.Region,
            azureRegion.PortalInstanceName,
            azureRegion.Status
        ];
    };
}
