import * as React from "react";
import FormFieldProps from "../components/form/FormFieldProps";
import {Text} from "components/form";

export interface Props extends FormFieldProps<number> {
    label?: string | JSX.Element;
    min?: number;
    max?: number;
    error?: string;
    validate?(value: string): string;
}

export class Number extends React.Component<Props, {}> {

    constructor(props: Props) {
        super(props);
        this.state = {
            error: null,
        };
    }

    render() {
        return <Text label={this.props.label}
              value={this.props.value && this.props.value.toString()}
              type="number"
              min={this.props.min}
              max={this.props.max}
              onChange={(value: string) => this.props.onChange(parseInt(value, 10))}
              validate={this.props.validate}
              error={this.props.error}
        />;
    }
}