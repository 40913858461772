// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* This file is DEPRECATED
   We are moving to the Octopus Design System
   https://www.octopus.design
  
   When changing component styles:
   1. Do not use variables from this file
   2. Aim to use Emotion \`css\` + themeTokens.colors
       - see Dashboard.tsx for example
   3. If you need to use Less, reference CSS variables
       - see index.ejs for example
       - for full list see https://github.com/OctopusDeploy/OctopusDeploy/blob/main/frontend/packages/design-system-tokens/src/generated/themeTokens.ts
   4. Remove variables from this file when they are no longer used
 */
.style_container__b0L7q {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-items: right;
  height: 2.6rem;
}
.style_label__U5RmM {
  white-space: nowrap;
  align-self: end;
  padding-right: 1rem;
  font-size: 1rem;
}
.style_value__a1eN5 {
  align-self: end;
  text-align: center;
  padding-left: 1rem;
  width: 2rem;
}
`, "",{"version":3,"sources":["webpack://./app/components/form/Slider/style.less"],"names":[],"mappings":"AAAA;;;;;;;;;;;;EAYE;AAVF;EACE,aAAA;EACA,WAAA;EACA,mBAAA;EACA,oBAAA;EACA,cAAA;AAYF;AATA;EACE,mBAAA;EACA,eAAA;EACA,mBAAA;EACA,eAAA;AAWF;AARA;EACE,eAAA;EACA,kBAAA;EACA,kBAAA;EACA,WAAA;AAUF","sourcesContent":["@import \"../../../resources/styles/colors\";\n\n.container {\n  display: flex;\n  width: 100%;\n  flex-direction: row;\n  justify-items: right;\n  height: 2.6rem;\n}\n\n.label {\n  white-space: nowrap;\n  align-self: end;\n  padding-right: 1rem;\n  font-size: 1rem;\n}\n\n.value {\n  align-self: end;\n  text-align: center;\n  padding-left: 1rem;\n  width: 2rem\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `style_container__b0L7q`,
	"label": `style_label__U5RmM`,
	"value": `style_value__a1eN5`
};
module.exports = ___CSS_LOADER_EXPORT___;
