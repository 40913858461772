import * as React from "react";
import IconButton from "material-ui/IconButton";
import CloseIcon from "material-ui/svg-icons/content/clear";

interface CloseButtonProps {
    onClose?: () => void;
    tooltip?: string;
    size?: string;
}

const CloseButton: React.SFC<CloseButtonProps> = (props) => {

    const style = {verticalAlign: "middle", padding: "0"} as any;
    if (props.size) {
        style.width = props.size;
        style.height = props.size;
    }

    return <IconButton style={style} onClick={props.onClose} tooltip={props.tooltip}>
        <CloseIcon />
    </IconButton>;
};

CloseButton.displayName = "CloseButton";

export default CloseButton;