import * as React from "react";
import {FormBaseComponent, OptionalFormBaseComponentState} from "components/form/FormBaseComponent";
import FormPaperLayout from "components/FormPaperLayout/FormPaperLayout";
import {InternalRedirect} from "components/Navigation/InternalRedirect";
import routeLinks from "routeLinks";
import Select from "components/form/Select/Select";
import Summary from "components/form/Sections/Summary";
import {Text} from "components/form";
import ExpandableFormSection from "components/form/Sections/ExpandableFormSection";
import repository from "client/repository";
import {
    TrackBranchUpgradeStrategy,
    UpgradeStrategyType,
    upgradeStrategyTypeDescriptions
} from "../../client/resources/upgradeStrategy";
import { ChangeUpgradeStrategyCallout } from "../instances/Instances/ChangeUpgradeStrategyCallout";
import {BulkChangeUpgradeStrategyRequest} from "../../client/resources/bulkChangeUpgradeStrategyRequest";

interface State extends OptionalFormBaseComponentState<Partial<BulkChangeUpgradeStrategyRequest>> {
    model: Partial<BulkChangeUpgradeStrategyRequest>;
    cleanModel: Partial<BulkChangeUpgradeStrategyRequest>;
    redirectTo?: string;
}

class BulkChangeUpgradeStrategy extends FormBaseComponent<{}, State, Partial<BulkChangeUpgradeStrategyRequest>> {
    constructor(props: {}) {
        super(props);
        this.state = {
            model: {
                Ids: []
            },
            cleanModel: {
                Ids: []
            },
        };
    }

    async componentDidMount() {
        return await this.doBusyTask(async () => {
            this.setState({});
        });
    }

    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo}/>;
        }

        return <FormPaperLayout
            title="Bulk Change Upgrade Strategy"
            busy={this.state.busy}
            errors={this.state.errors}
            model={this.state.model}
            cleanModel={this.state.cleanModel}
            onSaveClick={() => this.handleSaveClick()}
            saveButtonBusyLabel={"Changing"}
            saveButtonLabel={"Change"}
            overFlowActions={null}
            expandAllOnMount={true}>
            {this.getBody()}
        </FormPaperLayout>;
    }

    getBody() {
        const model = this.state.model;

        return <div>
            <ChangeUpgradeStrategyCallout />

            <ExpandableFormSection
                errorKey="UpgradeStrategy"
                title="Upgrade Strategy"
                summary={Summary.summary("Upgrade Strategy")}
                help={<span>Upgrade Strategy</span>}>
                <Select value={this.state.model.UpgradeStrategy && this.state.model.UpgradeStrategy.UpgradeStrategyType}
                        onChange={(upgradeStrategyType: UpgradeStrategyType) => this.setModelState({UpgradeStrategy: {UpgradeStrategyType: upgradeStrategyType}})}
                        items={Object.keys(UpgradeStrategyType).map(t => ({value: t, text: upgradeStrategyTypeDescriptions[t as UpgradeStrategyType]}))}
                        fieldName={"upgrade strategy"}
                />
            </ExpandableFormSection>
            {this.state.model.UpgradeStrategy && this.state.model.UpgradeStrategy.UpgradeStrategyType === UpgradeStrategyType.TrackBranchUpgradeStrategy &&
                <ExpandableFormSection
                    errorKey="Branch"
                    title="Branch"
                    summary={Summary.summary("Branch")}
                    help={<span>Branch</span>}>
                    <Text label="Branch"
                          hintText="Branch to track, eg refs/heads/main"
                          value={(this.state.model.UpgradeStrategy as TrackBranchUpgradeStrategy).BranchName}
                          onChange={(branchName: string) => this.setModelState({
                              UpgradeStrategy: {
                                  UpgradeStrategyType: UpgradeStrategyType.TrackBranchUpgradeStrategy,
                                  BranchName: branchName
                              }
                          })}
                          autoFocus={true}
                    />
                </ExpandableFormSection>
            }
            <ExpandableFormSection
                errorKey="Ids"
                summary={Summary.summary("Ids")}
                title="Ids"
                help={<span>Ids</span>}>
                <Text label="Ids, one per line"
                      value={this.state.model.Ids && this.state.model.Ids.join("\n")}
                      onChange={(ids: any) => this.setModelState({ Ids: ids.split("\n") })}
                      multiLine={true}
                      hintText={"Ids, e.g. HostedInstances-1"}
                />
            </ExpandableFormSection>
        </div>;
    }

    private handleSaveClick() {
        return this.doBusyTask(async () => {
            await repository.HostedInstances.bulkChangeUpgradeStrategy(this.state.model as BulkChangeUpgradeStrategyRequest);
            this.setState({redirectTo: routeLinks.instances.root});
        });
    }
}

export default BulkChangeUpgradeStrategy;
