import {DataBaseComponent, DataBaseComponentState} from "../../../components/DataBaseComponent";
import DeleteDialogLayout from "../../../components/DialogLayout/DeleteDialogLayout";
import * as React from "react";
import {
    InstanceEnvironmentVariable
} from "../../../client/resources/instanceEnvironmentVariablesResource";

interface Props {
    environmentVariable: InstanceEnvironmentVariable
    delete(): Promise<void>,
}

export class DeleteInstanceEnvironmentVariableDialog extends DataBaseComponent<Props, DataBaseComponentState> {

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    render() {
        return <DeleteDialogLayout title={`Are you sure you want to delete "${this.props.environmentVariable.Name}"?`}
                                   busy={this.state.busy}
                                   errors={this.state.errors}
                                   onDeleteClick={() => this.delete()}>
            Deleting this variable will mean that any instance that previously had this value set will no longer have
            this value when it's re-provisioned next.
            <p><strong>Note: Deleting the variable will not reprovision the instance automatically.</strong> You can use Bulk Reprovision from the Instances Tab in order to reprovision the instances in their next outage window. See <a href={"https://github.com/OctopusDeploy/hosted-docs/blob/master/portal/configure-environment-variables.md"}>Configure Environment Variables</a> for additional details on removing environment variables.</p>            
        </DeleteDialogLayout>
    }

    private delete = async (): Promise<boolean> => { 
        return this.doBusyTask(async () =>
            await this.props.delete()
        );
    }
}