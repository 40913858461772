import React from "react";
import {BooleanRadioButton, BooleanRadioButtonGroup} from "@octopusdeploy/design-system-components";
import {DataBaseComponent, DataBaseComponentState, Errors} from "components/DataBaseComponent";
import repository from "client/repository";
import {BusyState} from "components/BusyFromPromise/BusyFromPromise";
import {HostedInstanceResource} from "client/resources/hostedInstanceResource";
import {ReefResource} from "client/resources/reefResource";
import SaveDialogLayout from "components/DialogLayout/SaveDialogLayout";
import {Text} from "components/form";
import {HostedInstanceDowngradeRequest} from "../../../client/resources/hostedInstanceDowngradeRequest";
import Callout, {CalloutType} from "../../../components/Callout";
import semver = require("semver");

interface DowngradeDialogProps {
    errors: Errors;
    busy: BusyState;
    instance: HostedInstanceResource;
    reef: ReefResource;
    onSaveClick(): Promise<boolean>;
}

interface DowngradeDialogState extends DataBaseComponentState {
    downgradeRequest: Partial<HostedInstanceDowngradeRequest>;
}

export class DowngradeDialog extends DataBaseComponent<DowngradeDialogProps, DowngradeDialogState> {
    constructor(props: DowngradeDialogProps) {
        super(props);
        this.state = {
            downgradeRequest: {
                DowngradeDuringOutageWindow: true
            }
        };
    }

    render() {
        const request = this.state.downgradeRequest;
        return <SaveDialogLayout title={"Downgrade"}
                                 busy={this.state.busy}
                                 errors={this.state.errors}
                                 saveButtonDisabled={this.versionSelectionDoesNotResultInDowngrade()}
                                 onSaveClick={() => this.downgrade(request)}>

            {this.versionSelectionDoesNotResultInDowngrade() &&
                <Callout type={CalloutType.Danger} title="Version must be lower than the current value">
                    Please use the Upgrade dialog if you wish to upgrade or reprovision with the same Octopus Server version.
                </Callout>}

            <Callout type={CalloutType.Warning} title="Downgrade Warning">
                As a general rule, Octous Server instance can not be downgraded safely. If you know what you are doing then go ahead but you have been warned!
            </Callout>

            <BooleanRadioButtonGroup value={request.DowngradeDuringOutageWindow} onChange={DowngradeDuringOutageWindow => this.updateDowngradeRequest({DowngradeDuringOutageWindow})}>
                <BooleanRadioButton value={true} label="Downgrade During Outage Window" />
                <BooleanRadioButton value={false} label="Downgrade Immediately" />
            </BooleanRadioButtonGroup>

            <Text label="Version"
                  hintText="New version for this hosted instance"
                  value={request.Version}
                  onChange={Version => this.updateDowngradeRequest({Version})}
                  autoFocus={true}
            />

        </SaveDialogLayout>;
    }

    private versionSelectionDoesNotResultInDowngrade() {
        return semver.valid(this.props.instance.Version) &&
        semver.valid(this.state.downgradeRequest.Version) &&
        !semver.lt(this.state.downgradeRequest.Version, this.props.instance.Version)
    }
    
    private updateDowngradeRequest(update: Partial<HostedInstanceDowngradeRequest>) {
        return this.setState(prevState => ({downgradeRequest: {...prevState.downgradeRequest, ...update}}));
    }

    private downgrade(request: Partial<HostedInstanceDowngradeRequest>) {
        return this.doBusyTask(async () => {
            await repository.HostedInstances.downgrade(this.props.instance, request as HostedInstanceDowngradeRequest);
            await this.props.onSaveClick();
            return true;
        });
    }
}
