import * as React from "react";
import DataBaseComponent, {DataBaseComponentState} from "components/DataBaseComponent";
import PaperLayout from "components/PaperLayout";
import repository from "client/repository";
import { ReefResource } from "client/resources/reefResource";
import DynamicWorkersDataTable from "areas/dynamicWorkerServices/Workers/DynamicWorkersDataTable";

interface Props {
    instanceId: string;
    breadcrumbTitle: string;
}

interface State extends DataBaseComponentState {
    reef?: ReefResource;
    shortId: string;
}

class InstanceWorkers extends DataBaseComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { shortId: null };
    }

    async componentDidMount() {
        await this.loadData();
    }

    render() {
        if (!this.state.reef) return null;
        return (
            <PaperLayout busy={this.state.busy} errors={this.state.errors} title={"Leased Workers"} breadcrumbTitle={this.props.breadcrumbTitle}>
                <DynamicWorkersDataTable
                    id={this.state.reef.DynamicWorkerServiceId}
                    filter={{shortId: this.state.shortId}}
                    doBusyTask={this.doBusyTask}
                />
            </PaperLayout>
        );
    }

    async loadData() {
        const instance = await repository.HostedInstances.get(this.props.instanceId);
        const reef = await repository.Reefs.get(instance.ReefId);
        this.setState({reef, shortId: instance.ShortId});
    }
}

export default InstanceWorkers;